export default theme => ({
  warning: {
    backgroundColor: theme.palette.signals.warning,
  },
  danger: {
    backgroundColor: theme.palette.signals.danger,
  },
  success: {
    backgroundColor: theme.palette.signals.success,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  fallback: {
    border: `1px solid ${theme.palette.signals.fallback}`,
  },
  status: {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    boxSizing: 'border-box',
    borderRadius: '100%',
  },
})
