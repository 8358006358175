export default [
  {
    name: 'First layer',
    id: 'first-layer',
    checked: false,
  },
  {
    name: 'Second layer',
    id: 'second-layer',
    checked: false,
  },
  {
    name: 'Third layer',
    id: 'third-layer',
    checked: false,
  },
];
