import React, { forwardRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const props = {
  display: 'flex',
  justifyContent: 'space-between',
  width: 1,
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Modal = ({ open, handleClose, handleSave, children }) => {
  const save = () => handleSave()
  const close = () => handleClose()
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={false}
      style={{ padding: 0 }}
    >
      <DialogTitle style={{ padding: 0 }} id="alert-dialog-slide-title">
        <Box width={700} py={2} px={4}>
          <Stepper activeStep={0} alternativeLabel>
            <Step>
              <StepLabel>{t('common:concentration')}</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          width={700}
          height={500}
          py={2}
          px={4}
        >
          {children}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: 0 }}>
        <Box display="flex" width={700} py={2} px={4}>
          <Box {...props}>
            <Button
              key="back"
              type="button"
              variant="outlined"
              color="primary"
              onClick={close}
            >
              {t('common:close')}
            </Button>

            <Button
              key="submit"
              type="button"
              variant="contained"
              color="primary"
              onClick={save}
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
