import React, { memo } from 'react'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import get from 'lodash/get'
import { format } from 'date-fns'

const TableListRowCell = memo(
  ({
    index,
    row,
    id,
    field: name,
    component: Component,
    options = {},
    formatter,
    isNumber = false,
    decimals,
    prefix: Prefix,
    suffix: Suffix,
    cellProps = {},
    shared = {},
  }) => {
    const field = get(row, name)
    const checkpoint = () => {
      if (formatter) {
        return format(field, formatter)
      } else if (isNumber) {
        const fieldAsNumber = Number(field)
        const formattedNumber = decimals
          ? fieldAsNumber.toFixed(decimals)
          : fieldAsNumber
        return field ? formattedNumber : ''
      } else {
        return field
      }
    }
    const output = checkpoint()
    const isCustom = !!Component
    const hasPrefix = !!Prefix
    const hasSuffix = !!Suffix
    return (
      <TableCell key={id} {...options}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          {...cellProps}
        >
          {hasPrefix && <Prefix {...row} listIndex={index} />}
          {isCustom ? (
            <Component {...row} {...shared} listIndex={index} />
          ) : (
            output
          )}
          {hasSuffix && <Suffix {...row} listIndex={index} />}
        </Box>
      </TableCell>
    )
  }
)

export default TableListRowCell
