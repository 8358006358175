import { useEffect } from 'react'
import pickableObjectState from 'containers/Dilution/partials/View/states/pickableObject'
import {
  useRecoilState,
  useRecoilValue,
  selector,
  useResetRecoilState,
} from 'recoil'

import { getTimeseries } from 'data/common'
import { merge } from 'lodash'

import { interpolateCool } from 'd3-scale-chromatic'

const chartSelector = selector({
  key: 'chartSelector',
  get: ({ get }) => {
    const locations = get(pickableObjectState)
    let keys = []
    let data = []
    locations
      .filter((item) => item.data)
      .forEach((item) => {
        keys.push({
          name: `latlon-${item.idx}`,
          color: interpolateCool(item.idx / 4),
        })
        data = merge(data, item.data)
      })
    return { keys, data }
  },
})

const getColor = (idx) => {
  return interpolateCool(idx / 4)
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((x) => parseInt(x))
}

const useChart = (selectedItem) => {
  const [pickableLocation, setPickableLocation] = useRecoilState(
    pickableObjectState
  )
  const resetChart = useResetRecoilState(pickableObjectState)

  const {
    mapTimeSeriesConnectionId: connectionId,
    mapTimeSeriesItemName,
    path,
    baseURL,
  } = selectedItem
  const chartdata = useRecoilValue(chartSelector)

  const fetchTimeseries = async ({ lat, lon, idx }) => {
    let itemName = `Item=${mapTimeSeriesItemName};Longitude=${lon};Latitude=${lat}`
    getTimeseries({
      connectionId,
      path: `FilePath=${path}`,
      itemNumber: itemName,
      baseURL,
    }).then((response) => {
      const { dateTimes = [], values = [] } = response
      const data = dateTimes.map((date, index) => ({
        time: date,
        [`latlon-${idx}`]: values[index],
      }))
      setPickableLocation((oldstate) =>
        oldstate.map((item) => {
          if (item.idx === idx) {
            return { ...item, color: getColor(idx), data: data }
          } else {
            return item
          }
        })
      )
    })
  }
  useEffect(() => {
    setPickableLocation([])
  }, [selectedItem])

  useEffect(() => {
    pickableLocation
      .filter((location) => !location.data)
      .forEach((location) => fetchTimeseries(location))
  }, [pickableLocation])

  return { chartdata, resetChart }
}

export default useChart
