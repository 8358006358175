import React, { Suspense, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { Switch } from 'react-router-dom'
import Box from '@material-ui/core/Box'

import Loader from 'components/Loader'

import List from './partials/List'
import Form from './partials/Form'
import Viewer from './partials/Viewer'
import Projects from './partials/Projects'

import Route from 'components/Route'

import modelsState from 'containers/Dilution/states/models'
import projectsState from 'containers/Dilution/states/projects'

import useModels from 'containers/Dilution/hooks/useModels'
import useProjectsByUsername from 'containers/Dilution/hooks/useProjectsByUsername'

import { TOOLS, HOSTS } from 'config/constants'

const { DILUTION: HOST } = HOSTS
const { DILUTION: PATH } = TOOLS

const Dilution = ({ isUser, isAdmin }) => {
  const roles = { isUser, isAdmin }
  const setModels = useSetRecoilState(modelsState)
  const setProjects = useSetRecoilState(projectsState)
  const { models, isLoading: isLoadingModels } = useModels()
  const { projects, isLoading: isLoadingProjects } = useProjectsByUsername()

  const isLoading = isLoadingModels || isLoadingProjects

  useEffect(() => {
    if (!isLoading) {
      if (!!models.length) {
        setModels(models)
      }
      if (!!projects.length) {
        setProjects(projects)
      }
    }
  }, [models, projects, isLoading])

  useEffect(() => {
    return () => setProjects([])
  }, [])

  if (isLoading) return null

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Suspense fallback={<Loader isLoading isMajorLoading />}>
        <Switch>
          <Route path="/dilution/form/:id?" component={Form} {...roles} />
          <Route
            path="/dilution/view/:id?"
            component={Viewer}
            apiInfo={{ path: PATH, baseURL: HOST }}
            {...roles}
          />
          <Route path="/dilution/projects" component={Projects} {...roles} />
          <Route path="/dilution/:id?" component={List} isExact {...roles} />
        </Switch>
      </Suspense>
    </Box>
  )
}

export default Dilution
