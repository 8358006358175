import React, { Suspense } from 'react'
import { ConfirmProvider } from 'material-ui-confirm'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { RecoilRoot } from 'recoil'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { initialize } from '@dhi-solutions/domain-services'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'

import Loader from 'components/Loader'
import AppShell from 'components/AppShell'

import * as serviceWorker from './serviceWorker'

import DHITheme from 'styles/theme'

import i18n from './intl/index'

import './index.css'

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   })
// }

const App = () => (
  <RecoilRoot>
    <Router>
      <Suspense fallback={<Loader isLoading isMajorLoading />}>
        <I18nextProvider i18n={i18n}>
          <MuiThemeProvider theme={DHITheme}>
            <CssBaseline />
            <ConfirmProvider
              defaultOptions={{
                confirmationText: 'Confirm',
                cancellationText: 'Cancel',
                confirmationButtonProps: {
                  variant: 'contained',
                  color: 'primary',
                },
                cancellationButtonProps: {
                  variant: 'contained',
                  color: 'secondary',
                },
              }}
            >
              <SnackbarProvider preventDuplicate>
                <AppShell />
              </SnackbarProvider>
            </ConfirmProvider>
          </MuiThemeProvider>
        </I18nextProvider>
      </Suspense>
    </Router>
  </RecoilRoot>
)

const start = async () => {
  try {
    // Initialize returns the options if needed in your application
    const options = await initialize({
      baseURL: 'https://me-prod-brain.westeurope.cloudapp.azure.com/api',
      localStorageKey: 'DHI_ME',
    })
    return ReactDOM.render(
      <App options={options} />,
      document.getElementById('root')
    )
  } catch (error) {
    console.error(error)
  }
}

start()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
