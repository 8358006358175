import React from 'react'

import Modal from '../Modal'
import Block from 'components/Block'
import DropdownSearch from 'components/DropdownSearch'
import { Box, Chip, Typography } from '@material-ui/core'
import outletTypes from './types';

const EditSelectedOutfalls = ({
  open,
  handleSave,
  handleClose,
  translations,
  enabledOutlets,
}) => {
  const [type, setType] = React.useState(undefined);
  const handleOnSaveAndClose = () => handleSave(type)

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSave={handleOnSaveAndClose}
    >
      <Box display="flex" flexDirection="column">
        <Box mb={2} textAlign="center">
          <Typography variant="h6">
            {translations("common:editOutfallsDialogTitle")}:
          </Typography>
        </Box>
        <Box
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
          display="flex"
          flexWrap="wrap"
          maxHeight="200px"
          border="1px solid #ccc"
          bgcolor="#fafafa"
          p={1}
        >
          {enabledOutlets.map((outlet) => {
            const { source } = outlet

            return (
              <Box mr={1} mb={1}>
                <Chip
                  label={source.label}
                  style={{ backgroundColor: '#fff' }}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box display="flex" alignItems="end" mt={2} border="1px solid #ccc" p={1}>
        <Block headline={translations("common:editOutfallsDialogDropdownLabelTitle")} px={1}>
          <DropdownSearch
            id="project"
            value={type}
            options={outletTypes}
            placeholder={translations('common:editOutfallsDialogDropdownLabel')}
            onChange={(selectedType) => setType(selectedType)}
            getOptionLabel={(option) => option.label}
          />
        </Block>
      </Box>
    </Modal>
  )
}

export default EditSelectedOutfalls;