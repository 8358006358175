import React from 'react'

import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Field } from 'formik'

const TimePicker = ({ handleChange, value, values }) => {
  const display = !!value && !!values

  if (display) {
    return (
      <Box>
        <Field
          component={TextField}
          select
          value={value}
          disabled={!values}
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        >
          {values.map(({ label }) => (
            <MenuItem key={label} value={label}>
              {label}
            </MenuItem>
          ))}
        </Field>
      </Box>
    )
  }

  return null
}

export default TimePicker
