import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { getProjectsByUsername } from 'data/common'

import { username as usernameState } from 'states/user'

const useProjectsByUsername = ({ host }) => {
  const username = useRecoilValue(usernameState)
  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleGetProjectsByUsername = async () => {
    try {
      const projectsToSet = await getProjectsByUsername({ username, host })
      setProjects(projectsToSet)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (username) {
      handleGetProjectsByUsername()
    }
    return () => {
      setProjects([])
      setIsLoading(true)
    }
  }, [])

  return {
    projects,
    isLoading,
  }
}

export default useProjectsByUsername
