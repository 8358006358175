import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  coordinates: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    //alignItems: 'center',
    //justifyContent: 'space-between'
    //padding: theme.spacing(1)
  },
});

const CoordinateInput = ({ classes, lat, lng, index, updateCoords }) => {
  return (
    <div className={classes.coordinates}>
      <div id="id" style={{ width: 30 }}>
        <input type="number" value={index + 1} disabled={true} />
      </div>
      <div id="lat">
        <input
          type="number"
          value={lat[index]}
          onChange={e => {
            lat[index] =
              e.target.value !== '' ? parseFloat(e.target.value) : '';
            const changed = e.target.value !== '' ? true : false;
            updateCoords(lat, lng, changed);
          }}
        />
      </div>
      <div id="lng">
        <input
          type="number"
          value={lng[index]}
          onChange={e => {
            lng[index] =
              e.target.value !== '' ? parseFloat(e.target.value) : '';
            const changed = e.target.value !== '' ? true : false;
            updateCoords(lat, lng, changed);
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CoordinateInput);
