import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Wizard from './Wizard'
import { AppContext } from 'containers/LegacyFishfarm'

const styles = theme => ({
  row: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
  },
})

const Step4 = ({ classes }) => {
  const context = useContext(AppContext)
  const { indicators, impacts } = context.state
  const { updateThreshold } = context.functions

  return (
    <Wizard.Step>
      <div>
        <div>
          <h4>Impact Thresholds</h4>
        </div>

        <div>
          <div className={classes.row}>
            <div id="par" style={{ width: 120 }}>
              <input type="text" value="" disabled={true} />
            </div>
            {[1, 2, 3, 4].map(i => (
              <div key={i} id="threshold">
                <input
                  style={{ width: 60 }}
                  type="text"
                  value={impacts[i].impact}
                  disabled={true}
                />
              </div>
            ))}
          </div>

          {Object.keys(indicators).map(key => {
            return (
              <div key={key} className={classes.row}>
                <div id="par" style={{ width: 120 }}>
                  <input
                    type="text"
                    value={
                      indicators[key].id + ' (' + indicators[key].unit + ') '
                    }
                    disabled={true}
                  />
                </div>

                {[1, 2, 3, 4].map(i => {
                  return (
                    <div key={i}>
                      <input
                        style={{ width: 60 }}
                        type="number"
                        value={indicators[key].thresholds[i - 1]}
                        onChange={e => {
                          const value =
                            e.target.value !== ''
                              ? parseFloat(e.target.value)
                              : null
                          updateThreshold(key, value, i - 1)
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </Wizard.Step>
  )
}

export default withStyles(styles)(Step4)
