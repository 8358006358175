import { atom, selector } from 'recoil'

export const userState = atom({
  key: 'user',
  default: null,
})

export const username = selector({
  key: 'username',
  get: ({ get }) => {
    const user = get(userState)
    const { username } = user && user !== null ? user : {}
    return username
  },
})

export const isUser = selector({
  key: 'isUser',
  get: ({ get }) => {
    const profile = get(userState)
    const { roles = [] } = profile
    return roles.includes('User')
  },
})

export const isAdmin = selector({
  key: 'isAdmin',
  get: ({ get }) => {
    const profile = get(userState)
    const { roles = [] } = profile
    return roles.includes('Administrator')
  },
})

export const usersTools = selector({
  key: 'usersTools',
  get: ({ get }) => {
    const { metadata = {} } = get(userState)
    const { tools = [] } = metadata
    return tools
  },
})

export const usersModels = selector({
  key: 'usersModels',
  get: ({ get }) => {
    const { metadata = {} } = get(userState)
    const { models = [] } = metadata
    return models
  },
})

export const usersScenarioTypes = selector({
  key: 'usersScenarioTypes',
  get: ({ get }) => {
    const { metadata = {} } = get(userState)
    const { scenarioTypes = [] } = metadata
    return scenarioTypes
  },
})

export default userState
