import React from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, FieldArray } from 'formik'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { object, array } from 'yup'
import { getUserDefinedLegends } from 'data/dilution'
import Typography from '@material-ui/core/Typography'

const CustomLegends = ({
  id,
  scenarioId,
  setIsCustomLegendsEnabled,
  customLegends,
  setCustomLegends,
}) => {
  const { t } = useTranslation()
  const initialValues = {
    legends: [
      { threshold: 0 },
      { threshold: 0 },
      { threshold: 0 },
      { threshold: 0 },
      { threshold: 0 },
    ],
  }

  const handleSubmit = async ({ legends }) => {
    try {
      const data = await getUserDefinedLegends({ id: scenarioId, legends })
      const values = Object.values(legends).map(({ threshold }) => ({
        threshold,
      }))
      setCustomLegends({ ...customLegends, [id]: { ...data, legends: values } })
      setIsCustomLegendsEnabled(false)
    } catch (error) {
      console.error(error)
    }
  }

  const validationSchema = () =>
    object().shape({
      legends: array().of(
        object().test('correct order', t('errors:wrongOrder'), function () {
          const { path, parent } = this
          const index = Number(path.replace('legends[', '').replace(']', ''))
          const { threshold } = parent[index]

          if (index > 0) {
            const { threshold: prevThreshold } = parent[index - 1]
            return prevThreshold > threshold && threshold >= 0
          } else {
            return threshold >= 0
          }
        })
      ),
    })

  return (
    <Box marginTop={2} marginBottom={4} width={1} display="flex">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ handleChange, values, errors, isValid }) => {
          const { legends: thresholds = [] } = errors

          return (
            <form onSubmit={handleSubmit}>
              <Box
                marginBottom={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridGap={20}
              >
                <FieldArray
                  name="legends"
                  render={() => (
                    <>
                      <Box>
                        {values.legends.map((item, index) => {
                          return (
                            <Box
                              key={index}
                              marginBottom={1}
                              display="flex"
                              flexDirection="column"
                            >
                              <TextField
                                type="number"
                                autoComplete="off"
                                onChange={handleChange}
                                id={`legends[${index}].threshold`}
                                name={`legends[${index}].threshold`}
                                value={values.legends[index].threshold}
                                error={thresholds[index]}
                                label={`${t('common:value')} ${
                                  values.legends.length - index
                                }`}
                              />
                            </Box>
                          )
                        })}
                      </Box>
                      <Box textAlign="center">
                        {!isValid && (
                          <Typography
                            style={{ color: 'red' }}
                            variant="caption"
                          >
                            {t('errors:wrongOrder')}
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}
                />
              </Box>
              <Box display="flex">
                <Button
                  onClick={() => handleSubmit(values)}
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                >
                  {t('common:save')}
                </Button>
                <Box marginLeft="1rem">
                  <Button
                    onClick={() => setIsCustomLegendsEnabled(false)}
                    variant="outlined"
                    color="primary"
                  >
                    {t('common:cancel')}
                  </Button>
                </Box>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default CustomLegends
