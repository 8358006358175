import React from 'react';
import { WMSTileLayer } from 'react-leaflet';

const WmsDhi = ({
  attribution,
  filePath,
  format,
  item,
  layers,
  styles,
  timestamp,
  token = '',
  transparent,
  url,
  version,
}) => (
  <WMSTileLayer
    layers={layers}
    format={format}
    transparent={transparent}
    token={token}
    url={url}
    attribution={attribution}
    //dhi-specific stuff:
    version={version}
    styles={styles}
    item={item}
    filePath={filePath}
    timestamp={timestamp}
  />
);

export default WmsDhi;
