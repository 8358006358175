import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useHistory, useParams } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'

import { executeScenario } from 'data/dilution'

import TableList from 'components/TableList'
import Footer from 'components/Footer'
import DropdownSearch from 'components/DropdownSearch'

import Actions from './partials/Actions'
import Status from './partials/Status'

import useScenarios from 'containers/Dilution/hooks/useScenarios'

import projectsState from 'containers/Dilution/states/projects'

import { HOSTS } from 'config/constants'

const { DILUTION: HOST } = HOSTS

const List = () => {
  const projects = useRecoilValue(projectsState)
  const { t } = useTranslation()
  const { id: projectId } = useParams()
  const { push } = useHistory()

  const [sorting, setSorting] = useState([])

  const { scenarios, getScenarios: handleGetScenarios } = useScenarios({
    projectId,
    host: HOST
  })

  const selectedProject = projects.find(project => project.id === projectId)

  useEffect(() => {
    setSorting([
      {
        field: 'dateTime',
        direction: 'desc'
      },
      {
        field: 'data.siteInformation.title',
        direction: 'asc'
      }
    ])
  }, [scenarios])

  useEffect(() => {
    if ((!!projects.length && !projectId) || projectId === 'undefined') {
      const [defaultProject] = projects
      push(`/dilution/${defaultProject.id}`)
    }
  }, [projects])

  const handleExecuteScenario = async ({ job, scenario }) => {
    try {
      await executeScenario({ job, scenario })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Container maxWidth="xl" style={{ overflowX: 'auto' }}>
        <Box paddingY={10}>
          <Box paddingBottom={4}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width={1}
              marginBottom={4}
            >
              <Typography variant="h5" color="primary">
                {t('common:scenarios')}
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  width={1}
                  height={1}
                >
                  {!!projects.length && (
                    <Box
                      width={0.5}
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <DropdownSearch
                        placeholder="Select project"
                        id="project"
                        onChange={({ id }) => push(`/dilution/${id}`)}
                        options={projects}
                        getOptionLabel={({ data }) => {
                          const { title } = data
                          return title
                        }}
                        value={selectedProject}
                        label={`${t('common:project')}:`}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={1}
                  height={1}
                >
                  <Box marginRight={1}>
                    <Typography>
                      {t('common:create', { suffix: t('common:scenario') })}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip
                      title={t('common:create', {
                        suffix: t('common:scenario')
                      })}
                      disabled={!projects.length}
                    >
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="Add"
                          onClick={() => push('/dilution/form', { projectId })}
                          disabled={!projects.length}
                        >
                          <span
                            style={{
                              width: '1.5em',
                              height: '1.5em',
                              display: 'inline-block',
                              fontSize: '1.5rem'
                            }}
                          >
                            <AddIcon disabled={!projects.length} />
                          </span>
                        </Fab>
                      </div>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {!scenarios.length ? (
            <Box
              padding={4}
              display="flex"
              flexDirection="colunn"
              justifyContent="center"
              alignItems="center"
              bgcolor="shades.light"
              border={1}
              borderColor="shades.medium"
            >
              <Typography color="primary" variant="subtitle1">
                {t('common:none', { suffix: t('common:scenarios') })}
              </Typography>
            </Box>
          ) : (
            <TableList
              columns={[
                {
                  id: 'title',
                  heading: t('common:title'),
                  isSortable: true,
                  field: 'data.definition.title'
                },
                {
                  id: 'dateCreated',
                  heading: t('common:dateCreated'),
                  isSortable: true,
                  field: 'dateTime',
                  formatter: 'YYYY-MM-DD'
                },
                {
                  id: 'timeCreated',
                  heading: t('common:timeCreated'),
                  isSortable: true,
                  field: 'dateTime',
                  formatter: 'HH:mm'
                },
                {
                  id: 'status',
                  heading: t('common:status'),
                  component: Status
                },
                {
                  id: 'actions',
                  heading: t('common:actions'),
                  component: Actions,
                  shared: {
                    projectId,
                    handleGetScenarios,
                    handleExecuteScenario
                  }
                }
              ]}
              list={scenarios}
              sorting={sorting}
            />
          )}
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default List
