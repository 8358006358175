import React, { useContext } from 'react'
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import {
  AddLocation as AddLocationIcon,
  Autorenew as AutorenewIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
} from '@material-ui/icons'
import L from 'leaflet'

import Wizard from './Wizard'
import CoordinateInput from './CoordinateInput'
import { GEOGRAPHICAL_DOMAINS } from 'containers/LegacyFishfarm/config'
import { AppContext } from 'containers/LegacyFishfarm'

const styles = theme => ({
  title: {
    width: '100%',
    padding: '16px 20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  row: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
  },
})

const Step1 = ({ classes }) => {
  const context = useContext(AppContext)
  const {
    info,
    domain,
    coords,
    shapeData,
    drawControl,
    drawStatus,
  } = context.state
  const {
    updateInfo,
    updateDomain,
    updateCoords,
    makeGeojson,
    removeGeojson,
    updateDrawStatus,
  } = context.functions

  const removeLayers = () => {
    const featureGroup = drawControl.options.edit.featureGroup
    const layers = featureGroup._layers
    Object.keys(featureGroup._layers).forEach(key => {
      const layer = layers[key]
      featureGroup.removeLayer(layer)
    })
  }

  const drawOnMap = action => {
    switch (action) {
      case 'draw': {
        //drawControl._toolbars.draw._modes.marker.handler._tooltip._container.innerHTML = 'XXXXXX';

        drawControl._toolbars['draw']._modes['marker'].handler.enable()
        updateDrawStatus(true)
        break
      }

      case 'stopdraw': {
        drawControl._toolbars.draw.disable()
        updateDrawStatus(false)
        break
      }

      case 'update': {
        removeLayers()

        coords.lat.forEach((lat, index) => {
          L.marker([coords.lat[index], coords.lng[index]]).addTo(
            drawControl.options.edit.featureGroup
          )
        })

        const layers = drawControl.options.edit.featureGroup._layers
        Object.keys(layers).forEach((key, index) => {
          var heading =
            (info.title ? info.title + ' ' : '') + '#' + (index + 1).toString()
          heading = '<h4>' + heading + '</h4>'
          layers[key].bindPopup(heading, { maxWidth: 400 })
        })

        updateCoords(coords.lat, coords.lng, false)
        break
      }

      case 'remove': {
        removeLayers()
        updateCoords([], [], false)
        break
      }

      default:
        return console.warn('default')
    }
  }

  return (
    <Wizard.Step>
      <div>
        <Tooltip title="Add a descriptive title">
          <h4>Project Title</h4>
        </Tooltip>
        <input
          className={classes.title}
          type="text"
          name="title"
          placeholder="Title..."
          value={info.title}
          onChange={e => {
            updateInfo({
              ...info,
              title: e.target.value,
            })
          }}
        />
      </div>
      <div>
        <Tooltip title="The selected geographical domain defines which data layers are available">
          <h4>Geographical Domain</h4>
        </Tooltip>
        <FormControl fullWidth variant="outlined">
          <Select
            value={domain}
            onChange={e => {
              updateDomain(e)
            }}
            input={<OutlinedInput labelWidth={0} />}
          >
            {GEOGRAPHICAL_DOMAINS.map(domain => (
              <MenuItem key={domain.id} value={domain}>
                {domain.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div id="shape">
        <Tooltip title="Optionally display a shape file on the map">
          <h4>Display Shapefile (optional)</h4>
        </Tooltip>
        <div id="shape" className={classes.row}>
          <input
            type="file"
            className="file"
            size="60"
            id="input"
            accept=".zip"
            onChange={makeGeojson}
          />
          <Button
            style={{ marginLeft: '20px' }}
            variant="contained"
            color="secondary"
            disabled={shapeData === null}
            onClick={e => {
              removeGeojson()
            }}
          >
            Delete
          </Button>
        </div>
      </div>

      <div>
        <Tooltip title="Add fish farm locations">
          <h4>Site Location(s)</h4>
        </Tooltip>

        {(coords.lat.length === 0 || drawStatus) && (
          <div className={classes.row}>
            <Tooltip title="Click to add markers on map">
              <div>
                <IconButton
                  variant="contained"
                  color="secondary"
                  aria-label="Add Positions"
                  disabled={drawStatus}
                  onClick={() => {
                    drawOnMap('draw')
                  }}
                >
                  <AddLocationIcon fontSize="large" />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Click to finish adding markers">
              <div>
                <IconButton
                  variant="contained"
                  color="secondary"
                  aria-label="Stop Drawing"
                  disabled={!drawStatus}
                  onClick={() => {
                    drawOnMap('stopdraw')
                  }}
                >
                  <DoneIcon fontSize="large" />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )}
      </div>

      {coords.lat.length > 0 && !drawStatus && (
        <div>
          <div className={classes.row}>
            <Tooltip title="Push to redraw edited markers on map">
              <div>
                <IconButton
                  variant="contained"
                  color="secondary"
                  aria-label="Update Position"
                  disabled={!coords.changed}
                  onClick={() => drawOnMap('update')}
                >
                  <AutorenewIcon fontSize="large" />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Push to delete markers on map">
              <div>
                <IconButton
                  variant="contained"
                  color="secondary"
                  aria-label="Delete Position"
                  onClick={() => drawOnMap('remove')}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          <div id="sitelocation">
            <div className={classes.row}>
              <div id="id" style={{ width: 30 }}>
                <input type="text" value="#" disabled={true} />
              </div>
              <div id="lat">
                <input type="text" value="Latitude (°N)" disabled={true} />
              </div>
              <div id="lng">
                <input type="text" value="Longitude (°E)" disabled={true} />
              </div>
            </div>
            {coords.lat.map((dum, index) => (
              <CoordinateInput
                key={index}
                lat={coords.lat}
                lng={coords.lng}
                index={index}
                updateCoords={updateCoords}
              />
            ))}
          </div>
        </div>
      )}
    </Wizard.Step>
  )
}

export default withStyles(styles)(Step1)
