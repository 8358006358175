import { CompositeLayer } from '@deck.gl/core'
import { TextLayer, ScatterplotLayer } from '@deck.gl/layers'

const calcPlacement = ({ lat, lon, meters }) => {
  const lat0 = lat + (180 / Math.PI) * (meters / 6378137)
  return [lon, lat0]
}
export default class RadiusLayer extends CompositeLayer {
  renderLayers() {
    const { data, onClick } = this.props

    return [
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '20',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 20,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '50m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 50,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '100m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 100,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '150m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 150,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '200m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 200,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '250m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 250,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '300m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 300,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '350m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 350,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: '400m',
          data,
          pickable: true,
          stroked: true,
          filled: false,
          getPosition: (d) => [d.lon, d.lat],
          lineWidthMinPixels: 1,
          getRadius: (d) => 400,
          getLineColor: (d) => [0, 0, 0],
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-20m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 27 }),
          getText: () => '20 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-50m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 57 }),
          getText: () => '50 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-100m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 107 }),
          getText: () => '100 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-150m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 157 }),
          getText: () => '150 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-200m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 207 }),
          getText: () => '200 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-250m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 257 }),
          getText: () => '250 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-300m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 307 }),
          getText: () => '300 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-350m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 357 }),
          getText: () => '350 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text-400m',
          data,
          getPosition: ({ lat, lon }) =>
            calcPlacement({ lat, lon, meters: 407 }),
          getText: () => '400 m',
          fontFamily: 'Roboto',
          sizeUnits: 'meters',
          getSize: 25,
          getAngle: 0,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center',
          onClick,
        })
      ),
    ]
  }
}
