import { directionColumn, justifySpaceBetween } from 'styles/mixins'

export default () => ({
  root: {
    minHeight: '100%',
    position: 'relative',
    backgroundColor: '#fff'
  },
  content: {
    ...directionColumn,
    ...justifySpaceBetween,
    flexGrow: 1,
    overflow: 'hidden'
  },
  offlineContainer: {
    color: '#ffffff'
  }
})
