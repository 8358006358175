import API from '@dhi-solutions/domain-services'
import { HOST, SEDIMENT_CLASSES } from '../containers/SedimentSpill/config'

import { sedimentSpillBaseUrl as apiBaseUrl } from 'config/constants'

export const fetchDatetimes = async filePath => {
  try {
    const endpoint = `/timeseries/dfs0-timeseries/${filePath}/datetimes`

    const response = await API.get(endpoint, { baseURL: apiBaseUrl })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchMapAnimationFiles = async (dataSource, config) => {
  try {
    const query = `?&style=${config.style}&item=${config.item}&width=${config.width}&height=${config.height}&bbox=${config.bbox}&shadingtype=${config.shadingType}&scale=${config.scale}`
    const endpoint = `/maps/${dataSource.connection}` + query

    const body = JSON.stringify(dataSource.ids)

    const response = await API.post(endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getSedimentClass = async (point, map) => {
  try {
    // todo (backend): better endpoint or fetch only the relevant data from the sediment map (only the type)
    // maybe change to use sediment map as a dfsu and extract sediment class from there
    const size = map._size
    const { _southWest, _northEast } = map.getBounds()

    const mapSize = `&width=${size.x}&height=${size.y}`
    const southWest = `${_southWest.lat},${_southWest.lng}`
    const northEast = `${_northEast.lat},${_northEast.lng}`
    const queryLayers = `&query_layers=SeaStatus%3ASedimentClassesDK&TYPENAME=SeaStatus%3ASedimentClassesDK&I=${point.x}&J=${point.y}`
    const baseUrl =
      '/geoserver/SeaStatus/wms?service=wms&version=1.3.0&request=GetFeatureInfo&layers=SeaStatus%3ASedimentClassesDK&styles=polygon&crs=EPSG%3A4326&info_format=application%2Fjson&format=image%2Fpng&bbox='

    const endpoint = baseUrl + southWest + northEast + mapSize + queryLayers

    const response = await API.post(
      endpoint,
      {},
      {
        baseURL: HOST,
      }
    )
    if (!response) throw Error(`Request rejected`)

    const { data } = response || {}

    const sedimentClass = SEDIMENT_CLASSES.filter(
      sediment => sediment.SedimentType === data.features[0].properties.Sediment
    )
    return Promise.resolve(sedimentClass[0])
  } catch (error) {
    console.error(error.response)
    return Promise.resolve({
      SedimentType: 'Unknown',
      Composition: {
        Clay: 0,
        Silt: 0,
        Sand: 0,
        CoarseSand: 0,
      },
    })
  }
}

export const fetchShapefile = async ({ layer }) => {
  try {
    const { dateTime, item, file, folder, scenarioId, style } = layer
    const endpoint = `/mapstyles/${style}/palette`

    const response = await API.get(endpoint, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}

    const contours = Object.keys(data)
    // const contours = '1,5,10,20,30,40,50,60,70,80,90,99,100';

    const filePath = `${scenarioId}|${folder}|${file}`
    // const filePath = `2D.dfsu`;

    // const url = `http://52.166.253.160/featurecollection/dfsucontouring-featurecollection/File=${filePath};Item=${item};ContourIntervals=0,1,2,3,4,5,6,7,8,9,10,11;DateTime=${dateTime}/tozippedshapefile`;
    const url = `${apiBaseUrl}/featurecollection/dfsucontouring-featurecollection/File=${filePath};Item=${item};ContourIntervals=${contours};DateTime=${dateTime}/tozippedshapefile`
    window.open(url)

    return Promise.resolve(url)
  } catch (error) {
    return Promise.reject(error)
  }
}
