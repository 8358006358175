import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  popper: {
    zIndex: 9999,
    maxWidth: 450,
    background: '#f4f4f4',
    border: '1px solid',
    padding: 6,
  },
  icon: {
    padding: 0,
    marginLeft: 6,
  },
}))
