import React, { useContext } from 'react';
import { Marker as CoreMarker, Popup } from 'react-leaflet';

import { MarineMonitoringContext } from 'containers/MarineMonitoring';

const Marker = () => {
  const { state } = useContext(MarineMonitoringContext);
  const { position } = state;
  const { lat, lng } = position;
  if (!lat || !lng) return null;
  return (
    <CoreMarker position={[position.lat, position.lng]}>
      <Popup>
        <div>
          <h4>Site location </h4>
          <p>
            Lat/Lng: (
            {position.lat.toFixed(4) + '°N, ' + position.lng.toFixed(4)}
            °E){' '}
          </p>
          {position.area && (
            <p>Site polygon area (Ha): {position.area.toFixed(0)} </p>
          )}
        </div>
      </Popup>
    </CoreMarker>
  );
};

export default Marker;
