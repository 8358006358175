import React from 'react';

import Wms from './partials/Wms';
import WmsDhi from './partials/WmsDhi';
import WmsTerracotta from './partials/WmsTerracotta';
import AutoAnim from './partials/AutoAnim';
import Tile from './partials/Tile';
import WmsSingleTile from './partials/WmsSingleTile';

const WMS = 'wms';
const WMS_DHI = 'wms-dhi';
const WMS_TERRACOTTA = 'wms-terracotta';
const AUTO_ANIM = 'auto-anim';
const TILE = 'tile';
const WMS_SINGLE_TILE = 'wms-single-tile';

const MapLayer = ({ layer, map, onStatus, timestep }) => {
  const layerTypes = {
    [WMS]: <Wms {...layer} />,
    [WMS_DHI]: <WmsDhi {...layer} />,
    [WMS_TERRACOTTA]: <WmsTerracotta {...layer} />,
    [AUTO_ANIM]: (
      <AutoAnim {...layer} map={map} timestep={timestep} onStatus={onStatus} />
    ),
    [TILE]: <Tile {...layer} />,
    [WMS_SINGLE_TILE]: <WmsSingleTile {...layer} map={map} />,
  };

  const mapLayer = layerTypes[layer.type] || null;

  return mapLayer;
};

export default MapLayer;
