export default () => ({
  table: {
    margin: '0 0 10px 0'
  },
  name: {
    fontSize: '1rem',
    fontWeight: 'bold',
    width: '10rem'
  },
  action: {
    color: '#0D3958'
  },
  divider: {
    width: '0px',
    margin: '0rem .5rem'
  },
  disabled: {
    color: 'lightgray'
  },
  flex: {
    display: 'flex'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexColumnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  wideCell: {
    width: '8rem'
  },
  firstCellInRow: {
    borderRadius: '3px 0px 0px 0px'
  },
  lastCellInRow: {
    borderRadius: '0px 3px 0px 0px'
  },
  public: {
    width: '3rem',
    margin: '0',
    padding: '.1rem',
    textTransform: 'capitalize',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '.6rem',
    backgroundColor: '#e6f5fe',
    color: '#38b3fa',
    border: '1px solid #38b3fa'
  },
  private: {
    width: '3rem',
    margin: '0',
    padding: '.1rem',
    textTransform: 'capitalize',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '.6rem',
    backgroundColor: '#ffede6',
    color: '#fd804e',
    border: '1px solid #fd804e'
  },
  shared: {
    width: '3rem',
    margin: '0',
    padding: '.1rem',
    textTransform: 'capitalize',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '.6rem',
    backgroundColor: '#eefce9',
    color: '#47d147',
    border: '1px solid #47d147'
  }
})
