import React, { useContext } from 'react';

import { compose } from 'recompose';

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';

import withDataviewerState from 'hocs/withDataviewerState';

import { DataviewerContext } from 'containers/Dataviewer';

import styles from './styles';

const enhancer = compose(
  withDataviewerState,
  withStyles(styles)
);

const Layers = ({ classes, data, shared, identifier }) => {
  const { formik } = shared;
  const { values } = formik;
  const { actions } = useContext(DataviewerContext);
  const list = Object.keys(data);
  const handleCheckboxChange = event => actions.onStepChange(event, identifier);
  if (data) {
    return (
      <>
        {list.map(item => {
          const layer = data[item];
          const isMatching = layer.domains.includes(values.domain.id);
          if (isMatching) {
            return (
              <div key={item}>
                <div className={classes.selectionRow}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item}
                        checked={layer.checked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    disabled={layer.disabled}
                    label={layer.name}
                  />
                  <IconButton href={layer.info} target="_blank">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </div>

                {layer.checked &&
                  (layer.legendGradient
                    ? // configured legend
                      layer.legendGradient.map((lg, index) => (
                        <div key={index} className={classes.legend}>
                          <div
                            className={classes.legendItemIcon}
                            style={{ background: lg.color }}
                          />
                          <Typography>
                            {lg.value}
                            {layer.legendValuePostFix &&
                              ` ${layer.legendValuePostFix}`}
                          </Typography>
                        </div>
                      ))
                    : layer.legend && (
                        // legend from wms
                        <div className={classes.legendWMS}>
                          <img src={layer.legend} alt="Legend" />
                        </div>
                      ))}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }
  return null;
};

export default enhancer(Layers);
