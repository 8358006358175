import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Select from 'react-select'

import useStyles from './hooks/useStyles'

import partials from './partials'

const DropdownSearch = ({
  label,
  placeholder,
  id,
  onChange,
  options,
  value,
  renderValue,
  getOptionLabel,
  disabled = false
}) => {
  const handleChange = value => onChange(value)
  const classes = useStyles()
  const theme = useTheme()
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
    })
  }
  return (
    <div style={{ width: '100%' }}>
      <Select
        classes={classes}
        styles={selectStyles}
        inputId={id}
        TextFieldProps={{
          label,
          InputLabelProps: {
            htmlFor: id,
            shrink: true
          }
        }}
        placeholder={placeholder}
        options={options}
        components={partials}
        value={value}
        onChange={handleChange}
        renderValue={renderValue}
        getOptionLabel={getOptionLabel}
        isDisabled={disabled}
      />
    </div>
  )
}

export default DropdownSearch
