import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';

const OutletSelectionButton = ({ options, onClose }) => {
  const [selected, setSelected] = React.useState('All');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    onClose(selected);
  };

  const handleOnClick = (option) => {
    if (option === selected) {
      setSelected(null);
      return;
    }

    setSelected(option);
  }

  return (
    <div>
      <Button variant="outlined" style={{ color: "#fff", borderColor: "#fff" }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Checkbox
          checked={selected === 'All'}
          style={{ borderColor: '#fff', color: "#fff", padding: '3px' }}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem onClick={() => handleOnClick(option)}>
            <Box display="flex" alignItems="center" justifyContent="start">
              <Checkbox
                color="primary"
                style={{ padding: '3px' }}
                checked={option === selected}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              {option}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default OutletSelectionButton;