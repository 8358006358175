import React from 'react'
import { compose } from 'recompose'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

import { Link } from 'react-router-dom'

import Logo from 'components/Logo'

import pages from 'config/pages'
import styles from './styles'

const enhancer = compose(withStyles(styles))

const Footer = ({ classes }) => {
  return (
    <Box pb={1} className={classes.footer} display="flex" alignItems="center">
      <Box display="flex" flexGrow={1}>
        {pages.map(page => (
          <Link key={page.path} to={page.path} className={classes.link}>
            <Box ml={2}>{page.title}</Box>
          </Link>
        ))}
      </Box>
      <Link to="/">
        <Logo dark />
      </Link>
    </Box>
  )
}

export default enhancer(Footer)
