import React, { useEffect, useContext } from 'react'
import { compose } from 'recompose'
import styles from 'styles/global'
import { Add as AddIcon } from '@material-ui/icons'
import { Fab, Tooltip, Typography, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DropdownSearch from 'components/DropdownSearch'
import Drawer from 'components/Drawer'

import {
  DESCRIPTION_FIELDS,
  MENU_ITEMS,
  NAME_FIELD
} from 'containers/SedimentSpill/config'

import { SedimentSpillContext } from 'containers/SedimentSpill'

import ScenariosList from './partials/ScenariosList'

const enhancer = compose(
  withRouter,
  withStyles(styles)
)

const Scenarios = ({ classes, match, history }) => {
  const { path, params } = match
  const { projectName } = params
  const { state, actions } = useContext(SedimentSpillContext)
  const { newScenario, projects = [], selectedProject } = state
  const { t } = useTranslation()

  useEffect(() => {
    if (projects.length) {
      if (!projectName) {
        handleRedirect()
      } else {
        const projectToSet = projects.find(
          project => project.value === projectName
        )
        actions.handleProjectChange(projectToSet)
      }
    }
  }, [projects.length, projectName])

  const handleRedirect = newProject => {
    const defaultProject = projects[0]
    const projectToSet = newProject || defaultProject
    const { value } = projectToSet
    const newLocation = path.replace(':projectName', value)

    history.push(newLocation)
  }

  const handleChange = chosenProject => {
    actions.handleProjectChange(chosenProject)
    handleRedirect(chosenProject)
  }

  return (
    <>
      <Drawer.Header>
        <DropdownSearch
          label="Project"
          placeholder="Select project"
          id="project"
          onChange={handleChange}
          options={projects}
          value={selectedProject}
        />
        {selectedProject.value && (
          <div className={classes.header}>
            <Typography variant="inherit" component="h2">
              Screening Scenarios
            </Typography>
            <Tooltip
              title={t('common:create', { suffix: t('common:scenario') })}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="Add"
                className={classes.fab}
                onClick={() =>
                  history.push('/sediment-spill/form', { selectedProject })
                }
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        )}
      </Drawer.Header>
      {selectedProject.value && (
        <Drawer.Content>
          <div className={classes.drawerInnerContainer}>
            <ScenariosList
              menuItems={MENU_ITEMS}
              nameField={NAME_FIELD}
              descriptionFields={DESCRIPTION_FIELDS}
              onAction={actions.onAction}
              onAddScenario={newScenario}
              showDate
              showHour
              showMenu
              showStatus
            />
          </div>
        </Drawer.Content>
      )}
    </>
  )
}

export default enhancer(Scenarios)
