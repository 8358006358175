import React from 'react'
import { useRecoilValue } from 'recoil'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ClearIcon from '@material-ui/icons/Clear'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import { options as optionsState } from 'containers/Dilution/states/activeScenarioType'

import useMappedExistingSources from 'containers/Dilution/partials/Form/hooks/useMappedExistingSources'
import ErrorMessage from 'components/ErrorMessage'
import Popper from 'components/Popper'
import TableList from 'components/TableList'
import EditField from './partials/EditField'

const Step = ({ form }) => {
  const options = useRecoilValue(optionsState)
  const { values, setFieldValue, errors } = form
  const { components = [] } = values
  const { t } = useTranslation()

  useMappedExistingSources({ form })

  const {
    hasPredefinedComponents,
    canDeselectComponents = false,
    allowBackgroundConcentrations,
  } = options

  const errorInComponents = get(errors, 'components')

  const withoutSelectedComponent =
    errorInComponents === t('common:withoutSelectedComponent')

  const handleAdd = () => {
    const index = components.length

    const componentToSet = {
      index,
      componentDescription: t('common:newTracer', { suffix: `${index + 1}` }),
      decayRate: 0,
      concentrationValue: 0,
      unit: '',
      backgroundConcentration: 0,
      disabled: !canDeselectComponents,
      include: true,
    }

    setFieldValue(`components.${index}`, componentToSet)
  }

  const onBlurCallback = (listIndex, field, value) => {
    setFieldValue(`components.${listIndex}.${field}`, value)
  }

  const handleToggle = ({ event, listIndex }) => {
    const { target } = event
    const { checked } = target
    setFieldValue(`components.${listIndex}.include`, checked)
  }

  const handleRemove = ({ listIndex }) => {
    const componentsToSet = [...components].filter((item, index) => {
      return listIndex !== index
    })
    setFieldValue('components', componentsToSet)
  }

  // useEffect(() => {
  //   if (!components.length) {
  //     setFieldValue('components', [
  //       {
  //         componentDescription: t('common:newTracer', { suffix: '1' }),
  //         concentrationValue: 0,
  //         decayRate: 0,
  //         backgroundConcentration: 0,
  //         unit: '',
  //         include: true,
  //         disabled: false
  //       }
  //     ])
  //   }
  // }, [])

  return (
    <>
      <Box width={1} height={1}>
        <>
          <Box display="flex" justifyContent="flex-end" marginBottom={2}>
            <Popper
              icon={<HelpOutlineIcon />}
              popperContent={t('documentation:componentsTitle')}
              placement="left"
            />
          </Box>
          <TableList
            columns={[
              {
                id: 'checkbox',
                heading: '',
                component: ({ include, listIndex, disabled }) => (
                  <Tooltip
                    title={
                      disabled ? t('dilution:uneditable') : t('common:toggle')
                    }
                  >
                    <div>
                      <Checkbox
                        checked={include}
                        onChange={(event) => handleToggle({ event, listIndex })}
                        name={`components.${listIndex}.include`}
                        id={`components.${listIndex}.include`}
                        disabled={!canDeselectComponents}
                        color="primary"
                      />
                    </div>
                  </Tooltip>
                ),
              },
              {
                id: 'componentDescription',
                heading: t('common:componentTitle'),
                component: ({ componentDescription, listIndex }) => (
                  <EditField
                    field="componentDescription"
                    value={componentDescription}
                    defaultValue={componentDescription}
                    listIndex={listIndex}
                    disabled={hasPredefinedComponents}
                    placeHolder={t('common:typeIn', {
                      suffix: t('common:name'),
                    })}
                    onBlurCallback={onBlurCallback}
                  />
                ),
              },

              {
                id: 'unit',
                heading: t('common:unit'),
                component: ({ unit, listIndex }) => (
                  <EditField
                    field={'unit'}
                    value={unit}
                    listIndex={listIndex}
                    disabled={hasPredefinedComponents}
                    placeHolder={t('common:typeIn', {
                      suffix: t('common:unit'),
                    })}
                    onBlurCallback={onBlurCallback}
                  />
                ),
              },
              {
                id: 'backgroundConcentration',
                heading: t('common:backgroundConcentration'),
                component: ({ backgroundConcentration, listIndex }) => (
                  <EditField
                    type="number"
                    field={'backgroundConcentration'}
                    value={backgroundConcentration}
                    listIndex={listIndex}
                    disabled={!allowBackgroundConcentrations}
                    placeHolder={
                      allowBackgroundConcentrations ? '' : t('common:na')
                    }
                    onBlurCallback={onBlurCallback}
                  />
                ),
              },
              {
                id: 'decayRate',
                heading: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('common:decayRate', {
                        suffix: '(d<sub>-1</sub>)',
                      }),
                    }}
                  />
                ),
                component: ({ decayRate, listIndex }) => (
                  <EditField
                    type="number"
                    field="decayRate"
                    value={decayRate}
                    listIndex={listIndex}
                    disabled={hasPredefinedComponents}
                    placeHolder={!hasPredefinedComponents ? '' : t('common:na')}
                    onBlurCallback={onBlurCallback}
                  />
                ),
              },
              {
                id: 'delete',
                heading: '',
                component: ({ listIndex, disabled }) => (
                  <Tooltip
                    title={
                      hasPredefinedComponents
                        ? t('dilution:uneditable')
                        : t('dilution:delete')
                    }
                  >
                    <div>
                      <IconButton
                        aria-label="delete"
                        disabled={hasPredefinedComponents}
                        onClick={() => handleRemove({ listIndex })}
                      >
                        <ClearIcon
                          fontSize="small"
                          color={
                            hasPredefinedComponents ? 'disabled' : 'primary'
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>
                ),
              },
            ]}
            list={components}
          />
          <Tooltip title={t('common:add', { suffix: t('common:newTracer') })}>
            <Button
              variant="contained"
              color="secondary"
              disabled={hasPredefinedComponents}
              onClick={handleAdd}
            >
              {t('common:add', { suffix: '+' })}
            </Button>
          </Tooltip>
          <Box marginTop={4}>
            <ErrorMessage
              errors={errors}
              fields={components.map(
                (_, index) => `components.${index}.description`
              )}
            />
            {withoutSelectedComponent && (
              <Typography style={{ color: 'red' }} gutterBottom variant="body2">
                {errorInComponents}
              </Typography>
            )}
          </Box>
        </>
      </Box>
    </>
  )
}

export default Step
