import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { getLoads } from 'data/fishfarm'

const Lookup = ({ tonnes, handleChange, setFieldValue }) => {
  const handleOnClick = async () => {
    const loads = await getLoads({ tonnes })
    Object.entries(loads).map(([id, value]) => {
      return setFieldValue(`production.${id}`, Number(value).toFixed(2))
    })
  }

  const hasProduction = Number(tonnes) > 0

  return (
    <Box
      bgcolor="shades.light"
      border={1}
      borderColor="shades.medium"
      marginBottom={4}
      padding={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <TextField
            fullWidth
            placeholder="Fish production"
            id="production.tonnes"
            name="production.tonnes"
            value={tonnes}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">tonnes/year</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            disabled={!hasProduction}
            onClick={handleOnClick}
          >
            Get values
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Lookup
