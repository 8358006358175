import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';

import Drawer from 'components/Drawer';

import withHabitatsState from 'hocs/withHabitatsState';

import styles from './styles';

const enhancer = compose(
  withHabitatsState,
  withStyles(styles, { withTheme: true })
);

const Header = ({ classes, config }) => {
  const { activeStep, steps } = config;
  return (
    <Drawer.Header>
      <div className={classes.header}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map(({ title }) => (
            <Step key={title}>
              <StepLabel>{title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </Drawer.Header>
  );
};

export default enhancer(Header);
