import { atom, selector } from 'recoil'
import slugify from 'slugify'

import { decodeExistingSource } from 'helpers/dilutionsMapper'

import { activeModelState } from './activeModel'
import activeScenarioTypeIdState from './activeScenarioType'

export const existingSourcesState = atom({
  key: 'existingSources',
  default: selector({
    key: 'existingSourcesFromActiveModel',
    get: ({ get }) => {
      const { existingSources: sources = [] } = get(activeModelState)
      const activeScenarioTypeId = get(activeScenarioTypeIdState)
      return sources
        .filter(({ concentrations }) =>
          Object.keys(concentrations).includes(activeScenarioTypeId)
        )
        .map(({ label, ...source }) => ({
          id: slugify(label, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
          }),
          label,
          include: true,
          ...source,
        }))
    },
  }),
})

export const existingSourcesInList = selector({
  key: 'existingSourcesInList',
  get: ({ get }) => {
    const existingSources = get(existingSourcesState)
    return existingSources.map((existingSource) =>
      decodeExistingSource(existingSource)
    )
  },
})

export const existingSourcesInMap = selector({
  key: 'existingSourcesInMap',
  get: ({ get }) => {
    const existingSources = get(existingSourcesState)
    return existingSources.map(
      ({ label, position: [latitude, longitude], include }) => ({
        name: label,
        lat: latitude,
        lon: longitude,
        include,
      })
    )
  },
})

export default existingSourcesState
