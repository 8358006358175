import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { useSpring, animated } from 'react-spring'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ShowIcon from '@material-ui/icons/KeyboardArrowUp'
import HideIcon from '@material-ui/icons/KeyboardArrowDown'

const Legends = ({ headline, unit, thresholds = [] }) => {
  const [open, setOpen] = useState(true)

  const handleToggle = () => setOpen(state => !state)

  const styling = useSpring({
    position: 'absolute',
    zIndex: 6,
    bottom: 0,
    marginBottom: 0,
    right: 12,
    transform: `translate3d(0, ${open ? '-58px' : '-50px'}, 0)`,
    opacity: open ? 1 : 0,
    width: 250,
    pointerEvents: open ? 'auto' : 'none',
  })

  useEffect(() => {
    return () => setOpen(true)
  }, [])

  useEffect(() => {
    setOpen(true)
  }, [thresholds])

  const label = open ? 'Hide legends' : 'Show legends'

  return (
    <>
      <Tooltip title={label}>
        <IconButton aria-label={label} onClick={handleToggle}>
          {open ? (
            <HideIcon fontSize="small" color="primary" />
          ) : (
            <ShowIcon fontSize="small" color="secondary" />
          )}
        </IconButton>
      </Tooltip>
      <div style={{ position: 'relative', zIndex: 6 }}>
        <animated.div style={{ ...styling }}>
          <Box
            padding={1}
            style={{
              backgroundColor: 'white',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              width: 'inherit',
              height: 'auto',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="button">{headline}</Typography>
              </Box>
              <Box>
                <Typography variant="button">{unit}</Typography>
              </Box>
            </Box>

            <List>
              {thresholds.map(({ color, label }) => (
                <ListItem key={label}>
                  <Box display="flex" justifyContent="space-between" width={1}>
                    <Box
                      width={15}
                      height={15}
                      style={{ backgroundColor: color }}
                    />
                    <Box>{label}</Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </animated.div>
      </div>
    </>
  )
}

export default Legends
