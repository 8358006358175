import { object, boolean, number, ref } from 'yup'

import Step5 from './Step5'

export default {
  component: Step5,
  validationSchema: () =>
    object().shape({
      damping: object().shape({
        enabled: boolean(),
        latitude: number(),
        longitude: number(),
        innerRadius: number().lessThan(
          ref('outerRadius'),
          'Inner radius should be < Outer radius'
        ),
        outerRadius: number().moreThan(
          ref('innerRadius'),
          'Outer radius should be > Inner radius'
        )
      })
    })
}
