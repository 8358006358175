import slugify from 'slugify'

export const decodeExistingSource = (item, dischargeOptionId) => {
  const {
    label,
    include,
    position,
    depth,
    salinity,
    temperature,
    dischargeOptions = [],
    concentrations: rawConcentrations,
  } = item
  const [latitude, longitude] = position

  const concentrations = Object.values(rawConcentrations)
    .reduce((allConcentrations, thisConcentration) => {
      allConcentrations.push(...thisConcentration)
      return allConcentrations
    }, [])
    .map((rawConcentration) => {
      const {
        componentDescription,
        concentrationValue,
        unit,
      } = rawConcentration
      return {
        componentDescription,
        concentrationValue,
        unit,
      }
    })

  return {
    id: slugify(label, {
      replacement: '-',
      remove: /[*+~.()'"!:@]/g,
      lower: true,
    }),
    include,
    source: { label, latitude, longitude, depth },
    dischargeOptions,
    density: { temperature, salinity },
    concentrations,
  }
}

export const outletMapper = (item) => {
  const {
    jet: {
      enabled = false,
      pipeDiameter = 0.5,
      angleVertical = 0,
      angleHorizontal = 0,
      numberOfDiffuserPorts = 1,
    } = {},
    type,
    label,
    depth,
    include,
    latitude,
    salinity,
    longitude,
    discharges = [],
    temperature,
    concentrations,
  } = item
  const isExisting = type === 'existing'
  return {
    jet: {
      enabled: isExisting ? false : enabled,
      pipeDiameter,
      angleVertical,
      angleHorizontal,
      numberOfDiffuserPorts,
    },
    type,
    include,
    source: { label, latitude, longitude, depth },
    discharges,
    density: { temperature, salinity },
    concentrations,
  }
}

export const encodeDilution = ({ data }) => {
  const scenario = JSON.parse(data)
  const {
    version,
    definition: {
      title,
      description,
      model: { id: modelId },
      type: { id: typeId },
      project: { id: projectId },
      runHd,
      startDate,
      endDate,
    },
    components,
    outlet,
    damping,
    postProcessing: {
      fractileFractions: { first, second },
    },
  } = scenario

  const combinedMapper = (type, item) => {
    const isExisting = type === 'existing'
    const {
      jet: {
        enabled = false,
        pipeDiameter = 0.5,
        angleVertical = 0,
        angleHorizontal = 0,
        numberOfDiffuserPorts = 1,
      } = {},
      include,
      source: { label, latitude, longitude, depth },
      discharges = [],
      density: { temperature, salinity },
      concentrations,
    } = item

    return {
      type,
      jet: {
        enabled: isExisting ? false : enabled,
        pipeDiameter,
        angleVertical,
        angleHorizontal,
        numberOfDiffuserPorts,
      },
      version,
      label,
      depth,
      include,
      latitude,
      longitude,
      salinity,
      discharges,
      temperature,
      concentrations,
    }
  }

  const { hypotheticalOutlet, existingOutlet, dischargeOption } = outlet

  const hypothetical = hypotheticalOutlet.map((item) => {
    return combinedMapper('hypothetical', item)
  })

  const existing = existingOutlet.map((item) => {
    return combinedMapper('existing', item)
  })

  const sources = [...hypothetical, ...existing]

  return {
    title,
    description,
    type: typeId,
    model: modelId,
    project: projectId,
    runHD: runHd,
    sources,
    version: '1',
    components,
    endDateTime: endDate,
    startDateTime: startDate,
    damping,
    dischargeOption,
    postProcessing: {
      fractileFractions: { first, second },
    },
  }
}

export const decodeDilution = (scenario) => {
  const {
    type,
    project,
    model,
    runHD,
    title,
    description,
    sources = [],
    version,
    components,
    endDateTime,
    startDateTime,
    damping = {},
    dischargeOption,
    postProcessing = {},
  } = scenario

  const { fractileFractions = {} } = postProcessing
  const { first = 0.5, second = 0.95 } = fractileFractions

  const {
    enabled = false,
    latitude = 0,
    longitude = 0,
    innerRadius = 2000,
    outerRadius = 3000,
  } = damping

  const hypotheticalOutlet = sources
    .filter((item) => item.type === 'hypothetical')
    .map(outletMapper)
  const existingOutlet = sources
    .filter((item) => item.type === 'existing')
    .map(outletMapper)

  return {
    version,
    definition: {
      title: title || '',
      description: description || '',
      model: { id: model },
      type: { id: type },
      project: { id: project },
      runHd: runHD,
      startDate: startDateTime,
      endDate: endDateTime,
    },
    components,
    outlet: { hypotheticalOutlet, existingOutlet, dischargeOption },
    damping: {
      enabled,
      latitude,
      longitude,
      innerRadius,
      outerRadius,
    },
    postProcessing: {
      fractileFractions: { first, second },
    },
  }
}
