import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'

const EditField = ({
  value,
  listIndex,
  disabled,
  placeHolder,
  field,
  onBlurCallback,
  width,
  type,
}) => {
  const [data, setData] = useState(value)

  const handleChange = (event) => {
    const { target } = event
    const { value } = target
    setData(value)
  }

  const handleOnBlur = () => {
    onBlurCallback(listIndex, field, data)
  }

  return (
    <TextField
      key={`components.${listIndex}.${field}`}
      fullWidth
      onBlur={handleOnBlur}
      onChange={handleChange}
      id={`components.${listIndex}.${field}`}
      name={`components ${listIndex}.${field}`}
      disabled={disabled}
      placeholder={placeHolder}
      value={data}
      type={type}
    />
  )
}

export default EditField
