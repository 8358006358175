import { useEffect } from 'react'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import mapSourceState from 'containers/Dilution/states/mapSource'

const useMapSource = (existingOutlet) => {
  const setMapSource = useSetRecoilState(mapSourceState)
  const resetMapSource = useResetRecoilState(mapSourceState)

  useEffect(() => {
    setMapSource(
      existingOutlet.map(({ include, source }) => ({
        include,
        lat: source.latitude,
        lon: source.longitude,
        name: source.label,
      }))
    )
    return () => resetMapSource()
  }, [existingOutlet])
}

export default useMapSource
