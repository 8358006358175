import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import MarkerIcon from '@material-ui/icons/Room'
import { Marker } from 'react-map-gl'
import flatten from 'lodash/flatten'
import { GeoJsonLayer } from '@deck.gl/layers'
import convert from 'color-convert'

import { getIndicators } from 'data/fishfarm'

import { ToolContext } from 'containers/NewFishfarm'

import Accordion from 'components/Accordion'

import Point from './partials/Point'

const Step = ({ form }) => {
  const { states } = useContext(ToolContext)
  const { mapbox } = states
  const {
    setMapIsHidingEditable,
    setMapMarkers,
    setMapStepsLayers,
    setMapIsEditable,
  } = mapbox

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [selectedLayer, setSelectedLayer] = useState('')
  const { values } = form
  const { siteInformation } = values
  const { features } = siteInformation

  const [activePoint, setActivePoint] = useState([])

  useEffect(() => {
    handleDidMount()
    return () => handleDidUnmount()
  }, [])

  useEffect(() => {
    handleMapMarkers()
  }, [activePoint])

  useEffect(() => {
    if (!!data.length) handleMapVisualization()
  }, [selectedLayer, data])

  const handleMapVisualization = () => {
    setMapStepsLayers([
      new GeoJsonLayer({
        id: Date.now().toString(),
        data: {
          type: 'FeatureCollection',
          features: flatten(
            features.reduce((allFeatures, thisFeature, index) => {
              const { lat, lng } = thisFeature
              const layers = data[index]
              const newFeatures = layers
                .filter(({ id }) => id === selectedLayer)
                .map(({ assessmentAreas }) =>
                  assessmentAreas
                    .map(({ id, label, radius, impact }) => ({
                      type: 'Feature',
                      properties: {
                        id,
                        label,
                        radius,
                        ...impact,
                      },
                      geometry: {
                        type: 'Point',
                        coordinates: [Number(lng), Number(lat)],
                      },
                    }))
                    .sort((a, b) => {
                      const {
                        properties: { radius: radiusA },
                      } = a
                      const {
                        properties: { radius: radiusB },
                      } = b
                      return Number(radiusB) - Number(radiusA)
                    })
                )
              allFeatures.push(...newFeatures)
              return allFeatures
            }, [])
          ),
        },
        stroked: true,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 1,
        getFillColor: ({ properties }) =>
          convert.keyword.rgb(properties.colorCode),
        getLineColor: () => convert.keyword.rgb('black'),
        getRadius: ({ properties }) => properties.radius,
        getLineWidth: 1,
        visible: true,
        opacity: 0.5,
      }),
    ])
  }

  const handleSubmission = async () => {
    const indicators = await getIndicators({ values })
    setData(indicators)
    setIsLoading(false)
  }

  const handleDidMount = () => {
    setMapIsHidingEditable(true)
    setMapIsEditable(false)
    handleSubmission()
  }

  const handleDidUnmount = () => {
    setMapIsHidingEditable(false)
    setMapMarkers([])
    setMapStepsLayers([])
  }

  const handleActivePoint = ({ lat, lng }) => {
    const identifier = lat + lng
    const isActive = activePoint === identifier
    if (isActive) {
      setActivePoint(undefined)
    } else {
      setActivePoint(identifier)
    }
  }

  const handleMapMarkers = () => {
    const markers = features.map(({ lat, lng }) => {
      const isExpanded = activePoint === lat + lng
      const handleOnClick = () => handleActivePoint({ lat, lng })
      const color = isExpanded ? 'primary' : 'secondary'
      return (
        <div
          style={{
            position: 'absolute',
            zIndex: 2,
          }}
          onClick={handleOnClick}
        >
          <Marker
            latitude={Number(lat)}
            longitude={Number(lng)}
            offsetLeft={-17.5}
            offsetTop={-35}
          >
            <div style={{ cursor: 'pointer' }}>
              <MarkerIcon color={color} fontSize="large" />
            </div>
          </Marker>
        </div>
      )
    })
    setMapMarkers(markers)
  }

  return (
    <Box width={1} height={1}>
      <Box marginBottom={4}>
        <Typography variant="h6">Screening impact tables</Typography>
      </Box>
      {isLoading ? (
        <>
          <Box
            width={1}
            height={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" width={1}>
          {features.map((feature, index) => {
            const { lat, lng } = feature
            const isExpanded = activePoint === lat + lng
            const handleOnChange = () => handleActivePoint({ lat, lng })
            return (
              <Accordion
                key={index}
                headline={`Point ${index + 1}`}
                noPadding
                expanded={isExpanded}
                onChange={handleOnChange}
              >
                <Point
                  list={data[index]}
                  selectedLayer={selectedLayer}
                  setSelectedLayer={setSelectedLayer}
                />
              </Accordion>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default Step
