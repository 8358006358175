import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { existingSourcesInList as existingSourcesInListState } from 'containers/Dilution/states/existingSources'

const useMappedExistingSources = ({ form }) => {
  const existingSourcesInList = useRecoilValue(existingSourcesInListState)
  const { values, setFieldValue } = form
  const { outlet, dischargeOption } = values
  const { existingOutlet = [] } = outlet

  const mappedExistingSources = existingSourcesInList.map((itemFromModel) => {
    const {
      source: { label: labelFromModel },
      concentrations = [],
      dischargeOptions = [],
    } = itemFromModel
    const {
      include = true,
      concentrations: concentrationsFromForm = concentrations,
    } =
      existingOutlet.find(({ source: { label: labelFromForm } }) => {
        return labelFromForm === labelFromModel
      }) || {}
    const { discharges = [] } =
      dischargeOptions.find((option) => option.id === dischargeOption) || {}
    return {
      ...itemFromModel,
      include,
      concentrations: concentrationsFromForm,
      discharges,
    }
  })

  useEffect(() => {
    setFieldValue(`outlet.existingOutlet`, mappedExistingSources)
  }, [])
}

export default useMappedExistingSources
