import React, { useContext, useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { getScenario } from 'data/sedimentSpill'

import Control from 'components/CoreMap/MapControl'
import { SedimentSpillContext } from 'containers/SedimentSpill'

import { MODELS } from 'containers/SedimentSpill/config'

const enhancer = compose(withRouter)

const MapControl = ({ match }) => {
  const { params } = match
  const { scenarioId } = params

  const { state, actions } = useContext(SedimentSpillContext)
  const { timestep, drawControl, animationLoading } = state

  const [scenario, setScenario] = useState({})

  const { data } = scenario

  useEffect(() => {
    handleSetMapView()
  }, [scenario.data])

  useEffect(() => {
    if (scenarioId) setScenarioData()
  }, [scenarioId])

  const setScenarioData = async () => {
    const response = await getData()
    const scenearioAsJSON = { ...response, data: JSON.parse(response.data) }
    setScenario(scenearioAsJSON)
  }

  const getData = async () => {
    try {
      const data = await getScenario({
        scenarioId,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleSetMapView = () => {
    const hasData = !!data
    if (hasData) {
      const { Specifications, DredgingPlans } = data
      if ((Specifications, DredgingPlans)) {
        actions.setMapView({
          box: [
            Specifications.Domain.box.lowerLeft,
            Specifications.Domain.box.upperRight,
          ],
          center: [
            DredgingPlans.Plans[0].Positions[0].Latitude,
            DredgingPlans.Plans[0].Positions[0].Longitude,
          ],
          zoom: 10,
        })
      }
    }
  }

  const hasScenarioData = Object.keys(scenario).length

  if (!hasScenarioData) return null

  const { Specifications } = data
  const { Model } = Specifications
  const { id } = Model
  const activeModel = MODELS[id]
  const timezone = activeModel.timezone
  const customAnimationLayers = activeModel.animationLayers
  const customPlotLayers = activeModel.plotLayers

  return (
    <Control
      animationLoading={animationLoading}
      drawControl={drawControl}
      onAnimate={actions.onAnimate}
      onChangeTimestep={actions.onChangeTimestep}
      openControls={true}
      timestep={timestep}
      viewScenario={scenario}
      customAnimationLayers={customAnimationLayers}
      customPlotLayers={customPlotLayers}
      timezone={timezone}
    />
  )
}

export default enhancer(MapControl)
