import React, { useEffect, useState } from 'react'
import { useResetRecoilState, useRecoilState } from 'recoil'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import Block from 'components/Block'
import mapDampingState from 'containers/Dilution/states/mapDamping'
import useMapSource from 'containers/Dilution/hooks/useMapSource'

import ErrorMessage from 'components/ErrorMessage'
import Popper from 'components/Popper'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const Step = ({ form }) => {
  const { values, handleChange, setFieldValue, errors } = form
  const { damping = {}, outlet = {} } = values
  const { enabled, latitude, longitude, innerRadius, outerRadius } = damping
  const { hypotheticalOutlet = [], existingOutlet = [] } = outlet
  const [dampingState, setDampingState] = useRecoilState(mapDampingState)
  const resetDamping = useResetRecoilState(mapDampingState)
  const { t } = useTranslation()

  const withExistingOutlet = !!existingOutlet.length
  const withLatitude = latitude && latitude > 0
  const withLongitude = longitude && longitude > 0

  const [combinedOutlets, setCombinedOutlets] = useState([])

  useMapSource(combinedOutlets)

  useEffect(() => {
    setCombinedOutlets(hypotheticalOutlet.concat(existingOutlet))
  }, [])

  useEffect(() => {
    if (withExistingOutlet && withLatitude && withLongitude) {
      setDampingState({
        enabled: enabled,
        latitude: latitude,
        longitude: longitude,
        innerRadius: innerRadius,
        outerRadius: outerRadius,
        name: 'Select and Drag',
      })
    } else {
      const [master] = hypotheticalOutlet.filter(({ include }) => include)
      if (withExistingOutlet && master) {
        const {
          source: { latitude, longitude },
        } = master
        const damp = {
          name: 'Select and Drag',
          latitude,
          longitude,
          innerRadius: 1000,
          outerRadius: 3000,
          enabled: false,
        }
        setDampingState(damp)
        setFieldValue(`damping`, damp)
      }
    }
    return () => resetDamping()
  }, [latitude, longitude, enabled, outerRadius])

  useEffect(() => {
    if (dampingState.latitude && dampingState.longitude) {
      setFieldValue(`damping`, dampingState)
    }
  }, [dampingState.latitude])

  return (
    <Box width={1} height={1}>
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Popper
          icon={<HelpOutlineIcon />}
          popperContent={t('documentation:dampingTitle')}
          placement="left"
        />
      </Box>
      {withExistingOutlet ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="damping.enabled"
                    name="damping.enabled"
                    checked={enabled}
                    onChange={handleChange}
                  />
                }
                label={t('dilution:enableDamping')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Block headline={t('common:latitude')}>
                <TextField
                  fullWidth
                  id="damping.latitude"
                  name="damping.latitude"
                  onChange={handleChange}
                  type="number"
                  value={latitude}
                  disabled={!enabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°N</InputAdornment>
                    ),
                  }}
                />
              </Block>
            </Grid>
            <Grid item xs={6}>
              <Block headline={t('common:longitude')}>
                <TextField
                  fullWidth
                  id="damping.longitude"
                  name="damping.longitude"
                  onChange={handleChange}
                  type="number"
                  value={longitude}
                  disabled={!enabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°E</InputAdornment>
                    ),
                  }}
                />
              </Block>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Block headline={t('dilution:innerRadius')}>
                <TextField
                  fullWidth
                  id="damping.innerRadius"
                  name="damping.innerRadius"
                  onChange={handleChange}
                  type="number"
                  value={innerRadius}
                  disabled={!enabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </Block>
            </Grid>
            <Grid item xs={6}>
              <Block headline={t('dilution:outerRadius')}>
                <TextField
                  fullWidth
                  id="damping.outerRadius"
                  name="damping.outerRadius"
                  onChange={handleChange}
                  type="number"
                  value={outerRadius}
                  disabled={!enabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </Block>
            </Grid>
          </Grid>
          <Box marginTop={4}>
            <ErrorMessage
              errors={errors}
              fields={['damping.outerRadius', 'damping.innerRadius']}
            />
          </Box>
        </>
      ) : (
        <Box
          marginBottom={4}
          padding={4}
          display="flex"
          flexDirection="colunn"
          justifyContent="center"
          alignItems="center"
          bgcolor="shades.light"
          border={1}
          borderColor="shades.medium"
        >
          <Typography color="primary" variant="subtitle1">
            {t('dilution:dampingScenario')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Step
