import API from '@dhi-solutions/domain-services'

const rootUrl = 'https://me-prod-brain.westeurope.cloudapp.azure.com/users'

const apiBaseUrl =
  'https://me-prod-brain.westeurope.cloudapp.azure.com/users/api'

export const fetchCompanies = async () => {
  try {
    const endpoint = `/users/companies`
    const response = await API.get(endpoint, {
      baseURL: rootUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}

export const fetchAccounts = async () => {
  try {
    const endpoint = `/accounts`
    const response = await API.get(endpoint, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}

export const fetchAccount = async ({ id }) => {
  try {
    const endpoint = `/accounts/${id}`
    const response = await API.get(endpoint, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}

export const createAccount = async body => {
  try {
    const response = await API.post(`/accounts`, body, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}

export const deleteAccount = async ({ id }) => {
  try {
    const endpoint = `/accounts/${id}`
    const response = await API.delete(endpoint, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}

export const updateAccount = async body => {
  try {
    const response = await API.put(`/accounts`, body, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}

export const resetPassword = async body => {
  try {
    const endpoint = `/passwordreset`
    const response = await API.post(endpoint, body, { baseUrl: apiBaseUrl })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
  }
}
