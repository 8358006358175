import React, { lazy, useEffect } from 'react'
import { compose } from 'recompose'
import { Box, withStyles, Typography } from '@material-ui/core'
import { Network } from 'react-fns'
import { Switch, useLocation } from 'react-router-dom'
import { useSetRecoilState, useResetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import loaderState from 'states/loader'

import useProfile from 'hooks/useProfile'

import tools from 'config/tools'
import pages from 'config/pages'

import Communicator from 'components/Communicator'
import Route from 'components/Route'
import Loader from 'components/Loader'
import Navigation from 'components/Navigation'

import styles from './styles'

const Login = lazy(() => import('containers/Login'))
const Logout = lazy(() => import('containers/Logout'))
const Home = lazy(() => import('containers/Home'))
const Admin = lazy(() => import('containers/Admin'))
const Dredging = lazy(() => import('containers/Dredging'))
const Users = lazy(() => import('containers/Users'))
const Print = lazy(() => import('containers/Print'))

const routes = [
  {
    path: '/login',
    component: Login,
    redirect: '/',
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/dredging',
    component: Dredging,
    isPrivate: true,
  },
  {
    path: '/admin',
    component: Admin,
    isPrivate: true,
  },
  {
    path: '/users',
    component: Users,
    isPrivate: true,
    redirect: '/',
  },
  {
    path: '/print',
    component: Print,
    isPrivate: true,
    redirect: '/',
  },
  {
    path: '/',
    component: Home,
    isPrivate: true,
    isExact: true,
  },
]

const enhancer = compose(withStyles(styles, { withTheme: true }))

const AppShell = ({ classes }) => {
  const { t } = useTranslation()

  const startLoading = useSetRecoilState(loaderState)
  const stopLoading = useResetRecoilState(loaderState)

  const { pathname } = useLocation()
  const { loading, login, logout, isUser, isAdmin } = useProfile()

  const handleScrollToTop = () => window.scrollTo(0, 0)

  useEffect(() => {
    handleScrollToTop()
  }, [pathname])

  if (loading) return null

  return (
    <Communicator startLoading={startLoading} stopLoading={stopLoading}>
      <Network
        render={({ online }) => (
          <>
            {online ? (
              <div className={classes.root}>
                <Loader />
                {isUser && <Navigation />}
                <main className={classes.content}>
                  <Switch>
                    {[
                      ...pages,
                      ...tools.map(tool => ({
                        ...tool,
                        isPrivate: true,
                        isExact: true,
                        login,
                        logout,
                        isUser,
                        isAdmin,
                      })),
                      ...routes.map(route => ({
                        ...route,
                        login,
                        logout,
                        isUser,
                        isAdmin,
                      })),
                    ].map(({ path, component, redirect, ...rest }) => (
                      <Route
                        key={path}
                        path={path}
                        component={component}
                        {...rest}
                      />
                    ))}
                  </Switch>
                </main>
              </div>
            ) : (
              <Box
                width={1}
                height={1}
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Loader isLoading isMajorLoading />
                <Box
                  position="absolute"
                  bottom={0}
                  width="100vw"
                  height="50vh"
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  zIndex="2000"
                  className={classes.offlineContainer}
                >
                  <Typography variant="h5" color="inherit">
                    {t('common:notOnline')}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      />
    </Communicator>
  )
}

export default enhancer(AppShell)
