import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import Fab from '@material-ui/core/Fab'
import { useHistory, useLocation } from 'react-router-dom'

const Header = React.memo(({ heading, title }) => {
  const { push } = useHistory()
  const { state = {}, ...rest } = useLocation()
  const path = rest.pathname.split("/").filter((_, i) => i < 2).join("/");
  const { projectId } = state
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginY={2}
    >
      <Box>
        <Typography variant="h6">{heading}: {title}</Typography>
      </Box>
      <Box>
        <Tooltip title="Exit view">
          <Fab
            size="small"
            color="primary"
            aria-label="Close"
            onClick={() => push(`${path}/${projectId}`)}
          >
            <CloseIcon />
          </Fab>
        </Tooltip>
      </Box>
    </Box>
  )
})

export default Header
