import SedimentSpill from 'containers/SedimentSpill'
import Dataviewer from 'containers/Dataviewer'
import Habitats from 'containers/Habitats'
import NewFishfarm from 'containers/NewFishfarm'
import Dilution from 'containers/Dilution'
import MarineExplorer from 'containers/MarineExplorer'
import LegacyFishfarm from 'containers/LegacyFishfarm'
import MarineMonitoring from 'containers/MarineMonitoring'

import { TOOLS, HOSTS } from './constants'

export const sedimentSpill = {
  component: SedimentSpill,
  path: `/${TOOLS.SEDIMENT_SPILL}`,
  apiBaseUrl: `/${HOSTS.SEDIMENT_SPILL}`,
  title: 'PlumeCast',
  subtitle: 'Screening of impacts from marine dredging projects',
  shortDescription:
    'Our Marine Impact tool allows you to calculate the dispersal of spilled sediment and resulting reduction in available light for marine plants at the seabed. The scenarios use DHIs MIKE 3D models to calculate hydrodynamics, sediment transport and the light reduction in the water column caused by spilled sediment. You specify the position, the duration and intensity of the dredging and the character of the sediment, through our simple and intuitive interface.',
  description: [
    'You get access to a marine impact tool that makes it possible for non-expert to calculate the dispersal of the spilled sediment and resulting reduction in available light for marine plants at the seabed. The scenarios use DHIs MIKE 3D models to calculate hydrodynamics, sediment transport and the light reduction in the water column caused by spilled sediment. You specify the position, the duration and intensity of the dredging and the character of the sediment.',
    'The tool can be used for impact assessments in areas where the background model have a sufficiently high resolution and as a screening tool in areas where the model-grid is coarse.',
    'Calibrated and validated models calculate your scenarios and allow you to download result shapefiles for your report figures.',
  ],
  isReady: true,
}

export const newFishfarm = {
  component: NewFishfarm,
  path: `/${TOOLS.FISHFARM_SCREENING}`,
  apiBaseUrl: `/${HOSTS.FISHFARM_SCREENING}`,
  title: 'Fish Farm',
  subtitle: 'Impact Screening',
  shortDescription:
    'Every feed-based aquaculture activity invariably gives rise to changes in the environmental conditions locally or further away. The Fish Farm screening tool is surrogate models for benthic and pelagic variables developed based on output form mechanistic models of environmental impacts from marine fish farms.',
  description: [
    'Every feed-based aquaculture activity invariably gives rise to changes in the environmental conditions locally or further away. The Fish Farm screening tool is surrogate models for benthic and pelagic variables developed based on output form mechanistic models of environmental impacts from marine fish farms.',
  ],
  isReady: true,
  isHidden: false,
}

export const legacyFishfarm = {
  component: LegacyFishfarm,
  path: `/old-${TOOLS.FISHFARM_SCREENING}`,
  apiBaseUrl: `/${HOSTS.FISHFARM_SCREENING}`,
  title: 'Fish Farm Impact Screening',
  subtitle: 'Placeholder subtitle placeholder subtitle',
  description: [],
  isReady: true,
  isHidden: true,
}

export const marineScreening = {
  component: Dataviewer,
  path: `/${TOOLS.MARINE_SCREENING}`,
  apiBaseUrl: `/${HOSTS.MARINE_SCREENING}`,
  title: 'Prescreening',
  subtitle:
    'View relevant data on the marine environment from external databases',
  shortDescription:
    'Get a first overview of the planned project site. What is the current use, the water depth, wave and current exposure, distance to sensitive species and habitats? Here you get access to up-to-date data from all relevant database like HELCOM, OSPAR and Danmarks Miljøportal. Upload a shapefile or draw your project area to view and measure distances.',
  description: [
    'Get a first overview of the planned project site. What is the current use, the water depth, wave and current exposure, distance to sensitive species and habitats? Here you get access to up-to-date data from all relevant database like HELCOM, OSPAR and Danmarks Miljøportal. Upload a shapefile or draw your project area to view and measure distances.',
  ],
  isReady: true,
  isHidden: false,
}

export const habitats = {
  component: Habitats,
  path: `/${TOOLS.HABITATS}`,
  apiBaseUrl: `/${HOSTS.SEDIMENT_SPILL}`,
  title: 'Habitats',
  subtitle: 'This a placeholder subtitle',
  description: [],
  isReady: false,
  isHidden: false,
}

export const marineMonitoring = {
  component: MarineMonitoring,
  path: `/${TOOLS.MARINE_MONITORING}`,
  apiBaseUrl: `/${HOSTS.SEDIMENT_SPILL}`,
  title: 'Marine Monitoring',
  subtitle: 'Placeholder subtitle placeholder subtitle',
  description: [],
  isReady: false,
  isHidden: false,
}

export const dilution = {
  component: Dilution,
  path: `/${TOOLS.DILUTION}`,
  apiBaseUrl: `/${HOSTS.DILUTION}`,
  title: 'EnviroCast',
  subtitle: 'Point source impact screening',
  shortDescription:
    'The point source screening tool allows you to screen impacts of any outlet scenario in the marine environment. Powered by DHI’s MIKE 3D models the tool calculates variations in time and space of discharge plumes arising from your outlet scenario. You may inspect plumes as animations or statistical plots making it easy to assess potential impacts.',
  description: [
    'The point source screening tool allows you to screen impacts of any outlet scenario in the marine environment. Powered by DHI’s MIKE 3D models the tool calculates variations in time and space of discharge plumes arising from your outlet scenario. You may inspect plumes as animations or statistical plots making it easy to assess potential impacts.',
  ],
  isReady: true,
  isHidden: false,
}

export const marineExplorer = {
  component: MarineExplorer,
  path: `/${TOOLS.MARINE_EXPLORER}`,
  apiBaseUrl: `/${HOSTS.MARINE_EXPLORER}`,
  title: 'Marine Explorer',
  subtitle: '',
  shortDescription:
    '',
  description: [
    '',
  ],
  isReady: true,
  isHidden: false,
}


export default [
  newFishfarm,
  legacyFishfarm,
  habitats,
  marineMonitoring,
  marineScreening,
  sedimentSpill,
  dilution,
  marineExplorer
]
  .filter(tool => tool.isReady)
  .filter(tool => !tool.isHidden)
  .sort((first, second) => {
    const firstTitle = first.title.toUpperCase()
    const secondTitle = second.title.toUpperCase()
    if (firstTitle < secondTitle) {
      return -1
    }
    if (firstTitle > secondTitle) {
      return 1
    }
    return 0
  })
