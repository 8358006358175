const defaultValues = {
  version: '',
  definition: {
    title: '',
    description: '',
    project: '',
    model: {
      id: '',
    },
    type: {
      id: '',
    },
    runHd: false,
    startDate: '',
    endDate: '',
  },
  postProcessing: {
    fractileFractions: {
      first: 0.9,
      second: 0.95,
    },
  },
  components: [],
  damping: {
    enabled: false,
    latitude: 0,
    longitude: 0,
    innerRadius: 2000,
    outerRadius: 3000,
  },
  outlet: {
    hypotheticalOutlet: [
      // {
      //   type: 'hypothetical',
      //   checked: false,
      //   source: {
      //     name: '',
      //     latitude: '',
      //     longitude: '',
      //     depth: '',
      //   },
      //   discharges: [
      //     {
      //       timestamp: '',
      //       dischargeValue: '',
      //     },
      //   ],
      //   density: {
      //     temperature: '',
      //     salinity: '',
      //   },
      //   concentrations: [
      //     {
      //       id: '',
      //       label: '',
      //       value: '',
      //       unit: '',
      //     },
      //   ],
      // },
    ],
    existingOutlet: [
      // {
      //   type: 'existing',
      //   checked: false,
      //   source: {
      //     name: '',
      //     latitude: '',
      //     longitude: '',
      //     depth: '',
      //   },
      //   discharges: [
      //     {
      //       timestamp: '',
      //       dischargeValue: '',
      //     },
      //   ],
      //   density: {
      //     temperature: '',
      //     salinity: '',
      //   },
      //   concentrations: [
      //     {
      //       id: '',
      //       label: '',
      //       value: '',
      //       unit: '',
      //     },
      //   ],
      // },
    ],
    dischargeOption: '1',
  },
}

export default (initialValues = defaultValues) => initialValues
