import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { MVTLayer, TileLayer } from '@deck.gl/geo-layers'
import { BitmapLayer } from '@deck.gl/layers'
import assignIn from 'lodash/assignIn'

import { mapOverlays as mapOverlaysState } from 'containers/Dilution/states/mapOverlays'

const toLayer = ({ type, config }) => {
  if (type === 'mvt') {
    const options = assignIn(
      { ...config },
      {
        pickable: true,
        onTileError: () => ({}),
        // onHover: (a, b, c) => console.log('***', a, b, c),
      }
    )
    return new MVTLayer(options)
  } else if (type === 'tile') {
    const options = assignIn(
      { ...config },
      {
        pickable: true,
        highlightColor: () => config.highlightColor,
        renderSubLayers: (props) => {
          const {
            bbox: { west, south, east, north },
          } = props.tile
          return new BitmapLayer(props, {
            data: null,

            image: props.data,
            bounds: [west, south, east, north],
          })
        },
        onTileError: () => ({}),
        // onHover: (a, b, c) => console.log('***', a, b, c),
      }
    )
    return new TileLayer(options)
  }
}

const useLayers = () => {
  const [layers, setLayers] = useState([])
  const mapOverlays = useRecoilValue(mapOverlaysState)

  useEffect(() => {
    return () => setLayers([])
  }, [])

  useEffect(() => {
    setLayers(mapOverlays.map(toLayer))
  }, [mapOverlays])

  return layers
}

export default useLayers
