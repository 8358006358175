import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Article from 'components/Article'

const Contact = () => {
  return (
    <Article headline="Contact">
      <Box py={3}>
        <Typography variant="h5">
          With our global network of offices, we make sure you get the right
          answers to your local needs.
        </Typography>
      </Box>

      <Box py={3}>
        <Typography variant="h5">DHI A/S</Typography>
        <Typography variant="h5">Agern Alle 5</Typography>
        <Typography variant="h5">2970 Hørsholm</Typography>
        <Typography variant="h5">Denmark</Typography>
        <Typography variant="h5">CVR no: 36466871</Typography>
      </Box>
      <Box py={3}>
        <Typography variant="h5">+45 4516 9200</Typography>
      </Box>
      <Box py={3}>
        <Typography variant="h5">
          <a href="mailto:ami@dhigroup.com">info@dhigroup.com</a>
        </Typography>
      </Box>
    </Article>
  )
}

export default Contact
