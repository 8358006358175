import React, { Suspense, createRef, createContext, useState } from 'react'
import { compose } from 'recompose'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import { Switch, Route } from 'react-router-dom'

import styles from 'styles/global'

import Loader from 'components/Loader'

import List from './partials/List'
import Form from './partials/Form'

export const ToolContext = createContext()

const enhancer = compose(withStyles(styles, { withTheme: true }))

const Fishfarm = () => {
  const editor = createRef()

  const [mapPosition, setMapPosition] = useState({
    latitude: 55.83273,
    longitude: 10.59433,
    zoom: 6,
  })
  const [mapSource, setMapSource] = useState([])
  const [mapManagerLayers, setMapManagerLayers] = useState([])
  const [mapStepsLayers, setMapStepsLayers] = useState([])
  const [mapMarkers, setMapMarkers] = useState([])
  const [mapInteractions, setMapInteractions] = useState({})

  const [mapEditableData, setMapEditableData] = useState({
    type: 'FeatureCollection',
    features: [],
  })

  const [mapIsEditable, setMapIsEditable] = useState(true)
  const [mapIsHidingEditable, setMapIsHidingEditable] = useState(false)

  const [mapEditor, setMapEditor] = useState({
    withPolygon: false,
    withPath: false,
    withPoint: false,
  })

  const states = {
    mapbox: {
      mapPosition,
      setMapPosition,
      mapSource,
      setMapSource,
      mapInteractions,
      setMapInteractions,
      mapEditor,
      setMapEditor,
      mapManagerLayers,
      setMapManagerLayers,
      mapEditableData,
      setMapEditableData,
      mapIsEditable,
      setMapIsEditable,
      mapIsHidingEditable,
      setMapIsHidingEditable,
      mapMarkers,
      setMapMarkers,
      mapStepsLayers,
      setMapStepsLayers,
    },
  }

  const refs = {
    editor,
  }

  return (
    <ToolContext.Provider value={{ states, refs }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        height="calc(100vh - 80px)"
      >
        <Suspense fallback={<Loader isLoading isMajorLoading />}>
          <Switch>
            <Route path="/fishfarm-screening" exact>
              <List />
            </Route>
            <Route path="/fishfarm-screening/form/:id?">
              <Form />
            </Route>
          </Switch>
        </Suspense>
      </Box>
    </ToolContext.Provider>
  )
}

export default enhancer(Fishfarm)
