import React, { useState } from 'react'
import { Formik, FieldArray } from 'formik'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'

import find from 'lodash/find'
import isEqual from 'lodash/isEqual'

import Block from 'components/Block'
import DropdownSearch from 'components/DropdownSearch'

import useProject from 'containers/Dilution/hooks/useProject'
import { useTranslation } from 'react-i18next'

const Form = ({
  id,
  handleClose,
  handleGetProjects,
  header: Header,
  content: Content,
  footer: Footer,
  users,
}) => {
  const [dropdownValue, setDropdownValue] = useState()
  const { project, addProject, editProject } = useProject({ id })
  const { t } = useTranslation()

  const { data, ...meta } = project

  const editMode = id !== null

  const handleSubmit = (values, actions) => {
    const submit = editMode ? editProject : addProject
    submit(values, meta)
    actions.setSubmitting(false)
    actions.resetForm()
    handleGetProjects()
    handleClose()
  }

  const handleDropdownChange = (assignedUsers, id, callback) => {
    const isAlreadyIn = find(assignedUsers, (assignedUser) =>
      isEqual(assignedUser, id)
    )
    setDropdownValue('')
    if (callback && !isAlreadyIn) callback()
  }

  return (
    <Formik
      initialValues={{ assignedUsers: [], ...data }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, handleChange, values }) => (
        <form onSubmit={handleSubmit}>
          <Header>
            <Typography>
              {editMode
                ? t('common:edit', { suffix: t('common:project') })
                : t('common:create', { suffix: t('common:project') })}
            </Typography>
          </Header>
          <Content>
            <Block headline={t('common:title')}>
              <TextField
                fullWidth
                onChange={handleChange}
                id="title"
                name="title"
                placeholder={t('common:typeIn', { suffix: t('common:title') })}
                value={values.title}
              />
            </Block>

            <FieldArray
              name="assignedUsers"
              render={(arrayHelpers) => (
                <>
                  <Block headline={t('dilution:assignedUsers')}>
                    {!values.assignedUsers.length ? (
                      <Box
                        padding={2}
                        display="flex"
                        flexDirection="colunn"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="shades.light"
                        border={1}
                        borderColor="shades.medium"
                      >
                        <Typography color="primary" variant="subtitle1">
                          {t('dilution:noUsers')}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {values.assignedUsers.map((id, index) => {
                          const { name } = users.find((user) => user.id === id)
                          return (
                            <Chip
                              key={id}
                              label={name}
                              onDelete={() => arrayHelpers.remove(index)}
                            />
                          )
                        })}
                      </>
                    )}
                  </Block>
                  <Block headline={t('dilution:availableUsers')}>
                    <DropdownSearch
                      placeholder={t('dilution:selectUser')}
                      id="project"
                      onChange={({ id }) =>
                        handleDropdownChange(values.assignedUsers, id, () =>
                          arrayHelpers.push(id)
                        )
                      }
                      options={users}
                      getOptionLabel={({ name }) => name}
                      value={dropdownValue}
                    />
                  </Block>
                </>
              )}
            />
          </Content>
          <Footer>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              {t('common:close')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('common:save')}
            </Button>
          </Footer>
        </form>
      )}
    </Formik>
  )
}

export default Form
