import { string, object, number } from 'yup'

import Step2 from './Step2'

export default {
  title: 'Production of Rainbow Trout',
  component: Step2,
  validationSchema: () =>
    object().shape({
      production: object().shape({
        tonnes: number()
          .min(800, 'Minimum production is 800')
          .max(6000, 'Maximum production is 6000'),
        cld3mo: string().required('No C-Ld-3mo added.'),
        cld4mo: string().required('No C-Ld-4mo added.'),
        nld3mo: string().required('No N-Ld-3mo added.'),
        pld2mo: string().required('No P-Ld-2mo added.'),
        nh4ld: string().required('No NH4-Ld added.'),
      }),
    }),
}
