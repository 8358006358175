import { atom, selector } from 'recoil'

import { activeModelState } from './activeModel'

export const dischargeOptions = atom({
  key: 'dischargeOptions',
  default: selector({
    key: 'dischargeOptionsFromActiveModel',
    get: ({ get }) => {
      const { dischargeOptions = [] } = get(activeModelState)
      return dischargeOptions
    },
  }),
})

export default dischargeOptions
