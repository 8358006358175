export default ({
  existingOutlet,
  dischargeOption,
  fixedOutletConcentration,
  predefinedComponents,
  components,
}) => {
  const mappedOutlets = existingOutlet.map((existingOutletItem) => {
    const { concentrations } = existingOutletItem

    const dictionary = concentrations.reduce(
      (allConcentrations, thisConcentration) => {
        const { componentDescription, concentrationValue } = thisConcentration
        allConcentrations[componentDescription] = concentrationValue
        return allConcentrations
      },
      {}
    )

    const getConcentrations = (list) =>
      list.map(
        ({
          componentDescription,
          concentrationValue,
          unit,
          include = true,
        }) => {
          const value = dictionary[componentDescription] || concentrationValue
          return {
            componentDescription,
            concentrationValue: concentrationValue || value || 0,
            unit,
            include,
          }
        }
      )

    const concentrationsToSet = fixedOutletConcentration
      ? getConcentrations(predefinedComponents)
      : getConcentrations(components)

    const { dischargeOptions } = existingOutletItem
    const dischargeMatcher = (option) => option.id === dischargeOption
    const { discharges = [] } = dischargeOptions.find(dischargeMatcher) || {}
    return {
      ...existingOutletItem,
      discharges,
      concentrations: concentrationsToSet,
    }
  })
  return mappedOutlets
}
