import API from '@dhi-solutions/domain-services'

import { sedimentSpillBaseUrl as apiBaseUrl } from 'config/constants'

export const executeScenario = async ({ parameters, taskId }) => {
  try {
    const response = await API.post(
      `/jobs/Worker`,
      {
        TaskId: taskId,
        Parameters: parameters,
      },
      {
        baseURL: apiBaseUrl,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
