import React, { memo, useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import CoreListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import NoteAdd from '@material-ui/icons/NoteAdd'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

import Accordion from 'components/Accordion'

import Toggle from './partials/VectorIsolinesToggle'
import CustomLegends from './partials/CustomLegends'

import Chart from '../Chart'

import userDefinedLegendsState from 'containers/Dilution/states/userDefinedLegends'

const Layer = memo(
  ({
    scenarioId,
    item,
    selectedItem,
    setSelectedItem,
    handleMapPreview,
    viewFeatures,
    chart = { keys: [], data: [] },
    resetChart,
    withCustomLegends,
    downloadShapeBtn,
  }) => {
    const [isCustomLegendsEnabled, setIsCustomLegendsEnabled] = useState(false)
    const [customLegends, setCustomLegends] = useRecoilState(
      userDefinedLegendsState
    )
    const { id, label, legend: itemLegends = [] } = item
    const hasCustomLegends = !!customLegends[id]
    const isExpanded = selectedItem.label === label
    const { mapStyle } = hasCustomLegends ? customLegends[id] : item
    const itemToSet = isExpanded
      ? {}
      : {
        ...item,
        mapStyle,
      }
    const handleChange = () => {
      setSelectedItem(itemToSet)
    }
    const isChart = chart.keys && chart.keys.length > 0
    const { t } = useTranslation()

    const [ref, { height: heightParent, width: widthParent }] = useMeasure()
    const [refLegend, { width: widthLegend }] = useMeasure()
    const chartWidth = widthParent - widthLegend

    const handleCustomLegendsToggle = (event) => {
      const enabled = event.target.checked
      setIsCustomLegendsEnabled(enabled)
    }

    const { legend = [] } = customLegends[id] || { legend: itemLegends }

    useEffect(() => {
      if (isExpanded) {
        setSelectedItem({
          ...item,
          mapStyle,
        })
      }
    }, [customLegends[id]])

    return (
      <Accordion
        headline={label}
        noPadding
        expanded={isExpanded}
        onChange={handleChange}
        option={
          <Toggle
            vectorOption={item.vectorOption}
            vectorNumberHorizontal={item.vectorNumberHorizontal}
            label={item.label}
            {...viewFeatures}
          />
        }
      >
        <Box width={1}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            bgcolor="shades.light"
            padding="1rem"
            width={1}
          >
            <FormControlLabel
              labelPlacement="start"
              checked={isCustomLegendsEnabled}
              control={
                <Switch size="small" onChange={handleCustomLegendsToggle} />
              }
              label={
                <Typography variant="body2">
                  {t('common:customizeLegend')}
                </Typography>
              }
            />
            <Tooltip title={t('pdf:addChosenMapview')}>
              <Box marginLeft="1rem">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<NoteAdd />}
                  onClick={handleMapPreview}
                >
                  {t('common:addTo', { suffix: t('common:report') })}
                </Button>
              </Box>
            </Tooltip>
            {downloadShapeBtn && downloadShapeBtn}
          </Box>
          {isExpanded && isCustomLegendsEnabled && withCustomLegends && (
            <CustomLegends
              id={id}
              scenarioId={scenarioId}
              list={legend}
              setIsCustomLegendsEnabled={setIsCustomLegendsEnabled}
              customLegends={customLegends}
              setCustomLegends={setCustomLegends}
            />
          )}
          <Box style={{ display: 'flex', alignItems: 'flex-start' }} ref={ref}>
            {!isCustomLegendsEnabled && (
              <List ref={refLegend} style={{ minWidth: 150 }}>
                {legend.map(
                  ({ color: legendColor, label: legendLabel }, index) => (
                    <CoreListItem key={`${label}-${legendLabel}-${index}`}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width={1}
                      >
                        <Box
                          width={15}
                          height={15}
                          marginRight={2}
                          style={{ backgroundColor: legendColor }}
                          border={1}
                          borderColor="black"
                        />
                        <Box>{legendLabel}</Box>
                      </Box>
                    </CoreListItem>
                  )
                )}
              </List>
            )}
            {isExpanded && isChart && (
              <Box
                style={{
                  display: 'flex',
                  width: chartWidth,
                  height: heightParent,
                  padding: 10,
                }}
              >
                <Chart chartdata={chart} resetChart={resetChart} />
              </Box>
            )}
          </Box>
        </Box>
      </Accordion>
    )
  }
)

export default Layer
