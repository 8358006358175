import { object, array, string, boolean } from 'yup'
import some from 'lodash/some'

import Step3 from './Step3'

export default {
  component: Step3,
  validationSchema: ({ options, t }) =>
    object().shape({
      outlet: object().shape({
        hypotheticalOutlet: array()
          .min(1)
          .of(
            object().shape({
              type: string().required(
                t('errors:noAdded', { key: t('common:type') })
              ),
              include: boolean(),
              source: object().shape({
                label: string().required(
                  t('errors:noAdded', { key: t('common:label') })
                ),
                latitude: string().required(
                  t('errors:noAdded', { key: t('common:latitude') })
                ),
                longitude: string().required(
                  t('errors:noAdded', { key: t('common:longitude') })
                ),
                depth: string().required(
                  t('errors:noAdded', { key: t('common:depth') })
                ),
              }),
              discharges: array()
                .min(1)
                .of(
                  object().shape({
                    timestamp: string().required(
                      t('errors:noAdded', { key: t('common:timestamp') })
                    ),
                    dischargeValue: string().required(
                      t('errors:noAdded', { key: t('common:value') })
                    ),
                  })
                ),
              density: object().shape({
                temperature: string().required(
                  t('errors:noAdded', { key: t('common:temperature') })
                ),
                salinity: string().required(
                  t('errors:noAdded', { key: t('common:salinity') })
                ),
              }),
              concentrations: array().of(
                object().shape({
                  id: string(),
                  componentDescription: string(),
                  concentrationValue: string(),
                  unit: string(),
                })
              ),
            })
          )
          .test({
            name: 'with-selected',
            // eslint-disable-next-line
            message: '${message}',
            params: {
              message: t('errors:missingSelectedOutlet'),
            },
            test: (hypotheticalOutlets) => {
              if (options.onlyOneHypotheticalSource) {
                const matcher = ({ include }) => !!include
                const isLengthMatching =
                  hypotheticalOutlets.filter(matcher).length === 1
                return isLengthMatching
              } else {
                const withSelectedOutlet = some(hypotheticalOutlets, {
                  include: true,
                })
                return withSelectedOutlet
              }
            },
          }),
      }),
    }),
}
