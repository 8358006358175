import React from 'react'
import { Box, Typography } from '@material-ui/core'

const Block = ({
  headline,
  subline,
  headlineMarginBottom = 1,
  variant = 'subtitle1',
  children,
  py = 1,
  px = 0,
  helper,
}) => (
  <Box py={py} px={px} width="100%">
    {headline && (
      <Box mb={headlineMarginBottom} px={px} width="100%">
        <Box display="flex">
          <Typography variant={variant}>
            <span dangerouslySetInnerHTML={{ __html: headline }} />
          </Typography>
          {helper && <>{helper}</>}
        </Box>
        {subline && (
          <Box>
            <Typography variant="overline" color="primary">
              <span dangerouslySetInnerHTML={{ __html: subline }} />
            </Typography>
          </Box>
        )}
      </Box>
    )}
    {children}
  </Box>
)

export default Block
