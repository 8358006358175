import { atom } from 'recoil'

export default atom({
  key: 'timesteps',
  default: {
    timesteps: {},
    timestepsByDay: {},
    chosenTimesteps: [],
    requestingTimesteps: {},
  },
})
