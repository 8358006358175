import React from 'react';
import { compose } from 'recompose';
import { Typography, TextField, withStyles } from '@material-ui/core';
import { Field } from 'formik';

import withHabitatsState from 'hocs/withHabitatsState';

import Block from 'components/Block';

import styles from './styles';

const enhancer = compose(
  withHabitatsState,
  withStyles(styles)
);

const Step = ({ shared }) => {
  const { formik } = shared;
  const { errors, values, handleChange } = formik;
  const hasFile = values.csv.file && values.csv.file.length > 0;
  const isUnsupported =
    hasFile && errors.csv && errors.csv.file === 'Unsupported Format';
  return (
    <>
      <Block headline="Upload CSV">
        <Field
          component={TextField}
          autoComplete="off"
          type="file"
          id="csv.file"
          name="csv.file"
          onChange={handleChange}
          value={values.csv.file}
          fullWidth
          margin="dense"
          variant="outlined"
        />
        {isUnsupported && (
          <Typography variant="caption">Unsupported file format.</Typography>
        )}
      </Block>
    </>
  );
};

export default enhancer(Step);
