import React from 'react'
import { useHistory } from 'react-router-dom'
import { deleteFishfarmScenario } from 'data/fishfarm'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const Actions = ({ id, handleGetScenarios }) => {
  const handleDelete = async () => {
    try {
      await deleteFishfarmScenario({ id })
      await handleGetScenarios()
    } catch (error) {
      console.error(error)
    }
  }
  const { push } = useHistory()
  return (
    <>
      <Tooltip title="View">
        <IconButton
          size="small"
          color="primary"
          aria-label="view"
          onClick={() =>
            push(`/fishfarm-screening/form/${id}`, {
              view: true,
            })
          }
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          size="small"
          color="primary"
          aria-label="edit"
          onClick={() => push(`/fishfarm-screening/form/${id}`)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy">
        <IconButton
          size="small"
          color="primary"
          aria-label="copy"
          onClick={() =>
            push(`/fishfarm-screening/form`, {
              id,
            })
          }
        >
          <CopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          size="small"
          color="primary"
          aria-label="delete"
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default Actions
