export default () => ({
  dateBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateArea: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-30px',
  },
  dayText: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'gray',
  },
  textFields: {
    fontSize: '12px',
    color: 'gray',
  },
  divider: {
    marginLeft: '30px',
  },
  verticalLine: {
    marginLeft: '8px',
    borderRight: '1px solid #c9c9c9',
    alignSelf: 'stretch',
    height: 'auto',
    flex: 1,
  },
});
