import { directionColumn } from 'styles/mixins';

export default () => ({
  container: {
    height: 'calc(100vh - 80px)',
    ...directionColumn,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
});
