import { useEffect, useState } from 'react'
import { getDilutionProjects } from 'data/dilution'
import useInterval from 'hooks/useInterval'

const useProjects = () => {
  const [projects, setProjects] = useState([])
  const getProjects = async () => {
    try {
      const projectsToSet = await getDilutionProjects()
      setProjects(projectsToSet)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getProjects()
  }, [])

  useInterval(
    () => {
      getProjects()
    },
    10000,
    []
  )

  return {
    projects,
    getProjects,
  }
}

export default useProjects
