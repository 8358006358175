import { directionRow } from 'styles/mixins';

export default () => ({
  title: {
    width: '100%',
    padding: '16px 20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  row: {
    flex: '1',
    ...directionRow,
  },
});
