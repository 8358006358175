import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import get from 'lodash/get'

import View from 'containers/Dilution/partials/View'
import useResult from 'containers/Dilution/partials/View/partials/Sidebar/hooks/useResults'
import useScenario from 'containers/Dilution/hooks/useScenario'

import activeModelIdState from 'containers/Dilution/states/activeModel'

const Viewer = ({ apiInfo = {} }) => {
  const { baseURL } = apiInfo
  const setActiveModelId = useSetRecoilState(activeModelIdState)
  const { id } = useParams()
  const { scenario } = useScenario({ id, baseURL })
  const content = useResult(id)
  const { t } = useTranslation()

  const modelId = get(scenario, 'data.definition.model.id')

  useEffect(() => {
    if (modelId) {
      setActiveModelId(modelId)
    }
  }, [modelId])

  return (
    <View
      scenario={scenario}
      content={{ ...content, heading: t('common:scenario') }}
      pdf
      shapefile
      isolines
      vectorlines
    />
  )
}
export default Viewer
