export default {
  type: 'hypothetical',
  include: false,
  source: {
    name: '',
    latitude: '',
    longitude: '',
    depth: '0',
  },
  discharges: [
    {
      timestamp: '',
      dischargeValue: '',
    },
  ],
  jet: {
    enabled: true,
    pipeDiameter: 0.5,
    angleVertical: 0,
    angleHorizontal: 0,
    numberOfDiffuserPorts: 1,
  },
  density: {
    temperature: '15',
    salinity: '0',
  },
  concentrations: [],
}
