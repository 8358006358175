import { atom } from 'recoil'

const data = {
  type: 'FeatureCollection',
  features: []
}

export default atom({
  key: 'mapEditableData',
  default: data
})
