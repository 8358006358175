import React from 'react';
import { withLeaflet } from 'react-leaflet';
import MeasureControlDefault from 'react-leaflet-measure';

const MeasureControl = withLeaflet(MeasureControlDefault);

const measureOptions = {
  position: 'bottomleft',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'acres',
  activeColor: '#db4a29',
  completedColor: '#9b2d14',
};

const Measure = () => <MeasureControl {...measureOptions} />;

export default Measure;
