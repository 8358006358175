import { object, mixed } from 'yup';

import Step from './Step1';

export default {
  title: 'CSV',
  component: Step,
  validationSchema: () =>
    object().shape({
      csv: object().shape({
        file: mixed()
          .required()
          .test(
            'fileFormat',
            'Unsupported Format',
            value => value && value.includes('dsfu')
          ),
      }),
    }),
};
