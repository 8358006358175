import { useEffect, useState } from 'react'
import { getScenario as fetchScenario } from 'data/common'

const useScenario = ({ id, baseURL }) => {
  const [scenario, setScenario] = useState({})

  const getScenario = async () => {
    try {
      const scenarioToSet = await fetchScenario({ id, baseURL })
      setScenario(scenarioToSet)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (id) getScenario()
  }, [])

  return {
    scenario,
    getScenario,
  }
}

export default useScenario
