import React, { useContext } from 'react'
import { SedimentSpillContext } from 'containers/SedimentSpill'

import { GeoJSON, Rectangle } from 'react-leaflet'

import Map from 'components/CoreMap'

import Tools from './partials/Tools'
import Animation from './partials/Animation'
import Progress from './partials/Progress'

const MapContainer = ({ isFullWidth = false }) => {
  const { state, refs } = useContext(SedimentSpillContext)
  const { animationLoading, dredgerAreaForm, dredgerZone, mapView } = state
  const { mapRef } = refs

  return (
    <Map
      animationLoading={animationLoading}
      config={mapView}
      componentRef={mapRef}
      isFullWidth={isFullWidth}
    >
      {mapView.box && (
        <Rectangle
          bounds={mapView.box}
          color="red"
          weight={4}
          fillOpacity={0}
        />
      )}
      {dredgerZone && dredgerAreaForm && mapView.id === 'Brest' && (
        <GeoJSON
          key={dredgerZone.properties.name}
          data={dredgerZone}
          // onEachFeature={(feature, layer) =>
          //   layer.bindPopup(feature.properties.name)
          // }
          // onEachFeature={(feature, layer) =>
          //   layer.bindPopup(feature.properties.name, {
          //     background: "#ffffff",
          //     color: "#234c5e"
          //   })
          // }
        />
      )}
      <Tools />
      <Animation />
      <Progress />
    </Map>
  )
}

export default MapContainer
