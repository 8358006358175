import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import imagesGrabState from 'components/DeckGLMap/states/imagesGrab'
import viewFeaturesState from 'containers/Dilution/partials/View/states/viewFeatures'


const useViewFeatures = ({ pdf, shapefile, isolines, vectorlines }) => {
    const grabImagesState = useRecoilValue(imagesGrabState)
    const features = useRecoilValue(viewFeaturesState)
    const setViewFeatures = useSetRecoilState(viewFeaturesState)
    const { grab } = grabImagesState
    const id = grab ? 'preview' : 'view'

    useEffect(() => {
        setViewFeatures({ pdf, shapefile, isolines, vectorlines })
    }, [])

    return {
        layerId: id,
        openPreview: grab,
        features
    }
}

export default useViewFeatures
