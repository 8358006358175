import React, { useContext } from "react";
import LeafletAnimation from "components/CoreMap/MapControl/partials/LeafletAnimation";

import { SedimentSpillContext } from "containers/SedimentSpill";

const Animation = () => {
  const { actions, refs, state } = useContext(SedimentSpillContext);

  const { mapRef } = refs;
  const { animationEnabled, animationData, timestep } = state;

  if (mapRef.current && animationEnabled && animationData) {
    return (
      <LeafletAnimation
        opacity={1}
        scale={1}
        zIndex={300}
        pane="vector"
        onStatus={actions.onAnimationStatusChange}
        shadingType="ShadedContour"
        map={mapRef.current}
        enabled={animationEnabled}
        currentDateTime={timestep}
        dataSource={animationData || false}
      />
    );
  }
  return null;
};

export default Animation;
