import proj4 from 'proj4'

proj4.defs([
  [
    'WGS84',
    '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
  ], // WGS84 - default
  [
    'EPSG:25832',
    '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  ], // UTM32
  ['EPSG:32633', '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs'], // UTM33
  ['EPSG:32634', '+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs +type=crs'], // UTM34
])

const availableProjections = [
  { value: 'WGS84', title: 'WGS84' },
  { value: 'EPSG:25832', title: 'UTM32' },
  { value: 'EPSG:32633', title: 'UTM33' },
  { value: 'EPSG:32634', title: 'UTM34' },
]

export default availableProjections
