import React from 'react';
import { WMSTileLayer } from 'react-leaflet';

const Wms = ({ layers, format, transparent, url, attribution, token = '' }) => (
  <WMSTileLayer
    layers={layers}
    format={format}
    transparent={transparent}
    url={url}
    attribution={attribution}
    token={token}
  />
);

export default Wms;
