import { eachDay, differenceInMinutes, isSameDay } from 'date-fns'
import { setTimeOnDate } from './timing'

export const calcTotalHours = ({ EndDate, EndHour, StartDate, StartHour }) => {
  const endHour = setTimeOnDate(EndDate, EndHour)
  const startHour = setTimeOnDate(StartDate, StartHour)
  const totalHours = differenceInMinutes(endHour, startHour) / 60
  return totalHours
}

export const calcTotalVolume = ({ RatePerDay, SeabedDensity, TotalHours }) => {
  const ratePerDay = Number(RatePerDay)
  const seabedDensity = Number(SeabedDensity)
  const totalSeconds = Number(TotalHours) * 3600
  const totalVolume = (ratePerDay * totalSeconds) / seabedDensity
  return totalVolume
}

export const calcSpillMass = ({ RatePerDay, SpillFactor, TotalHours }) => {
  const ratePerDay = Number(RatePerDay)
  const spillFactor = Number(SpillFactor)
  const totalSeconds = Number(TotalHours) * 3600
  const spillMass = (ratePerDay * totalSeconds * spillFactor) / 100
  return spillMass
}

export const getCalendarDays = ({ EndDate, StartDate }) => {
  const endDate = new Date(EndDate)
  const startDate = new Date(StartDate)
  const sameDay = isSameDay(startDate, endDate)
  const days = sameDay ? 1 : eachDay(startDate, endDate).length
  return days
}

export const getTotalHours = ({ CalendarDays, EndHour, StartHour }) => {
  const endHour = new Date('2020-01-01T' + EndHour + ':00Z')
  const startHour = new Date('2020-01-01T' + StartHour + ':00Z')
  const totalHours =
    (CalendarDays * differenceInMinutes(endHour, startHour)) / 60
  return totalHours
}

export const getTotalVolume = ({ RatePerDay, TotalHours }) => {
  const ratePerDay = Number(RatePerDay)
  const totalHours = Number(TotalHours)
  const sum = ratePerDay * totalHours
  const totalVolume = sum / 24
  return totalVolume
}

export const getSpillMass = ({ TotalVolume, SpillFactor, seabedDensity }) => {
  const totalVolume = Number(TotalVolume)
  const spillFactor = Number(SpillFactor)
  const sum = totalVolume * spillFactor
  const spillMass = (sum / 100) * seabedDensity
  return spillMass
}

export const getReducedNumber = (list, field) =>
  list.reduce((allItems, thisItem) => allItems + thisItem[field], 0)

export const getCenterPosition = bounds => {
  const initialValues = {
    latitude: 0,
    longitude: 0,
  }

  const reducer = (allPositions, thisPosition, index, array) => {
    const [thisLatitude, thisLongitude] = thisPosition
    allPositions.latitude += thisLatitude / array.length
    allPositions.longitude += thisLongitude / array.length
    return allPositions
  }

  const { latitude, longitude } = bounds.reduce(reducer, initialValues)

  return {
    latitude,
    longitude,
  }
}
