export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    padding: '25px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  fab: { margin: '20px' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
