import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Article from 'components/Article'
import { useTranslation } from 'react-i18next'

const Disclaimer = () => {
  const { t } = useTranslation()

  return (
    <Article headline="Disclaimer">
      <Box my={3}>
        <Typography variant={t('disclaimer:header')}>
          <Typography variant="h5">{t('disclaimer:header')}</Typography>
        </Typography>
      </Box>
      <Box py={1}>
        <Typography variant="body2">
          <Typography variant="body2">
            {t('disclaimer:transmission')}
          </Typography>
        </Typography>
      </Box>
      <Box py={1}>
        <Typography variant="body2">
          {t('disclaimer:notResponsible')}
        </Typography>
      </Box>
    </Article>
  )
}

export default Disclaimer
