import layers from './layers';

export default [
  {
    name: 'First group',
    id: 'first-group',
    layers: [
      ...layers.map(layer => ({ ...layer, id: `first-group-${layer.id}` })),
    ],
  },
  {
    name: 'Second group',
    id: 'second-group',
    layers: [
      ...layers.map(layer => ({ ...layer, id: `second-group-${layer.id}` })),
    ],
  },
  {
    name: 'Third group',
    id: 'third-group',
    layers: [
      ...layers.map(layer => ({
        ...layer,
        id: `third-group-${layer.id}`,
      })),
    ],
  },
];
