import React from 'react'
import { Box, Divider } from '@material-ui/core'

const Header = ({ children }) => (
  <Box px={4}>
    <Box pt={4}>{children}</Box>
    <Divider />
  </Box>
)

export default Header
