import { atom, selector } from 'recoil'

import { activeModelState } from './activeModel'

export const mapOverlays = atom({
  key: 'mapOverlays',
  default: selector({
    key: 'mapOverlaysFromActiveModel',
    get: ({ get }) => {
      const { mapOverlays = [] } = get(activeModelState)
      // console.log({ selectorMapOverlays: mapOverlays })
      // return [1, 2, 3]
      return mapOverlays
    },
  }),
})