import React from 'react'
import { compose } from 'recompose'
import cx from 'classnames'
import { withStyles, Tooltip } from '@material-ui/core'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const Status = ({ classes, warning, danger, success, info, label }) => {
  const fallback = !warning && !danger && !success && !info
  return (
    <Tooltip title={label}>
      <div
        className={cx(classes.status, {
          [classes.warning]: warning,
          [classes.danger]: danger,
          [classes.success]: success,
          [classes.info]: info,
          [classes.fallback]: fallback,
        })}
      />
    </Tooltip>
  )
}

export default enhancer(Status)
