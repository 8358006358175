import { atom } from 'recoil'
import { FlyToInterpolator } from '@deck.gl/core'

export const initialViewState = {
  altitude: 1.5,
  bearing: 0,
  latitude: 55,
  longitude: 12,
  maxPitch: 60,
  maxZoom: 20,
  minPitch: 0,
  minZoom: 0,
  pitch: 0,
  transitionDuration: 1000,
  transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: t => ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2,
  zoom: 1,
}

export default atom({
  key: 'view',
  default: initialViewState,
})
