import { makeStyles } from '@material-ui/core/styles'

import DHITheme from 'styles/theme'
import { directionColumn } from 'styles/mixins'

const drawerWidth = DHITheme.drawerWidth

export default makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    height: 'calc(100vh - 80px)',
  },
  drawerChildrenWrapper: {
    ...directionColumn,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px',
  },
  drawerPaper: {
    width: 'inherit',
    bottom: 0,
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}))
