import React from 'react'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CoreAccordion from '@material-ui/core/Accordion'
import CoreAccordionSummary from '@material-ui/core/AccordionSummary'
import CoreAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CheckboxWithLabel } from 'formik-material-ui'
import { Field } from 'formik'

import styles from './styles'

const enhancer = compose(withStyles(styles))

const Layers = ({ classes, values, handleChange }) => (
  <>
    {values.dfsu.groups.map((group, groupIndex) => (
      <CoreAccordion defaultExpanded key={groupIndex}>
        <CoreAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{group.name}</Typography>
        </CoreAccordionSummary>
        <CoreAccordionDetails className={classes.container}>
          {group.layers.map((layer, layerIndex) => (
            <div key={layerIndex}>
              <Field
                checked={layer.checked}
                onChange={handleChange}
                id={`dfsu.groups.${groupIndex}.layers.${layerIndex}.checked`}
                name={`dfsu.groups.${groupIndex}.layers.${layerIndex}.checked`}
                Label={{ label: layer.name }}
                component={CheckboxWithLabel}
              />
            </div>
          ))}
        </CoreAccordionDetails>
      </CoreAccordion>
    ))}
  </>
)

export default enhancer(Layers)
