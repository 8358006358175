export default theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: '30px',
  },
  contentWithoutMargin: {
    margin: 0,
  },
  action: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    direction: 'row',
    justify: 'flex-start',
  },
  dayText: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'gray',
  },
  hourText: {
    fontSize: '15px',
    color: 'gray',
  },
  actionHour: {
    padding: '10px',
  },
  actionTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  actionProgress: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'gray',
  },
  actionDetails: {
    width: '100%',
    padding: '20px',
  },
  actionDetailsWithoutSpacing: {
    padding: '20px 0 !important',
  },
  textFields: {
    padding: '3px 0',
    fontSize: '12px',
    color: 'gray',
  },
  status: {
    display: 'contents',
  },
  verticalLine: {
    marginLeft: '8px',
    borderLeft: '1px solid #c9c9c9',
    alignSelf: 'stretch',
    height: 'auto',
  },
  actionStatus: {
    alignContent: 'center',
    display: 'flex column',
    marginLeft: '-8px',
    paddingTop: '.3rem',
    backgroundColor: '#ffffff',
  },
  selectedAction: {
    background: 'aliceblue',
  },
  listDetails: {
    listStyle: 'none',
    margin: theme.spacing(1, 0, 0),
    padding: 0,
  },
})
