import { useMemo } from 'react'
import { WebMercatorViewport } from '@deck.gl/core'
import { useRecoilState } from 'recoil'
import { useDebounce } from 'react-use'

import viewState from 'components/DeckGLMap/states/view'

const useBounds = ({ modelBounds, initialView }) => {
  const [view, setView] = useRecoilState(viewState)
  const { width, height } = view
  const withSize = useMemo(() => width > 1 && height > 1, [width, height])

  useDebounce(
    () => {
      const withBounds = !!modelBounds.length
      if (withBounds && withSize) {
        const [first, second] = modelBounds
        const [firstLatitude, firstLongitude] = first
        const [secondLatitude, secondLongitude] = second
        const viewport = new WebMercatorViewport(view)
        const { latitude, longitude, zoom } = viewport.fitBounds([
          [firstLongitude, firstLatitude],
          [secondLongitude, secondLatitude],
        ])
        setView({ ...view, latitude, longitude, zoom })
      } else if (initialView) {
        setView((oldView) => ({ ...oldView, ...initialView }))
      }
    },
    1000,
    [modelBounds, initialView, withSize]
  )
}

export default useBounds
