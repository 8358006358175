import mapPropsToValues from './mapPropsToValues';

import steps from '../steps/index';

export default {
  enableReinitialize: true,
  initialValid: true,
  validationSchema: ({ dataviewerActiveStep }) => {
    const activeStep = steps[dataviewerActiveStep];
    const { validationSchema = () => ({}) } = activeStep;
    return validationSchema();
  },
  mapPropsToValues,
  async handleSubmit(values, { setSubmitting }) {
    try {
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  },
  displayName: 'DataviewerForm',
};
