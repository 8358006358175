import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import Box from '@material-ui/core/Box'

import Loader from 'components/Loader'

import List from 'containers/MarineExplorer/partials/List'
import Viewer from 'containers/MarineExplorer/partials/Viewer'

import Route from 'components/Route'

import { HOSTS, TOOLS } from 'config/constants'

const { MARINE_EXPLORER: HOST } = HOSTS
const { MARINE_EXPLORER: PATH } = TOOLS

const MarineExplorer = ({ isUser, isAdmin }) => {
  const roles = { isUser, isAdmin }
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      height="calc(100vh - 80px)"
    >
      <Suspense fallback={<Loader isLoading isMajorLoading />}>
        <Switch>
          <Route
            path="/marine-explorer/view/:id?"
            component={Viewer}
            apiInfo={{ path: PATH, baseURL: HOST }}
            {...roles}
          />
          <Route
            path="/marine-explorer/:id?"
            component={List}
            apiInfo={{ path: PATH, baseURL: HOST }}
            isExact
            {...roles}
          />
        </Switch>
      </Suspense>
    </Box>
  )
}

export default MarineExplorer
