import API, { getDataAsJSON } from '@dhi-solutions/domain-services'
import { stringify } from 'query-string'

const apiBaseUrl =
  'https://me-prod-brain.westeurope.cloudapp.azure.com/sedimentspill/api'

export const getList = async ({ query = {} }) => {
  try {
    const endpoint = `/scenarios/Projects` + stringify({ ...query })
    const response = await API.get(endpoint, { baseURL: apiBaseUrl })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const queryProjects = async ({ query = {} }) => {
  try {
    const response = await API.post(`/scenarios/Projects/query`, query, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getProjectsByCompany = async ({ company }) => {
  try {
    const response = await queryProjects({
      query: [
        {
          item: 'company',
          queryOperator: 'Like',
          value: '%' + company,
        },
      ],
    })

    const projects = response.map((project) => {
      const { data, id } = project
      const { company, description, label, value } = data
      return {
        company,
        description,
        id,
        label,
        value,
      }
    })

    return Promise.resolve(projects)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addProject = async ({ body = {} }) => {
  try {
    const response = await API.post(`/scenarios/Projects`, body, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteProject = async ({ id }) => {
  try {
    const response = await API.delete(`/scenarios/Scenarios/${id}`, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateProject = async ({ body = {} }) => {
  try {
    const response = await API.put(`/scenarios/Projects`, body, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
