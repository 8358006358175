import React from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Drawer from 'components/Drawer'

import withDataviewerState from 'hocs/withDataviewerState'

import styles from './styles'

const enhancer = compose(
  withDataviewerState,
  withStyles(styles, { withTheme: true })
)

const Footer = ({ actions: { goToPrevious, goToNext }, classes, config }) => {
  const { t } = useTranslation()
  const { isFirstStep, isLastStep, isDisabled } = config
  return (
    <Drawer.Footer>
      <div className={classes.footerButtons}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          disabled={isFirstStep}
          onClick={goToPrevious}
        >
          {t('common:next', { suffix: '«' })}
        </Button>
        {!isLastStep && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={isLastStep || isDisabled}
            onClick={goToNext}
          >
            {t('common:next', { suffix: '»' })}
          </Button>
        )}
      </div>
    </Drawer.Footer>
  )
}

export default enhancer(Footer)
