import DHITheme from 'styles/theme'

import { calc, spacing } from 'styles/variables'
import { directionRow, justifySpaceBetween } from 'styles/mixins'

export default theme => ({
  content: {
    ...directionRow,
    flex: 1,
    position: 'relative',
    width: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: DHITheme.map.wrapper.height,
  },
  drawerInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentDrawerOpen: {
    width: '100vw',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentDrawerClose: {
    display: 'flex',
    width: calc.contentWidth,
    height: DHITheme.map.wrapper.height,
  },
  chart: {
    display: 'flex',
    width: '100vw',
    height: DHITheme.map.wrapper.height,
  },
  fab: { margin: spacing.medium, marginRight: 0 },
  header: {
    ...justifySpaceBetween,
    alignItems: 'center',
    padding: '1rem 0rem',
  },
  tabButton: {
    height: '40px',
    marginLeft: '10px',
  },
  flexLayout: {
    width: '49vw',
    ...directionRow,
    padding: '16px',
    flex: 1,
  },
  footerButtons: {
    ...justifySpaceBetween,
    alignItems: 'flex-end',
    padding: '1rem 0rem',
  },
})
