import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControlLabel,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import styles from './styles'

class LayerControl extends PureComponent {
  static propTypes = {
    // Name displayed next to the checkbox
    name: PropTypes.string,
    /**
     * The `legendGradient` prop sets the legend gradient object.
     * e.g. below is a sample value.
     * '[ {"value": "0", "icon" : "/dhi_elements/dhi-layer-control/demo/0.png"},
     *    {"value": "1", "icon" : "/dhi_elements/dhi-layer-control/demo/1.png"},
     *    {"value": "5", "icon" : "/dhi_elements/dhi-layer-control/demo/5.png"}
     *  ]'
     */
    legendGradient: PropTypes.array,
    // LegendValuePostFix is the postfix appended to all values of the legend
    legendValuePostFix: PropTypes.string,
    // If legendHeight is specified, then scrollbars will be added when the colors exceed the height
    legendHeight: PropTypes.number,
    // The enabled prop controls if the checkbox should be enabled or not.
    disabled: PropTypes.bool,
    // The `disabled` prop tells if the formcontrollabel si disabled or not.
    enabled: PropTypes.bool,
    // The `checked` prop tells if the layer is enabled or disabled.
    checked: PropTypes.bool,
    // The `onCheck` prop expects a function it calls if the layer is enabled or disabled.
    onCheck: PropTypes.func,
    // The `opacity` prop tells opacity of the layer
    opacity: PropTypes.number,
    // The `onOpacityChange` prop expects a function it calls if the layer's opacity changes
    onOpacityChange: PropTypes.func,
    // The `opacityMinimum` prop controls minimum opacity of the layer.
    opacityMinimum: PropTypes.number,
    // The `opacityMaximum` prop controls maximum opacity of the layer.
    opacityMaximum: PropTypes.number,
    // The `showOpacityControl` attribute controls visibility of the opacity control for the layer.
    showOpacityControl: PropTypes.bool,
    classes: PropTypes.object,
  }

  render() {
    const {
      classes,
      checked,
      disabled,
      enabled,
      legendHeight,
      onCheck,
      name,
      legendGradient,
      legendValuePostFix,
      showOpacityControl,
      opacityMinimum,
      opacityMaximum,
      opacity,
      onOpacityChange,
    } = this.props
    const contentVisible = checked
    const isEnabled = enabled !== undefined ? enabled : true
    const legendStyle =
      legendHeight !== undefined
        ? { height: legendHeight, overflow: 'scroll' }
        : { height: 'auto' }

    const checkbox = isEnabled && (
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checked={checked}
            color="primary"
            onChange={onCheck}
          />
        }
        label={name}
      />
    )

    const legend =
      legendGradient &&
      legendGradient.map((lg, index) => (
        <div key={index} className={classes.legend}>
          {lg.color ? (
            <div
              className={classes.legendItemIcon}
              style={{ background: lg.color }}
            />
          ) : (
            <img
              style={{ width: 'auto', height: 'auto', marginRight: '5px' }}
              src={lg.icon}
              alt={lg.value}
            />
          )}
          <Typography>
            {lg.value}
            {legendValuePostFix && ` ${legendValuePostFix}`}
          </Typography>
        </div>
      ))

    const slider = showOpacityControl && contentVisible && (
      <Slider
        step={0.05}
        min={opacityMinimum}
        max={opacityMaximum}
        value={opacity}
        onChange={onOpacityChange}
        classes={{ root: classes.slider }}
      />
    )

    return (
      <div className={classes.root}>
        <div className={classes.controls}>
          {checkbox}
          {slider}
        </div>
        {contentVisible && (
          <div className={classes.content} style={legendStyle}>
            {legend}
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(LayerControl)
