import { string, array, object } from 'yup'

import Step from './Step3'

export default {
  title: 'Conditions',
  component: Step,
  validationSchema: () =>
    object().shape({
      conditions: object().shape({
        bedShear: array().of(string().required('No bed-shear added.')),
        bedShear2mo: array().of(string().required('No bed-shear-2mo added.')),
        botTemp: array().of(string().required('No bot-temp added.')),
        botTemp2mo: array().of(string().required('No bot-temp-2mo added.')),
        botCur: array().of(string().required('No bot-cur added.')),
        surfTemp: array().of(string().required('No surf-temp added.')),
        surfCur: array().of(string().required('No surf-cur added.')),
        dayLength: array().of(string().required('No day-length added.')),
      }),
    }),
}
