import React, { PureComponent } from 'react'
import { Button, Stepper, Step, StepLabel, withStyles } from '@material-ui/core'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import Drawer from 'components/Drawer'

const styles = theme => ({
  root: theme.flexLayout,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px'
  },
  fab: { margin: '20px' },
  footerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  header: {},
  title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  stepper: {
    borderBottom: '1px solid gray',
    marginBottom: '10px',
    padding: '5px'
  }
})

const steps = [
  'Site Information',
  'Fish Production',
  'Ambient Conditions',
  'Impact Thresholds',
  'Screening Results'
]

const getStepContent = step => {
  switch (step) {
    case 0:
      return 'Specify Site Information'
    case 1:
      return 'Specify Fish Production'
    case 2:
      return 'Specify Ambient Conditions'
    case 3:
      return 'Specify Impact Thresholds'
    case 4:
      return 'View Screening Results'
    default:
      return 'Unknown Step'
  }
}

class Wizard extends PureComponent {
  static Step = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      values: props.initialValues
    }
  }

  next = values =>
    this.setState(state => ({
      step: Math.min(state.step + 1, this.props.children.length - 1),
      values
    }))

  previous = () => {
    const { resetIndicatorDisplay, removeTable } = this.props
    resetIndicatorDisplay()
    removeTable()

    if (this.state.step === 0) {
      this.props.toggleForm(true)()
    }
    this.setState(state => ({
      step: Math.max(state.step - 1, 0)
    }))
  }

  validate = values => {
    const activeStep = React.Children.toArray(this.props.children)[
      this.state.step
    ]
    return activeStep.props.validate ? activeStep.props.validate(values) : {}
  }

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props
    const {
      initInputData,
      calcImpacts,
      resetIndicatorDisplay,
      coords
    } = this.props
    const { step } = this.state
    const isLastStep = step === React.Children.count(children) - 1
    if (isLastStep) {
      this.setState({ step: 0 })
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      bag.setSubmitting(false)

      //this.next(values);
      if (step === 0) {
        // Only allow to continue if at least one position exists
        if (coords.lat.length > 0) {
          initInputData()
          this.next(values)
        }
      } else if (step === 3) {
        calcImpacts()
        this.next(values)
      } else {
        this.next(values)
      }
    }

    resetIndicatorDisplay()
  }

  render() {
    const { children, classes } = this.props
    const { step, values } = this.state
    const { t } = useTranslation()
    const activeStep = React.Children.toArray(children)[step]
    const isLastStep = step === React.Children.count(children) - 1

    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={({ values, handleSubmit, isSubmitting }) => (
          <form className={classes.root} onSubmit={handleSubmit}>
            <Drawer.Container>
              <Drawer.Header>
                <div className={classes.header}>
                  <div className={classes.title}>
                    <h2>{getStepContent(step)}</h2>
                  </div>
                  <Stepper
                    activeStep={step}
                    alternativeLabel
                    className={classes.stepper}
                  >
                    {steps.map(label => {
                      return (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                </div>
              </Drawer.Header>
              <Drawer.Content>
                <div className={classes.content}>{activeStep}</div>
              </Drawer.Content>
              <Drawer.Footer>
                <div className={classes.footerButtons}>
                  {step !== 0 ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.previous}
                    >
                      {t('common:back', { suffix: '«' })}
                    </Button>
                  ) : (
                    <Button disabled={true}> </Button>
                  )}
                  {!isLastStep && (
                    <Button type="submit" variant="contained" color="primary">
                      {t('common:next', { suffix: '»' })}
                    </Button>
                  )}
                </div>
              </Drawer.Footer>
            </Drawer.Container>
          </form>
        )}
      />
    )
  }
}

export default withStyles(styles)(Wizard)
