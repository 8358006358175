import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil'
import Box from '@material-ui/core/Box'

import activeModelState from 'containers/Dilution/states/activeModel'
import { mapOverlays } from 'containers/Dilution/states/mapOverlays'
import useBitmapLayers from 'hooks/useBitmapLayers'
import useDischargeOutlets from 'containers/Dilution/hooks/useDischargeOutlets'
import useOverlays from 'containers/Dilution/hooks/useOverlays'
import useChartLayer from 'containers/Dilution/hooks/useChartLayer'
import useViewFeatures from 'containers/Dilution/partials/View/hooks/useViewFeatures'

import stepState from 'containers/Dilution/partials/View/states/step'
import imagesState from 'containers/Dilution/partials/View/states/images'
import pickableObjectState from 'containers/Dilution/partials/View/states/pickableObject'
import DeckGLMap from 'components/DeckGLMap'
import LayerManager from 'components/LayerManager'

import Sidebar from './partials/Sidebar'
import MapPreview from './partials/MapPreview'
import PrintCollection from './partials/PrintCollection'

const View = ({
  scenario,
  content,
  pdf = false,
  shapefile = false,
  isolines = false,
  vectorlines = false,
  withCustomLegends = true,
}) => {
  const viewFeatures = useViewFeatures({
    pdf,
    shapefile,
    isolines,
    vectorlines,
  })
  const { layerId, openPreview } = viewFeatures
  const { id: scenarioId } = useParams()
  const images = useRecoilValue(imagesState)
  const resetActiveModel = useResetRecoilState(activeModelState)
  const resetMapOverlays = useResetRecoilState(mapOverlays)
  const resetImages = useResetRecoilState(imagesState)
  const step = useRecoilValue(stepState)
  const setPickableObject = useSetRecoilState(pickableObjectState)

  const setCoordinatesTimeseries = (pickableObject) => {
    setPickableObject((stateOld) => [
      ...stateOld,
      { idx: stateOld.length + 1, ...pickableObject },
    ])
  }
  const bitmapLayers = useBitmapLayers({
    images: images,
    step,
    id: layerId,
    getPickableObject: setCoordinatesTimeseries,
  })

  const dischargePoint = useDischargeOutlets({ id: layerId })
  const overlays = useOverlays({ id: layerId })
  const chartLayer = useChartLayer({ id: layerId })

  const layers = useMemo(
    () => [...overlays, ...bitmapLayers, chartLayer, ...dischargePoint],
    [bitmapLayers, dischargePoint, overlays, chartLayer, openPreview]
  )

  useEffect(() => {
    return () => {
      resetActiveModel()
      resetMapOverlays()
      resetImages()
    }
  }, [])

  if (!scenario.data || !dischargePoint.length) return null

  return (
    <>
      <Box width={0.5} height="calc(100vh - 80px)">
        <MapPreview layers={openPreview ? layers : []} />
        <DeckGLMap layers={!openPreview ? layers : []}>
          <LayerManager />
          {pdf && <PrintCollection scenario={scenario} />}
        </DeckGLMap>
      </Box>
      <Sidebar
        scenarioId={scenarioId}
        content={content}
        withCustomLegends={withCustomLegends}
      />
    </>
  )
}

export default View
