import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Wizard from './Wizard'
import { AppContext } from 'containers/LegacyFishfarm'

const styles = theme => ({
  row: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
  },
})

const Step2 = ({ classes }) => {
  const context = useContext(AppContext)
  const { inputData } = context.state
  const { updateInputData } = context.functions

  return (
    <Wizard.Step>
      <div>
        <div key="production">
          <h4>Fish Production (tonnes/year)</h4>
          <input
            className={classes.title}
            type="text"
            name="title"
            placeholder="Production..."
            //value={info.title}
            //onChange={};
          />
        </div>

        <div key="loads">
          <h4>Loads (required temporarily)</h4>
          {Object.keys(inputData).map(key => {
            return (
              inputData[key].category === 'Load' && (
                <div key={key} className={classes.row}>
                  <div id="par" style={{ width: 140 }}>
                    <input
                      type="text"
                      value={
                        inputData[key].id + ' (' + inputData[key].unit + ') '
                      }
                      disabled={true}
                    />
                  </div>
                  <div>
                    <input
                      style={{ width: 50 }}
                      type="number"
                      value={inputData[key].values[0]}
                      onChange={e => {
                        const value =
                          e.target.value !== ''
                            ? parseFloat(e.target.value)
                            : null
                        updateInputData(key, value, 0)
                      }}
                    />
                  </div>
                </div>
              )
            )
          })}
        </div>
      </div>
    </Wizard.Step>
  )
}

export default withStyles(styles)(Step2)
