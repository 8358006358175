import React, { forwardRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'

const props = {
  display: 'flex',
  justifyContent: 'space-between',
  width: 1,
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Header = ({ children }) => (
  <DialogTitle style={{ padding: 0 }} id="alert-dialog-slide-title">
    <Box width={700} py={2} px={4}>
      {children}
    </Box>
  </DialogTitle>
)

const Content = ({ children, boxProps = {} }) => (
  <DialogContent style={{ padding: 0 }}>
    <Box
      display="flex"
      flexDirection="column"
      width={700}
      height={400}
      py={2}
      px={4}
      {...boxProps}
    >
      {children}
    </Box>
  </DialogContent>
)

const Footer = ({ children }) => (
  <DialogActions style={{ padding: 0 }}>
    <Box display="flex" width={700} py={2} px={4}>
      <Box {...props}>{children}</Box>
    </Box>
  </DialogActions>
)

const Modal = ({ open, handleClose, children }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={false}
      style={{ padding: 0 }}
    >
      {children({ Header, Content, Footer })}
    </Dialog>
  )
}

export default Modal
