import { useEffect, useState } from 'react'
import { getMapResults, getMarineExplorerScenario } from 'data/marine-explorer'


const animationFilter = item => item.type === 'Animation'
const plotFilter = item => item.type === 'Plot'
// const pointConverter = ({ label: name, position }) => ({
//     name,
//     checked: true,
//     lon: position[0],
//     lat: position[1],
// })
const useResults = ({ id, baseURL }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({
        title: '',
        animations: [],
        plots: [],
        modelBounds: [],
        initialView: { latitude: 0, longitude: 0, zoom: 0 },
        dischargeOutlets: [],
    })
    const [error, setError] = useState([])

    const handleGetResult = async () => {
        try {
            if (id) {
                const { title, view: { zoom = 0, coordinates = [0, 0] } } = await getMarineExplorerScenario({ id })
                const initialView = { zoom, latitude: coordinates[1], longitude: coordinates[0] }

                const results = await getMapResults({ id })
                const animations = results.filter(animationFilter)
                const plots = results.filter(plotFilter)

                setData((oldstate) => ({ ...oldstate, title, animations, plots, initialView: initialView }))
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            setError(error)
        }
    }

    useEffect(() => {
        if (id) handleGetResult()
    }, [id])

    useEffect(() => {
        return () => {
            setIsLoading(true)
            setData({
                title: '',
                animations: [],
                plots: [],
                modelBounds: [],
                dischargeOutlets: [],
            })
            setError([])
        }
    }, [])

    return {
        isLoading,
        data,
        error,
    }
}

export default useResults
