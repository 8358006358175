import React from 'react';

import { compose } from 'recompose';

import { withStyles } from '@material-ui/core';

import withDataviewerState from 'hocs/withDataviewerState';

import Block from 'components/Block';

import styles from './styles';

const enhancer = compose(
  withDataviewerState,
  withStyles(styles)
);

const Step = () => {
  return (
    <>
      <Block headline="Extract and download"></Block>
    </>
  );
};

export default enhancer(Step);
