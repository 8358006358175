import { useRecoilValue } from 'recoil'

import { options as optionsState } from 'containers/Dilution/states/activeScenarioType'

import Step1 from '../steps/Step1'
import Step2 from '../steps/Step2'
import Step3 from '../steps/Step3'
import Step4 from '../steps/Step4'
import Step5 from '../steps/Step5'
import Step6 from '../steps/Step6'

const useSteps = ({ t, withExistingSources }) => {
  const options = useRecoilValue(optionsState)

  const existingSourcesSteps = withExistingSources
    ? [
        {
          ...Step4,
          title: t('common:existingOutlets'),
          validationSchema: () => Step4.validationSchema({ t }),
        },
        {
          ...Step5,
          title: t('common:damping'),
          validationSchema: () => Step5.validationSchema({ t }),
        },
      ]
    : []

  return [
    {
      ...Step1,
      title: t('common:definition'),
      validationSchema: () => Step1.validationSchema({ t }),
    },
    {
      ...Step2,
      title: t('common:components'),
      validationSchema: () => Step2.validationSchema({ t }),
    },
    {
      ...Step3,
      title: t('common:hypotheticalOutletsTitle'),
      validationSchema: () => Step3.validationSchema({ options, t }),
    },
    ...existingSourcesSteps,
    {
      ...Step6,
      title: t('common:fractiles'),
      validationSchema: () => Step6.validationSchema({ t }),
    },
  ]
}

export default useSteps
