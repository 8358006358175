import React, { useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import useUsers from 'hooks/useUsers'

import useProjects from 'containers/Dilution/hooks/useProjects'

import TableList from 'components/TableList'
import Footer from 'components/Footer'
import Modal from 'components/Modal'

import Actions from './partials/Actions'
import Form from './partials/Form'

const List = () => {
  const [open, setOpen] = useState(false)
  const [sorting, setSorting] = useState([])
  const [selectedProject, setSelectedProject] = useState(undefined)
  const users = useUsers()
  const { projects, getProjects: handleGetProjects } = useProjects()
  const { t } = useTranslation()

  const handleModalClose = () => setSelectedProject(undefined)

  const handleCreateProject = () => setSelectedProject(null)

  const handleEditProject = id => setSelectedProject(id)

  useEffect(() => {
    const isOpen = !!selectedProject || selectedProject === null
    setOpen(isOpen)
  }, [selectedProject])

  useEffect(() => {
    setSorting([
      {
        field: 'dateTime',
        direction: 'desc'
      },
      {
        field: 'data.title',
        direction: 'asc'
      }
    ])
  }, [projects])

  return (
    <>
      <Container maxWidth="xl" style={{ overflowX: 'auto' }}>
        <Box paddingY={10}>
          <Box paddingBottom={4}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width={1}
              marginBottom={4}
            >
              <Typography variant="h5" color="primary">
                {t('common:projects')}
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={1}
                  height={1}
                >
                  <Box marginRight={1}>
                    <Typography>
                      {t('common:create', { suffix: t('common:project') })}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title="Create project">
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="Add"
                        onClick={handleCreateProject}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {!projects.length ? (
            <Box
              padding={4}
              display="flex"
              flexDirection="colunn"
              justifyContent="center"
              alignItems="center"
              bgcolor="shades.light"
              border={1}
              borderColor="shades.medium"
            >
              <Typography color="primary" variant="subtitle1">
                {t('common:noProjects')}
              </Typography>
            </Box>
          ) : (
            <TableList
              columns={[
                {
                  id: 'title',
                  heading: t('common:title'),
                  isSortable: true,
                  field: 'data.title'
                },
                {
                  id: 'dateCreated',
                  heading: t('common:dateCreated'),
                  isSortable: true,
                  field: 'dateTime',
                  formatter: 'YYYY-MM-DD'
                },
                {
                  id: 'actions',
                  heading: t('common:actions'),
                  component: Actions,
                  shared: {
                    handleGetProjects,
                    handleEditProject
                  }
                }
              ]}
              list={projects}
              sorting={sorting}
            />
          )}
        </Box>
        <Modal open={open} handleClose={handleModalClose}>
          {({ Header, Content, Footer }) => (
            <Form
              id={selectedProject}
              users={users}
              handleClose={handleModalClose}
              handleGetProjects={handleGetProjects}
              header={Header}
              content={Content}
              footer={Footer}
            />
          )}
        </Modal>
      </Container>
      <Footer />
    </>
  )
}

export default List
