// TODO: This looks like a spaceship. Let's get rid of it :-)

export const getObjectProperty = (objectItem, property, compareValue) => {
  let valid = true;
  const properties = property.split(".");
  let value = objectItem;
  for (let i = 0; i < properties.length; i++) {
    if (properties[i].indexOf("!") >= 0) {
      valid = !valid;
      properties[i] = properties[i].replace("!", "");
    }
    value = value[properties[i]];
  }
  if (compareValue) {
    if (typeof compareValue === "object") {
      for (let i = 0; i < compareValue.length; i++) {
        if (value === compareValue[i]) {
          return valid;
        }
      }
      return !valid;
    }
    return valid ? value === compareValue : !(value === compareValue);
  }
  return valid ? value : !value;
};

export const changeObjectProperty = (objectItem, property, intent) => {
  const properties = property.split(".");
  let value = objectItem;
  const body = [value];
  for (let i = 0; i < properties.length; i++) {
    value = value[properties[i]];
    body.push(value);
  }
  body[properties.length] = intent;
  for (let j = properties.length; j > 0; j--) {
    body[j - 1][properties[j - 1]] = body[j];
  }
  return body[0];
};
