import { object, array, string, boolean } from 'yup'

import Step4 from './Step4'

export default {
  title: 'common:existingOutlets',
  component: Step4,
  validationSchema: () =>
    object().shape({
      outlet: object().shape({
        dischargeOption: string(),
        existingOutlet: array().of(
          object().shape({
            type: string(),
            include: boolean(),
            source: object().shape({
              label: string().required('No label added.'),
              latitude: string().required('No latitude added.'),
              longitude: string().required('No longitude added.'),
              depth: string().required('No depth added.'),
            }),
            discharges: array().of(
              object().shape({
                timestamp: string().required('No time added.'),
                dischargeValue: string().required('No q added.'),
              })
            ),
            density: object().shape({
              temperature: string().required('No temperature added.'),
              salinity: string().required('No salinity added.'),
            }),
            concentrations: array().of(
              object().shape({
                id: string(),
                componentDescription: string(),
                concentrationValue: string(),
                unit: string(),
              })
            ),
          })
        ),
      }),
    }),
}
