import API, { getDataAsJSON } from '@dhi-solutions/domain-services'
import { stringify } from 'query-string'

import { sedimentSpillBaseUrl as apiBaseUrl } from 'config/constants'

export const getScenarios = async ({ projectId }) => {
  try {
    const scenariosList = await queryWithSelections({
      connection: 'scenarios',
      tool: 'brain',
      query: [
        {
          item: 'Specifications.Project',
          queryOperator: 'Like',
          value: '%' + projectId,
        },
      ],
    })
    if (!scenariosList) throw Error(`Request rejected`)
    return Promise.resolve(scenariosList)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getScenario = async ({ scenarioId }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${scenarioId}`
    const response = await API.get(endpoint, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getList = async ({ query }) => {
  try {
    const endpoint = `/scenarios/Scenarios` + stringify({ ...query })
    const response = await API.get(endpoint, { baseURL: apiBaseUrl })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const queryWithSelections = async ({ query }) => {
  try {
    const response = await API.post(
      `/scenarios/Scenarios/query?dataSelectors=[Specifications,ScenarioDetails]`,
      query,
      {
        baseURL: apiBaseUrl,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const queryList = async ({ query }) => {
  try {
    const response = await API.post(`/scenarios/Projects/query`, query, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addItem = async ({ body }) => {
  try {
    const response = await API.post(`/scenarios/Scenarios`, body, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteItem = async ({ id }) => {
  try {
    const response = await API.delete(`/scenarios/Scenarios/${id}`, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateItem = async ({ body }) => {
  try {
    const response = await API.put(`/scenarios/Scenarios`, body, {
      baseURL: apiBaseUrl,
    })
    if (!response) throw Error(`Request rejected`)
    const data = await getDataAsJSON(response)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
