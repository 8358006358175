import { useMemo, useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { EditableGeoJsonLayer, ModifyMode } from 'nebula.gl'
import mapDampingState from 'containers/Dilution/states/mapDamping'
import mapInteractionsState from 'containers/Dilution/states/mapInteractions'

const toFeature = (source) => {
    return {
        type: 'Feature',
        outerRadius: source.outerRadius,
        innerRadius: source.innerRadius,
        enabled: source.enabled,
        name: source.name,
        geometry: {
            type: 'Point',
            coordinates: [source.longitude, source.latitude],
        },
        properties: { name: source.name }
    }
}

const toSource = (feature) => {
    return {
        name: feature.name,
        enabled: feature.enabled,
        outerRadius: feature.outerRadius,
        innerRadius: feature.innerRadius,
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0]
    }
}


const useDampingLayers = () => {
    const [visible, setVisible] = useState(true);
    const [editLayer, setEditLayer] = useRecoilState(mapDampingState)
    const [localState, setLocalState] = useState();
    const interaction = useRecoilValue(mapInteractionsState)
    const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([])

    useEffect(() => {
        if (Object.keys(editLayer).length > 0) {
            setLocalState({
                type: 'FeatureCollection',
                features: [toFeature(editLayer)]
            })
            setVisible(editLayer.enabled)
        } else {
            setLocalState({
                type: 'FeatureCollection',
                features: []
            })
        }
    }, [editLayer])

    const onClick = ({ picked, isGuide, layer, index }) => {

        if (selectedFeatureIndexes.indexOf(index) === -1) {
            setSelectedFeatureIndexes(
                selectedFeatureIndexes.map(x => x).concat([index])
            )
        } else {
            setSelectedFeatureIndexes(
                selectedFeatureIndexes.filter(x => x !== index).map(x => x)
            )
        }

    }

    const onEdit = ({ updatedData, editType }) => {
        setLocalState(updatedData)

        if (editType === 'finishMovePosition') {
            setEditLayer(toSource(updatedData.features[0]))
        }

    }


    const editableLayer = new EditableGeoJsonLayer({
        id: 'damping-layer',
        data: localState,
        mode: ModifyMode,
        pickable: true,
        selectedFeatureIndexes,
        stroked: false,
        highlightColor: [255, 255, 255, 100],
        filled: true,
        getRadius: d => d.outerRadius,
        _subLayerProps: {
            geojson: {
                visible: visible
            }
        },
        onEdit,
        onClick
    })

    const layers = useMemo(() => editableLayer, [
        localState,
        interaction,
        selectedFeatureIndexes,
    ])

    return layers
}

export default useDampingLayers
