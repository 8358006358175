import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import get from 'lodash/get'
import userState from 'states/user'

const useUser = () => {
  const user = useRecoilValue(userState)
  const roles = useMemo(() => get(user, 'roles', []), [user])

  const isRole = role => roles.includes(role)

  const isUser = isRole('User')
  const isAdmin = isRole('Administrator')

  return { user, isUser, isAdmin }
}

export default useUser
