import React, { useContext } from 'react';
import { GeoJSON } from 'react-leaflet';
import { isNil } from 'lodash';

import { MarineMonitoringContext } from 'containers/MarineMonitoring';

const ShapeFile = () => {
  const { state } = useContext(MarineMonitoringContext);
  const { shapeData } = state;
  const noShapeData = isNil(shapeData);
  if (noShapeData) return null;
  const key = Math.random()
    .toString(36)
    .substr(2, 5);
  return <GeoJSON key={key} data={shapeData} />;
};

export default ShapeFile;
