import React from 'react'
import Radio from '@material-ui/core/Radio'
import Tooltip from '@material-ui/core/Tooltip'

import TableList from 'components/TableList'

const Value = ({ assessmentAreas = [], id }) => {
  const assessmentArea = assessmentAreas.find(item => item.id === id)
  if (assessmentArea) {
    const { impact } = assessmentArea
    const { value, colorCode, name } = impact
    const isTransparent = colorCode === 'transparent'
    const borderProps = isTransparent
      ? {}
      : {
          border: '1px solid rgba(0,0,0,0.5)',
        }
    const label = Number(value).toFixed(3)
    return (
      <>
        {label}
        <Tooltip title={name}>
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '10px',
              display: 'block',
              marginLeft: '5px',
              content: ' ',
              backgroundColor: colorCode,
              ...borderProps,
            }}
          />
        </Tooltip>
      </>
    )
  }
  return null
}

const Point = ({ list, selectedLayer, setSelectedLayer }) => {
  const handleOnChange = id => setSelectedLayer(id)
  return (
    <TableList
      columns={[
        {
          id: 'checkbox',
          component: ({ id }) => (
            <Radio
              checked={selectedLayer === id}
              onChange={() => handleOnChange(id)}
            />
          ),
        },
        {
          id: 'name',
          component: ({ label, unit }) => (
            <>
              {label} ({unit})
            </>
          ),
        },
        {
          id: 'centreFootprint',
          heading: 'Centre footprint',
          field: 'assessmentAreas[0].impact.value',
          component: ({ assessmentAreas }) => (
            <Value assessmentAreas={assessmentAreas} id="Cent" />
          ),
        },
        {
          id: 'centTo500m',
          heading: 'Cent-to-500m',
          field: 'assessmentAreas[1].impact.value',
          component: ({ assessmentAreas }) => (
            <Value assessmentAreas={assessmentAreas} id="Cent-to-500m" />
          ),
        },
        {
          id: '500mTo1000m',
          heading: '500m-to-1000m',
          field: 'assessmentAreas[2].impact.value',
          component: ({ assessmentAreas }) => (
            <Value assessmentAreas={assessmentAreas} id="500m-to-1000m" />
          ),
        },
        {
          id: '1kmTo10km',
          heading: '1km-to-10km',
          field: 'assessmentAreas[3].impact.value',
          component: ({ assessmentAreas }) => (
            <Value assessmentAreas={assessmentAreas} id="1km-to-10km" />
          ),
        },
      ]}
      list={list}
    />
  )
}

export default Point
