import { justifySpaceBetween } from 'styles/mixins';

export default () => ({
  selectionRow: {
    ...justifySpaceBetween,
    alignItems: 'flex-end',
  },
  legend: {
    display: 'flex',
    marginLeft: '35px',
    alignItems: 'center',
  },
  legendItemIcon: {
    border: '1px solid #1976D2',
    width: '30px',
    height: '16px',
    borderRadius: '2px',
    marginRight: '10px',
  },
  legendWMS: {
    overflowY: 'auto',
    maxHeight: '300px',
    overflowX: 'auto',
    maxWidth: '410px',
  },
});
