import React, { forwardRef } from 'react'
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil'
import axios from 'axios'

import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

import DeckGLMap from 'components/DeckGLMap'

import imagesGrabState from 'components/DeckGLMap/states/imagesGrab'
import imagesReportState from 'components/DeckGLMap/states/imagesReport'

import viewState from 'components/DeckGLMap/states/view'

const STYLE = process.env.REACT_APP_MAPBOX_STYLE
const TOKEN = process.env.REACT_APP_MAPBOX_KEY

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MapPreview = ({ layers }) => {
  const view = useRecoilValue(viewState)
  const grabImagesState = useRecoilValue(imagesGrabState)
  const { grab: open, id } = grabImagesState
  const { t } = useTranslation()
  const saveImages = useSetRecoilState(imagesReportState)
  const stopGrabImages = useResetRecoilState(imagesGrabState)

  const handleAddToCollection = async () => {
    try {
      const { longitude, latitude, zoom } = view
      const url = `https://api.mapbox.com/styles/v1/${STYLE}/static/${longitude},${latitude},${zoom},0/${500}x${500}?access_token=${TOKEN}`

      const basemap = await axios.get(url, { responseType: 'arraybuffer' })

      saveImages((imagesStateOld) => ({
        ...imagesStateOld,
        [id]: {
          ...imagesStateOld[id],
          basemap:
            'data:' +
            basemap.headers['content-type'] +
            ';base64,' +
            Buffer.from(basemap.data, 'binary').toString('base64'),
        },
      }))

      stopGrabImages()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={stopGrabImages}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Box paddingY="1rem">
            {t('common:addTo', { suffix: t('common:collection') })}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box width={500} height={500}>
            <DeckGLMap layers={layers} id={'report-component'} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box padding="1rem">
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToCollection}
            >
              {t('common:done')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MapPreview
