import React, { PureComponent } from 'react';
import DataviewerState from 'contexts/DataviewerState';

export default WrappedComponent => {
  return class extends PureComponent {
    static contextType = DataviewerState;
    render() {
      const consumedFromContext = this.context;
      return <WrappedComponent {...this.props} {...consumedFromContext} />;
    }
  };
};
