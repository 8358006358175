import React, { useState, useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import DropdownSearch from 'components/DropdownSearch'
import { format } from 'date-fns'
import { Field } from 'formik'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import isString from 'lodash/isString'
import get from 'lodash/get'
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Tooltip as ChartTooltip,
  YAxis,
  Legend,
  Line,
} from 'recharts'
import { FieldArray } from 'formik'
import assignIn from 'lodash/assignIn'

import Block from 'components/Block'
import TableList from 'components/TableList'
import UploadField from 'components/UploadField'
import ErrorMessage from 'components/ErrorMessage'

import { getDischargeValuesFromFile, getDepth } from 'data/dilution'

import { activeModelState } from 'containers/Dilution/states/activeModel'
import {
  options as optionsState,
  predefinedComponents as predefinedComponentsState,
} from 'containers/Dilution/states/activeScenarioType'

import Modal from './partials/Modal'

import mapEditableDataState from 'containers/Dilution/states/mapEditableData'
import mapInteractionsState from 'containers/Dilution/states/mapInteractions'

import hypotheticalOutletSchema from './lib/hypotheticalOutletSchema'
import toFeatureCollection from './lib/toFeatureCollection'
import cursorState from 'components/DeckGLMap/states/cursor'
import Popper from 'components/Popper'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import proj4 from 'proj4'
import availableProjections from './projections'

const defaultDepth = { minDepth: 0, maxDepth: 100 }

const Step = ({ form }) => {
  const [prevProjection, setPrevProjection] = useState(availableProjections[0])
  const [projection, setProjection] = useState(availableProjections[0])
  const [coordinates, setCoordinates] = useState({
    longitude: 0,
    latitude: 0,
  })
  const [file, setFile] = useState({
    lastModified: '',
    lastModifiedDate: '',
    name: '',
    size: '',
    type: '',
    webkitRelativePath: '',
  })

  const { t } = useTranslation()
  const mapEditableData = useRecoilValue(mapEditableDataState)
  const activeModel = useRecoilValue(activeModelState)
  const options = useRecoilValue(optionsState)
  const predefinedComponents = useRecoilValue(predefinedComponentsState)
  const setMapEditableData = useSetRecoilState(mapEditableDataState)
  const setMapInteractions = useSetRecoilState(mapInteractionsState)

  const { values, setFieldValue, handleChange, errors, setErrors } = form

  const errorInHypotheticalOutlet = get(errors, 'outlet.hypotheticalOutlet')
  const errorIsSummary = isString(errorInHypotheticalOutlet)

  const { components, outlet = {} } = values
  const { hypotheticalOutlet = [] } = outlet
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [isCheckingDepth, setIsCheckingDepth] = useState(false)
  const [depthRange, setDepthRange] = useState(defaultDepth)
  const [isUploading, setIsUploading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const setCursor = useSetRecoilState(cursorState)
  const {
    constantDischarge,
    onlyOneHypotheticalSource,
    fixedOutletConcentration,
  } = options

  const {
    latitude: activeLatitude,
    longitude: activeLongitude,
    depth: activeDepth,
  } = useMemo(() => {
    const { source = {} } = hypotheticalOutlet[activeIndex] || {}
    const { latitude = 0, longitude = 0, depth = '0' } = source
    return { latitude, longitude, depth }
  }, [hypotheticalOutlet[activeIndex]])

  const isPointModel = activeModel.modelType === 'Point'
  const isFullModel = activeModel.modelType === 'Full'

  useEffect(() => {
    if (isFullModel) {
      setFieldValue()
    }
  }, [isFullModel])

  const handleClose = () => {
    setOpen(false)
    setActiveIndex(null)
  }

  const handleUpdateMap = (list) => {
    setMapEditableData(toFeatureCollection(list))
  }

  const isDepthValid = useMemo(() => {
    const isBelowMin = Number(activeDepth) < depthRange.minDepth
    const isAboveMax = Number(activeDepth) > depthRange.maxDepth
    const isPassing = !isBelowMin && isAboveMax
    return isPassing
  }, [activeDepth])

  const handleDepthCheck = async ({ latitude, longitude, index }) => {
    try {
      const { minDepth, maxDepth } = await getDepth({
        model: activeModel.id,
        latitude,
        longitude,
      })
      setIsCheckingDepth(false)
      setDepthRange({ minDepth, maxDepth })
    } catch (error) {
      const field = `outlet.hypotheticalOutlet.${index}.source.latitude`
      setErrors({
        [field]: t('errors:sourceOnLand'),
      })
      setIsCheckingDepth(false)
      setDepthRange(defaultDepth)
    }
  }

  useEffect(() => {
    const withLatitude = activeLatitude > 0
    const withLongitude = activeLongitude > 0
    if (withLatitude && withLongitude) {
      if (!isCheckingDepth) {
        setIsCheckingDepth(true)
        handleDepthCheck({
          latitude: activeLatitude,
          longitude: activeLongitude,
          index: activeIndex,
        })
      }
    }
  }, [activeLatitude, activeLongitude])

  useEffect(() => {
    handleUpdateMap(hypotheticalOutlet)
    return () => {
      handleUpdateMap([])
      setCursor('grab')
      setMapInteractions({ show: false, edit: false, remove: false })
    }
  }, [])

  useEffect(() => {
    const edit = !(
      (isPointModel && hypotheticalOutlet.length === 1) ||
      (options.onlyOneHypotheticalSource && hypotheticalOutlet.length === 1)
    )

    setCursor('crosshair')
    setMapInteractions({
      show: false,
      edit,
      remove: false,
      select: false,
    })

    if (
      (isPointModel && hypotheticalOutlet.length === 1) ||
      (options.onlyOneHypotheticalSource && hypotheticalOutlet.length === 1)
    ) {
      setCursor('not-allowed')
    }
  }, [hypotheticalOutlet, isPointModel])

  useEffect(() => {
    const { features } = mapEditableData
    handleAddSourceFromMap(features)
  }, [mapEditableData])

  useEffect(() => {
    if (activeIndex !== null) handleFollowingConcentrations()
  }, [activeIndex])

  useEffect(() => {
    if (
      !hypotheticalOutlet[activeIndex] ||
      !hypotheticalOutlet[activeIndex].source
    )
      return

    // Set initial coordinates based on scenario
    if (coordinates.longitude === 0 && coordinates.latitude === 0) {
      setCoordinates({
        longitude: hypotheticalOutlet[activeIndex].source.longitude,
        latitude: hypotheticalOutlet[activeIndex].source.latitude,
      })
    }
  }, [hypotheticalOutlet[activeIndex]])

  useEffect(() => {
    console.log({ projection })
    // Skip conversion for initial values as the
    // default projection is WSG84
    if (coordinates.longitude === 0 || coordinates.latitude === 0) {
      return
    }

    // x: longitude
    // y: latitude
    // https://help.arcgis.com/en/geodatabase/10.0/sdk/arcsde/concepts/geometry/coordref/coordsys/geographic/geographic.htm#:~:text=Even%20though%20geographic%20coordinates%20are,values%20are%20the%20y%2Dcoordinate.
    const conversion = proj4(prevProjection.value, projection.value, {
      x: coordinates.longitude,
      y: coordinates.latitude,
    })

    setCoordinates({
      longitude: conversion.x,
      latitude: conversion.y,
    })
  }, [projection])

  useEffect(() => {
    if (!!file.name.length) handleFileUpload()
  }, [file])

  const handleFileUpload = async () => {
    try {
      setIsUploading(true)
      const dischargeValues = await getDischargeValuesFromFile({ file })
      setIsUploading(false)
      setFieldValue(
        `outlet.hypotheticalOutlet.${activeIndex}.discharges`,
        dischargeValues
      )
    } catch (error) {
      setIsUploading(false)
      console.error(error)
    }
  }

  const handleFollowingConcentrations = () => {
    const concentrations = get(
      outlet,
      `hypotheticalOutlet[${activeIndex}].concentrations`,
      []
    )

    const dictionary = concentrations.reduce(
      (allConcentrations, thisConcentration) => {
        const { componentDescription, concentrationValue } = thisConcentration
        allConcentrations[componentDescription] = concentrationValue
        return allConcentrations
      },
      {}
    )

    const getConcentrations = (list) =>
      list.map(
        ({
          componentDescription,
          concentrationValue,
          unit,
          include = true,
        }) => {
          const value = dictionary[componentDescription] || concentrationValue
          return {
            componentDescription,
            concentrationValue: concentrationValue || value || 0,
            unit,
            include,
          }
        }
      )

    const concentrationsToSet = fixedOutletConcentration
      ? getConcentrations(predefinedComponents)
      : getConcentrations(components)

    setFieldValue(
      `outlet.hypotheticalOutlet[${activeIndex}].concentrations`,
      concentrationsToSet
    )
  }

  const handleAddSourceFromMap = (features) =>
    features.map((feature, index) => {
      const { geometry } = feature
      const { coordinates } = geometry
      const { label } = feature
      const [longitude, latitude] = coordinates
      const itemFromSchema = hypotheticalOutletSchema
      const itemFromForm = get(outlet, `hypotheticalOutlet.${index}`, {})
      const itemAsDefault = assignIn({ ...itemFromSchema }, { ...itemFromForm })
      const itemName = label
        ? label
        : get({ ...itemFromForm }, `source.label`, '')
      const itemDepth = get({ ...itemFromForm }, `source.depth`, '0')
      const itemJet = get({ ...itemFromForm }, `jet`, {
        enabled: !isFullModel,
        pipeDiameter: 0.5,
        angleVertical: 0,
        angleHorizontal: 0,
        numberOfDiffuserPorts: 1,
      })
      const itemDischarges = get({ ...itemFromForm }, `discharges`, [
        {
          timestamp: format(
            new Date(activeModel.startDate),
            'YYYY-MM-DDTHH:mm'
          ),
          dischargeValue: '0',
        },
      ])

      handleDepthCheck({
        latitude,
        longitude,
        index,
      })

      const withItemName = !!itemName.length

      const concentrations = get(
        outlet,
        `hypotheticalOutlet[${index}].concentrations`,
        []
      )

      const dictionary = concentrations.reduce(
        (allConcentrations, thisConcentration) => {
          const { componentDescription, concentrationValue } = thisConcentration
          allConcentrations[componentDescription] = concentrationValue
          return allConcentrations
        },
        {}
      )

      const getConcentrations = (list) =>
        list.map(
          ({
            componentDescription,
            concentrationValue,
            unit,
            include = true,
          }) => {
            const value = dictionary[componentDescription] || concentrationValue
            return {
              componentDescription,
              concentrationValue: concentrationValue || value || 0,
              unit,
              include,
            }
          }
        )

      const concentrationsToSet = fixedOutletConcentration
        ? getConcentrations(predefinedComponents)
        : getConcentrations(components)

      const overwrittenItem = assignIn(
        { ...itemAsDefault },
        {
          include: feature.properties.include,
          source: {
            label: withItemName
              ? itemName
              : t('common:newOutlet', { suffix: `${index + 1}` }),
            latitude: latitude,
            longitude: longitude,
            depth: itemDepth,
          },
          jet: itemJet,
          discharges: itemDischarges,
          concentrations: concentrationsToSet,
        }
      )

      return setFieldValue(
        `outlet.hypotheticalOutlet.${index}`,
        overwrittenItem
      )
    })

  const handleToggle = ({ event, listIndex }) => {
    const { target } = event
    const { checked = false } = target
    const items = hypotheticalOutlet.map((item, index) => {
      const isIndexMatching = index === listIndex
      const isOneOutletOnly = onlyOneHypotheticalSource && !isIndexMatching

      const include = isIndexMatching
        ? checked
        : isOneOutletOnly
          ? false
          : item.include

      return {
        ...item,
        include,
      }
    })
    setFieldValue(`outlet.hypotheticalOutlet`, items)
    handleUpdateMap(
      hypotheticalOutlet.map((outlet, index) => {
        const includeOutlet = index === listIndex
        return {
          source: outlet.source,
          discharge: outlet.discharge,
          include: includeOutlet ? checked : outlet.include,
        }
      })
    )
  }

  const handleOnBlur = () => {
    handleUpdateMap(hypotheticalOutlet)
  }

  const handleEdit = (index) => {
    setActiveIndex(index)
    setOpen(true)
  }

  const handleDelete = (index) => {
    let list = hypotheticalOutlet.filter((_, i) => i !== index)
    setFieldValue(`outlet.hypotheticalOutlet`, list)
    handleUpdateMap(list)
  }

  const withHypotheticalOutlet = !!hypotheticalOutlet.length

  const handleOnApplyCoordinateChanges = () => {
    // if currently selected projection is not default WGS84,
    // convert input values to WGS84 to send to api
    if (projection.value !== availableProjections[0].value) {
      const longitudeValue = parseFloat(coordinates.longitude)
      const latitudeValue = parseFloat(coordinates.latitude)
      const wsg84 = availableProjections[0] // Always first in the list

      const wsg84Values = proj4(projection.value, wsg84.value, {
        x: longitudeValue,
        y: latitudeValue,
      })

      // Set form values
      setFieldValue(
        `outlet.hypotheticalOutlet.${activeIndex}.source.longitude`,
        wsg84Values.x
      )
      setFieldValue(
        `outlet.hypotheticalOutlet.${activeIndex}.source.latitude`,
        wsg84Values.y
      )
    }
  }

  // name: 'longitude' | 'latitude'
  // value: e.target.value
  const handleOnCoordinateChange = (name, value) => {
    const coord = value === '' ? value : parseFloat(value)

    setCoordinates({
      ...coordinates,
      [name]: coord,
    })
  }

  const nonStandardProjection = projection.value !== 'WGS84'

  return (
    <>
      <Box width={1} height={1}>
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Popper
            icon={<HelpOutlineIcon />}
            popperContent={t('documentation:hyptheticalOutletTitle')}
            placement="left"
          />
        </Box>
        <Box marginBottom={4}>
          {withHypotheticalOutlet ? (
            <>
              <TableList
                columns={[
                  {
                    id: 'checkbox',
                    heading: '',
                    component: ({ include, listIndex }) => (
                      <Tooltip title="Toggle">
                        <div>
                          <Checkbox
                            checked={include}
                            onChange={(event) =>
                              handleToggle({ event, listIndex })
                            }
                            name={`outlet.hypotheticalOutlet.${listIndex}.include`}
                            id={`outlet.hypotheticalOutlet.${listIndex}.include`}
                            color="primary"
                          />
                        </div>
                      </Tooltip>
                    ),
                    options: {
                      style: {
                        maxWidth: '75px',
                      },
                    },
                  },
                  {
                    id: 'name',
                    heading: t('common:name'),
                    field: 'source.label',
                  },
                  {
                    id: 'edit',
                    heading: t('common:edit'),
                    component: ({ listIndex }) => (
                      <Tooltip title={t('common:edit')}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEdit(listIndex)}
                        >
                          <EditIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>
                    ),
                  },
                  {
                    id: 'delete',
                    heading: t('common:remove'),
                    component: ({ listIndex }) => (
                      <Tooltip title={t('common:remove')}>
                        <IconButton
                          aria-label="remove"
                          onClick={() => handleDelete(listIndex)}
                        >
                          <DeleteIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>
                    ),
                  },
                ]}
                list={hypotheticalOutlet}
              />
              <Box alignitems="center" display="flex">
                <Typography color="primary" variant="subtitle2">
                  {t('dilution:rememberToDefine')}
                </Typography>
                <EditIcon fontSize="small" color="primary" />
              </Box>
            </>
          ) : (
            <Box
              marginBottom={4}
              padding={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor="shades.light"
              border={1}
              borderColor="shades.medium"
            >
              <Typography color="primary" variant="subtitle1">
                {t('dilution:placeSource')}
              </Typography>
            </Box>
          )}
        </Box>
        <Box marginTop={4}>
          {outlet.hypotheticalOutlet.map((hypotheticalOutletItem, index) => (
            <ErrorMessage
              key={index}
              errors={errors}
              fields={[
                `outlet.hypotheticalOutlet.${index}.include`,
                `outlet.hypotheticalOutlet.${index}.source.label`,
                `outlet.hypotheticalOutlet.${index}.source.latitude`,
                `outlet.hypotheticalOutlet.${index}.source.longitude`,
                `outlet.hypotheticalOutlet.${index}.source.depth`,
                `outlet.hypotheticalOutlet.${index}.density.temperature`,
                `outlet.hypotheticalOutlet.${index}.density.salinity`,
              ]}
            />
          ))}
          {errorInHypotheticalOutlet && errorIsSummary && (
            <Typography style={{ color: 'red' }} gutterBottom variant="body2">
              {errorInHypotheticalOutlet}
            </Typography>
          )}
        </Box>
      </Box>
      <Modal
        open={open}
        step={step}
        setStep={setStep}
        activeIndex={activeIndex}
        form={form}
        handleClose={handleClose}
      >
        {open && step === 0 && (
          <>
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
              <Popper
                icon={<HelpOutlineIcon />}
                popperContent={t('documentation:modalStep1')}
                placement="left"
              />
            </Box>
            <Box marginBottom={4}>
              <Block headline={t('common:name')}>
                <TextField
                  onBlur={handleOnBlur}
                  fullWidth
                  onChange={handleChange}
                  id={`outlet.hypotheticalOutlet.${activeIndex}.source.label`}
                  name={`outlet.hypotheticalOutlet.${activeIndex}.source.label`}
                  placeholder={t('common:typeIn', { suffix: t('common:name') })}
                  value={hypotheticalOutlet[activeIndex].source.label}
                />
              </Block>
            </Box>
            <Box marginBottom={4}>
              <Block headline={t('common:position')}>
                <Grid container spacing={3}>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label={nonStandardProjection ? t('common:northing') : t('common:latitude')}
                      value={coordinates.latitude ? parseFloat(coordinates.latitude.toFixed(6)) : coordinates.latitude}
                      placeholder={t('common:latitude')}
                      onBlur={handleOnApplyCoordinateChanges}
                      onChange={(e) => handleOnCoordinateChange('latitude', e.target.value)}
                      id={`outlet.hypotheticalOutlet.${activeIndex}.source.latitude`}
                      name={`outlet.hypotheticalOutlet.${activeIndex}.source.latitude`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{nonStandardProjection ? 'm' : '°N'}</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label={nonStandardProjection ? t('common:easting') : t('common:longitude')}
                      onBlur={handleOnApplyCoordinateChanges}
                      type="number"
                      fullWidth
                      onChange={(e) =>
                        handleOnCoordinateChange('longitude', e.target.value)
                      }
                      id={`outlet.hypotheticalOutlet.${activeIndex}.source.longitude`}
                      name={`outlet.hypotheticalOutlet.${activeIndex}.source.longitude`}
                      value={coordinates.longitude ? parseFloat(coordinates.longitude.toFixed(6)) : coordinates.longitude}
                      placeholder={t('common:longitude')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{nonStandardProjection ? 'm' : '°E'}</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <DropdownSearch
                        fullWidth
                        id="projection"
                        value={projection}
                        options={availableProjections}
                        label={`${t('common:projection')}:`}
                        getOptionLabel={(option) => option.title}
                        onChange={(option) => {
                          setPrevProjection(projection)
                          setProjection(option)
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Block>
            </Box>
            <Box marginBottom={4}>
              <Block>
                <TextField
                  label={
                    <Popper
                      prefix={t('common:depth')}
                      icon={<HelpOutlineIcon />}
                      popperContent={t('documentation:depth')}
                      placement="top"
                    />
                  }
                  type="number"
                  fullWidth
                  onChange={handleChange}
                  id={`outlet.hypotheticalOutlet.${activeIndex}.source.depth`}
                  name={`outlet.hypotheticalOutlet.${activeIndex}.source.depth`}
                  value={hypotheticalOutlet[activeIndex].source.depth}
                  placeholder={t('common:typeIn', {
                    suffix: t('common:depth'),
                  })}
                  InputProps={{
                    inputProps: {
                      min: depthRange.minDepth,
                      max: depthRange.maxDepth,
                    },
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
                {isDepthValid && (
                  <Typography variant="caption" color="error">
                    Depth should be between {depthRange.minDepth}-
                    {depthRange.maxDepth} meters.
                  </Typography>
                )}
              </Block>
            </Box>
          </>
        )}
        {open && step === 1 && (
          <Grid container={!constantDischarge} spacing={2}>
            {!constantDischarge ? (
              <>
                {isUploading ? (
                  <Grid item xs={12}>
                    <Box
                      width={1}
                      height={1}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Box>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Popper
                          icon={<HelpOutlineIcon />}
                          popperContent={t('documentation:dischargeModalFalse')}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        marginBottom={2}
                        padding={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="shades.light"
                        border={1}
                        borderColor="shades.medium"
                      >
                        <Field
                          id="file"
                          name="file"
                          value={file}
                          disabled={constantDischarge}
                          render={({ field }) => (
                            <UploadField
                              disabled={constantDischarge}
                              {...field}
                              onChange={(uploadedFile) => setFile(uploadedFile)}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FieldArray
                        name={`outlet.hypotheticalOutlet.${activeIndex}.discharges`}
                        render={(arrayHelpers) => (
                          <>
                            {hypotheticalOutlet[activeIndex].discharges &&
                              hypotheticalOutlet[activeIndex].discharges.map(
                                (discharge, index) => (
                                  <Box key={index}>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="flex-end"
                                    >
                                      <Grid item xs={5}>
                                        <Block headline={t('common:timestamp')}>
                                          <Tooltip
                                            title={
                                              hypotheticalOutlet[activeIndex]
                                                .discharges[index].timestamp
                                            }
                                          >
                                            <TextField
                                              fullWidth
                                              autoComplete="off"
                                              onChange={handleChange}
                                              id={`outlet.hypotheticalOutlet.${activeIndex}.discharges[${index}].timestamp`}
                                              name={`outlet.hypotheticalOutlet.${activeIndex}.discharges[${index}].timestamp`}
                                              value={
                                                hypotheticalOutlet[activeIndex]
                                                  .discharges[index].timestamp
                                              }
                                              placeholder={t(
                                                'common:timestamp'
                                              )}
                                              type="datetime-local"
                                            />
                                          </Tooltip>
                                        </Block>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Block
                                          headline={t('common:discharge', {
                                            suffix: '(m<sub>3</sub>/s)',
                                          })}
                                        >
                                          <TextField
                                            fullWidth
                                            autoComplete="off"
                                            onChange={handleChange}
                                            id={`outlet.hypotheticalOutlet.${activeIndex}.discharges.${index}.dischargeValue`}
                                            name={`outlet.hypotheticalOutlet.${activeIndex}.discharges.${index}.dischargeValue`}
                                            value={
                                              hypotheticalOutlet[activeIndex]
                                                .discharges[index]
                                                .dischargeValue
                                            }
                                            placeholder={t('common:typeIn', {
                                              suffix: t('common:value'),
                                            })}
                                            type="number"
                                          />
                                        </Block>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Tooltip title="Remove">
                                          <div>
                                            <IconButton
                                              aria-label="remove"
                                              disabled={
                                                hypotheticalOutlet[activeIndex]
                                                  .discharges.length === 1 ||
                                                constantDischarge
                                              }
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <DeleteIcon
                                                fontSize="small"
                                                color="primary"
                                              />
                                            </IconButton>
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )}
                            <Box
                              marginTop={2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              padding={0.5}
                              bgcolor="shades.light"
                              border={1}
                              borderColor="shades.medium"
                            >
                              <Tooltip title={t('common:addNew')}>
                                <div>
                                  <IconButton
                                    aria-label="add"
                                    disabled={constantDischarge}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        timestamp: format(
                                          new Date(activeModel.startDate),
                                          'YYYY-MM-DDTHH:mm'
                                        ),
                                        dischargeValue: '0',
                                      })
                                    }
                                  >
                                    <AddIcon fontSize="small" color="primary" />
                                  </IconButton>
                                </div>
                              </Tooltip>
                            </Box>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ResponsiveContainer width="100%" height={250}>
                        <LineChart
                          data={hypotheticalOutlet[activeIndex].discharges}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="timestamp"
                            tickFormatter={(date) =>
                              format(new Date(date), 'DD.MM.YYYY')
                            }
                          />
                          <YAxis />
                          <ChartTooltip />
                          <Legend />
                          <Line
                            type="linear"
                            dataKey="dischargeValue"
                            stroke="#8884d8"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <FieldArray
                fullWidth
                name={`outlet.hypotheticalOutlet.${activeIndex}.discharges`}
                render={(arrayHelpers) => (
                  <>
                    {hypotheticalOutlet[activeIndex].discharges &&
                      hypotheticalOutlet[activeIndex].discharges.map(
                        (discharge, index) => (
                          <Box key={index}>
                            <Box display="flex" justifyContent="flex-end">
                              <Popper
                                icon={<HelpOutlineIcon />}
                                popperContent={t(
                                  'documentation:dischargeModalTrue'
                                )}
                              />
                            </Box>
                            <Block
                              headline={t('common:discharge', {
                                suffix: '(m<sub>3</sub>/s)',
                              })}
                            >
                              <TextField
                                fullWidth
                                autoComplete="off"
                                onChange={handleChange}
                                id={`outlet.hypotheticalOutlet.${activeIndex}.discharges.${index}.dischargeValue`}
                                name={`outlet.hypotheticalOutlet.${activeIndex}.discharges.${index}.dischargeValue`}
                                value={
                                  hypotheticalOutlet[activeIndex].discharges[
                                    index
                                  ].dischargeValue
                                }
                                placeholder={t('common:typeIn', {
                                  suffix: t('common:value'),
                                })}
                                type="number"
                              />
                            </Block>
                          </Box>
                        )
                      )}
                  </>
                )}
              />
            )}
          </Grid>
        )}
        {open && step === 2 && (
          <>
            {isPointModel ? (
              <>
                <Box display="flex" justifyContent="flex-end">
                  <Popper
                    icon={<HelpOutlineIcon />}
                    popperContent={t('documentation:jetModal')}
                    placement="right-top"
                  />
                </Box>
                <Box marginBottom={4}>
                  <Block>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={`outlet.hypotheticalOutlet.${activeIndex}.jet.enabled`}
                          name={`outlet.hypotheticalOutlet.${activeIndex}.jet.enabled`}
                          checked={hypotheticalOutlet[activeIndex].jet.enabled}
                          onChange={handleChange}
                        />
                      }
                      label={t('dilution:enableNearField')}
                    />
                  </Block>
                </Box>
                <Box marginBottom={4}>
                  <Block headline={t('common:pipeDiameter')}>
                    <TextField
                      label={
                        <Popper
                          prefix={t('common:pipeDiameter')}
                          icon={<HelpOutlineIcon />}
                          popperContent={t('documentation:pipeDiameter')}
                          placement="top"
                        />
                      }
                      onBlur={handleOnBlur}
                      fullWidth
                      onChange={handleChange}
                      id={`outlet.hypotheticalOutlet.${activeIndex}.jet.pipeDiameter`}
                      name={`outlet.hypotheticalOutlet.${activeIndex}.jet.pipeDiameter`}
                      placeholder={t('common:typeIn', {
                        suffix: t('common:pipeDiameter'),
                      })}
                      type="number"
                      value={hypotheticalOutlet[activeIndex].jet.pipeDiameter}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">m</InputAdornment>
                        ),
                      }}
                      disabled={!hypotheticalOutlet[activeIndex].jet.enabled}
                    />
                  </Block>
                </Box>
                <Box marginBottom={4}>
                  <Block headline={t('dilution:angleOfOutletPipe')}>
                    <Grid container spacing={3}>
                      <Grid item sm={6}>
                        <TextField
                          label={
                            <Popper
                              prefix={t('dilution:angleHorizontal')}
                              icon={<HelpOutlineIcon />}
                              popperContent={t(
                                'documentation:popperHorizontal'
                              )}
                              placement="top"
                            />
                          }
                          onBlur={handleOnBlur}
                          type="number"
                          fullWidth
                          onChange={handleChange}
                          id={`outlet.hypotheticalOutlet.${activeIndex}.jet.angleHorizontal`}
                          name={`outlet.hypotheticalOutlet.${activeIndex}.jet.angleHorizontal`}
                          placeholder={t('dilution:angleHorizontal')}
                          value={
                            hypotheticalOutlet[activeIndex].jet.angleHorizontal
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">°</InputAdornment>
                            ),
                          }}
                          disabled={
                            !hypotheticalOutlet[activeIndex].jet.enabled
                          }
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label={
                            <Popper
                              prefix={t('dilution:angleVertical')}
                              icon={<HelpOutlineIcon />}
                              popperContent={t('documentation:popperVertical')}
                              placement="top"
                            />
                          }
                          onBlur={handleOnBlur}
                          type="number"
                          fullWidth
                          onChange={handleChange}
                          id={`outlet.hypotheticalOutlet.${activeIndex}.jet.angleVertical`}
                          name={`outlet.hypotheticalOutlet.${activeIndex}.jet.angleVertical`}
                          value={
                            hypotheticalOutlet[activeIndex].jet.angleVertical
                          }
                          placeholder={t('dilution:angleVertical')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">°</InputAdornment>
                            ),
                          }}
                          disabled={
                            !hypotheticalOutlet[activeIndex].jet.enabled
                          }
                        />
                      </Grid>
                    </Grid>
                  </Block>
                </Box>
                <Box marginBottom={4}>
                  <Block
                    headline={t('dilution:numberOfDiffuserPorts')}
                    helper={
                      <Popper
                        icon={<HelpOutlineIcon />}
                        popperContent={t('documentation:numberOfDiffusorPorts')}
                      />
                    }
                  >
                    <TextField
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      id={`outlet.hypotheticalOutlet.${activeIndex}.jet.numberOfDiffuserPorts`}
                      name={`outlet.hypotheticalOutlet.${activeIndex}.jet.numberOfDiffuserPorts`}
                      value={
                        hypotheticalOutlet[activeIndex].jet
                          .numberOfDiffuserPorts
                      }
                      placeholder={t('common:typeIn', {
                        suffix: t('dilution:numberOfDiffuserPorts'),
                      })}
                      disabled={!hypotheticalOutlet[activeIndex].jet.enabled}
                    />
                  </Block>
                </Box>
              </>
            ) : (
              <Box
                marginBottom={4}
                padding={4}
                display="flex"
                flexDirection="colunn"
                justifyContent="center"
                alignItems="center"
                bgcolor="shades.light"
                border={1}
                borderColor="shades.medium"
              >
                <Typography color="primary" variant="subtitle1">
                  {t('dilution:noJet')}
                </Typography>
              </Box>
            )}
          </>
        )}
        {open && step === 3 && (
          <>
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
              <Popper
                icon={<HelpOutlineIcon />}
                popperContent={t('documentation:densityTitle')}
                placement="left"
              />
            </Box>
            <Box marginBottom={4}>
              <Block
                headline={t('common:temperature', {
                  suffix: '(°C)',
                })}
              >
                <TextField
                  fullWidth
                  onChange={handleChange}
                  id={`outlet.hypotheticalOutlet.${activeIndex}.density.temperature`}
                  name={`outlet.hypotheticalOutlet.${activeIndex}.density.temperature`}
                  placeholder="Type in temperature"
                  type="number"
                  value={hypotheticalOutlet[activeIndex].density.temperature}
                />
              </Block>
            </Box>
            <Box marginBottom={4}>
              <Block
                headline={t('common:salinity', {
                  suffix: '(PSU)',
                })}
              >
                <TextField
                  fullWidth
                  onChange={handleChange}
                  id={`outlet.hypotheticalOutlet.${activeIndex}.density.salinity`}
                  name={`outlet.hypotheticalOutlet.${activeIndex}.density.salinity`}
                  placeholder="Type in salinity"
                  type="number"
                  value={hypotheticalOutlet[activeIndex].density.salinity}
                />
              </Block>
            </Box>
          </>
        )}
        {open && step === 4 && (
          <>
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
              <Popper
                icon={<HelpOutlineIcon />}
                popperContent={t('documentation:concentrationTitle')}
                placement="left"
              />
            </Box>
            <Box marginBottom={4}>
              <FieldArray
                name={`outlet.hypotheticalOutlet.${activeIndex}.concentrations`}
                render={(arrayHelpers) => (
                  <>
                    {hypotheticalOutlet[activeIndex].concentrations.map(
                      (concentration, index) => (
                        <Box key={index}>
                          <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={5}>
                              <Block
                                headline={t('common:componentDescription')}
                              >
                                <TextField
                                  fullWidth
                                  autoComplete="off"
                                  onChange={handleChange}
                                  id={`outlet.hypotheticalOutlet.${activeIndex}.concentrations[${index}].componentDescription`}
                                  name={`outlet.hypotheticalOutlet.${activeIndex}.concentrations[${index}].componentDescription`}
                                  value={
                                    hypotheticalOutlet[activeIndex]
                                      .concentrations[index]
                                      .componentDescription
                                  }
                                  placeholder="label"
                                  disabled
                                />
                              </Block>
                            </Grid>
                            <Grid item xs={5}>
                              <Block headline={t('common:concentrationValue')}>
                                <TextField
                                  fullWidth
                                  autoComplete="off"
                                  onChange={handleChange}
                                  id={`outlet.hypotheticalOutlet.${activeIndex}.concentrations.${index}.concentrationValue`}
                                  name={`outlet.hypotheticalOutlet.${activeIndex}.concentrations.${index}.concentrationValue`}
                                  disabled={
                                    !concentration.include ||
                                    fixedOutletConcentration
                                  }
                                  value={
                                    hypotheticalOutlet[activeIndex]
                                      .concentrations[index].concentrationValue
                                  }
                                  placeholder={t('common:typeIn', {
                                    suffix: t('common:value'),
                                  })}
                                  type="number"
                                />
                              </Block>
                            </Grid>
                            <Grid item xs={2}>
                              <Block headline={t('common:unit')}>
                                <TextField
                                  fullWidth
                                  autoComplete="off"
                                  id={`outlet.hypotheticalOutlet.${activeIndex}.concentrations.${index}.unit`}
                                  name={`outlet.hypotheticalOutlet.${activeIndex}.concentrations.${index}.unit`}
                                  value={
                                    hypotheticalOutlet[activeIndex]
                                      .concentrations[index].unit
                                  }
                                  disabled
                                />
                              </Block>
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    )}
                  </>
                )}
              />
            </Box>
          </>
        )}
      </Modal>
    </>
  )
}

export default Step
