import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Container = ({ classes, children }) => (
  <div className={classes.container}>{children}</div>
);

export default withStyles(styles)(Container);
