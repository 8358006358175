
const outletTypes = [
  {
    label: 'Regnbetingede Overløb',
    value: 'Regnbetingede Overløb',
  },
  {
    label: 'Separate Regnudløb',
    value: 'Separate Regnudløb',
  },
]

export default outletTypes;