// TODO: This looks like a spaceship. Let's get rid of it :-)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { format } from 'date-fns'

import ActionsItemMenu from './partials/ActionsItemMenu'

import styles from './styles'

const getUnit = name => {
  const matcher = name.toUpperCase()
  const units = {
    'SPILL MASS': 'kg',
    'TOTAL VOLUME': 'm<sup>3</sup>',
  }
  return units[matcher]
}

const ActionsItem = ({
  classes,
  description,
  functions,
  handleActionsItemClick,
  menu,
  name,
  action,
  showHour,
  showMenu,
  showStatus,
  status,
  noMarginOnItems,
}) => {
  const onActionsItemClick = () => handleActionsItemClick(action)

  const timeStampFromServer = new Date(`${action.dateTime}Z`)
  const timeToClient = format(timeStampFromServer, 'HH:mm')

  return (
    <div
      className={classNames(classes.content, {
        [classes.contentWithoutMargin]: noMarginOnItems,
      })}
      onClick={onActionsItemClick}
      onKeyPress={onActionsItemClick}
    >
      <div className={classes.action}>
        {showHour && (
          <Grid item className={classes.actionHour}>
            <Typography component="div" className={classes.hourText}>
              {timeToClient}
            </Typography>
          </Grid>
        )}
        {showStatus && (
          <Grid item className={classes.status}>
            <div className={classes.verticalLine} />
            <div className={classes.actionStatus}>
              <Tooltip title={status.message}>
                <CircularProgress
                  style={{
                    color: status.color,
                    backgroundColor: 'transparent',
                  }}
                  variant={status.progress ? 'indeterminate' : 'determinate'}
                  value={status.progress ? status.progress : 100}
                  size={16}
                  thickness={7}
                />
              </Tooltip>
              <Typography component="span" className={classes.actionProgress}>
                {status.progress && `${status.progress}%`}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid
          item
          className={classNames(classes.actionDetails, {
            [classes.actionDetailsWithoutSpacing]: noMarginOnItems,
          })}
        >
          <Typography
            component="h3"
            color="primary"
            className={classes.actionTitle}
          >
            {name}
          </Typography>
          <ul className={classes.listDetails}>
            {description.map(({ name, value }) => {
              const unit = getUnit(name)
              return (
                <Typography
                  key={name}
                  component="li"
                  className={classes.textFields}
                >
                  {`${name}: ${value}`}
                  {unit && (
                    <>
                      {' '}
                      <span dangerouslySetInnerHTML={{ __html: unit }} />
                    </>
                  )}
                </Typography>
              )
            })}
          </ul>
        </Grid>
        {showMenu && (
          <ActionsItemMenu functions={functions} menu={menu} action={action} />
        )}
      </div>
    </div>
  )
}

ActionsItem.propTypes = {
  classes: PropTypes.object,
  functions: PropTypes.objectOf(PropTypes.func),
  menu: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.shape({
    data: PropTypes.object,
    date: PropTypes.string,
    id: PropTypes.string,
    lastJobId: PropTypes.string,
    lastJobStatus: PropTypes.string,
    lastJobProgress: PropTypes.number,
    version: PropTypes.string,
  }),
  status: PropTypes.shape({
    color: PropTypes.string,
    message: PropTypes.string,
    progress: PropTypes.number,
  }),
  showHour: PropTypes.bool,
  showMenu: PropTypes.bool,
  showStatus: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.array,
}

export default withStyles(styles, { withTheme: true })(ActionsItem)
