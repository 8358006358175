import React, { useContext } from 'react';
import { Pane } from 'react-leaflet';

import { DataviewerContext } from 'containers/Dataviewer';

import CoreMap from 'components/CoreMap';
import CoreMapLayer from 'components/CoreMap/MapLayer';

import Tools from './partials/Tools';
import Progress from './partials/Progress';

import Measure from './partials/Measure';
import Marker from './partials/Marker';
import ShapeFile from './partials/ShapeFile';

const MapContainer = ({ isFullWidth }) => {
  const { actions, state, refs } = useContext(DataviewerContext);
  const { animationLoading, mapView, step, layers = {}, timestep } = state;
  const { mapRef } = refs;
  const keys = step ? Object.keys(layers[step]) : [];

  const MapLayer = ({ id, layer, checked }) => {
    if (!checked) return null;
    return (
      <CoreMapLayer
        key={id}
        layer={layer}
        map={mapRef.current}
        timestep={timestep}
        onStatus={actions.onStatus}
        isFullWidth={isFullWidth}
      />
    );
  };

  return (
    <CoreMap
      animationLoading={animationLoading}
      config={mapView}
      componentRef={mapRef}
    >
      <Tools />
      <Measure />
      <Marker />
      <ShapeFile />
      <Pane style={{ zIndex: 201 }}>
        {keys.map(key => {
          const id = key;
          const layer = layers[step][id];
          const { checked = false } = layer;
          return <MapLayer key={id} id={id} layer={layer} checked={checked} />;
        })}
      </Pane>
      <Progress />
    </CoreMap>
  );
};

export default MapContainer;
