import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { withFormik, Form } from 'formik';

import Drawer from 'components/Drawer';

import Wizard, {
  WizardHeader,
  WizardContent,
  WizardFooter,
} from 'components/Wizard';

import Header from './partials/Header';
import Content from './partials/Content';
import Footer from './partials/Footer';

import HabitatsState from 'contexts/HabitatsState';

import formikConfig from './lib/formik.config';

import steps from './steps';

const enhancer = compose(withFormik(formikConfig));

const Sidebar = ({
  habitatsActiveStep,
  errors,
  handleChange,
  isSubmitting,
  isValid,
  open,
  resetForm,
  setHabitatsActiveStep,
  setFieldValue,
  setValues,
  validateForm,
  values,
}) => {
  const formik = {
    errors,
    handleChange,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
    setValues,
    values,
  };

  useEffect(() => {
    handleFormValidation();
  }, []);

  useEffect(() => {
    handleFormValidation();
  }, [habitatsActiveStep]);

  const handleFormValidation = async () => {
    await validateForm();
  };

  if (open) {
    return (
      <Drawer>
        <Form>
          <Wizard
            context={HabitatsState}
            disabled={!isValid}
            initialStep={habitatsActiveStep}
            onStepChange={setHabitatsActiveStep}
            steps={steps}
            shared={{
              formik,
            }}
          >
            <Drawer.Container>
              <WizardHeader component={Header} />
              <WizardContent component={Content} />
              <WizardFooter component={Footer} />
            </Drawer.Container>
          </Wizard>
        </Form>
      </Drawer>
    );
  }
  return null;
};

export default enhancer(Sidebar);
