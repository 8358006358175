import React from 'react'

import {
  GeoJSON,
  LayersControl,
  Pane,
  TileLayer,
  WMSTileLayer,
} from 'react-leaflet'

import { buoys } from 'assets/geojsons'
import brest_zones from 'assets/geojsons/brest_zones'

const { features: brestZones } = brest_zones
const { BaseLayer, Overlay } = LayersControl

const Layers = () => (
  <LayersControl position="bottomleft">
    <BaseLayer checked name="Normal">
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </BaseLayer>

    <BaseLayer name="Black & White">
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
      />
    </BaseLayer>

    <Overlay checked={false} name={buoys.properties.NAME}>
      <GeoJSON
        data={buoys}
        // onEachFeature={(feature, layer) =>
        //   layer.bindPopup(feature.properties.name)
        // }
        onEachFeature={(feature, layer) =>
          layer.bindPopup(feature.properties.name, {
            background: '#ffffff',
            color: '#234c5e',
          })
        }
      />
    </Overlay>

    {brestZones.map(zone => (
      <Overlay
        key={zone.properties.name}
        checked={false}
        name={zone.properties.name}
      >
        <GeoJSON data={zone} />
      </Overlay>
    ))}

    {/* <Overlay checked={false} name="Sediment Layer">
      <WMSTileLayer
        url="http://192.43.239.132/geoserver/SeaStatus/wms"
        format="image/png"
        transparent
        layers="SeaStatus:SedimentClassesDK"
      />
    </Overlay> */}

    {/* <Overlay name="Cadastral map (DK)">
      <WMSTileLayer
        layers="MatrikelSkel,Centroide"
        attribution='&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>'
        format="image/png"
        transparent
        token="d12107f70a3ee93153f313c7c502169a"
        url="https://services.kortforsyningen.dk/mat"
        // https://services.kortforsyningen.dk/service?request=GetCapabilities&version=1.1.1&ticket=ffd7f89b20b0a2bed843efdbba764870&servicename=mat&service=WMS
      />
    </Overlay> */}

    <Overlay name="Exclusive Economic Zone">
      <WMSTileLayer
        layers="eez_boundaries"
        //layers="worldheritagemarineprogramme"
        attribution='&copy; <a target="_blank" href="http://www.marineregions.org/disclaimer.php">Marineregions.org</a>'
        format="image/png"
        transparent
        token=""
        url="http://geo.vliz.be/geoserver/MarineRegions/wms"
        // http://geo.vliz.be/geoserver/MarineRegions/wms?Request=getCapabilities
      />
    </Overlay>

    <Overlay name="Exclusive Economic Zone (HELCOM)">
      <WMSTileLayer
        layers="6"
        attribution='&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>'
        format="image/png"
        transparent
        url="https://maps.helcom.fi/arcgis/services/MADS/Background/MapServer/WmsServer"
        // https://maps.helcom.fi/arcgis/services/MADS/Background/MapServer/WMSServer?request=GetCapabilities&service=WMS
      />
    </Overlay>

    <Overlay name="Seamarks (OpenSeaMap)">
      <Pane>
        <TileLayer
          attribution='&copy; <a href="http://www.openseamap.org">OpenSeaMap</a>'
          url="https://t1.openseamap.org/seamark/{z}/{x}/{y}.png"
        />
      </Pane>
    </Overlay>
  </LayersControl>
)

export default Layers
