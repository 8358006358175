import React, { memo } from 'react'
import { compose } from 'recompose'
import {
  Table,
  withStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { orderBy } from 'lodash'

import TableListRow from './partials/TableListRow'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const TableList = memo(
  ({ columns = [], list = [], hover = true, sorting = [], classes }) => {
    if (!columns.length || !list.length) return null
    const fields = sorting.map(item => item.field)
    const directions = sorting.map(item => item.direction)
    const sortedList = orderBy(list, fields, directions)
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map(({ id, heading = '', options = {} }) => (
              <TableCell key={id} {...options}>
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedList.map((row, index) => (
            <TableListRow
              key={index}
              row={row}
              columns={columns}
              hover={hover}
              index={index}
            />
          ))}
        </TableBody>
      </Table>
    )
  }
)

export default enhancer(TableList)
