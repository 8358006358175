import React, { useState, forwardRef, useEffect } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import PrintIcon from '@material-ui/icons/Print'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

import {
  readyToPrintImages,
  imagesReport,
} from 'components/DeckGLMap/states/imagesReport'
import useCollection from './hooks/useCollection'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PrintCollection = ({ scenario }, index) => {
  const { push } = useHistory()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { t } = useTranslation()

  const reportImages = useRecoilValue(readyToPrintImages)
  const resetReportImages = useResetRecoilState(imagesReport)
  const withReportImages = !!reportImages.length

  const report = useCollection({
    scenario,
    reportImages,
  })

  useEffect(() => {
    return () => resetReportImages()
  }, [])

  const handleGoToPrint = () => push('/print', report)

  return (
    <>
      {withReportImages && (
        <Box position="absolute" zIndex={9} top="1.5rem" left="1.5rem">
          <Badge
            badgeContent={reportImages.length}
            color="secondary"
            style={{ pointerEvents: 'auto' }}
          >
            <Tooltip title={t('common:printScenario')}>
              <IconButton size="small" color="primary" onClick={handleOpen}>
                <PrintIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Badge>
        </Box>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Box paddingY="1rem">{t('common:includingImages')}</Box>
        </DialogTitle>
        <DialogContent>
          <Box width={500}>
            {withReportImages ? (
              <>
                <Divider />
                <List>
                  {reportImages.map(({ properties: { id, label } }) => (
                    <div key={id}>
                      <ListItem disableGutters>
                        <ListItemText primary={label} />
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </>
            ) : (
              <Box
                marginBottom={4}
                padding={4}
                display="flex"
                flexDirection="colunn"
                justifyContent="center"
                alignItems="center"
                bgcolor="shades.light"
                border={1}
                borderColor="shades.medium"
              >
                <Typography color="primary" variant="subtitle1">
                  The collection is empty
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box padding="1rem">
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoToPrint}
            >
              Print
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PrintCollection
