import React, { useContext, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import { compose } from 'recompose';

import {
  IconButton,
  Tooltip,
  TextField,
  Grid,
  withStyles,
} from '@material-ui/core';

import {
  AddLocation as AddLocationIcon,
  Autorenew as AutorenewIcon,
  Delete as DeleteIcon,
  Timeline as LineIcon,
} from '@material-ui/icons';

import { Field, FieldArray } from 'formik';

import L from 'leaflet';

import { GEOGRAPHICAL_DOMAINS, SITE_TYPES } from 'containers/Dataviewer/config';
import withDataviewerState from 'hocs/withDataviewerState';
import { DataviewerContext } from 'containers/Dataviewer';

import Block from 'components/Block';
import DropdownSearch from 'components/DropdownSearch';

import styles from './styles';

const enhancer = compose(
  withDataviewerState,
  withStyles(styles)
);

const Step1 = ({ classes, shared }) => {
  const { formik } = shared;
  const { setFieldValue, handleChange, values } = formik;
  const { state, actions } = useContext(DataviewerContext);
  const { coords: coordinatesFromMap, drawControl, position } = state;
  const {
    makeGeojson,
    updateCoords,
    updateDomain,
    updatePosition,
    updateSite,
  } = actions;

  useEffect(() => {
    const hasChanged = !isEqual(values.coordinates, coordinatesFromMap);
    const isInitiated = values.coordinates.length > 0;
    if (!isInitiated || hasChanged) {
      setFieldValue('coordinates', coordinatesFromMap);
    }
  }, [coordinatesFromMap]);

  useEffect(() => {
    const hasChanged = !isEqual(values.coordinates, coordinatesFromMap);
    if (hasChanged) {
      updateCoords(values.coordinates);
    }
  }, [values.coordinates]);

  const removeLayers = () => {
    // remove all drawControl layers from map
    const featureGroup = drawControl.options.edit.featureGroup;
    const layers = featureGroup._layers;
    Object.keys(featureGroup._layers).forEach(id => {
      const layer = layers[id];
      featureGroup.removeLayer(layer);
    });
  };

  const drawOnMap = (action, source) => {
    switch (action) {
      case 'draw': {
        drawControl._toolbars[action]._modes[source].handler.enable();
        break;
      }

      case 'update': {
        removeLayers();

        if (coordinatesFromMap.length === 1) {
          L.circleMarker([
            coordinatesFromMap[0].lat,
            coordinatesFromMap[0].lng,
          ]).addTo(drawControl.options.edit.featureGroup);

          updatePosition({
            lat: coordinatesFromMap[0].lat,
            lng: coordinatesFromMap[0].lng,
            exists: true,
          });
        } else if (coordinatesFromMap.length > 1) {
          const polygonList = coordinatesFromMap.map(
            ({ lat, lng }) => new L.LatLng(lat, lng)
          );

          L.polygon(polygonList, {
            color: 'blue',
            weight: 3,
            opacity: 0.5,
            smoothFactor: 1,
          }).addTo(drawControl.options.edit.featureGroup);

          const layers = drawControl.options.edit.featureGroup._layers;
          const layer = layers[Object.keys(layers)[0]];
          const center = layer.getBounds().getCenter();
          const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
          const { lat, lng } = center;

          updatePosition({ lat, lng, exists: true, area });
        }
        break;
      }

      case 'remove': {
        removeLayers();
        updatePosition({ lat: '', lng: '', exists: false });
        updateCoords([]);
        break;
      }

      default:
        return console.warn(
          "If we don't need a default case - then we don't need a switch. Let's refactor :-)"
        );
    }
  };

  return (
    <>
      <Block headline="Geographical Domain">
        <Field
          component={DropdownSearch}
          value={values.domain}
          onChange={data => {
            setFieldValue('domain', data);
            updateDomain(data);
          }}
          autoComplete="off"
          fullWidth
          id="domain"
          name="domain"
          placeholder="Select domain"
          margin="dense"
          variant="outlined"
          options={GEOGRAPHICAL_DOMAINS}
          getOptionLabel={option => {
            const label = option.label;
            return label;
          }}
        />
      </Block>
      <Block headline="Site Location">
        <Field
          component={DropdownSearch}
          value={values.site}
          onChange={data => {
            setFieldValue('site', data);
            updateSite(data);
            updatePosition({ lat: '', lng: '', exists: false });
            updateCoords([]);
          }}
          autoComplete="off"
          fullWidth
          id="site"
          name="site"
          placeholder="Select site"
          margin="dense"
          variant="outlined"
          options={SITE_TYPES}
          getOptionLabel={option => {
            const label = option.label;
            return label;
          }}
        />
      </Block>
      {values.site.id === 'draw' && !position.exists && (
        <div>
          <Tooltip title="Draw a point on the map">
            <IconButton
              variant="contained"
              color="secondary"
              aria-label="Add Positions"
              onClick={() => {
                drawOnMap('draw', 'circlemarker');
              }}
            >
              <AddLocationIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Draw a polygon on the map, click on first point to close polygon">
            <IconButton
              variant="contained"
              color="secondary"
              aria-label="Add Positions"
              onClick={() => {
                drawOnMap('draw', 'polygon');
              }}
            >
              <LineIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {values.site.id === 'draw' && position.exists && (
        <div>
          <div className={classes.row}>
            <Tooltip title="Update site location">
              <div>
                <IconButton
                  variant="contained"
                  color="secondary"
                  aria-label="Update Position"
                  onClick={() => drawOnMap('update', 'update')}
                >
                  <AutorenewIcon fontSize="large" />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Delete site location">
              <div>
                <IconButton
                  variant="contained"
                  color="secondary"
                  aria-label="Delete Position"
                  onClick={() => drawOnMap('remove', 'remove')}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </div>
            </Tooltip>
          </div>

          <div id="sitelocation">
            <FieldArray
              name="coordinates"
              render={() => (
                <>
                  {values.coordinates.map((coordinate, index) => (
                    <Block headline={`Position ${index + 1}`} key={index}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Field
                            component={TextField}
                            fullWidth
                            autoComplete="off"
                            value={values.coordinates[index].lat.toFixed(4)}
                            onChange={handleChange}
                            id={`coordinates.${index}.lat`}
                            name={`coordinates.${index}.lat`}
                            label="Latitude (°N)"
                            margin="dense"
                            type="number"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={TextField}
                            fullWidth
                            autoComplete="off"
                            value={values.coordinates[index].lng.toFixed(4)}
                            onChange={handleChange}
                            id={`coordinates.${index}.lng`}
                            name={`coordinates.${index}.lng`}
                            label="Longitude (°E)"
                            margin="dense"
                            type="number"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Block>
                  ))}
                </>
              )}
            />
          </div>
        </div>
      )}
      {values.site.id === 'shape' &&
        (position.exists ? (
          <p>Done. Shapefile loaded.</p>
        ) : (
          <div id="shape">
            <label>Select zipped shapefile </label>
            <input
              type="file"
              id="input"
              accept=".zip"
              onChange={makeGeojson}
            />
          </div>
        ))}
    </>
  );
};

export default enhancer(Step1);
