export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    marginTop: '30px',
  },
  rootWithoutMargin: {
    marginTop: '0 !important',
  },
})
