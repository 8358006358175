import React from 'react'
import { format } from 'date-fns'
import Tooltip from '@material-ui/core/Tooltip'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'


import {
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
} from 'recharts'


const Chart = ({ chartdata, resetChart }) => {
    const { keys, data } = chartdata

    const handleResetChart = () => {
        resetChart()
    }

    return (
        <Box display='flex' width='100%' height='100%'>
            <IconButton style={{ position: 'absolute', right: 0, zIndex: 1 }} onClick={handleResetChart}>
                <Close />
            </IconButton>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={'time'}
                        tickFormatter={date =>
                            format(new Date(date), 'DD.MM.YYYY')
                        }
                    />
                    <YAxis domain={['auto', 'auto']} />
                    <Tooltip />
                    {/* <Legend /> */}
                    {keys.map(({ name, color }) => <Line key={name} type="linear" dataKey={name} stroke={color} dot={false} />)}

                </LineChart>
            </ResponsiveContainer>
        </Box>

    )
}

export default Chart