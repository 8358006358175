import React, { useEffect } from "react";
import { compose } from "recompose";
import { withFormik, Form } from "formik";

import Drawer from "components/Drawer";

import Wizard, {
  WizardHeader,
  WizardContent,
  WizardFooter
} from "components/Wizard";

import Header from "./partials/Header";
import Content from "./partials/Content";
import Footer from "./partials/Footer";

import MarineMonitoringState from "contexts/MarineMonitoringState";

import formikConfig from "./lib/formik.config";

import steps from "./steps";

const enhancer = compose(withFormik(formikConfig));

const Sidebar = ({
  marineMonitoringActiveStep,
  errors,
  handleChange,
  isSubmitting,
  isValid,
  resetForm,
  setMarineMonitoringActiveStep,
  setFieldValue,
  setValues,
  validateForm,
  values
}) => {
  const formik = {
    errors,
    handleChange,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
    setValues,
    values
  };

  useEffect(() => {
    handleFormValidation();
  }, []);

  useEffect(() => {
    handleFormValidation();
  }, [marineMonitoringActiveStep]);

  const handleFormValidation = async () => {
    await validateForm();
  };

  return (
    <Drawer>
      <Form>
        <Wizard
          context={MarineMonitoringState}
          disabled={!isValid}
          initialStep={marineMonitoringActiveStep}
          onStepChange={setMarineMonitoringActiveStep}
          steps={steps}
          shared={{
            formik
          }}
        >
          <Drawer.Container>
            <WizardHeader component={Header} />
            <WizardContent component={Content} />
            <WizardFooter component={Footer} />
          </Drawer.Container>
        </Wizard>
      </Form>
    </Drawer>
  );
};

export default enhancer(Sidebar);
