import { atom, selector } from 'recoil'

import scenarioTypesState from './scenarioTypes'

export const activeScenarioTypeId = atom({
  key: 'activeScenarioType',
  default: null,
})

export const activeScenarioTypeState = selector({
  key: 'activeScenarioTypeState',
  get: ({ get }) => {
    const scenarioTypes = get(scenarioTypesState)
    const scenarioTypeId = get(activeScenarioTypeId)
    return scenarioTypes.find((type) => type.id === scenarioTypeId) || {}
  },
})

export const scenarioPeriods = selector({
  key: 'scenarioPeriods',
  get: ({ get }) => {
    const { scenarioPeriods: periods = [] } = get(activeScenarioTypeState)
    return periods
  },
})

export const options = selector({
  key: 'options',
  get: ({ get }) => {
    const { options: scenarioOptions = {} } = get(activeScenarioTypeState)
    return scenarioOptions
  },
})

export const predefinedComponents = selector({
  key: 'predefinedComponents',
  get: ({ get }) => {
    const { canDeselectComponents = false } = get(options)

    const { predefinedComponents = [] } = get(activeScenarioTypeState)

    const components = !!predefinedComponents.length
      ? predefinedComponents
      : [
          {
            componentDescription: 'New tracer 1',
            concentrationValue: 0,
            decayRate: 0,
            backgroundConcentration: 0,
            unit: '',
            include: true,
            disabled: false,
          },
        ]

    return components.map(
      (
        {
          componentDescription = 'New tracer 1',
          decayRate = 0,
          concentrationValue = 0,
          backgroundConcentration = 0,
          unit = '',
          include = true,
        },
        index
      ) => {
        return {
          componentDescription,
          decayRate,
          concentrationValue,
          backgroundConcentration,
          unit,
          include,
          index,
          disabled: !canDeselectComponents,
        }
      }
    )
  },
})

export default activeScenarioTypeId
