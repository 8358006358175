import React from 'react';
import LeafletAnimation from 'components/CoreMap/MapControl/partials/LeafletAnimation';

const AutoAnim = ({ dataSource, map, onStatus, timestep }) => (
  <LeafletAnimation
    scale={1}
    opacity={1}
    pane="ecoli"
    zIndex={210}
    onStatus={onStatus}
    shadingType="ShadedContour"
    enabled
    map={map}
    refresh
    dataSource={dataSource}
    currentDateTime={timestep}
  />
);

export default AutoAnim;
