import React from 'react'
import { Box, Divider } from '@material-ui/core'

const Footer = ({ children }) => (
  <Box px={4} display="flex" flexDirection="column">
    <Divider />
    <Box>{children}</Box>
  </Box>
)

export default Footer
