import React from 'react';
import { compose } from 'recompose';
import Drawer from 'components/Drawer';
import withDataviewerState from 'hocs/withDataviewerState';

const enhancer = compose(withDataviewerState);

const Content = ({ config }) => {
  const { steps, activeStep } = config;
  const { component: Component, ...rest } = [...steps].find(
    (step, index) => index === activeStep
  );
  return (
    <Drawer.Content>
      <Component {...rest} />
    </Drawer.Content>
  );
};

export default enhancer(Content);
