import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Wizard from './Wizard'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '25px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fab: { margin: '20px' },
})

class WizardDrawer extends PureComponent {
  render() {
    const {
      resetIndicatorDisplay,
      calcImpacts,
      initInputData,
      removeTable,
      coords,
    } = this.props

    const wizard = (
      <Wizard
        initialValues={{ title: '', description: '' }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false)
            alert(JSON.stringify(values, null, 2))
          }, 500)
        }}
        resetIndicatorDisplay={resetIndicatorDisplay}
        calcImpacts={calcImpacts}
        initInputData={initInputData}
        removeTable={removeTable}
        coords={coords}
      >
        <Wizard.Step>
          <Step1 />
        </Wizard.Step>
        <Wizard.Step>
          <Step2 />
        </Wizard.Step>
        <Wizard.Step>
          <Step3 />
        </Wizard.Step>
        <Wizard.Step>
          <Step4 />
        </Wizard.Step>
        <Wizard.Step>
          <Step5 />
        </Wizard.Step>
      </Wizard>
    )

    return wizard
  }
}

export default withStyles(styles)(WizardDrawer)
