import React from 'react';
import { compose } from 'recompose';
import Drawer from 'components/Drawer';
import withHabitatsState from 'hocs/withHabitatsState';

const enhancer = compose(withHabitatsState);

const Content = ({ config }) => {
  const { steps, activeStep } = config;
  const { component: Component, ...rest } = [...steps].find(
    (step, index) => index === activeStep
  );
  return (
    <Drawer.Content>
      <Component {...rest} />
    </Drawer.Content>
  );
};

export default enhancer(Content);
