export default (initialValues = {}) => {
  const {
    siteInformation = {},
    production = {},
    conditions = {},
    thresholds = {},
  } = initialValues
  const { title = '', features = [] } = siteInformation
  const {
    tonnes = 0,
    cld3mo = '',
    cld4mo = '',
    nld3mo = '',
    pld2mo = '',
    nh4ld = '',
  } = production
  const {
    bedShear = [],
    bedShear2mo = [],
    botTemp = [],
    botTemp2mo = [],
    botCur = [],
    surfTemp = [],
    surfCur = [],
    dayLength = [],
  } = conditions
  const {
    soc = { minor: '50', medium: '100', high: '200', veryHigh: '500' },
    son = { minor: '2', medium: '5', high: '10', veryHigh: '5' },
    sop = { minor: '0.5', medium: '1', high: '2', veryHigh: '5' },
    h2sSed = { minor: '1', medium: '2', high: '10', veryHigh: '50' },
    inFlux = { minor: '5', medium: '20', high: '50', veryHigh: '100' },
    doReduc = { minor: '0.2', medium: '0.5', high: '1.0', veryHigh: '4.0' },
    nh4Rel = { minor: '1', medium: '5', high: '20', veryHigh: '50' },
    pp = { minor: '0.005', medium: '0.01', high: '0.05', veryHigh: '0.2' },
    chl = { minor: '0.01', medium: ' 0.012', high: '0.015', veryHigh: '0.02' },
    sd = { minor: '0.1', medium: '0.5', high: '1.0', veryHigh: '2.0' },
  } = thresholds
  return {
    siteInformation: {
      title,
      features,
    },
    production: {
      tonnes,
      cld3mo,
      cld4mo,
      nld3mo,
      pld2mo,
      nh4ld,
    },
    conditions: {
      bedShear,
      bedShear2mo,
      botTemp,
      botTemp2mo,
      botCur,
      surfTemp,
      surfCur,
      dayLength,
    },
    thresholds: {
      soc,
      son,
      sop,
      h2sSed,
      inFlux,
      doReduc,
      nh4Rel,
      pp,
      chl,
      sd,
    },
  }
}
