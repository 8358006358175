import { BitmapLayer } from '@deck.gl/layers'

import { config } from 'config/fishfarm'

export default () =>
  Object.entries(config)
    .filter(([key, value]) => value.isLayer)
    .map(([key, value]) => {
      const { label, unit, filePath, item, timestamp, style } = value
      return {
        name: key,
        type: BitmapLayer,
        label,
        unit,
        bounds: [9, 53.6, 16.1, 58],
        filePath,
        item,
        timestamp,
        style,
      }
    })
