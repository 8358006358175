import React, { useCallback, useEffect, useState, useRef } from 'react'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { Pane, ImageOverlay } from 'react-leaflet'

import { debounce } from 'data/utils'
import { fetchMapAnimationFiles } from 'data/map'

const enhancer = compose(withSnackbar)

const LeafletAnimation = ({
  // React Leaflet map React.ref() '.current' property
  map,
  // Scale of vectors returned by the DHI WMS
  scale,
  // The pane name for the layer
  pane,
  // Z-index of the layer (minimum required zIndex: 200)
  zIndex,
  // The opacity of the layer
  opacity,
  // Specify whether the animation should refresh
  refresh,
  // Emit an event of current component's status: [loading, idle]
  onStatus,
  // Enable or disable component
  enabled,
  // Specify where to fetch the animation data from
  dataSource,
  // Options are NoShading, BoxContour, ShadedContour, ShadedContourWithLand, BoxContourTransparent
  shadingType,
  // Current animation date time to show from dataSource response
  currentDateTime,
  // Snackbar popup notification
  enqueueSnackbar,
}) => {
  let _isMounted = true
  const dsource = useRef()
  const [data, setData] = useState({
    bounds: null,
    images: [],
    firstImage: null,
  })

  const getBounds = () => {
    const b = map.leafletElement.getBounds()
    // setBounds(b);
    return b
  }

  const getBboxValues = () => {
    // if (map.leafletElement.options.crs === L.CRS.EPSG4326) {
    //   alert(`Unsupported projection: ${map.leafletElement.options.crs}. Please use EPSG4326`);
    // }

    const mapBounds = getBounds()
    const nw = map.leafletElement.options.crs.project(mapBounds.getNorthWest())
    const se = map.leafletElement.options.crs.project(mapBounds.getSouthEast())

    return [nw.x, se.y, se.x, nw.y].join(',')
  }

  const handleAnimations = async sources => {
    try {
      const bounds = getBounds()
      const config = {
        scale,
        shadingType,
        bbox: getBboxValues(),
        item: sources.item,
        style: sources.style,
        width: map.leafletElement.getSize().x,
        height: map.leafletElement.getSize().y,
      }
      const images = await fetchMapAnimationFiles(sources, config)

      if (_isMounted && images) {
        enqueueSnackbar('Animations successfully loaded, press play.', {
          variant: 'success',
        })
        setData({ images, bounds })
        onStatus('idle')
      }
    } catch (error) {
      onStatus('idle')
      enqueueSnackbar('Animations could not load, try again.', {
        variant: 'error',
      })
      console.error(error)
    }
  }

  const fetchData = useCallback(
    currentDataSource => {
      onStatus('loading')

      if (currentDataSource) {
        handleAnimations(currentDataSource)
      } else {
        dsource.current = dataSource
        handleAnimations(dsource.current)
      }
    },
    [dataSource]
  )

  // const fetchData = useCallback(
  //   async currentDataSource => {
  //     onStatus('loading');

  //     if (currentDataSource) {
  //       const bounds = getBounds();
  //       const config = {
  //         scale,
  //         shadingType,
  //         bbox: getBboxValues(),
  //         item: currentDataSource.item,
  //         style: currentDataSource.style,
  //         width: map.leafletElement.getSize().x,
  //         height: map.leafletElement.getSize().y,
  //       };

  //       const images = await fetchMapAnimationFiles(currentDataSource, config);
  //       if (_isMounted && images) {
  //         enqueueSnackbar('Animations successfully loaded, press play.');
  //         setData({ images, bounds });
  //         onStatus('idle');
  //       }
  //     }

  //     dsource.current = dataSource;
  //     const bounds = getBounds();
  //     const config = {
  //       scale,
  //       shadingType,
  //       bbox: getBboxValues(),
  //       item: dsource.current.item,
  //       style: dsource.current.style,
  //       width: map.leafletElement.getSize().x,
  //       height: map.leafletElement.getSize().y,
  //     };

  //     const images = await fetchMapAnimationFiles(dsource.current, config);
  //     if (_isMounted && images) {
  //       setData({ images, bounds });
  //       onStatus('idle');
  //     }
  //   },
  //   [dataSource]
  // );

  useEffect(() => {
    const fn = debounce(1200, () => {
      if (JSON.stringify(dataSource) !== JSON.stringify(dsource.current)) return

      const currentDataSource = dsource.current
      fetchData(currentDataSource)
    })

    fetchData()

    map.leafletElement.on('moveend zoomend', fn)

    return () => {
      map.leafletElement.off('moveend zoomend', fn)
      _isMounted = false
    }
  }, [fetchData])

  return (
    data.images &&
    data.bounds &&
    enabled && (
      <Pane name={pane} style={{ zIndex }}>
        <ImageOverlay
          bounds={data.bounds}
          opacity={opacity}
          url={`data:image/png;base64,${data.images[currentDateTime] ||
            Object.values(data.images)[0]}`}
        />
      </Pane>
    )
  )
}

export default enhancer(LeafletAnimation)
