import React from 'react';
import { compose } from 'recompose';
import { Avatar as MaterialAvatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LightLogo from 'assets/images/DHI_Logo_Light.png';
import DarkLogo from 'assets/images/DHI_Logo_Dark.png';

import styles from './styles';

const enhancer = compose(withStyles(styles));

const Avatar = enhancer(MaterialAvatar);

const Logo = ({ dark = false, ...rest }) => (
  <Avatar alt="DHI Logo" src={dark ? DarkLogo : LightLogo} {...rest} />
);

export default Logo;
