import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { withFormik, Form } from 'formik'

import Drawer from 'components/Drawer'

import Wizard, {
  WizardHeader,
  WizardContent,
  WizardFooter,
} from 'components/Wizard'

import Header from './partials/Header'
import Content from './partials/Content'
import Footer from './partials/Footer'

import DataviewerState from 'contexts/DataviewerState'

import formikConfig from './lib/formik.config'

import steps from './steps'

const enhancer = compose(withFormik(formikConfig))

const Sidebar = ({
  dataviewerActiveStep,
  errors,
  handleChange,
  isSubmitting,
  isValid,
  resetForm,
  setDataviewerActiveStep,
  setFieldValue,
  setValues,
  validateForm,
  values,
}) => {
  const formik = {
    errors,
    handleChange,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
    setValues,
    values,
  }

  useEffect(() => {
    handleFormValidation()
  }, [])

  useEffect(() => {
    handleFormValidation()
  }, [dataviewerActiveStep])

  const handleFormValidation = async () => {
    await validateForm()
  }

  const handleStepChange = activeStep => {
    setDataviewerActiveStep(activeStep)
  }

  return (
    <Drawer>
      <Form>
        <Wizard
          context={DataviewerState}
          disabled={!isValid}
          initialStep={dataviewerActiveStep}
          onStepChange={handleStepChange}
          steps={steps}
          shared={{
            formik,
          }}
        >
          <Drawer.Container>
            <WizardHeader component={Header} />
            <WizardContent component={Content} />
            <WizardFooter component={Footer} />
          </Drawer.Container>
        </Wizard>
      </Form>
    </Drawer>
  )
}

export default enhancer(Sidebar)
