import { useMemo } from 'react'
import { BitmapLayer } from '@deck.gl/layers'

const useBitmapLayers = ({ images, step, id, getPickableObject }) => {
  const pickable = getPickableObject ? true : false

  const handleOnClick = ({ x, y, coordinate }) => {
    if (getPickableObject) {
      const pickableObject = { x, y, lat: coordinate[1], lon: coordinate[0] }
      getPickableObject(pickableObject)
    }
  }

  const layers = useMemo(
    () =>
      images.map(([timestep, image, bounds, obj], index) => {
        return new BitmapLayer({
          id: `${timestep}-${id}`,
          visible: obj.tStep === step,
          bounds,
          image: `data:image/png;base64,${image}`,
          pickable: pickable,
          onClick: ({ x, y, coordinate }) =>
            handleOnClick({ x, y, coordinate }),
        })
      }),

    [images, step, id]
  )

  return layers
}

export default useBitmapLayers
