import React, { useEffect } from 'react'
import { Route as CoreRoute, Redirect } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'

import redirectAfterLoginState from 'states/redirectAfterLogin'

const Route = React.memo(
  ({
    component: Component,
    isAdministrator = false,
    isExact = false,
    isPrivate = false,
    isUser = false,
    path,
    redirect,
    ...rest
  }) => {
    const { pathname } = useLocation()
    const setRedirectAfterLogin = useSetRecoilState(redirectAfterLoginState)
    const willRedirectAfterLogin = isPrivate && !isUser

    useEffect(() => {
      if (willRedirectAfterLogin) {
        setRedirectAfterLogin(pathname)
      }
    }, [willRedirectAfterLogin])

    return (
      <CoreRoute
        path={path}
        exact={isExact}
        {...rest}
        render={(routeProps) => (
          <>
            {isPrivate ? (
              isUser ? (
                <Component {...routeProps} {...rest} />
              ) : (
                <Redirect to="/logout" />
              )
            ) : isUser && redirect ? (
              <Redirect to={redirect} />
            ) : (
              <>
                <Component {...routeProps} {...rest} />
              </>
            )}
          </>
        )}
      />
    )
  }
)

Route.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isAdministrator: PropTypes.bool,
  isExact: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isUser: PropTypes.bool,
  path: PropTypes.string.isRequired,
  redirect: PropTypes.string,
}

export default Route
