import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { FieldArray } from 'formik'

import { getConditions } from 'data/fishfarm'

import Block from 'components/Block'
import ErrorMessage from 'components/ErrorMessage'
import get from 'lodash/get'

import { config } from 'config/fishfarm'

const fields = Object.entries(config).reduce((allItems, thisItem) => {
  const [key, value] = thisItem
  const { label, unit } = value
  allItems[key] = {
    label,
    unit,
  }
  return allItems
}, {})

const Row = ({ id, headline, unit, value, handleChange, features }) => (
  <Box marginBottom={4}>
    <Block headline={headline} py={1.5}>
      <FieldArray
        name={`conditions.${id}`}
        render={() => (
          <Grid container spacing={3}>
            {features.map((feature, index) => (
              <Grid key={index} item xs={12 / features.length}>
                <TextField
                  fullWidth
                  onChange={handleChange}
                  id={`conditions.${id}[${index}]`}
                  name={`conditions.${id}[${index}]`}
                  value={value[index]}
                  label={index + 1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{unit}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      />
    </Block>
  </Box>
)

const Step = ({ form }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { values, errors, handleChange, setFieldValue } = form
  const { siteInformation, conditions } = values
  const { features } = siteInformation
  const { id } = useParams()

  useEffect(() => {
    if (!id) handleGetValuesFromMap()
  }, [])

  const handleClearValues = () => {
    const rows = Object.keys(conditions)
    const conditionValues = features.map(() => '')
    rows.map(id => handleSetFieldValue({ id, conditionValues }))
  }

  const handleGetValuesFromMap = async () => {
    setIsLoading(true)
    const rows = await getConditions({ features })
    rows.map(({ id, values: conditionValues }) =>
      handleSetFieldValue({ id, conditionValues })
    )
    setIsLoading(false)
  }

  const handleSetFieldValue = ({ id, conditionValues }) => {
    setFieldValue(`conditions.${id}`, conditionValues)
  }

  const rows = Object.entries(fields)

  return (
    <Box width={1}>
      <Box marginBottom={4}>
        <Typography variant="h6">Conditions at site</Typography>
      </Box>
      {isLoading ? (
        <>
          <Box
            width={1}
            height={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box
            bgcolor="shades.light"
            border={1}
            borderColor="shades.medium"
            padding={4}
            marginBottom={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box marginRight={1}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleGetValuesFromMap}
              >
                Get values
              </Button>
            </Box>
            <Box marginLeft={1}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={handleClearValues}
              >
                Clear values
              </Button>
            </Box>
          </Box>

          <Box marginBottom={4}>
            <ErrorMessage
              errors={errors}
              fields={[
                'conditions.bedShear',
                'conditions.bedShear2mo',
                'conditions.botTemp',
                'conditions.botTemp2mo',
                'conditions.botCur',
                'conditions.surfTemp',
                'conditions.surfCur',
                'conditions.dayLength',
              ]}
            />
          </Box>

          {rows.map(([id, { label, unit }]) => (
            <Row
              key={id}
              id={id}
              headline={label}
              unit={unit}
              features={features}
              handleChange={handleChange}
              value={get(conditions, id)}
            />
          ))}
        </>
      )}
    </Box>
  )
}

export default Step
