import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import { getFishfarmScenarios } from 'data/fishfarm'

import TableList from 'components/TableList'
import Footer from 'components/Footer'

import useInterval from 'hooks/useInterval'

import Actions from './partials/Actions'

const List = () => {
  const { push } = useHistory()
  const [sorting, setSorting] = useState([])
  const [scenarios, setScenarios] = useState([])

  useEffect(() => {
    handleGetScenarios()
  }, [])

  useInterval(() => handleGetScenarios(), 10000, [])

  const handleGetScenarios = async () => {
    try {
      const scenariosToSet = await getFishfarmScenarios()
      setScenarios(scenariosToSet)
      setSorting([
        {
          field: 'dateTime',
          direction: 'desc',
        },
        {
          field: 'data.siteInformation.title',
          direction: 'asc',
        },
      ])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box paddingY={10}>
          <Box paddingBottom={4}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={1}
                  height={1}
                >
                  <Box marginRight={1}>
                    <Typography>Create scenario</Typography>
                  </Box>
                  <Box>
                    <Tooltip title="Create scenario">
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="Add"
                        onClick={() => push('/fishfarm-screening/form')}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <TableList
            columns={[
              {
                id: 'title',
                heading: 'Title',
                isSortable: true,
                field: 'data.siteInformation.title',
              },
              {
                id: 'dateCreated',
                heading: 'Date created',
                isSortable: true,
                field: 'dateTime',
                formatter: 'YYYY-MM-DD',
              },
              {
                id: 'timeCreated',
                heading: 'Time created',
                isSortable: true,
                field: 'dateTime',
                formatter: 'HH:mm',
              },
              {
                id: 'Actions',
                heading: 'Actions',
                component: Actions,
                shared: {
                  handleGetScenarios,
                },
              },
            ]}
            list={scenarios}
            sorting={sorting}
          />
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default List
