const toFeature = (source, include) => {
  return {
    type: 'Feature',
    name: source.label,
    geometry: {
      type: 'Point',
      coordinates: [source.longitude, source.latitude],
    },
    properties: { include },
  }
}

const toFeatureCollection = (listOutlets) => {
  return {
    type: 'FeatureCollection',
    features: listOutlets.map(({ source, include }) =>
      toFeature(source, include)
    ),
  }
}

export default toFeatureCollection
