// TODO: This looks like a spaceship. Let's get rid of it :-)

import React, { useState } from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { Divider, Grid, Typography, withStyles } from '@material-ui/core'
import { isDate, sortBy } from 'lodash'
import { format } from 'date-fns'

import { getObjectProperty } from 'data/utils'

import ActionsItem from './partials/ActionsItem'

import createDescriptionObject from './lib/createDescriptionObject'
import buildMenu from './lib/buildMenu'
import checkStatus from './lib/checkStatus'

import styles from './styles'

const enhancer = compose(withStyles(styles))

const ActionsWrapper = ({
  groupedByKey,
  onSelectAction,
  group,
  showHour,
  idField,
  showDate,
  showStatus,
  showMenu,
  menuItems,
  classes,
  functions,
  nameField,
  descriptionFields,
  noMarginOnItems,
}) => {
  const isKeyValidDate = isDate(new Date(groupedByKey))

  const dateObject = isKeyValidDate && {
    day: format(groupedByKey, 'DD'),
    dayName: format(groupedByKey, 'ddd'),
    monthName: format(groupedByKey, 'MMM'),
  }

  const [selectedId, setSelectedId] = useState('')

  const sortedActions = sortBy(group, ['Date'])
  const dateBlockwidth = showHour ? '97px' : '39px'

  const handleActionsItemClick = clickedAction => {
    if (!clickedAction) return
    const id = getObjectProperty(clickedAction, idField)
    if (selectedId !== id) {
      setSelectedId(id)
      if (onSelectAction) {
        onSelectAction(clickedAction)
      }
    }
  }

  return (
    <>
      {showDate && isKeyValidDate && (
        <div className={classes.dateBlock} style={{ width: dateBlockwidth }}>
          <Grid item className={classes.dateArea}>
            <Typography component="span" className={classes.dayText}>
              {dateObject.day}
            </Typography>
            <Typography component="span" className={classes.textFields}>
              {dateObject.monthName}
            </Typography>
            <Typography component="span" className={classes.textFields}>
              {dateObject.dayName}
            </Typography>
          </Grid>
          {showStatus && <div className={classes.verticalLine} />}
        </div>
      )}
      <div>
        {sortedActions.reverse().map((sortedActionsItem, index) => (
          <ActionsItem
            noMarginOnItems={noMarginOnItems}
            handleActionsItemClick={handleActionsItemClick}
            role="presentation"
            name={getObjectProperty(sortedActionsItem, nameField)}
            description={createDescriptionObject(
              sortedActionsItem,
              descriptionFields
            )}
            key={index}
            functions={functions}
            menu={buildMenu(sortedActionsItem, menuItems)}
            showHour={showHour}
            showMenu={showMenu}
            showStatus={showStatus}
            action={sortedActionsItem}
            status={checkStatus(sortedActionsItem)}
          />
        ))}
        {showDate && <Divider variant="inset" className={classes.divider} />}
      </div>
    </>
  )
}

ActionsWrapper.propTypes = {
  classes: PropTypes.object,
  descriptionFields: PropTypes.arrayOf(PropTypes.object),
  functions: PropTypes.objectOf(PropTypes.func),
  idField: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  nameField: PropTypes.string,
  onSelectAction: PropTypes.func,
  showDate: PropTypes.bool,
  showHour: PropTypes.bool,
  showMenu: PropTypes.bool,
  showStatus: PropTypes.bool,
}

ActionsWrapper.defaultProps = {
  idField: 'id',
  nameField: 'name',
  showDate: false,
  showHour: false,
  showMenu: false,
  showStatus: false,
}

export default enhancer(ActionsWrapper)
