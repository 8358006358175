import { useRef, useEffect } from "react";

export default (callback, delay, triggers = []) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    let id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay, ...triggers]);
};
