import React, { useMemo, useState, useEffect } from 'react'
import DeckGL from '@deck.gl/react'
import { TileLayer } from '@deck.gl/geo-layers'
import { BitmapLayer } from '@deck.gl/layers'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import RoomIcon from '@material-ui/icons/Room'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import {
  EditableGeoJsonLayer,
  ViewMode,
  ModifyMode,
  TranslateMode,
  DrawLineStringMode,
  DrawPolygonMode,
  DrawPointMode,
} from 'nebula.gl'

const sizeInPixels = 8

const Editor = ({
  viewport,
  data,
  setData,
  withPath = true,
  withPoint = true,
  withPolygon = true,
  withDelete = true,
  editable = true,
  hidden = false,
  mapManagerLayers = [],
  mapStepsLayers = [],
}) => {
  const [mousePosition, setMousePosition] = useState({})
  const [mode, setMode] = useState(() => ViewMode)
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([])

  const isPath = useMemo(() => mode === DrawLineStringMode, [mode])
  const isPoint = useMemo(() => mode === DrawPointMode, [mode])
  const isPolygon = useMemo(() => mode === DrawPolygonMode, [mode])

  const withEditor = editable && (withPath || withPoint || withPolygon)

  useEffect(() => {
    if (!withEditor) setSelectedFeatureIndexes([])
  }, [withEditor])

  const handleDelete = () => {
    const updatedData = {
      ...data,
      features: data.features.filter(
        (feature, index) => !selectedFeatureIndexes.includes(index)
      ),
    }
    setSelectedFeatureIndexes([])
    setData(updatedData)
  }

  const handleStopPath = () => setMode(() => ViewMode)

  const handleSetPath = () =>
    setMode(() => (isPath ? ViewMode : DrawLineStringMode))

  const handleSetPoint = () =>
    setMode(() => (isPoint ? ViewMode : DrawPointMode))

  const handleSetPolygon = () =>
    setMode(() => (isPolygon ? ViewMode : DrawPolygonMode))

  const onEdit = ({ editType, updatedData }) => {
    if (!withEditor) return
    if (editType === 'addFeature') {
      setMode(() => ViewMode)
    }
    setData(updatedData)
  }

  const onClick = ({ picked, isGuide, layer, index }) => {
    if (!withEditor) return
    if (picked && !isGuide) {
      setSelectedFeatureIndexes([index])
      setMode(() => TranslateMode)
    } else {
      setSelectedFeatureIndexes([])
    }

    if (mode === ModifyMode && layer === null) {
      setMode(() => ViewMode)
      setSelectedFeatureIndexes([])
    }

    if (mode !== ViewMode) {
      return
    }

    if (mode === ViewMode && picked) setMode(() => ModifyMode)
  }

  const editableLayer =
    !hidden &&
    new EditableGeoJsonLayer({
      data,
      id: 'editableLayer',
      mode,
      onEdit,
      onClick,
      selectedFeatureIndexes,
      editHandleType: 'point',
      editHandlePointOutline: 0,
      editHandlePointStrokeWidth: 0,
      editHandlePointRadiusMinPixels: sizeInPixels,
      editHandlePointRadiusMaxPixels: sizeInPixels,
      stroked: false,
      filled: true,
      lineWidthUnits: 'pixels',
      autoHighlight: withEditor,
      highlightColor: [255, 255, 255, 100],
      pointRadiusMinPixels: sizeInPixels,
      pointRadiusMaxPixels: sizeInPixels,
      getFillColor: (feature) => {
        const isSelected = selectedFeatureIndexes.some(
          (i) => data.features[i] === feature
        )
        if (isSelected) {
          return [13, 57, 88, 200]
        } else {
          return [100, 141, 174, 200]
        }
      },
      getEditHandlePointColor: () => [13, 57, 88, 200],
    })

  // TODO: TileLayer

  const tileLayer = new TileLayer({
    data: 'https://t1.openseamap.org/seamark/{z}/{x}/{y}.png',
    pickable: false,
    autoHighlight: false,
    highlightColor: [60, 60, 60, 40],
    minZoom: 3,
    maxZoom: 11,

    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      })
    },
  })

  return (
    <>
      <Box
        position="absolute"
        zIndex={1}
        width={1}
        height={1}
        style={{ pointerEvents: 'none !important' }}
      >
        <DeckGL
          viewState={viewport}
          layers={[
            ...mapManagerLayers,
            ...mapStepsLayers,
            tileLayer,
            editableLayer,
          ]}
          onHover={({ coordinate }) => {
            if (coordinate) {
              setMousePosition({ lng: coordinate[0], lat: coordinate[1] })
            }
          }}
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            zIndex={9}
            height="auto"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              width="100%"
              height={54}
              paddingX={0.5}
              paddingY={0.25}
            >
              <Typography variant="caption">
                Latitude: {mousePosition.lat}
              </Typography>
              <Typography variant="caption">
                Longitude: {mousePosition.lng}
              </Typography>
            </Box>
          </Box>
        </DeckGL>
      </Box>
      {withEditor && (
        <Box position="absolute" top={0} right={0} zIndex={9} height="inherit">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="inherit"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="auto"
              padding={0.5}
              style={{
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRight: '0',
              }}
            >
              {withPolygon && (
                <Tooltip title="Polygon">
                  <IconButton aria-label="Polygon" onClick={handleSetPolygon}>
                    <SignalCellular4BarIcon
                      fontSize="small"
                      color={isPolygon ? 'primary' : 'secondary'}
                    />
                  </IconButton>
                </Tooltip>
              )}

              {withPath && (
                <>
                  {isPath ? (
                    <Tooltip title="Stop path">
                      <IconButton
                        aria-label="Stop path"
                        onClick={handleStopPath}
                      >
                        <ShowChartIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Path">
                      <IconButton aria-label="Path" onClick={handleSetPath}>
                        <ShowChartIcon fontSize="small" color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}

              {withPoint && (
                <Tooltip title="Point">
                  <IconButton aria-label="Point" onClick={handleSetPoint}>
                    <RoomIcon
                      fontSize="small"
                      color={isPoint ? 'primary' : 'secondary'}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {withDelete && (
                <Tooltip title="Delete">
                  <IconButton aria-label="Delete" onClick={handleDelete}>
                    <DeleteIcon fontSize="small" color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Editor
