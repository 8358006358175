import React, {
  Suspense,
  lazy,
  createContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { compose } from 'recompose'
import classNames from 'classnames'
import { withSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core'
import { isEmpty, sortBy } from 'lodash'
import { Switch, Route, withRouter } from 'react-router-dom'

import { getSedimentClass } from 'data/map'
import { addItem, updateItem, queryList } from 'data/sedimentSpill'
import { queryProjects } from 'data/projects'

import Loader from 'components/Loader'
import Drawer from 'components/Drawer'

import ReviewChart from './partials/ReviewChart'
import Map from './partials/Map'
import Scenarios from './partials/Scenarios'
import MapControl from './partials/MapControl'

import { MODELS } from './config'

import styles from 'styles/global'
import useProfile from 'hooks/useProfile'

const ScenarioForm = lazy(() => import('./partials/ScenarioForm'))

const scenariosRequestOptions = {
  connection: 'scenarios',
  tool: 'sediment-spill',
}

const enhancer = compose(
  withSnackbar,
  withStyles(styles),
  withRouter
)

export const SedimentSpillContext = createContext()

const SedimentSpill = ({ classes, enqueueSnackbar, history }) => {
  const { loading, user } = useProfile()
  const [animationData, setAnimationData] = useState(false)
  const [animationEnabled, setAnimationEnabled] = useState(true)
  const [animationLoading, setAnimationLoading] = useState(false)
  const [chartData, setChartData] = useState({})
  const [drawControl, setDrawControl] = useState({})
  const [dredgerAreaForm, setDredgerAreaForm] = useState(false)
  const [dredgerUploadForm, setDredgerUploadForm] = useState(false)
  const [dredgerZone, setDredgerZone] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [editScenario, setEditScenario] = useState({})
  const [mapView, setMapView] = useState({
    center: [55.8, 12.1],
    zoom: 3,
  })
  const [models, setModels] = useState()
  const [openChart, setOpenChart] = useState(false)
  const [scenarios, setScenarios] = useState([])
  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: null,
  })

  const [showForm, setShowForm] = useState(false)
  const [timestep, setTimestep] = useState('2011-01-01T01:00:00')
  const [positions, setPositions] = useState([])
  const [projects, setProjects] = useState()

  const mapRef = useRef(null)

  useEffect(() => {
    getProjects()
    getModels()
  }, [])

  const getModels = () => {
    const { metadata } = user
    const { models: userModels } = metadata
    const models = Object.values(MODELS)
    const availableModels = models.filter(model =>
      userModels.includes(model.id)
    )
    const sortedModels = sortBy(availableModels, model => model.label)
    setModels(sortedModels)
  }

  const getProjects = async () => {
    try {
      const query = [
        {
          item: 'company',
          queryOperator: 'Like',
          value: '%' + user.company,
        },
      ]

      const projectsToSet = await queryProjects({
        query,
      })

      setProjects(
        projectsToSet.map(p => {
          const {
            data: { company, description, label, value },
            id,
          } = p
          return {
            company,
            description,
            id,
            label,
            value,
          }
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  const getScenarios = async () => {
    try {
      const query = [
        {
          item: 'Specifications.Project',
          queryOperator: 'Like',
          value: '%' + selectedProject.id,
        },
      ]

      const scenariosToSet = await queryList({
        query,
      })

      setScenarios(scenariosToSet)
    } catch (error) {
      console.error(error)
    }
  }

  const toggleChart = chartData => {
    if (isEmpty(chartData)) {
      setChartData(chartData)
      setOpenChart(false)
    } else {
      setChartData(chartData)
      setOpenChart(true)
    }
  }

  const toggleDomain = domain =>
    setMapView({
      box: [domain.box.lowerLeft, domain.box.upperRight],
      center: [domain.lat, domain.lng],
      id: domain.id,
      zoom: domain.zoom,
    })

  const toggleForm = trueOrFalse => {
    setEditForm(false)
    setEditScenario({})
    setShowForm(!trueOrFalse)
  }

  const onCreated = async e => {
    if (e.layerType === 'marker') {
      const LatLng = e.layer._latlng
      const Latitude = Math.round(LatLng.lat * 10000) / 10000
      const Longitude = Math.round(LatLng.lng * 10000) / 10000
      const position = {
        Latitude,
        Longitude,
        SedimentClass: await getSedimentClass(
          e.layer._icon._leaflet_pos,
          e.target
        ),
      }

      setPositions([position])
    }

    if (e.layerType === 'polyline') {
      const LatLngs = e.layer._latlngs

      const positionsToSet = await Promise.all(
        LatLngs.map(async (latlng, index) => {
          const Latitude = Math.round(latlng.lat * 10000) / 10000
          const Longitude = Math.round(latlng.lng * 10000) / 10000
          return {
            Latitude,
            Longitude,
            SedimentClass: await getSedimentClass(
              e.layer._parts[0][index],
              e.target
            ),
          }
        })
      )

      setPositions(positionsToSet)
    }
  }

  const onDeleted = () => setPositions([])

  const onMounted = drawControlToSet => setDrawControl(drawControlToSet)

  const onViewResults = scenarioToSet => {
    history.push(`/sediment-spill/view/${scenarioToSet.id}`)
  }

  const onEditScenario = scenarioToSet => {
    history.push(`/sediment-spill/form/${scenarioToSet.id}`, {
      selectedProject,
    })
  }

  const onAction = (scenarioToSet, action) => {
    const actions = {
      View: () => onViewResults(scenarioToSet),
      Edit: () => onEditScenario(scenarioToSet),
    }
    const handler = actions[action]
    if (handler) return handler()
  }

  const addScenario = async newScenario => {
    toggleForm(true)
    try {
      await addItem({
        body: {
          data: JSON.stringify(newScenario),
        },
        ...scenariosRequestOptions,
      })

      showMessage('You have successfully created a scenario.')
      getScenarios()
    } catch (error) {
      console.error(error)
    }
  }

  const updateScenario = async (updatedScenario, id) => {
    const body = {
      data: JSON.stringify(updatedScenario),
      id,
    }

    toggleForm(true)

    try {
      await updateItem({ body, ...scenariosRequestOptions })
      showMessage('You have successfully updated a scenario.')
      getScenarios()
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeTimestep = timestepToSet => setTimestep(timestepToSet)

  const onAnimate = (
    animationDataToSet,
    animationEnabledToSet,
    animationLoadingToSet
  ) => {
    setAnimationData(animationDataToSet)
    setAnimationEnabled(animationEnabledToSet)
    setAnimationLoading(animationLoadingToSet)
  }

  const onAnimationStatusChange = status => {
    showMessage('Fetching animations, please wait.')
    setAnimationLoading(status === 'loading')
  }

  const handleProjectChange = selectedProjectToSet =>
    setSelectedProject(selectedProjectToSet)

  const showMessage = message => enqueueSnackbar(message, { variant: 'info' })

  if (loading) return null

  return (
    <SedimentSpillContext.Provider
      value={{
        state: {
          animationData,
          animationEnabled,
          animationLoading,
          chartData,
          drawControl,
          dredgerAreaForm,
          dredgerUploadForm,
          dredgerZone,
          editForm,
          editScenario,
          mapView,
          models,
          openChart,
          positions,
          projects,
          scenarios,
          selectedProject,
          showForm,
          timestep,
          user,
        },
        refs: {
          mapRef,
        },
        actions: {
          addScenario,
          handleProjectChange,
          onAction,
          onAnimate,
          onAnimationStatusChange,
          onChangeTimestep,
          onCreated,
          onDeleted,
          onMounted,
          setDredgerAreaForm,
          setDredgerUploadForm,
          setDredgerZone,
          setMapView,
          setOpenChart,
          setPositions,
          setScenarios,
          toggleChart,
          toggleDomain,
          toggleForm,
          updateScenario,
        },
      }}
    >
      <div
        className={classNames({
          [classes.content]: !openChart,
          [classes.chart]: openChart,
        })}
      >
        {openChart ? <ReviewChart /> : <Map />}
        <Suspense fallback={<Loader isLoading isMajorLoading />}>
          <Switch>
            <Route path="/sediment-spill/view/:scenarioId">
              <Drawer>
                <MapControl />
              </Drawer>
            </Route>
            <Route path="/sediment-spill/form/:scenarioId?">
              <Drawer>
                <ScenarioForm />
              </Drawer>
            </Route>
            <Route path="/sediment-spill/:projectName?">
              <Drawer>
                <Scenarios />
              </Drawer>
            </Route>
          </Switch>
        </Suspense>
      </div>
    </SedimentSpillContext.Provider>
  )
}

SedimentSpill.defaultProps = {
  queryDates: {},
}

export default enhancer(SedimentSpill)
