// TODO: This looks like a spaceship. Let's get rid of it :-)

import { isEmpty } from "lodash";

import { getObjectProperty } from "data/utils";

export default (action, condition) => {
  if (!isEmpty(action)) {
    if (condition) {
      const check =
        typeof condition === "object"
          ? getObjectProperty(action, condition.field, condition.value)
          : getObjectProperty(action, condition);
      return check;
    }
    return true;
  }
  return false;
};
