import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import Block from 'components/Block'
import ErrorMessage from 'components/ErrorMessage'
import Lookup from './partials/Lookup'
import get from 'lodash/get'

const fields = {
  cld3mo: {
    headline: 'Benthic load of organic carbon (3 months acc.)',
    unit: 'tonnes',
  },
  cld4mo: {
    headline: 'Benthic load of organic carbon (4 months acc.)',
    unit: 'tonnes',
  },
  nld3mo: {
    headline: 'Benthic load of organic nitrogen (3 months acc.)',
    unit: 'tonnes',
  },
  pld2mo: {
    headline: 'Benthic load of organic phosphorous (2 months acc.)',
    unit: 'tonnes',
  },
  nh4ld: {
    headline: 'Pelagic load of ammonium (1 month acc.)',
    unit: 'tonnes',
  },
}

const Row = ({ headline, unit, id, handleChange, value }) => (
  <Box marginBottom={4}>
    <Block headline={headline}>
      <TextField
        fullWidth
        onChange={handleChange}
        id={`production.${id}`}
        name={`production.${id}`}
        value={value}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        }}
      />
    </Block>
  </Box>
)

const Step = ({ form }) => {
  const { values, errors, handleChange, setFieldValue } = form
  const { production } = values
  const { tonnes } = production

  const rows = Object.entries(fields)

  return (
    <Box width={1}>
      <Box marginBottom={4}>
        <Typography variant="h6">Annual production of rainbow trout</Typography>
      </Box>

      <Lookup
        tonnes={tonnes}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />

      <Box marginTop={4}>
        <ErrorMessage
          errors={errors}
          fields={[
            'production.tonnes',
            'production.cld3mo',
            'production.cld4mo',
            'production.nld3mo',
            'production.pld2mo',
            'production.nh4ld',
          ]}
        />
      </Box>

      {rows.map(([id, { headline, unit }]) => (
        <Row
          key={id}
          id={id}
          headline={headline}
          unit={unit}
          value={get(production, id)}
          handleChange={handleChange}
        />
      ))}
    </Box>
  )
}

export default Step
