import { addDays } from 'date-fns'

const HOST = 'http://192.43.239.132'
const MAP_CONNECTION = 'Map'
const SCENARIO_CONNECTION = 'Scenarios'
const JOB_CONNECTION = 'Worker'
const SHP_CONNECTION = 'ShpfileDownload'

const tssGradation = [
  { value: '> 2000', color: '#221e1b' },
  { value: '1000 - 2000', color: '#392d25' },
  { value: '500 - 1000', color: '#503b2e' },
  { value: '200 - 500', color: '#684835' },
  { value: '100 - 200', color: '#815738' },
  { value: '50 - 100', color: '#97673a' },
  { value: '20 - 50', color: '#aa793c' },
  { value: '10 - 20', color: '#ba8f42' },
  { value: '5 - 10', color: '#c7a853' },
  { value: '2 - 5', color: '#d1c16b' },
  { value: '1 - 2', color: '#dcdb89' },
  { value: '≤ 1', color: '#e8f5ab' },
]

const percentageGradation = [
  { value: '75 - 100', color: '#67001f' },
  { value: '50 - 75', color: '#800026' },
  { value: '40 - 50', color: '#bd0026' },
  { value: '30 - 40', color: '#e31a1c' },
  { value: '20 - 30', color: '#fc4e2a' },
  { value: '15 - 20', color: '#fd8d3c' },
  { value: '10 - 15', color: '#feb24c' },
  { value: '5 - 10', color: '#fed976' },
  { value: '1 - 5', color: '#ffeda0' },
]

const netGradation = [
  { value: '> 20000', color: '#221e1b' },
  { value: '10000 - 20000', color: '#392d25' },
  { value: '5000 - 10000', color: '#503b2e' },
  { value: '2000 - 5000', color: '#684835' },
  { value: '1000 - 2000', color: '#815738' },
  { value: '500 - 1000', color: '#97673a' },
  { value: '200 - 500', color: '#aa793c' },
  { value: '100 - 200', color: '#ba8f42' },
  { value: '50 - 100', color: '#c7a853' },
  { value: '20 - 50', color: '#d1c16b' },
  { value: '10 - 20', color: '#dcdb89' },
  { value: '≤ 10', color: '#e8f5ab' },
]

const lightReductionGradient = [
  { value: '≤ 1', color: '#e7fa5a' },
  { value: '1 - 5', color: '#f6d346' },
  { value: '5 - 10', color: '#fbad3c' },
  { value: '10 - 20', color: '#f68b45' },
  { value: '20 - 30', color: '#e17161' },
  { value: '30 - 40', color: '#c16479' },
  { value: '40 - 50', color: '#9e5987' },
  { value: '50 - 60', color: '#7e4d8f' },
  { value: '60 - 70', color: '#5d3e99' },
  { value: '70 - 80', color: '#35329b' },
  { value: '80 - 90', color: '#0d3064' },
  { value: '> 90', color: '#032333' },
]

const bedThicknessGradation = [
  { value: '> 50', color: '#2f0f3d' },
  { value: '20 - 50', color: '#4c1550' },
  { value: '10 - 20', color: '#6b185d' },
  { value: '5 - 10', color: '#8a1d63' },
  { value: '2 - 5', color: '#a82860' },
  { value: '1 - 2', color: '#c3385a' },
  { value: '0.5 - 1', color: '#d85053' },
  { value: '0.2 - 0.5', color: '#e76d54' },
  { value: '0.1 - 0.2', color: '#f08e62' },
  { value: '0.05 - 0.1', color: '#f6ad77' },
  { value: '0.02 - 0.05', color: '#facd91' },
  { value: '≤ 0.02', color: '#fdedb0' },
]

const brestTssGradation = [
  { value: '> 2000', color: '#580000' },
  { value: '1000 - 2000', color: '#720505' },
  { value: '500 - 1000', color: '#930b0b' },
  { value: '200 - 500', color: '#b61111' },
  { value: '100 - 200', color: '#d11616' },
  { value: '60 - 100', color: '#F08080' },
  { value: '45 - 60', color: '#fab635' },
  { value: '30 - 45', color: '#faff39' },
  { value: '10 - 30', color: '#c9f03b' },
  { value: '5 - 10', color: '#b0e93d' },
  { value: '2 - 5', color: '#9de43e' },
  { value: '1 - 2', color: '#85dc3f' },
  { value: '≤ 1', color: '#54cd41' },
]

const MENU_ITEMS = [
  {
    name: 'View',
    field: 'Action',
    condition: {
      field: 'lastJobStatus',
      value: 'Completed',
    },
  },
  {
    name: 'Edit',
    field: 'Action',
  },
  {
    name: 'Execute',
    field: 'Execute',
    condition: {
      field: 'lastJobStatus!',
      value: ['Pending', 'InProgress'],
    },
  },
  {
    name: 'Clone',
    field: 'Clone',
  },
  {
    name: 'Delete',
    field: 'Delete',
  },
]

const NAME_FIELD = 'data.Specifications.Name'

const DESCRIPTION_FIELDS = [
  { field: 'data.Specifications.CreatedBy', name: 'Creator' },
  { field: 'data.Specifications.Description', name: 'Description' },
  // { field: "data.Specifications.Visibility.Label", name: "Visibility" }
]

const SCENARIO_STEPPER = {
  0: {
    Name: 'Scenario Specifications',
    Description: 'Scenario',
  },
  1: {
    Name: 'Dredging Plans',
    Description: 'Dredging Plan',
  },
  2: {
    Name: 'Scenario Details',
    Description: 'Choose Scenario Details',
  },
  3: {
    Name: 'Review Scenario',
    Description: 'Review Scenario',
  },
}

const ABSTRACT = [
  {
    field: 'Specifications.Name',
    label: 'Name',
  },
  {
    field: 'Specifications.Description',
    label: 'Description',
  },
  {
    field: 'Specifications.Visibility.Label',
    label: 'Visibility',
  },
  {
    field: 'ScenarioDetails.StartDate',
    label: 'Start Date',
  },
  {
    field: 'ScenarioDetails.EndDate',
    label: 'End Date',
  },
  {
    field: 'ScenarioDetails.Deposition',
    label: 'Deposition Plot',
  },
  {
    field: 'ScenarioDetails.Exceedance',
    label: 'Exceedance Plot',
  },
  {
    field: 'DredgingPlans.Abstract.TotalVolume',
    label: 'Total Volume',
  },
  {
    field: 'DredgingPlans.Abstract.SpillMass',
    label: 'Spill Mass',
  },
  {
    field: 'DredgingPlans.Abstract.TotalHours',
    label: 'Total Dredging Hours',
  },
  {
    field: 'DredgingPlans.Abstract.CalendarDays',
    label: 'Total Calendar Days',
  },
]

const SCENARIO_FIELDS = {
  Specifications: {
    CreatedBy: 'Admin',
    Description: '',
    Domain: 'Roskilde Fjord',
    Model: '',
    ModifiedBy: '',
    Name: '',
    Project: 'test',
    TimeCreated: '2019-03-01T00:00:00',
    TimeModified: '',
    Visibility: {
      Label: 'Private',
      Shared: false,
      ReadOnly: false,
    },
  },
  DredgingPlans: {
    Plans: [],
    ScenarioAbstract: {
      StartDate: '2011-03-01',
      EndDate: '2011-03-07',
      TotalVolume: 1800,
      SpillMass: 90,
      TotalHours: 56,
      CalendarDays: 7,
    },
  },
  ScenarioDetails: {
    StartDate: '2011-03-01',
    EndDate: '2011-03-07',
    Deposition: true,
    Exceedance: true,
  },
}

const DREDGER_FIELDS = {
  Id: '',
  Name: '',
  Dredger: {
    DredgerType: 'None',
    Type: 2,
    RatePerDay: 0,
    SpillFactor: 0,
    SpillLocation: 'Bottom',
  },
  Positions: [
    // {
    //   Longitude: 12.044,
    //   Latitude: 55.837,
    //   SedimentClass: {
    //     SedimentType: 'Mud and sandy mud',
    //     Composition: {
    //       Clay: 80,
    //       Silt: 15,
    //       Sand: 5,
    //       CoarseSand: 0
    //     }
    //   }
    // }
  ],
  DredgerAbstract: {
    StartDate: '2011-03-01',
    EndDate: '2011-03-07',
    StartHour: '08:00',
    EndHour: '16:00',
    TotalVolume: 30000,
    SpillMass: 3000,
    TotalHours: 120,
    CalendarDays: 15,
  },
}

const SCENARIO_VISIBILITY = [
  {
    Label: 'Private',
    Shared: false,
    ReadOnly: false,
  },
  {
    Label: 'Shared',
    Shared: true,
    ReadOnly: true,
  },
  {
    Label: 'Shared Read Only',
    Shared: true,
    ReadOnly: false,
  },
]

const SOURCE_TYPE = [
  {
    Value: 'Stationary',
    Label: 'Stationary Source',
  },
  {
    Value: 'Moving',
    Label: 'Moving Source',
  },
]

const SEDIMENT_CLASSES = [
  {
    SedimentType: 'Sand',
    Composition: {
      Clay: 0,
      Silt: 0,
      Sand: 100,
      CoarseSand: 0,
    },
  },
  {
    SedimentType: 'Muddy sand',
    Composition: {
      Clay: 20,
      Silt: 30,
      Sand: 50,
      CoarseSand: 0,
    },
  },
  {
    SedimentType: 'Mud and sandy mud',
    Composition: {
      Clay: 80,
      Silt: 15,
      Sand: 5,
      CoarseSand: 0,
    },
  },
  {
    SedimentType: 'Gravel and coarse sand',
    Composition: {
      Clay: 0,
      Silt: 0,
      Sand: 0,
      CoarseSand: 100,
    },
  },
  {
    SedimentType: 'Till/diamicton',
    Composition: {
      Clay: 20,
      Silt: 30,
      Sand: 30,
      CoarseSand: 20,
    },
  },
  {
    SedimentType: 'Quaternary clay and silt',
    Composition: {
      Clay: 50,
      Silt: 50,
      Sand: 0,
      CoarseSand: 0,
    },
  },
  {
    SedimentType: 'Sedimentary rock',
    Composition: {
      Clay: 0,
      Silt: 0,
      Sand: 0,
      CoarseSand: 0,
    },
  },
]

const DREDGER_TYPES = [
  {
    DredgerType: 'TSHD (Trailing Sunction Hopper Dredger)',
    Type: 2,
    RatePerDay: 1300,
    SpillFactor: 8,
    SpillLocation: 'Surface',
  },
  {
    DredgerType: 'CSD (Cutter Suction Dredger)',
    Type: 1,
    RatePerDay: 1000,
    SpillFactor: 5,
    SpillLocation: 'Bottom',
  },
  {
    DredgerType: 'Backhoe Dredger',
    Type: 4,
    RatePerDay: 900,
    SpillFactor: 5,
    SpillLocation: 'All over water column',
  },
  {
    DredgerType: 'Grab Dredger',
    Type: 4,
    RatePerDay: 800,
    SpillFactor: 5,
    SpillLocation: 'All over water column',
  },
  {
    DredgerType: 'Bucket Ladder Dredger',
    Type: 4,
    RatePerDay: 1100,
    SpillFactor: 5,
    SpillLocation: 'All over water column',
  },
]

const FILES = [
  {
    // filePath: 'Scenarios\\{scenarioId}\\{resultFolder}\\{fileName}',
    // filePath: '..\\Data\\Scenarios\\{scenarioId}\\{resultFolder}\\{fileName}',
    filePath: '{scenarioId}\\{resultFolder}\\{fileName}',
    timeSpan: {
      type: 'Fixed',
      start: 'Data.ScenarioDetails.StartDate',
      end: 'Data.ScenarioDetails.EndDate',
    },
    timeStep: 3600,
  },
]

const ANIMATION_LAYERS = [
  {
    name: 'TSS in Surface [mg/l]',
    id: 'tss-surface',
    type: 'animation',
    item: 1,
    style: 'suspended',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Surface.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: tssGradation,
  },
  {
    name: 'TSS in Bottom [mg/l]',
    id: 'tss-bottom',
    type: 'animation',
    item: 1,
    style: 'suspended',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Bottom.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: tssGradation,
  },
  {
    name: 'Bed Thickness Change [cm]',
    id: 'bed-thickness-change',
    type: 'animation',
    item: 1,
    style: 'thickness',
    folder: 'MT.m3fm - Result Files',
    file: '2D_BedThicknessChange_cm.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: bedThicknessGradation,
  },
  {
    name: `Net Deposition [g m\u207b\xb2]`,
    id: 'net-deposition',
    type: 'animation',
    item: 4,
    style: 'deposition',
    folder: 'MT.m3fm - Result Files',
    file: '2D.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: netGradation,
  },
  {
    name: 'Light Reduction at Bottom due to TSS [%]',
    id: 'light-reduction-bottom',
    type: 'animation',
    item: 5,
    style: 'light_percentage_ss',
    folder: 'ECOLab.m3fm - Result Files',
    file: '2D.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: lightReductionGradient,
  },
]

const PLOT_LAYERS = [
  {
    name: 'TSS Mean in Surface [mg/l]',
    id: 'tss-mean-surface',
    type: 'plot',
    item: 1,
    style: 'suspended',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Mean_Surface.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: tssGradation,
  },
  {
    name: 'TSS Mean in Bottom [mg/l]',
    id: 'tss-mean-bottom',
    type: 'plot',
    item: 1,
    style: 'suspended',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Mean_Bottom.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: tssGradation,
  },
  {
    name: 'TSS Maximum in Surface [mg/l]',
    id: 'tss-maximum-surface',
    type: 'plot',
    item: 1,
    style: 'suspended',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Max_Surface.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: tssGradation,
  },
  {
    name: 'TSS Maximum in Bottom [mg/l]',
    id: 'tss-maximum-bottom',
    type: 'plot',
    item: 1,
    style: 'suspended',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Max_Bottom.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: tssGradation,
  },
  {
    name: 'TSS Exceedence of 2 mg l\u207b\xb1 in Surface [% of time]',
    id: 'tss-exceedence-surface-2mg',
    type: 'plot',
    item: 1,
    style: 'exceedance',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Exc2_Perc_Surface.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: percentageGradation,
  },
  {
    name: 'TSS Exceedence of 2 mg l\u207b\xb1 in Bottom [% of time]',
    id: 'tss-exceedence-bottom-2mg',
    type: 'plot',
    item: 1,
    style: 'exceedance',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Exc2_Perc_Bottom.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: percentageGradation,
  },
  {
    name: 'TSS Exceedence of 5 mg l\u207b\xb1 in Surface [% of time]',
    id: 'tss-exceedence-surface-5mg',
    type: 'plot',
    item: 1,
    style: 'exceedance',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Exc5_Perc_Surface.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: percentageGradation,
  },
  {
    name: 'TSS Exceedence of 5 mg l\u207b\xb1 in Bottom [% of time]',
    id: 'tss-exceedence-bottom-5mg',
    type: 'plot',
    item: 1,
    style: 'exceedance',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Exc5_Perc_Bottom.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: percentageGradation,
  },
  {
    name: 'TSS Exceedence of 10 mg l\u207b\xb1 in Surface [% of time]',
    id: 'tss-exceedence-surface-10mg',
    type: 'plot',
    item: 1,
    style: 'exceedance',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Exc10_Perc_Surface.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: percentageGradation,
  },
  {
    name: 'TSS Exceedence of 10 mg l\u207b\xb1 in Bottom [% of time]',
    id: 'tss-exceedence-bottom-10mg',
    type: 'plot',
    item: 1,
    style: 'exceedance',
    folder: 'MT.m3fm - Result Files',
    file: '2D_TSS_mgl_Exc10_Perc_Bottom.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: percentageGradation,
  },
  {
    name: `Net Deposition Maximum [g m\u207b\xb2]`,
    id: 'net-deposition-max',
    type: 'plot',
    item: 1,
    style: 'deposition',
    folder: 'MT.m3fm - Result Files',
    file: '2D_NetDepo_Max.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: netGradation,
  },
]

const TEST_LAYERS = [
  {
    name: 'Light Reduction at Bottom due to TSS [%]',
    id: 'test-light-reduction-bottom',
    item: 5,
    style: 'light_percentage_ss',
    folder: 'ECOLab.m3fm - Result Files',
    file: '2D.dfsu',
    checked: false,
    opacity: 1,
    opacityMinimum: 0,
    opacityMaximum: 1,
    showOpacityControl: true,
    legendValuePostFix: '',
    enabled: true,
    legendGradient: lightReductionGradient,
  },
]

const DATA_SOURCES = [
  {
    host: 'http://192.43.239.120/WebAPIDemo',
    connection: 'dfsu-mapsource',
    style: 'Ecoli',
    item: 3,
    ids: {
      '2018-09-12T12:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T12:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T13:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T13:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T14:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T14:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T15:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T15:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T16:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T16:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T17:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T17:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T18:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T18:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T19:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T19:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T20:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T20:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T21:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T21:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T22:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T22:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T23:00:00': 'KBHEC3dF012.dfsu',
      '2018-09-12T23:30:00': 'KBHEC3dF012.dfsu',
      '2018-09-13T00:00:00': 'KBHEC3dF012.dfsu',
    },
  },
  {
    host: 'http://192.43.239.132/',
    connection: 'Map',
    style: 'Model',
    item: 3,
    ids: {
      '2011-01-04T14:00:00':
        'Scenarios\\20190327170515-5d593bd8-f0f5-449d-9f86-c139dd61f4fe\\RoskildeFjord_c9_MT_v1.m3fm - Result Files\\3D.dfsu',
    },
  },
]

const DOMAIN_BOX = {
  geometry: {
    coordinates: [
      [
        [11.891, 55.632],
        [12.13, 55.632],
        [12.13, 55.98],
        [11.891, 55.98],
        [11.891, 55.632],
      ],
    ],
    type: 'Polygon',
  },
  properties: {
    style: {
      fillOpacity: 0,
    },
  },
  type: 'Feature',
}

const MODELS = {
  Grenland: {
    id: 'Grenland',
    label: 'Grenlandfjordene',
    startDate: '2017-12-01',
    endDate: '2017-12-31',
    timezone: 'Etc/GMT+1',
    timeStep: 300,
    seabedDensity: 500,
    noMpiCores: 32,
    box: {
      lowerLeft: [58.9, 9.5],
      upperRight: [59.146667, 10.15],
    },
    lat: 59.023,
    lng: 9.825,
    zoom: 9,
    points: [
      {
        stationName: 'P1',
        label: 'P1 - Frierfjorden East',
        position: {
          lat: 59.111117,
          lng: 9.633333,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P1_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
      {
        stationName: 'P2',
        label: 'P2 - Frierfjorden Centre',
        position: {
          lat: 59.1,
          lng: 9.616667,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P2_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
      {
        stationName: 'P3',
        label: 'P3 - Frierfjorden North',
        position: {
          lat: 59.1125,
          lng: 9.575,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P3_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
    ],
  },
  KbhHavn: {
    id: 'KbhHavn',
    label: 'Copenhagen Harbour',
    startDate: '2014-01-01',
    endDate: '2014-01-31',
    timezone: 'Etc/GMT+1',
    timeStep: 300,
    seabedDensity: 500,
    noMpiCores: 32,
    box: {
      lowerLeft: [55.58, 12.46],
      upperRight: [55.8, 12.7],
    },
    lat: 55.6667,
    lng: 12.5667,
    zoom: 12,
    points: [
      {
        stationName: 'P1',
        label: 'P1 - Kalvebod',
        position: {
          lat: 55.637786,
          lng: 12.545394,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P1_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
      {
        stationName: 'P2',
        label: 'P2 - Inderhavnen',
        position: {
          lat: 55.675866,
          lng: 12.589137,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P2_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
      {
        stationName: 'P3',
        label: 'P3 - Yderhavnen',
        position: {
          lat: 55.704577,
          lng: 12.604878,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P3_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
    ],
  },
  RoskildeFjord: {
    id: 'RoskildeFjord', // This would be the 'Model' parameter
    label: 'Roskilde Fjord',
    startDate: '2011-01-01', // the first day the user can pick
    endDate: '2011-12-31', // the last day the user can pick - model ends 2012-01-01 00:00
    timezone: 'Etc/GMT+1',
    timeStep: 3600,
    seabedDensity: 500, // Dry density in kg/m^3 (180: freshly deposited, 500: consolidated seabed, ...)
    noMpiCores: 24,
    box: {
      lowerLeft: [55.6, 11.9],
      upperRight: [56.0, 12.15],
    },
    lat: 55.8,
    lng: 12.1,
    zoom: 10,
    points: [
      {
        stationName: 'P1',
        label: 'P1 - Dyrnaes Hage',
        position: {
          lat: 55.902,
          lng: 12.03416,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P1_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
      {
        stationName: 'P2',
        label: 'P2 - Frederiksvaerk Bredning',
        position: {
          lat: 55.932,
          lng: 12.008,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P2_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
      {
        stationName: 'P3',
        label: 'P3 - Frederikssund Bredning',
        position: {
          lat: 55.865,
          lng: 12.009,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'P3_TSS_mgl.dfs0',
        yAxis: {
          unit: 'mg/l',
          label: 'TSS (mg/l)',
          minYmax: 1.0,
        },
        items: [
          {
            label: 'TSS Surface',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [],
      },
    ],
  },
  // ukns2: {
  //   id: 'ukns2', // This would be the 'Model' parameter
  //   label: 'North Sea',
  //   startDate: '2016-01-01', // the first day the user can pick
  //   endDate: '2016-12-30', // the last day the user can pick - model ends 2016-12-31 00:00
  //   timezone: 'Etc/GMT+1',
  //   timeStep: 3600,
  //   seabedDensity: 500, // Dry density in kg/m^3 (180: freshly deposited, 500: consolidated seabed, ...)
  //   noMpiCores: 80,
  //   box: {
  //     lowerLeft: [49, -11],
  //     upperRight: [61, 9],
  //   },
  //   lat: 55,
  //   lng: 2,
  //   zoom: 5,
  //   points: [],
  // },
  LaSpezia: {
    id: 'LaSpezia', // This would be the 'Model' parameter
    label: 'La Spezia',
    startDate: '2015-02-01', // the first day the user can pick
    endDate: '2015-03-09', // the last day the user can pick - model ends 2015-03-10 10:00
    timezone: 'Etc/GMT+1',
    timeStep: 3600,
    seabedDensity: 500, // Dry density in kg/m^3 (180: freshly deposited, 500: consolidated seabed, ...)
    noMpiCores: 48,
    box: {
      lowerLeft: [44.02, 9.8],
      upperRight: [44.15, 9.95],
    },
    lat: 44.08,
    lng: 9.87,
    zoom: 12,
    points: [],
  },
  Brest: {
    id: 'Brest', // This would be the 'Model' parameter
    label: 'Brest',
    startDate: '2020-02-20', // the first day the user can pick
    endDate: addDays(Date.now(), 2), // the last day the user can pick,
    timezone: 'Etc/GMT+1',
    timeStep: 60,
    seabedDensity: 180, // Dry density in kg/m^3 (180: freshly deposited, 500: consolidated seabed, ...)
    noMpiCores: 44,
    box: {
      lowerLeft: [48.2333, -4.6333],
      upperRight: [48.45, -4.15],
    },
    lat: 48.35,
    lng: -4.5,
    zoom: 11,
    plotLayers: [
      {
        name: 'TSS Mean in Surface [NTU]',
        id: 'tss-mean-surface',
        type: 'plot',
        item: 1,
        style: 'brest_ntu_alarms',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Mean_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: brestTssGradation,
      },
      {
        name: 'TSS Mean in Bottom [NTU]',
        id: 'tss-mean-bottom',
        type: 'plot',
        item: 1,
        style: 'brest_ntu_alarms',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Mean_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: brestTssGradation,
      },
      {
        name: 'TSS Maximum in Surface [NTU]',
        id: 'tss-max-surface',
        type: 'plot',
        item: 1,
        style: 'brest_ntu_alarms',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Max_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: brestTssGradation,
      },
      {
        name: 'TSS Maximum in Bottom [NTU]',
        id: 'tss-max-bottom',
        type: 'plot',
        item: 1,
        style: 'brest_ntu_alarms',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Max_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: brestTssGradation,
      },
      {
        name: 'TSS Exceedence of 30 NTU in Surface [% of time]',
        id: 'tss-exceedence-surface-30',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc30_Perc_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 30 NTU in Bottom [% of time]',
        id: 'tss-exceedence-bottom-30',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc30_Perc_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 45 NTU in Surface [% of time]',
        id: 'tss-exceedence-surface-45',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc45_Perc_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 45 NTU in Bottom [% of time]',
        id: 'tss-exceedence-bottom-45',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc45_Perc_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 60 NTU in Surface [% of time]',
        id: 'tss-exceedence-surface-60',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc60_Perc_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 60 NTU in Bottom [% of time]',
        id: 'tss-exceedence-bottom-60',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc60_Perc_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 100 NTU in Surface [% of time]',
        id: 'tss-exceedence-surface-100',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc100_Perc_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: 'TSS Exceedence of 100 NTU in Bottom [% of time]',
        id: 'tss-exceedence-bottom-100',
        type: 'plot',
        item: 1,
        style: 'exceedance',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Exc100_Perc_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: percentageGradation,
      },
      {
        name: `Net Deposition Maximum [g m\u207b\xb2]`,
        id: 'net-deposition-max',
        type: 'plot',
        item: 1,
        style: 'deposition',
        folder: 'MT.m3fm - Result Files',
        file: '2D_NetDepo_Max.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: netGradation,
      },
    ],
    animationLayers: [
      {
        name: 'TSS in Surface [NTU]',
        id: 'tss-surface',
        type: 'animation',
        item: 1,
        style: 'brest_ntu_alarms',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Surface.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: brestTssGradation,
      },
      {
        name: 'TSS in Bottom [NTU]',
        id: 'tss-bottom',
        type: 'animation',
        item: 1,
        style: 'brest_ntu_alarms',
        folder: 'MT.m3fm - Result Files',
        file: '2D_TSS_NTU_Bottom.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: brestTssGradation,
      },
      {
        name: 'Bed Thickness Change [cm]',
        id: 'bed-thickness-change',
        type: 'animation',
        item: 1,
        style: 'thickness',
        folder: 'MT.m3fm - Result Files',
        file: '2D_BedThicknessChange_cm.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: bedThicknessGradation,
      },
      {
        name: `Net Deposition [g m\u207b\xb2]`,
        id: 'net-deposition',
        type: 'animation',
        item: 8,
        style: 'deposition',
        folder: 'MT.m3fm - Result Files',
        file: '2D.dfsu',
        checked: false,
        opacity: 1,
        opacityMinimum: 0,
        opacityMaximum: 1,
        showOpacityControl: true,
        legendValuePostFix: '',
        enabled: true,
        legendGradient: netGradation,
      },
    ],
    points: [
      {
        stationName: 'B1',
        label: 'Bouée 1 - Futur polder',
        position: {
          lat: 48.37899999999999,
          lng: -4.4506,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B1_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
      {
        stationName: 'B2',
        label: 'Bouée 2 - Moulin Blanc',
        position: {
          lat: 48.38699999999999,
          lng: -4.429250000000001,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B2_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
      {
        stationName: 'B3',
        label: 'Bouée 3 - Océanopolis',
        position: {
          lat: 48.37489166666668,
          lng: -4.447200000000001,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B3_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
      {
        stationName: 'B4',
        label: 'Bouée 4 - Keraliou',
        position: {
          lat: 48.37413333333333,
          lng: -4.432175,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B4_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
      {
        stationName: 'B5',
        label: 'Bouée 5 - Porstrein',
        position: {
          lat: 48.37230833333334,
          lng: -4.479183333333333,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B5_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
      {
        stationName: 'B6',
        label: 'Bouée 6 - Île Ronde',
        position: {
          lat: 48.32644444444444,
          lng: -4.46155,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B6_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
      {
        stationName: 'B ENT',
        label: 'Bouée - SIMBAT - ENT',
        position: {
          lat: 48.3809,
          lng: -4.454633333,
        },
        folder: 'MT.m3fm - Result Files',
        file: 'B_ENT_median_NTU.dfs0',
        yAxis: {
          unit: 'NTU',
          label: 'TSS (NTU)',
          minYmax: 45,
        },
        items: [
          {
            label: 'TSS Surface Median',
            itemNo: 1,
            color: '#7ED321',
          },
          {
            label: 'TSS Bottom Median',
            itemNo: 2,
            color: '#BD10E0',
          },
        ],
        thresholds: [
          {
            label: 'Alarm 1',
            value: [30, 45],
            color: '#F8E71C',
          },
          {
            label: 'Alarm 2',
            value: [45, 60],
            color: '#F5A623',
          },
          {
            label: 'Alarm 3',
            value: [60, 100],
            color: '#D0021B',
          },
          {
            label: 'Alarm 4',
            value: [100],
            color: '#461314',
          },
        ],
      },
    ],
  },
}

const SCENARIO_DOMAINS = [
  {
    Label: 'Roskilde Fjord',
    Area: {
      geometry: {
        coordinates: [
          [
            [11.891, 55.632],
            [12.13, 55.632],
            [12.13, 55.98],
            [11.891, 55.98],
            [11.891, 55.632],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        style: {
          fillOpacity: 0,
        },
      },
      type: 'Feature',
    },
  },
  {
    Label: 'Fehmarnbelt',
    Area: {
      geometry: {
        coordinates: [
          [
            [10.601, 53.93],
            [12.65, 53.93],
            [12.65, 55.708],
            [10.601, 55.708],
            [10.601, 53.93],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        style: {
          fillOpacity: 0,
        },
      },
      type: 'Feature',
    },
  },
  {
    Label: 'Lynetteholmen',
    Area: {
      geometry: {
        coordinates: [
          [
            [12.442, 55.361],
            [13.051, 55.361],
            [13.051, 55.796],
            [12.442, 55.796],
            [12.442, 55.361],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        style: {
          fillOpacity: 0,
        },
      },
      type: 'Feature',
    },
  },
]

const GEOGRAPHICAL_DOMAINS = [
  {
    id: 'RoskildeFjord',
    label: 'Roskilde Fjord',
    lat: 55.8,
    lng: 12.1,
    zoom: 10,
  },
  {
    id: 'ukns2',
    label: 'North Sea',
    lat: 56,
    lng: 9,
    zoom: 6,
  },
  // {
  //   id: 'IDW',
  //   label: 'Inner Danish Waters',
  //   lat: 55.25,
  //   lng: 12.75,
  //   zoom: 7,
  // },
  // {
  //   id: 'BS',
  //   label: 'Baltic Sea',
  //   lat: 58.5,
  //   lng: 24,
  //   zoom: 6,
  // },
]

const PROJECTS = [
  { id: 'test', value: 'test', label: 'Test Project' },
  { id: '', value: 'all', label: 'All Projects' },
]

export {
  HOST,
  SCENARIO_CONNECTION,
  JOB_CONNECTION,
  SHP_CONNECTION,
  MAP_CONNECTION,
  MENU_ITEMS,
  NAME_FIELD,
  DESCRIPTION_FIELDS,
  SCENARIO_STEPPER,
  SCENARIO_FIELDS,
  DREDGER_FIELDS,
  SCENARIO_VISIBILITY,
  SOURCE_TYPE,
  SEDIMENT_CLASSES,
  DREDGER_TYPES,
  ABSTRACT,
  FILES,
  ANIMATION_LAYERS,
  PLOT_LAYERS,
  DATA_SOURCES,
  DOMAIN_BOX,
  GEOGRAPHICAL_DOMAINS,
  SCENARIO_DOMAINS,
  PROJECTS,
  MODELS,
  TEST_LAYERS,
}
