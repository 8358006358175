import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

import Drawer from 'components/Drawer'

import Header from './partials/Header'
import Content from './partials/Content'
import Footer from './partials/Footer'

const Sidebar = React.memo(({ scenarioId, content, withCustomLegends }) => {
  const {
    isLoading,
    heading,
    data: {
      title = '',
      animations = [],
      plots = [],
      modelBounds = [],
      initialView,
      dischargeOutlets = [],
    },
  } = content

  return (
    <Drawer>
      {isLoading ? (
        <Box
          width={1}
          height={1}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Drawer.Container>
          <Drawer.Header>
            <Header heading={heading} title={title} />
          </Drawer.Header>
          <Drawer.Content>
            <Content
              scenarioId={scenarioId}
              animations={animations}
              plots={plots}
              modelBounds={modelBounds}
              initialView={initialView}
              dischargeOutlets={dischargeOutlets}
              withCustomLegends={withCustomLegends}
            />
          </Drawer.Content>
          <Drawer.Footer>
            <Footer />
          </Drawer.Footer>
        </Drawer.Container>
      )}
    </Drawer>
  )
})

export default Sidebar
