import React, { useContext } from 'react'
import { Fab, withStyles } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts'

import { some } from 'lodash'

import orderData from './lib/orderData'

import { SedimentSpillContext } from 'containers/SedimentSpill'

import styles from './styles'

const ReviewChart = ({ classes }) => {
  const {
    state: { chartData },
    actions,
  } = useContext(SedimentSpillContext)
  const { DredgingPlans } = chartData
  const data = orderData(DredgingPlans)
  const hasTotalVolume = some(data, 'totalVolume')
  const hasSpillMass = some(data, 'spillMass')
  const handleToggleChart = () => actions.toggleChart()
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1>Review Chart</h1>
        <Fab
          size="small"
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={handleToggleChart}
        >
          <CloseIcon />
        </Fab>
      </div>
      <div className={classes.content}>
        <BarChart width={600} height={250} data={data}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          <Legend
            formatter={(value, { payload }) => `${value} (${payload.unit})`}
          />
          {hasTotalVolume && (
            <Bar
              dataKey="totalVolume"
              name="Total volume"
              unit="m3"
              fill="#0D3958"
            />
          )}
          {hasSpillMass && (
            <Bar
              dataKey="spillMass"
              name="Spill mass"
              unit="kg"
              fill="#648dae"
            />
          )}
        </BarChart>
      </div>
    </div>
  )
}

export default withStyles(styles)(ReviewChart)
