// TODO: This looks like a spaceship. Let's get rid of it :-)

import React, { useEffect, useState, useContext } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { isSameDay, isBefore } from 'date-fns'
import { get, set } from 'lodash'

import { getScenario } from 'data/sedimentSpill'

import { setTimeOnDate } from 'helpers/timing'

// import {
//   addItem,
//   deleteItem,
//   updateItem,
//   queryWithSelections,
// } from 'data/factory'

import {
  addItem,
  deleteItem,
  updateItem,
  queryWithSelections,
} from 'data/sedimentSpill'

import { executeScenario } from 'data/job'

import { SedimentSpillContext } from 'containers/SedimentSpill'

import { MODELS } from 'containers/SedimentSpill/config'

import ActionsList from 'components/ActionsList'

import ActionsDialog from 'components/ActionsDialog'

import { getObjectProperty } from './lib/index'

import styles from './styles'

// const tool = 'sediment-spill';
const tool = 'brain'

const scenariosRequestOptions = {
  connection: 'scenarios',
  tool,
}

const workerRequestOptions = {
  connection: 'worker',
  tool,
}

const enhancer = compose(
  withSnackbar,
  withStyles(styles)
)

const ScenariosList = ({
  classes,
  descriptionFields,
  enqueueSnackbar,
  idField,
  menuItems,
  nameField,
  onAction,
  showDate,
  showHour,
  showMenu,
  showStatus,
}) => {
  const { state, actions } = useContext(SedimentSpillContext)
  const [dialog, setDialog] = useState({
    title: '',
    message: '',
    command: null,
    open: false,
  })
  const { scenarios, selectedProject } = state

  const showMessage = message => enqueueSnackbar(message, { variant: 'info' })

  let interval

  useEffect(() => {
    getScenarios()
    clearInterval(interval)
    interval = setInterval(() => getScenarios(), 10000)
    return () => clearInterval(interval)
  }, [selectedProject])

  const getScenarios = async () => {
    try {
      const query = [
        {
          item: 'Specifications.Project',
          queryOperator: 'Like',
          value: '%' + selectedProject.id,
        },
      ]

      const scenariosList = await queryWithSelections({
        query,
      })

      actions.setScenarios(scenariosList)
    } catch (error) {
      console.error(error)
    }
  }

  const onDeleteScenario = async deletingScenario => {
    const { id } = deletingScenario
    closeDialog()
    await deleteItem({ id, ...scenariosRequestOptions })
    showMessage('You have successfully deleted a scenario.')
    getScenarios()
  }

  const closeDialog = () => setDialog({ ...dialog, open: false })

  const deleteDialog = deletingScenario => {
    setDialog({
      open: true,
      title: `Delete ${getObjectProperty(deletingScenario, nameField)}`,
      message: `This will delete the selected scenario from the list. After it is deleted you cannot retrieve the data. Are you sure you want to delete ${getObjectProperty(
        deletingScenario,
        nameField
      )} ?`,
      command: () => onDeleteScenario(deletingScenario),
    })
  }

  const cloneScenario = async ({ id: scenarioId }) => {
    try {
      closeDialog()

      const {
        id: originalId,
        data: originalData,
        ...originalRest
      } = await getScenario({
        scenarioId,
      })

      const originalScenario = {
        data: JSON.parse(originalData),
        ...originalRest,
      }

      const originalName = get(originalScenario, nameField)

      const clonedNamed = `Clone of ${originalName}`

      const { data: clonedData, ...clonedRest } = set(
        { ...originalScenario },
        nameField,
        clonedNamed
      )

      const clonedScenario = {
        data: JSON.stringify(clonedData),
        ...clonedRest,
      }

      await addItem({ body: clonedScenario, ...scenariosRequestOptions })

      showMessage('You have successfully cloned a scenario.')

      getScenarios()
    } catch (error) {
      console.error(error)
    }
  }

  const cloneDialog = cloningScenario => {
    setDialog({
      open: true,
      title: `Clone ${getObjectProperty(cloningScenario, nameField)}`,
      message: `This will start a new job in the background. You can delete this cloned scenario later. Are you sure you want to clone ?`,
      command: () => cloneScenario(cloningScenario),
    })
  }

  const onExecuteScenario = async ({ id: scenarioId }) => {
    closeDialog()

    const { data, ...originalRest } = await getScenario({
      scenarioId,
    })

    const executingScenario = {
      data: JSON.parse(data),
      ...originalRest,
    }

    const { ScenarioDetails, Specifications } = executingScenario.data

    const { EndDate: scenarioEndDate } = ScenarioDetails
    const { Model } = Specifications
    const { endDate: modelEndDate } = MODELS[Model.id]
    const today = new Date(Date.now())
    // Set booleans for a forecast models, so we don't target the others
    const modelIsAfter = isBefore(today, new Date(modelEndDate))
    const modelIsSame = isSameDay(today, new Date(modelEndDate))
    const isForecastModel = modelIsAfter || modelIsSame
    // Set boolean for an exceeded scenarios, so we don't run those.
    const todayIsBefore = isBefore(
      setTimeOnDate(today, '00:00:00'),
      setTimeOnDate(scenarioEndDate, '23:59:059')
    )
    // Show toaster messsage if the scenario can't be executed
    if (isForecastModel && !todayIsBefore) {
      enqueueSnackbar('Scenario is  not a forecast.', {
        variant: 'error',
      })
    } else {
      handleExecuteScenario({ id: scenarioId, executingScenario })
    }
  }

  const handleExecuteScenario = async ({ id, executingScenario: { data } }) => {
    try {
      const noMpiCores = get(data, 'Specifications.Model.noMpiCores', undefined)
      const NoMPIcores = noMpiCores ? { NoMPIcores: noMpiCores } : {}
      const { id: lastJobId } = await executeScenario({
        parameters: {
          ScenarioId: id,
          Model: data.Specifications.Model.id,
          TimeStep: data.Specifications.Model.timeStep,
          ...NoMPIcores,
        },
        taskId: 'workflow',
        ...workerRequestOptions,
      })
      const updatedScenario = {
        id,
        lastJobId,
        data: JSON.stringify(data),
      }
      await updateItem({ body: updatedScenario, ...scenariosRequestOptions })
      enqueueSnackbar('Scenario execution started successfully.', {
        variant: 'success',
      })
      getScenarios()
    } catch (error) {
      console.error(error)
    }
  }

  const executeDialog = (executingScenario, connection, menuItemName) => {
    setDialog({
      open: true,
      title: `${menuItemName} ${getObjectProperty(
        executingScenario,
        nameField
      )}`,
      message: `This will start a new job in the background. The status will change after job completion. Are you sure you want to execute ${getObjectProperty(
        executingScenario,
        nameField
      )} ?`,
      command: () => onExecuteScenario(executingScenario, connection),
    })
  }

  const onActionScenario = (actionScenario, menuItemName) =>
    onAction(actionScenario, menuItemName)

  return (
    <div className={classes.root}>
      <ActionsList
        actions={scenarios}
        descriptionFields={descriptionFields}
        functions={{
          action: onActionScenario,
          clone: cloneDialog,
          delete: deleteDialog,
          execute: executeDialog,
        }}
        idField={idField}
        menuItems={menuItems}
        nameField={nameField}
        showDate={showDate}
        showHour={showHour}
        showMenu={showMenu}
        showStatus={showStatus}
      />
      <ActionsDialog
        title={dialog.title}
        message={dialog.message}
        command={dialog.command}
        showDialog={dialog.open}
        closeDialog={closeDialog}
      />
    </div>
  )
}

ScenariosList.defaultProps = {
  idField: 'id',
  nameField: 'data.Name',
  showDate: false,
  showHour: false,
  showMenu: false,
  showStatus: false,
  queryDates: {},
}

export default enhancer(ScenariosList)
