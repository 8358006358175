import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import Wizard, {
  WizardHeader,
  WizardContent,
  WizardFooter,
} from '@dhi-solutions/wizard'

import Mapbox from '@dhi-solutions/mapbox'

import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ZoomInIcon from '@material-ui/icons/AddCircle'
import ZoomOutIcon from '@material-ui/icons/RemoveCircle'
import CloseIcon from '@material-ui/icons/Close'

import {
  getFishfarmScenario,
  addFishfarmScenario,
  updateFishfarmScenario,
} from 'data/fishfarm'
import Drawer from 'components/Drawer'
import MapboxLayers from 'components/MapboxLayers'
import Editor from 'components/Editor'

import { ToolContext } from 'containers/NewFishfarm'

import mapboxConfig from 'config/mapbox'

import steps from './steps'

import getLayers from './lib/getLayers'
import getInitialValues from './lib/getInitialValues'
import getFullWidthProps from './lib/getFullWidthProps'

const layers = getLayers()
const fullWidthProps = getFullWidthProps()

const Form = () => {
  const [initialValues, setInitialValues] = useState({})
  const [metaData, setMetaData] = useState(false)
  const { state: locationState = {} } = useLocation()
  const { id: copyId, view = false } = locationState
  const { id } = useParams()
  const { push } = useHistory()
  const { states } = useContext(ToolContext)
  const { mapbox } = states
  const {
    mapEditableData,
    mapInteractions,
    mapIsEditable,
    mapIsHidingEditable,
    mapManagerLayers,
    mapMarkers,
    mapPosition,
    mapSource,
    mapStepsLayers,
    setMapEditableData,
    setMapManagerLayers,
    setMapPosition,
  } = mapbox

  useEffect(() => {
    handleInitialValues()
  }, [id, copyId])

  useEffect(() => {
    const { siteInformation = {} } = initialValues
    const { features = [] } = siteInformation
    setMapEditableData({
      type: 'FeatureCollection',
      features: features.map(({ lng, lat }) => ({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [Number(lng), Number(lat)],
        },
      })),
    })
  }, [initialValues])

  useEffect(() => {
    return () => {
      setInitialValues({})
      setMetaData(false)
      setMapEditableData({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }, [])

  const handleDefaultValues = () => {
    const values = getInitialValues()
    setInitialValues(values)
  }

  const handleInitialValues = async () => {
    try {
      if (id) {
        const { data, ...rest } = await getFishfarmScenario({ id })
        setInitialValues(data)
        setMetaData(rest)
      } else if (copyId) {
        const { data } = await getFishfarmScenario({ id: copyId })
        setInitialValues({
          ...data,
          siteInformation: {
            ...data.siteInformation,
            title: `Clone of ${data.siteInformation.title}`,
          },
        })
        setMetaData(false)
      } else {
        handleDefaultValues()
        setMetaData(false)
      }
    } catch (error) {
      handleDefaultValues()
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (!!metaData) {
        await updateFishfarmScenario({
          body: {
            ...metaData,
            data: JSON.stringify(values),
          },
        })
      } else {
        await addFishfarmScenario({
          body: {
            data: JSON.stringify(values),
          },
        })
      }
      resetForm()
      push('/fishfarm-screening')
    } catch (error) {
      console.error(error)
    }
  }

  const hasValues = !!Object.values(initialValues).length

  if (!hasValues) return null

  const handleZoomIn = viewport => {
    const { zoom } = viewport
    setMapPosition({ ...viewport, zoom: zoom + 1 })
  }
  const handleZoomOut = viewport => {
    const { zoom } = viewport
    setMapPosition({ ...viewport, zoom: zoom - 1 })
  }

  return (
    <>
      <Box width={0.5} height={1}>
        <Mapbox
          mapPosition={mapPosition}
          mapInteractions={mapInteractions}
          mapSource={mapSource}
          mapMarkers={mapMarkers}
          {...mapboxConfig}
          minZoom={3}
          maxZoom={11}
        >
          {({ viewport }) => (
            <>
              <Box
                position="absolute"
                top={0}
                left={0}
                zIndex={9}
                height="inherit"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height="inherit"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="auto"
                    padding={0.5}
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      borderLeft: '0',
                    }}
                  >
                    <Tooltip title="Zoom in">
                      <IconButton
                        aria-label="Zoom in"
                        onClick={() => handleZoomIn(viewport)}
                      >
                        <ZoomInIcon fontSize="small" color="secondary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom out">
                      <IconButton
                        aria-label="Zoom in"
                        onClick={() => handleZoomOut(viewport)}
                      >
                        <ZoomOutIcon fontSize="small" color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Editor
                viewport={viewport}
                data={mapEditableData}
                setData={setMapEditableData}
                withPath={false}
                withPolygon={false}
                mapManagerLayers={mapManagerLayers}
                mapStepsLayers={mapStepsLayers}
                editable={mapIsEditable}
                hidden={mapIsHidingEditable}
              />

              <Box position="absolute" bottom={0} left={0} right={0} zIndex={5}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignitems="center"
                    height="auto"
                    width="auto"
                  >
                    <MapboxLayers
                      viewport={viewport}
                      layers={layers}
                      setMapManagerLayers={setMapManagerLayers}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Mapbox>
      </Box>
      <Drawer>
        <Wizard
          steps={steps}
          initialStep={view ? 4 : 0}
          form={{
            initialValues,
            onSubmit: handleSubmit,
            isInitialValid: !!id || !!copyId,
          }}
          shared={{ metaData }}
        >
          <Drawer.Container>
            <Drawer.Header>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6">Scenario</Typography>
                </Box>
                <Box>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="Close"
                    onClick={() => push(`/fishfarm-screening`)}
                  >
                    <CloseIcon />
                  </Fab>
                </Box>
              </Box>
              <WizardHeader boxProps={{ ...fullWidthProps }} />
            </Drawer.Header>
            <Drawer.Content>
              <WizardContent boxProps={{ ...fullWidthProps }} />
            </Drawer.Content>
            <Drawer.Footer>
              <WizardFooter
                boxProps={{ ...fullWidthProps }}
                labels={{
                  submitLabel: !!id ? 'Update' : 'Create',
                }}
              />
            </Drawer.Footer>
          </Drawer.Container>
        </Wizard>
      </Drawer>
    </>
  )
}

export default Form
