export default {
  type: 'FeatureCollection',
  properties: {},
  features: [
    {
      type: 'Feature',
      properties: {
        center: [48.38055763646332, -4.468828439712524],
        disabled: true,
        name: 'DES Q1 13.50',
        id: 'zone-q1',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.471019667160248, 48.38067256826528],
            [-4.47089554119662, 48.38068298158659],
            [-4.470835596090653, 48.380662343595446],
            [-4.470732617534065, 48.380641027941905],
            [-4.47062517082944, 48.38063491499869],
            [-4.470557973539999, 48.380638982004584],
            [-4.470492214286783, 48.380649049113664],
            [-4.470416881886662, 48.380668767511445],
            [-4.470347306092346, 48.3806963277134],
            [-4.470279862967685, 48.38073466994408],
            [-4.467427952357668, 48.38097436946199],
            [-4.466738230127057, 48.381031521779015],
            [-4.466629771942593, 48.38048898987937],
            [-4.468078766520979, 48.380371244409105],
            [-4.470972660975564, 48.380132424391824],
            [-4.471067922804068, 48.38063429083266],
            [-4.471058575202912, 48.38065780904257],
            [-4.471019667160248, 48.38067256826528],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.3825350290767, -4.465094804763794],
        disabled: true,
        name: 'DES Q2 11.50',
        id: 'zone-q2',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.467994079450365, 48.380323746783375],
            [-4.467210882654252, 48.37995148189198],
            [-4.466699460053634, 48.38042895450475],
            [-4.46661341409334, 48.380436126002735],
            [-4.466550369161173, 48.38047821864582],
            [-4.466549444353926, 48.38049611633855],
            [-4.466561690191339, 48.38055735533996],
            [-4.462951860848542, 48.38392596971953],
            [-4.463832176587624, 48.384344427817915],
            [-4.46733729576329, 48.38107197802665],
            [-4.467373220699626, 48.38103330659052],
            [-4.466748230411841, 48.38108508552915],
            [-4.46669213070118, 48.3810759146687],
            [-4.466672865269977, 48.38106342254567],
            [-4.466657901724572, 48.381038648308795],
            [-4.466564757393351, 48.38057270539415],
            [-4.466726241098335, 48.380426774464766],
            [-4.467994079450365, 48.380323746783375],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.38471897885, -4.462729096412659],
        disabled: true,
        name: 'DES Q3 9.00',
        id: 'zone-q3',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.463773338418252, 48.384399347460366],
            [-4.462893023502717, 48.38398089789889],
            [-4.462274296913865, 48.38410736200592],
            [-4.462418821367418, 48.384421094379604],
            [-4.461712298782136, 48.38507694807985],
            [-4.462268308716125, 48.385339705462115],
            [-4.462405591769994, 48.385211541081716],
            [-4.462735442398794, 48.38536830148915],
            [-4.463773338418252, 48.384399347460366],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.38190084666002, -4.448357820510864],
        disabled: true,
        name: 'DES Q4 12.00',
        id: 'zone-q4',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.450487308311179, 48.38058070827896],
            [-4.449881786625134, 48.38029403839067],
            [-4.449023863046763, 48.38039462038646],
            [-4.446328918256341, 48.38292053017751],
            [-4.447433143441684, 48.38344329562407],
            [-4.450487308311179, 48.38058070827896],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.37994124432318, -4.465534687042236],
        disabled: false,
        name: 'DES L1 north -9.40',
        id: 'zone-l1-north',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.460700227635641, 48.380689662045256],
            [-4.468062230273574, 48.377499460902186],
            [-4.472166671642753, 48.379333329436406],
            [-4.471910014403193, 48.380597898436804],
            [-4.471230352157647, 48.38065515542871],
            [-4.471233681038939, 48.380641669387046],
            [-4.471232741613676, 48.38062041010511],
            [-4.471137457137274, 48.38011849090236],
            [-4.471070731513691, 48.38004310980722],
            [-4.470956053238353, 48.380022317148075],
            [-4.468016590699904, 48.380264811956636],
            [-4.467260299892246, 48.37990534272359],
            [-4.467196163771405, 48.379895649720766],
            [-4.467141620311054, 48.37991855297355],
            [-4.466651954423412, 48.380375708899294],
            [-4.466609656940648, 48.38037914939696],
            [-4.466485442272301, 48.38043419800391],
            [-4.466465111947343, 48.38050360420431],
            [-4.466472909125685, 48.38054264896499],
            [-4.46288261065871, 48.383893046518324],
            [-4.462868298269768, 48.383937572098205],
            [-4.462888258433622, 48.38397058024623],
            [-4.462269545400773, 48.38409704348044],
            [-4.460700227635641, 48.380689662045256],
          ],
        ],
      },
    },

    {
      type: 'Feature',
      properties: {
        center: [48.37828799386725, -4.46319580078125],
        disabled: false,
        name: 'DES L1 mid -9.40',
        id: 'zone-l1-mid',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.459372027284587, 48.377805806831816],
            [-4.464805346920571, 48.37545537680819],
            [-4.465121781125276, 48.37618566526368],
            [-4.468062230273574, 48.377499460902186],
            [-4.460700227635641, 48.380689662045256],
            [-4.459372027284587, 48.377805806831816],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.3746392511429, -4.461500644683838],
        disabled: false,
        name: 'DES L1 south -9.40',
        id: 'zone-l1-south',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.464805346920571, 48.37545537680819],
            [-4.459372027284587, 48.377805806831816],
            [-4.457644769923718, 48.374055499025076],
            [-4.465351270008114, 48.37147168872497],
            [-4.464555908259285, 48.374879705240176],
            [-4.464805346920571, 48.37545537680819],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.378038576422, -4.455184042453766],
        disabled: false,
        name: 'DES L2A 8.00',
        id: 'zone-l2a',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.455993008009865, 48.37771567700661],
            [-4.455886709641752, 48.37749027680051],
            [-4.456000361478626, 48.37724926427339],
            [-4.455916637819721, 48.37712664566203],
            [-4.455826179502825, 48.37712535821949],
            [-4.455401445260361, 48.377416324726646],
            [-4.454954366864131, 48.377793484109034],
            [-4.45459287964982, 48.37823716772101],
            [-4.454366336151183, 48.378541798475275],
            [-4.454352941616577, 48.378659726679594],
            [-4.454797435581047, 48.37870894564314],
            [-4.455586210239143, 48.378068816425795],
            [-4.455993008009865, 48.37771567700661],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.37933731530548, -4.452268481254578],
        disabled: false,
        name: 'DES L2B 8.00',
        id: 'zone-l2b',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.452343130193574, 48.37881989257347],
            [-4.451506647895488, 48.37957151148356],
            [-4.451655615039116, 48.37968438877164],
            [-4.4516626079758, 48.37981418848791],
            [-4.451871312007544, 48.37985044298273],
            [-4.452119917678696, 48.37977340597535],
            [-4.45221945348677, 48.37964569981544],
            [-4.452313203831458, 48.37951136374198],
            [-4.452524278024979, 48.379415184429476],
            [-4.45294955755604, 48.37937928301163],
            [-4.453188372207869, 48.37936787297953],
            [-4.453298805420867, 48.37932756183438],
            [-4.453192250684761, 48.3792252507901],
            [-4.452835571326974, 48.37901293712714],
            [-4.452775716913505, 48.37891372639495],
            [-4.452474249485808, 48.37886186968411],
            [-4.452343130193574, 48.37881989257347],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.380183526517094, -4.450471401214599],
        disabled: true,
        name: 'DES L2C 8.00',
        id: 'zone-l2c',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.451131546187134, 48.37975150107076],
            [-4.450898105110272, 48.379839418252196],
            [-4.450777644174774, 48.37987867829123],
            [-4.450530172748015, 48.37994748045461],
            [-4.450403567645698, 48.379976915679094],
            [-4.448995766054233, 48.38028830066688],
            [-4.449853686679972, 48.380187709909684],
            [-4.449908538703311, 48.380187559700886],
            [-4.4499756685838, 48.38020603779731],
            [-4.450713292875009, 48.38055524668564],
            [-4.451131546187134, 48.37975150107076],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        center: [48.38219121767976, -4.450626969337463],
        disabled: true,
        name: 'DES L2 Polluted 8.00',
        id: 'zone-l2p',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.451791162750391, 48.381761353879156],
            [-4.45085437033786, 48.38131622763481],
            [-4.44971996363587, 48.382390603390796],
            [-4.449900370648829, 48.383031706209294],
            [-4.45025193676404, 48.383198760098786],
            [-4.451791162750391, 48.381761353879156],
          ],
        ],
      },
    },
  ],
}
