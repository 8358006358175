import React from 'react'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CoreAccordion from '@material-ui/core/Accordion'
import CoreAccordionSummary from '@material-ui/core/AccordionSummary'
import CoreAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import slugify from 'slugify'
import cx from 'classnames'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const Accordion = ({
  headline,
  subline,
  children,
  classes,
  expanded,
  onChange,
  noPadding = false,
  option
}) => {
  if (!headline || !children) return null
  const identifier = slugify(headline, {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true
  })
  return (
    <>
      <CoreAccordion
        expanded={expanded}
        onChange={onChange}
        square
        elevation={0}
        className={classes.expansionPanel}
      >
        <CoreAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${identifier}-content`}
          id={`${identifier}-header`}
          className={classes.expansionPanelSummary}
        >
          <Box width={1} display="flex" alignItems="center">
            <Typography className={classes.heading}>{headline}</Typography>
            {subline && (
              <Typography className={classes.secondaryHeading}>
                {subline}
              </Typography>
            )}
            {option && option}
          </Box>
        </CoreAccordionSummary>
        <CoreAccordionDetails
          className={cx({
            [classes.noPadding]: noPadding
          })}
        >
          {children}
        </CoreAccordionDetails>
      </CoreAccordion>
    </>
  )
}

export default enhancer(Accordion)
