import React from 'react'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import CoreStatus from 'components/Status'

const Status = ({ lastJobStatus, lastJobProgress = 20 }) => {
  const isPending = lastJobStatus === 'Pending'
  const isCompleted = lastJobStatus === 'Completed'
  const isInProgress = lastJobStatus === 'InProgress'
  const isError = lastJobStatus === 'Error'

  if (isPending) {
    return <CoreStatus warning label={lastJobStatus} />
  } else if (isInProgress) {
    return (
      <Box width={1} height={1} display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={lastJobProgress} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {lastJobProgress}%
          </Typography>
        </Box>
      </Box>
    )
  } else if (isCompleted) {
    return <CoreStatus success label={lastJobStatus} />
  } else if (isError) {
    return <CoreStatus danger label={lastJobStatus} />
  } else {
    return <CoreStatus label={lastJobStatus} />
  }
}

export default Status
