export const BRAIN = 'https://me-prod-brain.westeurope.cloudapp.azure.com/api'

export const sedimentSpillBaseUrl =
  'https://me-prod-brain.westeurope.cloudapp.azure.com/sedimentspill/api'

export const IDS = {
  ACCOUNTS: 'Accounts',
  WORKER: 'Worker',
  MAP: 'Map',
  PROJECTS: 'Projects',
  SCENARIOS: 'Scenarios',
  SHP_FILE_DOWNLOAD: 'dfsucontouring-featurecollection',
}

export const TOOLS = {
  BRAIN: 'brain',
  SEDIMENT_SPILL: 'sediment-spill',
  MARINE_SCREENING: 'marine-screening',
  HABITATS: 'habitats',
  MARINE_MONITORING: 'marine-monitoring',
  FISHFARM_SCREENING: 'fishfarm-screening',
  DILUTION: 'dilution',
  MARINE_EXPLORER: 'marine-explorer',
}

export const HOSTS = {
  BRAIN,
  SEDIMENT_SPILL: BRAIN,
  MARINE_SCREENING: '51.144.61.36/dataviewer',
  FISHFARM_SCREENING:
    'https://me-prod-brain.westeurope.cloudapp.azure.com/fishfarm/api',
  DILUTION: 'https://me-prod-brain.westeurope.cloudapp.azure.com/dilution/api',
  DILUTION_ROOT: 'https://me-prod-brain.westeurope.cloudapp.azure.com/dilution',
  MARINE_EXPLORER: 'https://me-prod-brain.westeurope.cloudapp.azure.com/marineexplorer/api',
  MARINE_EXPLORER_ROOT: 'https://me-prod-brain.westeurope.cloudapp.azure.com/marineexplorer',
}
