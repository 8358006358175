import React, { useEffect } from 'react';
import { compose } from 'recompose';
import L from 'leaflet';
import classNames from 'classnames';
import { Map as LeafletMap, ScaleControl } from 'react-leaflet';
import { CoordinatesControl } from 'react-leaflet-coordinates';
import { withStyles } from '@material-ui/core';

import Layers from './partials/Layers';

import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';

import styles from './styles';

const enhancer = compose(withStyles(styles, { withTheme: true }));

// in order to show marker in map, since the default couldnt be fetched to show
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

const CoreMap = ({
  animationLoading,
  config,
  children,
  classes,
  componentRef,
  isFullWidth,
}) => {
  const { zoom, center } = config;

  //handlerResize triggers event, so map rerenders when drawer is open/closed
  const handlerResize = () => window.dispatchEvent(new Event('resize'));

  useEffect(() => {
    handlerResize();
  }, [isFullWidth]);

  return (
    <LeafletMap
      className={classNames(classes.coreMap, {
        [classes.fillScreen]: isFullWidth,
      })}
      ref={componentRef}
      zoom={zoom}
      center={center}
      preferCanvas
      boxZoom={!animationLoading}
      doubleClickZoom={!animationLoading}
      dragging={!animationLoading}
      keyboard={!animationLoading}
      scrollWheelZoom={!animationLoading}
    >
      <CoordinatesControl position="bottomleft" />
      <ScaleControl position="bottomleft" />
      <Layers />
      {children}
    </LeafletMap>
  );
};

export default enhancer(CoreMap);
