import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import nth from 'lodash/nth'
import get from 'lodash/get'
import { useRecoilValue } from 'recoil'

import { getDischargeList, getJetTable } from 'data/dilution'
import useUser from 'hooks/useUser'
import modelsState from 'containers/Dilution/states/models'
import userDefinedLegendsState from 'containers/Dilution/states/userDefinedLegends'

const useCollection = ({ scenario, reportImages }) => {
  const models = useRecoilValue(modelsState)
  const customLegends = useRecoilValue(userDefinedLegendsState)
  const modelId = get(scenario, 'data.definition.model.id')
  const scenarioTypeId = get(scenario, 'data.definition.type.id')
  const runHd = get(scenario, 'data.definition.runHd')
  const model = models.find((item) => item.id === modelId)
  const modelType = get(model, 'modelType')
  const { label: scenarioTypeLabel } = model.scenarioTypes.find(
    (item) => item.id === scenarioTypeId
  )
  const isFullModel = modelType === 'Full'
  const isPointModel = modelType === 'Point'

  const { t } = useTranslation()
  const [dischargeList, setDischargeList] = useState({
    hypotheticalDischarges: [],
    existingDischarges: [],
  })

  const [jetTableList, setJetTableList] = useState({
    jetParameters: {},
    jetConcentrations: {
      rows: [],
      columns: { fractileFractions: [0, 0] },
    },
  })

  const { jetConcentrations, jetParameters } = jetTableList
  const { columns } = jetConcentrations
  const { fractileFractions } = columns

  const { user } = useUser()

  const getter = (key, defaultValue) =>
    get(scenario, `data.${key}`, defaultValue)

  const hypotheticalOutlet = getter('outlet.hypotheticalOutlet', []).filter(
    ({ include }) => !!include
  )
  const existingOutlet = getter('outlet.existingOutlet', []).filter(
    ({ include }) => !!include
  )

  console.log(existingOutlet)

  const damping = getter('damping', {})

  const isJetEnabled = hypotheticalOutlet.some((item) => item.jet.enabled)

  const fullModelLabel = runHd
    ? t('pdf:fullModel', { suffix: t('pdf:includingHd') })
    : t('pdf:fullModel')
  const pointModelLabel = isJetEnabled
    ? t('pdf:pointModel', { suffix: t('pdf:includingJet') })
    : t('pdf:pointModel')

  const modelLabel = isFullModel
    ? fullModelLabel
    : isPointModel
    ? pointModelLabel
    : ''

  const handleDischargeList = async () => {
    try {
      const list = await getDischargeList({ id: scenario.id })
      setDischargeList(list)
    } catch (error) {
      console.error(error)
    }
  }

  const handleJetTable = async () => {
    try {
      const list = await getJetTable({ id: scenario.id })
      setJetTableList(list)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (scenario.id) {
      handleDischargeList()

      if (isJetEnabled) {
        handleJetTable()
      }
    }
  }, [scenario])

  const getOutletConcentrations = (list) =>
    list.reduce((allConcentrations, outlet) => {
      const { source, concentrations } = outlet
      const { label } = source
      const concentrationsToSet = concentrations
        .filter(({ include }) => !!include)
        .map((concentration, index) => ({
          ...concentration,
          label: index === 0 ? label : '',
        }))

      allConcentrations.push(...concentrationsToSet)

      return allConcentrations
    }, [])

  return {
    title: t('pdf:envirocastTitle'),
    blocks: [
      {
        type: 'text',
        locked: true,
        content: isFullModel
          ? t('pdf:reportGeneratedFull')
          : t('pdf:reportGeneratedPoint'),
        elementStyle: {
          color: 'grey',
          fontSize: 10,
        },
      },
      {
        type: 'text',
        locked: true,
        content: t('pdf:scenarioReport'),
        elementStyle: {
          fontSize: 24,
        },
      },
      {
        type: 'text',
        locked: 'true',
        content: `${t('common:user')}: ${user.name}, ${user.company}`,
        elementStyle: {
          width: '50%',
        },
      },
      {
        type: 'text',
        locked: 'true',
        content: t('pdf:scenarioDefinition'),
        elementStyle: {
          fontSize: 18,
        },
      },
      {
        type: 'table',
        headline: t('pdf:table', {
          number: '1:',
          suffix: t('pdf:scenarioSummary'),
        }),
        columns: [
          {
            id: 'label',
            field: 'label',
            elementStyle: {
              width: '50%',
              padding: 12,
            },
          },
          {
            id: 'value',
            field: 'value',
            elementStyle: {
              width: '50%',
              padding: 12,
            },
          },
        ],
        rows: [
          {
            id: 'label',
            label: t('pdf:scenarioName'),
            value: getter('definition.title', ''),
          },
          {
            id: 'scenario description',
            label: t('pdf:scenarioDescription'),
            value: getter('definition.description', ''),
          },
          {
            id: 'geographical domain',
            label: t('pdf:geographicalDomain'),
            value: get(model, 'label'),
          },
          {
            id: 'scenario type',
            label: t('common:scenarioType'),
            value: scenarioTypeLabel,
          },
          {
            id: 'model type',
            label: t('pdf:modelType'),
            value: modelLabel,
          },
          {
            id: 'scenario period',
            label: t('common:scenarioPeriod'),
            value: `${getter('definition.startDate', '')} - ${getter(
              'definition.endDate',
              ''
            )}`,
          },
          {
            id: 'hypohetical outlets',
            label: t('pdf:hypotheticalOutlets'),
            value: hypotheticalOutlet.length,
          },
          {
            id: 'existing outlets',
            label: t('pdf:existing', { suffix: t('pdf:outlets') }),
            value: existingOutlet.length,
          },
        ],
      },
      {
        type: 'table',
        pageBreak: true,
        headline: t('pdf:table', {
          number: '2:',
          suffix: t('pdf:scenarioComponents'),
        }),
        columns: [
          {
            id: 'componentDescription',
            heading: t('pdf:componentName'),
            field: 'componentDescription',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'unit',
            heading: t('common:unit'),
            field: 'unit',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'backgroundConcentration',
            heading: t('pdf:backgroundConcentration'),
            field: 'backgroundConcentration',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'decayRate',
            heading: t('common:decayRate', {
              suffix: t('common:day-1'),
            }),
            field: 'decayRate',
            elementStyle: {
              width: '25%',
            },
          },
        ],
        rows: getter('components', []).filter(({ include }) => !!include),
      },
      {
        type: 'table',
        headline: t('pdf:table', {
          number: '3:',
          suffix: t('pdf:outletSummary'),
        }),
        subHeader: t('pdf:varying'),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'source.label',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'latitude',
            heading: t('common:latitude', { suffix: '(°N)' }),
            field: 'source.latitude',
            isNumber: true,
            decimals: 6,
            elementStyle: {
              width: '12.5%',
            },
          },
          {
            id: 'longitude',
            heading: t('common:longitude', { suffix: '(°E)' }),
            field: 'source.longitude',
            isNumber: true,
            decimals: 6,
            elementStyle: {
              width: '12.5%',
            },
          },
          {
            id: 'depth',
            heading: t('common:depth', { suffix: '(m)' }),
            field: 'source.depth',
            elementStyle: {
              width: '10%',
            },
          },
          {
            id: 'discharge',
            heading: t('pdf:discharge', { suffix: '(m3/s)' }),
            field: 'discharge',
            elementStyle: {
              width: '15%',
            },
          },
          {
            id: 'temperature',
            heading: t('common:temperature', { suffix: '(°C)' }),
            field: 'density.temperature',
            elementStyle: {
              width: '10%',
            },
          },
          {
            id: 'salinity',
            heading: t('common:salinity', { suffix: '(PSU)' }),
            field: 'density.salinity',
            elementStyle: {
              width: '15%',
            },
          },
        ],
        rows: hypotheticalOutlet.map((item, index) => ({
          ...item,
          discharge: nth(dischargeList.hypotheticalDischarges, index),
        })),
      },
      {
        type: 'table',
        headline: t('pdf:table', {
          number: '4:',
          suffix: t('pdf:hypotheticalOutletConcentrations'),
        }),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'label',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'component description',
            heading: t('pdf:componentDescription'),
            field: 'componentDescription',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'unit',
            heading: t('common:unit'),
            field: 'unit', // TODO
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'outlet concentration',
            heading: t('pdf:outletConcentration'),
            field: 'concentrationValue', // TODO
            elementStyle: {
              width: '25%',
            },
          },
        ],
        rows: getOutletConcentrations(hypotheticalOutlet),
      },
      existingOutlet && {
        type: 'table',
        headline: t('pdf:table', {
          number: '5:',
          suffix: t('pdf:existingOutletSummary'),
        }),
        subHeader: t('pdf:varying'),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'source.label',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'latitude',
            heading: t('common:latitude', { suffix: '(°N)' }),
            field: 'source.latitude',
            isNumber: true,
            decimals: 6,
            elementStyle: {
              width: '12.5%',
            },
          },
          {
            id: 'longitude',
            heading: t('common:longitude', { suffix: '(°E)' }),
            field: 'source.longitude',
            isNumber: true,
            decimals: 6,
            elementStyle: {
              width: '12.5%',
            },
          },
          {
            id: 'depth',
            heading: t('common:depth', { suffix: '(m)' }),
            field: 'source.depth',
            elementStyle: {
              width: '10%',
            },
          },
          {
            id: 'discharge',
            heading: t('pdf:discharge', { suffix: '(m3/s)' }),
            field: 'discharge',
            elementStyle: {
              width: '15%',
            },
          },
          {
            id: 'temperature',
            heading: t('common:temperature', { suffix: '(°C)' }),
            field: 'density.temperature',
            elementStyle: {
              width: '10%',
            },
          },
          {
            id: 'salinity',
            heading: t('common:salinity', { suffix: '(PSU)' }),
            field: 'density.salinity',
            elementStyle: {
              width: '15%',
            },
          },
        ],
        rows: existingOutlet.map((item, index) => ({
          ...item,
          discharge: nth(dischargeList.existingDischarges, index),
        })),
      },
      {
        type: 'table',
        headline: t('pdf:table', {
          number: '6:',
          suffix: t('pdf:existing', { suffix: t('pdf:outletConcentrations') }),
        }),
        subHeader: t('pdf:varying'),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'label',
            elementStyle: {
              width: '40%',
            },
          },
          {
            id: 'component description',
            heading: t('pdf:componentDescription'),
            field: 'componentDescription',
            elementStyle: {
              width: '30%',
            },
          },
          {
            id: 'unit',
            heading: t('common:unit'),
            field: 'unit',
            elementStyle: {
              width: '15%',
            },
          },
          {
            id: 'outlet concentration',
            heading: t('pdf:outletConcentration'),
            field: 'concentrationValue',
            elementStyle: {
              width: '15%',
            },
          },
        ],
        rows: getOutletConcentrations(existingOutlet),
      },
      existingOutlet.length > 0 && {
        type: 'table',
        headline: t('pdf:table', {
          number: '7:',
          suffix: t('pdf:distanceDamping'),
        }),
        columns: [
          {
            id: 'enabled',
            heading: t('common:enabled'),
            field: 'damping.enabled',
            isBoolean: true,
            elementStyle: {
              width: '36%',
            },
          },
          {
            id: 'latitude',
            heading: t('common:latitude', { suffix: '(°N)' }),
            value: damping.enabled ? damping.latitude : '',
            elementStyle: {
              width: '17%',
            },
          },
          {
            id: 'longitude',
            heading: t('common:longitude', { suffix: '(°E)' }),
            value: damping.enabled ? damping.longitude : '',
            elementStyle: {
              width: '17%',
            },
          },
          {
            id: 'inner radius',
            heading: t('dilution:innerRadius', { suffix: '(m)' }),
            value: damping.enabled ? damping.innerRadius : '',
            elementStyle: {
              width: '15%',
            },
          },
          {
            id: 'outer radius',
            heading: t('dilution:outerRadius', { suffix: '(m)' }),
            value: damping.enabled ? damping.outerRadius : '',
            elementStyle: {
              width: '15%',
            },
          },
        ],
        rows: [
          {
            damping: getter('damping', {}),
          },
        ],
      },
      isJetEnabled && {
        type: 'text',
        locked: true,
        pageBreak: true,
        content: t('common:jetModel'),
        elementStyle: {
          fontSize: 24,
        },
      },
      isJetEnabled && {
        type: 'table',
        headline: t('pdf:table', {
          number: existingOutlet.length > 0 ? '8:' : '5:',
          suffix: t('pdf:jetSpecifications'),
        }),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'source.label',
            elementStyle: {
              width: '21%',
            },
          },
          {
            id: 'pipeDiameter',
            heading: t('common:pipeDiameter', { suffix: '(m)' }),
            field: 'jet.pipeDiameter',
            elementStyle: {
              width: '19.75%',
            },
          },
          {
            id: 'horizontal',
            heading: t('common:angle', {
              prefix: t('common:horizontal'),
              suffix: '(°)',
            }),
            field: 'jet.angleHorizontal',
            elementStyle: {
              width: '19.75%',
            },
          },
          {
            id: 'vertical',
            heading: t('common:angle', {
              prefix: t('common:vertical'),
              suffix: '(°)',
            }),
            field: 'jet.angleVertical',
            elementStyle: {
              width: '19.75%',
            },
          },
          {
            id: 'discharge',
            heading: t('dilution:numberOfDiffuserPorts'),
            field: 'jet.numberOfDiffuserPorts',
            elementStyle: {
              width: '19.75%',
            },
          },
        ],
        rows: hypotheticalOutlet,
      },
      isJetEnabled && {
        type: 'table',
        headline: t('pdf:table', {
          number: existingOutlet.length > 0 ? '9:' : '6:',
          suffix: t('pdf:jetParameters'),
        }),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'outletName',
            elementStyle: {
              width: '20%',
            },
          },
          {
            id: 'timeValue',
            heading: t('pdf:timeValue', { suffix: '(s)' }),
            field: 'timeValue',
            isNumber: true,
            decimals: 2,
            elementStyle: {
              width: '16%',
            },
          },
          {
            id: 'distanceValue',
            heading: t('pdf:distanceValue', { suffix: '(m)' }),
            field: 'distanceValue',
            isNumber: true,
            decimals: 2,
            elementStyle: {
              width: '16%',
            },
          },
          {
            id: 'depthValue',
            heading: t('pdf:depthValue', { suffix: '(m)' }),
            field: 'depthValue',
            isNumber: true,
            decimals: 2,
            elementStyle: {
              width: '16%',
            },
          },
          {
            id: 'widthValue',
            heading: t('pdf:widthValue', { suffix: '(m)' }),
            field: 'widthValue',
            isNumber: true,
            decimals: 2,
            elementStyle: {
              width: '16%',
            },
          },
          {
            id: 'dilutionFactor',
            heading: t('pdf:dilutionFactor', { suffix: '(-)' }),
            field: 'dilutionFactorValue',
            isNumber: true,
            decimals: 2,
            elementStyle: {
              width: '16%',
            },
          },
        ],
        rows: jetParameters.rows,
      },
      isJetEnabled && {
        type: 'table',
        headline: t('pdf:table', {
          number: existingOutlet.length > 0 ? '10:' : '7:',
          suffix: t('pdf:jetConcentrations'),
        }),
        columns: [
          {
            id: 'label',
            heading: t('pdf:outletName'),
            field: 'outletName',
            elementStyle: {
              width: '20%',
            },
          },
          {
            id: 'componentDescription',
            heading: t('pdf:componentDescription'),
            field: 'componentDescription',
            elementStyle: {
              width: '25%',
            },
          },
          {
            id: 'units',
            heading: t('common:unit'),
            field: 'unit',
            elementStyle: {
              width: '15%',
            },
          },
          ...fractileFractions.map((item, index) => ({
            id: item,
            heading: t('common:fractile', { prefix: `${item}` }),
            field: `fractiles[${index}]`,
            isNumber: true,
            decimals: 6,
            elementStyle: {
              width: '20%',
            },
          })),
        ],
        rows: jetConcentrations.rows,
      },
      {
        type: 'text',
        locked: true,
        pageBreak: true,
        content: t('common:scenarioResults'),
        elementStyle: {
          fontSize: 24,
        },
      },
      ...reportImages.map(
        ({
          basemap,
          report,
          properties: { id, label, timestep, legend: itemLegends },
        }) => {
          const { legend = [] } = customLegends[id] || { legend: itemLegends }
          return {
            basemap,
            report,
            type: 'image',
            label,
            timestep,
            legend,
          }
        }
      ),
    ],
  }
}

export default useCollection
