import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LayersIcon from '@material-ui/icons/Layers'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import isNil from 'lodash/isNil'

import Legends from './partials/Legends'

import { getImage, getLegends } from 'data/fishfarm'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MapboxLayers = ({ viewport: { zoom }, layers, setMapManagerLayers }) => {
  const [lastZoom, setLastZoom] = useState(zoom)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('none')
  const [activeLayer, setActiveLayer] = useState(null)
  const [legends, setLegends] = useState(null)

  useEffect(() => {
    const newActiveLayer = layers.find(layer => layer.name === value)
    setActiveLayer({ ...newActiveLayer, id: Date.now().toString() })
  }, [value])

  useEffect(() => {
    const isValid = !isNil(activeLayer)
    if (isValid) {
      const { style } = activeLayer || {}
      if (style) {
        handleLegends(style)
      } else {
        setLegends(null)
      }
    }
  }, [activeLayer])

  useEffect(() => {
    const zoomDifference = Math.abs(lastZoom - zoom)
    const isValidDifference = zoomDifference >= 2
    const isValidLayer = !isNil(activeLayer)
    const isValid = isValidDifference && isValidLayer
    if (isValid) {
      handleLayerChange(activeLayer)
    }
  }, [zoom])

  const handleLegends = async style => {
    try {
      const { label: headline, unit } = activeLayer
      const { thresholds } = await getLegends({ style })
      setLegends({ headline, unit, thresholds })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    handleLayerChange(activeLayer)
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleLayerChange = ({ type: Layer, ...rest }) => {
    const getSize = () => {
      const factor = zoom * 100
      const size = Math.round(factor * zoom)
      return size.toString()
    }
    const image = getImage({
      ...rest,
      id: Date.now().toString() + zoom.toString,
      width: getSize(),
      height: getSize(),
    })
    setMapManagerLayers(Layer ? [new Layer({ ...rest, image })] : [])
    setLastZoom(zoom)
  }

  const hasLegends = !isNil(legends)

  return (
    <>
      <Box
        padding={0.5}
        style={{
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '0',
        }}
      >
        <Tooltip title="Select layer">
          <IconButton aria-label="Select layer" onClick={handleClickOpen}>
            <LayersIcon
              fontSize="small"
              color={open ? 'primary' : 'secondary'}
            />
          </IconButton>
        </Tooltip>
      </Box>
      {hasLegends && (
        <Box position="absolute" right={10} bottom={30}>
          <Box
            padding={0.5}
            style={{
              backgroundColor: 'white',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Legends {...legends} />
          </Box>
        </Box>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: 0 }}
      >
        <DialogTitle style={{ padding: 0 }} id="alert-dialog-slide-title">
          <Box display="flex" padding={4}>
            Select layer
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <Box display="flex" width={500} py={2} px={4}>
            <RadioGroup
              aria-label="layer"
              name="layer"
              value={value}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {layers.map(({ name, label }) => (
                <FormControlLabel
                  key={name}
                  value={name}
                  control={<Radio />}
                  label={label}
                />
              ))}
              <Box
                borderTop={1}
                marginTop={2}
                paddingTop={2}
                width={1}
                bordercolor="shades.medium"
              >
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                />
              </Box>
            </RadioGroup>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width={1}
            padding={4}
          >
            <Button variant="contained" onClick={handleClose} color="primary">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MapboxLayers
