export const brest = require('./brest.json')
export const buoys = require('./Bouees_Brest_B1_B7.json')

const Chenal_Acces_Commerce = require('./Chenal_Acces_Commerce.json')
const Chenal_Acces_Polder = require('./Chenal_Acces_Polder.json')
const Souille_Multimodale = require('./Souille_Multimodale.json')
const Souille_Quai_6eme_Sud = require('./Souille_Quai_6eme_Sud.json')

export default [
  Chenal_Acces_Commerce,
  Chenal_Acces_Polder,
  Souille_Multimodale,
  Souille_Quai_6eme_Sud,
]
