import React from 'react'
import Box from '@material-ui/core/Box'
import RoomIcon from '@material-ui/icons/Room'
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { useRecoilState, useSetRecoilState } from 'recoil'
import cursorState from 'components/DeckGLMap/states/cursor'
import mapInteractionsState from 'containers/Dilution/states/mapInteractions'

const EditControl = () => {
  const [mapInteractions, setMapinteractions] = useRecoilState(
    mapInteractionsState
  )
  const setCursor = useSetRecoilState(cursorState)

  const onAdd = () => {
    setMapinteractions({ ...mapInteractions, edit: !mapInteractions.edit })
    if (!mapInteractions.edit) {
      setCursor('pointer')
    } else {
      setCursor('grab')
    }
  }

  return (
    <>
      {mapInteractions.show && (
        <Box position="absolute" top={0} right={0} zIndex={9} height="inherit">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="inherit"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="auto"
              padding={0.5}
              style={{
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRight: '0',
              }}
            >
              <Tooltip title="Add Point">
                <IconButton aria-label="Point" onClick={() => onAdd()}>
                  <RoomIcon
                    fontSize="small"
                    color={mapInteractions.edit ? 'secondary' : 'primary'}
                  />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Select from map">
                                <IconButton aria-label="Point" onClick={() => setMapinteractions({ ...mapInteractions, select: !mapInteractions.select, edit: mapInteractions.select })}>
                                    <CheckCircleOutlineIcon
                                        fontSize="small"
                                        color={mapInteractions.select ? 'secondary' : 'primary'}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove selected">
                                <IconButton aria-label="Point" onClick={() => setMapinteractions({ ...mapInteractions, select: !mapInteractions.remove, edit: mapInteractions.remove })}>
                                    <DeleteIcon
                                        fontSize="small"
                                        color={mapInteractions.select ? 'secondary' : 'primary'}
                                    />
                                </IconButton>
                            </Tooltip> */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default EditControl
