import { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
// import { EditableGeoJsonLayer, DrawPointMode, ModifyMode } from 'nebula.gl'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { DrawPointMode, ModifyMode } from '@nebula.gl/edit-modes'
import mapEditableDataState from 'containers/Dilution/states/mapEditableData'
import mapInteractionsState from 'containers/Dilution/states/mapInteractions'

const useEditLayers = () => {
  const [editLayer, setEditLayer] = useRecoilState(mapEditableDataState)
  const interaction = useRecoilValue(mapInteractionsState)
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([])

  const onClick = ({ picked, isGuide, layer, index }) => {
    if (interaction.select) {
      if (selectedFeatureIndexes.indexOf(index) === -1) {
        setSelectedFeatureIndexes(
          selectedFeatureIndexes.map((x) => x).concat([index])
        )
      } else {
        setSelectedFeatureIndexes(
          selectedFeatureIndexes.filter((x) => x !== index).map((x) => x)
        )
      }
    }
  }

  const onEdit = ({ updatedData }) => {
    if (interaction.edit) {
      setEditLayer({
        ...updatedData,
        features: updatedData.features.map((x, i) => {
          if (x.name) {
            return x
          } else {
            x.properties.include = true
            x.name = `New outlet ${i + 1}`
            return x
          }
        }),
      })
    } else if (interaction.select) {
      setEditLayer(updatedData)
    }
  }

  const editableLayer = new EditableGeoJsonLayer({
    id: 'editable-layer',
    data: editLayer,
    mode: interaction.select ? ModifyMode : DrawPointMode,
    pickable: true,
    selectedFeatureIndexes,
    stroked: false,
    highlightColor: [255, 255, 255, 100],
    filled: true,
    pointRadiusMinPixels: 5,
    pointRadiusMaxPixels: 5,
    getFillColor: (d) =>
      d.properties.include ? [128, 0, 128] : [128, 128, 128],
    onEdit,
    onClick,
  })

  const layers = useMemo(() => editableLayer, [
    editLayer,
    interaction,
    selectedFeatureIndexes,
  ])

  return layers
}

export default useEditLayers
