import React from 'react'
import { deleteDilutionProject } from 'data/dilution'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { useConfirm } from 'material-ui-confirm'

const Actions = ({ id, handleGetProjects, handleEditProject }) => {
  const confirm = useConfirm()
  const handleDelete = async () => {
    try {
      await confirm({
        title: 'Delete',
        description: 'Yes, I want to delete the project.',
      })
      await deleteDilutionProject({ id })
      await handleGetProjects()
    } catch (error) {
      console.error(error)
    }
  }
  const handleEdit = () => handleEditProject(id)
  return (
    <>
      <Tooltip title="Edit">
        <IconButton
          size="small"
          color="primary"
          aria-label="edit"
          onClick={handleEdit}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          size="small"
          color="primary"
          aria-label="delete"
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default Actions
