import React, { useContext } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { SedimentSpillContext } from 'containers/SedimentSpill';

const Tools = () => {
  const { actions } = useContext(SedimentSpillContext);
  return (
    <FeatureGroup>
      <EditControl
        position="topleft"
        onCreated={actions.onCreated}
        onDeleted={actions.onDeleted}
        onMounted={actions.onMounted}
        draw={{
          circle: false,
          circlemarker: false,
          polygon: false,
          rectangle: false,
        }}
      />
    </FeatureGroup>
  );
};

export default Tools;
