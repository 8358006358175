export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '.5rem 0rem',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {},
  legend: {
    display: 'flex',
    marginLeft: '35px',
    alignItems: 'center',
  },
  legendItemIcon: {
    width: '30px',
    height: '23px',
    marginRight: '10px',
  },
});
