import { useEffect, useState } from 'react'
import useInterval from 'hooks/useInterval'

import { getScenariosByProject } from 'data/common'

const useScenarios = ({ projectId, host }) => {
  const [scenarios, setScenarios] = useState([])
  const getScenarios = async () => {
    try {
      const scenariosToSet = await getScenariosByProject({ projectId, host })
      setScenarios(scenariosToSet)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (projectId) getScenarios()
  }, [])

  useInterval(
    () => {
      if (projectId) getScenarios()
    },
    10000,
    []
  )

  return {
    scenarios,
    getScenarios,
  }
}

export default useScenarios
