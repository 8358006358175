import React from 'react';
import { compose } from 'recompose';
import { Typography, TextField, withStyles } from '@material-ui/core';
import { Field } from 'formik';

import withHabitatsState from 'hocs/withHabitatsState';

import Block from 'components/Block';

import Layers from './partials/Layers';

import styles from './styles';

const enhancer = compose(
  withHabitatsState,
  withStyles(styles)
);

const Step = ({ shared }) => {
  const { formik } = shared;
  const { errors, values, handleChange } = formik;
  const hasFile = values.dfsu.file && values.dfsu.file.length > 0;
  const isUnsupported =
    hasFile && errors.dfsu && errors.dfsu.file === 'Unsupported Format';
  return (
    <>
      <Block headline="Upload DFSU">
        <Field
          component={TextField}
          autoComplete="off"
          type="file"
          id="dfsu.file"
          name="dfsu.file"
          onChange={handleChange}
          value={values.dfsu.file}
          fullWidth
          margin="dense"
          variant="outlined"
        />
        {isUnsupported && (
          <Typography variant="caption">Unsupported file format.</Typography>
        )}
      </Block>
      {hasFile && !isUnsupported && (
        <Block headline="Layers">
          <Layers {...formik} />
        </Block>
      )}
    </>
  );
};

export default enhancer(Step);
