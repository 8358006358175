import React, { createRef, createContext, useState } from 'react'
import { compose } from 'recompose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'

import L from 'leaflet'

import Map from './partials/Map'
import Sidebar from './partials/Sidebar'

import styles from 'styles/global'

const enhancer = compose(withStyles(styles, { withTheme: true }))

export const HabitatsContext = createContext()

const Habitats = ({ classes }) => {
  const [coordinates, setCoordinates] = useState([])
  const [drawControl, setDrawControl] = useState({})
  const [habitatsActiveStep, setHabitatsActiveStep] = useState(0)
  const [mapView, setMapView] = useState({ center: [55.8, 12.1], zoom: 10 })
  const [position, setPosition] = useState({ lat: '', lng: '', exists: false })
  const [shapeData, setShapeData] = useState(null)
  const [timestep, setTimestep] = useState(null)

  const mapRef = createRef()

  const onCreated = event => {
    const { layer, layerType } = event

    const handleCirlemarker = () => {
      const { lat, lng } = layer._latlng
      const position = { lat, lng }
      setPosition({ ...position, exists: true })
      setCoordinates([position])
    }

    const handlePolygon = () => {
      const { lat: centerLat, lng: centerLng } = layer.getBounds().getCenter()
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const position = {
        lat: centerLat,
        lng: centerLng,
      }
      setPosition({
        ...position,
        exists: true,
        area,
      })
      setCoordinates(layer._latlngs[0])
    }

    const actions = {
      circlemarker: handleCirlemarker,
      polygon: handlePolygon,
    }

    return actions[layerType]
  }

  const onDeleted = () => setPosition({ lat: '', lng: '', exists: false })

  const onEdited = event => {
    const { layers } = event
    layers.eachLayer(layer => {
      const isCircleMarker = layer instanceof L.CircleMarker
      const isPolygon = layer instanceof L.Polygon
      if (isCircleMarker) {
        const lat = layer._latlng.lat
        const lng = layer._latlng.lng
        setPosition({ lat: lat, lng: lng, exists: true })
      } else if (isPolygon) {
        const center = layer.getBounds().getCenter()
        const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
        const lat = center.lat
        const lng = center.lng
        setPosition({
          lat: lat,
          lng: lng,
          exists: true,
          area: area,
        })
      }
    })
  }

  const onMounted = drawControl => setDrawControl(drawControl)

  const onStatus = () => ({})

  return (
    <HabitatsContext.Provider
      value={{
        state: {
          coordinates,
          drawControl,
          mapView,
          position,
          shapeData,
          timestep,
        },
        refs: {
          mapRef,
        },
        actions: {
          onCreated,
          onDeleted,
          onEdited,
          onMounted,
          onStatus,
          setCoordinates,
          setDrawControl,
          setMapView,
          setPosition,
          setShapeData,
          setTimestep,
        },
      }}
    >
      <div className={classNames(classes.content, classes.contentDrawerOpen)}>
        <Map />
        <Sidebar
          habitatsActiveStep={habitatsActiveStep}
          setHabitatsActiveStep={setHabitatsActiveStep}
        />
      </div>
    </HabitatsContext.Provider>
  )
}

export default enhancer(Habitats)
