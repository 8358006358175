export default theme => ({
  expansionPanel: {
    borderTop: `1px solid ${theme.palette.shades.dark}`,
  },
  expansionPanelSummary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50%',
    flexShrink: 0,
    padding: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.secondary.main,
  },
  noPadding: {
    padding: '0',
  },
})
