import Contact from 'containers/Contact';
import Privacy from 'containers/Privacy';
import Terms from 'containers/Terms';
import Disclaimer from 'containers/Disclaimer';

export const contact = {
  component: Contact,
  path: `/contact`,
  title: 'Contact Us',
};

export const privacy = {
  component: Privacy,
  path: `/privacy`,
  title: 'Privacy Policy',
};

export const terms = {
  component: Terms,
  path: `/terms`,
  title: 'Terms of use',
};

export const disclaimer = {
  component: Disclaimer,
  path: `/disclaimer`,
  title: 'Disclaimer',
};

export default [contact, privacy, terms, disclaimer];
