import { eachDay, format, isSameDay } from 'date-fns'
import { some, concat, intersectionWith, groupBy, sortBy } from 'lodash'

import { getReducedNumber } from 'helpers/calculations'

export default ({ Plans, ScenarioAbstract }) => {
  const dataByPlans = Plans.map(({ DredgerAbstract }) => {
    // Get fields for calculation
    const { CalendarDays, SpillMass, TotalVolume } = DredgerAbstract
    // Get only the matching days
    const days = intersectionWith(
      eachDay(DredgerAbstract.StartDate, DredgerAbstract.EndDate),
      eachDay(ScenarioAbstract.StartDate, ScenarioAbstract.EndDate),
      (firstItem, secondItem) => isSameDay(firstItem, secondItem)
    )
    // Calculate fields and wrap output with labels
    return days.map(day => ({
      label: format(new Date(day), 'DD/MM/YYYY'),
      totalVolume: Math.round(TotalVolume / CalendarDays),
      spillMass: Math.round(SpillMass / CalendarDays),
    }))
  })
  // Get chart data grouped by days
  const dataByDay = groupBy(concat(...dataByPlans), 'label')
  // Update exising values by reducing numbers
  const data = Object.values(dataByDay).map(day => ({
    label: day[0].label,
    totalVolume: getReducedNumber(day, 'totalVolume'),
    spillMass: getReducedNumber(day, 'spillMass'),
  }))
  //
  const hasInvalidTotalVolume = some(data, {
    totalVolume: NaN,
  })
  const hasInvalidSpillMass = some(data, {
    spillMass: NaN,
  })
  const validData = data.map(({ label, totalVolume, spillMass }) => {
    const totalVolumeOrNot = hasInvalidTotalVolume ? {} : { totalVolume }
    const spillMassOrNot = hasInvalidSpillMass ? {} : { spillMass }
    return {
      label,
      ...totalVolumeOrNot,
      ...spillMassOrNot,
    }
  })
  return sortBy(validData, 'label')
}
