import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { deleteDilutionScenario } from 'data/dilution'
import PlayIcon from '@material-ui/icons/PlayArrow'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { useConfirm } from 'material-ui-confirm'

const Actions = ({
  id,
  data,
  dateTime,
  lastJobStatus,
  version,
  handleGetScenarios,
  handleExecuteScenario,
  projectId,
}) => {
  const confirm = useConfirm()
  const { t } = useTranslation()
  const isPending = lastJobStatus === 'Pending'
  const isProgressing = lastJobStatus === 'InProgress'
  const isDisabled = isPending || isProgressing
  const handleExecution = async () => {
    try {
      await confirm({
        title: t('common:execute'),
        description: t('common:actionScenario', {
          action: t('common:execute', { count: 2 }),
        }),
        confirmationText: t('common:confirm'),
        cancellationText: t('common:cancel'),
      })
      const { definition } = data
      const {
        model: { id: modelId },
      } = definition
      await handleExecuteScenario({
        job: {
          scenarioId: id,
          modelId,
        },
        scenario: {
          id,
          data,
          dateTime,
          lastJobStatus,
          version,
        },
      })
      await handleGetScenarios()
    } catch (error) {}
  }
  const handleDelete = async () => {
    try {
      await confirm({
        title: t('common:delete'),
        description: t('common:actionScenario', {
          action: t('common:delete', { count: 2 }),
        }),
      })
      await deleteDilutionScenario({ id })
      await handleGetScenarios()
    } catch (error) {}
  }
  const { push } = useHistory()
  return (
    <>
      <Tooltip title={t('common:execute')}>
        <span>
          <IconButton
            size="small"
            color="primary"
            aria-label="exexute"
            onClick={handleExecution}
            disabled={isDisabled}
          >
            <PlayIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('common:view')}>
        <span>
          <IconButton
            size="small"
            color="primary"
            aria-label="view"
            disabled={isDisabled}
            onClick={() =>
              push(`/dilution/view/${id}`, {
                projectId,
              })
            }
          >
            <VisibilityIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('common:edit')}>
        <span>
          <IconButton
            size="small"
            color="primary"
            aria-label="edit"
            disabled={isDisabled}
            onClick={() =>
              push(`/dilution/form/${id}`, {
                projectId,
              })
            }
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('common:copy')}>
        <span>
          <IconButton
            size="small"
            color="primary"
            aria-label="copy"
            onClick={() =>
              push(`/dilution/form`, {
                id,
                projectId,
              })
            }
          >
            <CopyIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('common:delete')}>
        <span>
          <IconButton
            size="small"
            color="primary"
            aria-label="delete"
            disabled={isDisabled}
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

export default Actions
