import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lowerCaseLng: true,
    nonExplicitSupportedLngs: true,
    supportedLngs: ['da', 'da-DK', 'da-dk', 'en', 'en-US', 'en-us'],
    fallbackLng: ['da', 'en'],
    debug: false,
    ns: [
      'common',
      'dilution',
      'home',
      'disclaimer',
      'errors',
      'pdf',
      'documentation',
    ],
    interpolation: {
      escapeValue: false,
      format: function (value, format) {
        if (format !== 'uppercase') return value.toLowerCase()
      },
    },
    nonExplicitWhitelist: true,
  })

export default i18n
