import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import TableList from 'components/TableList'
import Footer from 'components/Footer'
import DropdownSearch from 'components/DropdownSearch'

import Actions from './partials/Actions'

import useScenarios from 'containers/MarineExplorer/hooks/useScenarios'
import useProjectsByUsername from 'containers/MarineExplorer/hooks/useProjectsByUsername'

const List = ({ apiInfo = {} }) => {
  const { baseURL, path } = apiInfo
  const { isLoading, projects } = useProjectsByUsername({ host: baseURL })

  const { id: projectId } = useParams()
  const { push } = useHistory()

  const [sorting, setSorting] = useState([])
  const { scenarios, getScenarios: handleGetScenarios } = useScenarios({
    projectId,
    host: baseURL,
  })

  const selectedProject = projects.find((project) => project.id === projectId)

  useEffect(() => {
    setSorting([
      {
        field: 'dateTime',
        direction: 'desc',
      },
      {
        field: 'data.siteInformation.title',
        direction: 'asc',
      },
    ])
  }, [scenarios])

  useEffect(() => {
    if ((!!projects.length && !projectId) || projectId === 'undefined') {
      const [defaultProject] = projects
      push(`/${path}/${defaultProject.id}`)
    }
  }, [projects])

  if (isLoading) return null

  return (
    <>
      <Container maxWidth="xl" style={{ overflowX: 'auto' }}>
        <Box paddingY={10}>
          <Box paddingBottom={4}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width={1}
              marginBottom={4}
            >
              <Typography variant="h5" color="primary">
                Reports
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  width={1}
                  height={1}
                >
                  {!!projects.length && (
                    <Box
                      width={0.5}
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <DropdownSearch
                        placeholder="Select project"
                        id="project"
                        onChange={({ id }) => push(`/${path}/${id}`)}
                        options={projects}
                        getOptionLabel={({ data }) => {
                          const { title } = data
                          return title
                        }}
                        value={selectedProject}
                        label="Project:"
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={1}
                  height={1}
                >
                  <Box marginRight={1}>
                    <Typography>Create report</Typography>
                  </Box>
                  <Box>
                    <Tooltip title="Create report" disabled={true}>
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="Add"
                          onClick={() => push(`/${path}/form`, { projectId })}
                          disabled={true}
                        >
                          <span
                            style={{
                              width: '1.5em',
                              height: '1.5em',
                              display: 'inline-block',
                              fontSize: '1.5rem',
                            }}
                          >
                            <AddIcon disabled={!projects.length} />
                          </span>
                        </Fab>
                      </div>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {!scenarios.length ? (
            <Box
              padding={4}
              display="flex"
              flexDirection="colunn"
              justifyContent="center"
              alignItems="center"
              bgcolor="shades.light"
              border={1}
              borderColor="shades.medium"
            >
              <Typography color="primary" variant="subtitle1">
                No reports
              </Typography>
            </Box>
          ) : (
            <TableList
              columns={[
                {
                  id: 'title',
                  heading: 'Title',
                  isSortable: true,
                  field: 'data.definition.title',
                },
                {
                  id: 'dateCreated',
                  heading: 'Date created',
                  isSortable: true,
                  field: 'dateTime',
                  formatter: 'YYYY-MM-DD',
                },
                {
                  id: 'timeCreated',
                  heading: 'Time created',
                  isSortable: true,
                  field: 'dateTime',
                  formatter: 'HH:mm',
                },
                {
                  id: 'actions',
                  heading: 'Actions',
                  component: Actions,
                  shared: {
                    projectId,
                    handleGetScenarios,
                    // handleExecuteScenario,
                    path,
                    host: baseURL,
                  },
                },
              ]}
              list={scenarios}
              sorting={sorting}
            />
          )}
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default List
