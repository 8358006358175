import React, { memo } from 'react'
import TableRow from '@material-ui/core/TableRow'

import TableListRowCell from './partials/TableListRowCell'

const TableListRow = memo(({ hover, row, columns, index }) => (
  <TableRow hover={hover}>
    {columns.map(({ id, ...column }) => (
      <TableListRowCell key={id} id={id} {...column} row={row} index={index} />
    ))}
  </TableRow>
))

export default TableListRow
