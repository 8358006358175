// TODO: This looks like a spaceship. Let's get rid of it :-)

import React, { PureComponent } from 'react'
import { compose } from 'recompose'
import { isSameDay, isBefore } from 'date-fns'
import { Box, Button, Fab, withStyles } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'

import {
  MAP_CONNECTION,
  FILES,
  ANIMATION_LAYERS,
  PLOT_LAYERS
} from 'containers/SedimentSpill/config'

import { withRouter } from 'react-router-dom'

import { fetchDatetimes, fetchShapefile } from 'data/map'

import Drawer from 'components/Drawer'
import LayerControl from './partials/LayerControl'
import AnimationControl from './partials/AnimationControl'

import styles from './styles'

const enhancer = compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)

class MapControl extends PureComponent {
  state = {
    activeLayer: false,
    animationLayers: [
      ...JSON.parse(
        JSON.stringify(this.props.customAnimationLayers || ANIMATION_LAYERS)
      ),
      ...JSON.parse(JSON.stringify(this.props.customPlotLayers || PLOT_LAYERS))
    ],
    dateTimes: []
  }

  animationControl = React.createRef()

  componentDidMount() {
    const { drawControl, viewScenario } = this.props
    const { data } = viewScenario
    const { DredgingPlans } = data
    const { Plans: plans } = DredgingPlans

    plans
      .filter(plan => {
        const { DredgerAbstract = {} } = plan
        const { EndDate: abstractEndDate } = DredgerAbstract

        const today = new Date(Date.now())
        const abstractIsAfter = isBefore(today, new Date(abstractEndDate))
        const abstractIsSame = isSameDay(today, new Date(abstractEndDate))

        const isForecast = abstractIsAfter || abstractIsSame

        return isForecast
      })
      .map(plan => {
        const positions = plan.Positions

        if (positions.length === 1 && drawControl && drawControl.options) {
          L.marker([positions[0].Latitude, positions[0].Longitude], {
            opacity: 0.2
          }).addTo(drawControl.options.edit.featureGroup)
        } else if (positions.length > 1 && drawControl.options) {
          const polylineList = positions.map(
            position => new L.LatLng(position.Latitude, position.Longitude)
          )

          L.polyline(polylineList, {
            color: 'blue',
            weight: 2,
            opacity: 0.2,
            smoothFactor: 1
          }).addTo(drawControl.options.edit.featureGroup)
        }

        return null
      })
  }

  componentWillUnmount() {
    this.removeLayers()
    this.animationControl.stop()
    this.props.onAnimate(false, false, false)
    this.setState({ animationLayers: [], dateTimes: [] })
  }

  onCheck = id => () => {
    const { animationLayers } = this.state
    this.animationControl.stop()

    const activeAnimation = [...animationLayers]

    const index = activeAnimation.findIndex(animation => animation.id === id)

    const selectedAnimation = animationLayers[index]
    activeAnimation.map((animation, i) =>
      index === i
        ? (animation.checked = !selectedAnimation.checked)
        : (animation.checked = false)
    )

    this.setState({
      activeLayer: selectedAnimation,
      animationLayers: activeAnimation
    })

    if (animationLayers[index].checked) {
      this.generateIds(animationLayers[index])
    } else {
      this.props.onAnimate(false, false, false)
    }
  }

  generateIds = async selectedAnimation => {
    try {
      const { viewScenario, onAnimate, onChangeTimestep } = this.props
      const ids = {}
      const [file] = FILES

      let fileId = file.filePath
      fileId = fileId
        .replace('{scenarioId}', viewScenario.id)
        .replace('{resultFolder}', selectedAnimation.folder)
        .replace('{fileName}', selectedAnimation.file)

      const datesFilePath = `${viewScenario.id}|${selectedAnimation.folder}|${selectedAnimation.file};1`
      const dates = await fetchDatetimes(datesFilePath)

      dates.forEach(date => {
        ids[date] = fileId
      })

      this.setState({
        dateTimes: [...dates]
      })

      onAnimate(
        {
          connection: MAP_CONNECTION,
          style: selectedAnimation.style,
          item: selectedAnimation.item,
          ids
        },
        true
      )

      onChangeTimestep(dates[0])
    } catch (error) {
      console.error(error)
    }
  }

  removeLayers = () => {
    // remove all layers from map
    const { drawControl } = this.props
    if (drawControl && drawControl.options) {
      const layerContainer = this.props.drawControl.options.edit.featureGroup
      const layers = layerContainer._layers
      Object.keys(layerContainer._layers).forEach(id => {
        const layer = layers[id]
        layerContainer.removeLayer(layer)
      })
    }
  }

  onShapefileDownload = async () => {
    // todo refactor with axios request and fix filepath on server !
    const { activeLayer } = this.state
    if (activeLayer) {
      try {
        const {
          timestep,
          viewScenario: { id: scenarioId }
        } = this.props
        const { item, file, folder, style } = activeLayer
        const layer = {
          dateTime: timestep.split(':').join(''),
          item,
          file,
          folder,
          scenarioId,
          style
        }
        await fetchShapefile({ layer })
      } catch (error) {
        console.error(error)
      }
    }
  }

  render() {
    const { activeLayer, animationLayers, dateTimes } = this.state
    const { t } = useTranslation()
    const {
      history,
      classes,
      onChangeTimestep,
      viewScenario,
      animationLoading,
      timezone
    } = this.props

    const { data } = viewScenario
    const { Specifications } = data
    const { Name, Model } = Specifications

    const hasTimeseries = !!Model.points && !!Model.points.length
    const isPlot = activeLayer.type === 'plot'

    const animationsList = animationLayers.filter(
      item => item.type === 'animation'
    )

    const plotsList = animationLayers.filter(item => item.type === 'plot')
    const hasPlots = plotsList.length > 0
    const hasAnimations = animationsList.length > 0

    const showToolTip = activeLayer && !animationLoading

    return (
      <>
        <Drawer.Header>
          <div className={classes.header}>
            <h2>Scenario: {Name}</h2>
            <Fab
              size="small"
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={() => history.push(`/sediment-spill`)}
            >
              <CloseIcon />
            </Fab>
          </div>
        </Drawer.Header>
        <Drawer.Content>
          {hasAnimations && (
            <div>
              <h3>{t('common:animations')}</h3>
              <div className={classes.layers}>
                {animationsList.map((layer, index) => (
                  <LayerControl
                    key={index}
                    name={layer.name}
                    disabled={animationLoading}
                    enabled={layer.enabled}
                    checked={layer.checked}
                    onCheck={this.onCheck(layer.id)}
                    legendGradient={layer.legendGradient}
                    legendValuePostFix={layer.legendValuePostFix}
                  />
                ))}
              </div>
            </div>
          )}
          {hasPlots && (
            <div>
              <h3>Plots</h3>
              <div className={classes.layers}>
                {plotsList.map((layer, index) => (
                  <LayerControl
                    key={index}
                    name={layer.name}
                    disabled={animationLoading}
                    enabled={layer.enabled}
                    checked={layer.checked}
                    onCheck={this.onCheck(layer.id)}
                    legendGradient={layer.legendGradient}
                    legendValuePostFix={layer.legendValuePostFix}
                  />
                ))}
              </div>
            </div>
          )}
          {hasTimeseries && (
            <Box marginTop={2}>
              <Button
                type="button"
                onClick={() =>
                  history.push(`/dredging/timeseries/${viewScenario.id}`)
                }
                variant="contained"
                color="primary"
              >
                Explore timeseries
              </Button>
            </Box>
          )}
        </Drawer.Content>
        <Drawer.Footer>
          <div className={classes.header}>
            <AnimationControl
              enabled={
                !isPlot &&
                !animationLoading &&
                dateTimes &&
                dateTimes.length > 0
              }
              onRef={ref => (this.animationControl = ref)}
              onChange={onChangeTimestep}
              dateTimes={dateTimes}
              showToolTip={showToolTip}
              onShapefileDownload={this.onShapefileDownload}
              timezone={timezone}
            />
          </div>
        </Drawer.Footer>
      </>
    )
  }
}

export default enhancer(MapControl)
