import { useEffect, useState } from 'react'

import { getDilutionScenario } from 'data/dilution'

import getInitialValues from '../lib/getInitialValues'

const useValues = ({ identifier: id, isCopy }) => {
  const initialValues = getInitialValues()
  const [data, setData] = useState(initialValues)
  const [meta, setMeta] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const getValues = async () => {
    try {
      if (id) {
        const { data, ...meta } = await getDilutionScenario({ id })
        setData(data)
        setMeta(!isCopy && meta)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getValues()
    return () => {
      setData(initialValues)
      setMeta(false)
      setIsLoading(true)
    }
  }, [id])

  return {
    values: {
      data,
      meta,
    },
    isLoading,
  }
}

export default useValues
