import React, { useContext } from 'react'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { TableChart as TableChartIcon } from '@material-ui/icons'

import Wizard from './Wizard'
import { AppContext } from 'containers/LegacyFishfarm'

const Step5 = () => {
  const context = useContext(AppContext)
  const { indicators } = context.state
  const { onIndicatorCheck, toggleTable } = context.functions

  return (
    <Wizard.Step>
      <div>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <h4 style={{ width: '150px', float: 'left' }}>Screening Impacts</h4>

          <Button
            style={{ marginLeft: '170px' }}
            variant="contained"
            color="secondary"
            onClick={e => {
              toggleTable()
            }}
          >
            <TableChartIcon style={{ paddingRight: '5px' }} />
            Table
          </Button>
        </div>

        {indicators.map((indicator, index1) => (
          <div key={indicator.id}>
            <FormControlLabel
              control={
                <Checkbox
                  value={indicator.id}
                  checked={indicator.checked}
                  onChange={e => {
                    onIndicatorCheck(e)
                  }}
                />
              }
              disabled={indicator.disabled}
              label={indicator.label}
            />
          </div>
        ))}
      </div>
    </Wizard.Step>
  )
}

export default Step5
