import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import isArray from 'lodash/isArray'

const ErrorMessage = ({ errors, fields }) => {
  const messages = fields.reduce((allMessages, thisField) => {
    const message = get(errors, thisField)
    if (message) allMessages.push(message)
    return allMessages
  }, [])

  const getFields = (message) => (isArray(message) ? message : [message])

  return (
    <Box display="flex" flexDirection="column">
      {messages.map((message) => {
        const fields = getFields(message)
        return (
          <Box key={message}>
            {fields.map((field, index) => {
              const count = fields.length > 1 ? `${index + 1} - ` : ''
              const identifier = `${field}-${index}`
              return (
                <Typography
                  key={identifier}
                  style={{ color: 'red' }}
                  gutterBottom
                  variant="caption"
                >
                  {count}
                  {field}
                </Typography>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export default ErrorMessage
