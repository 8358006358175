import React, { useContext } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  withStyles,
} from '@material-ui/core'

import Wizard from './Wizard'
import { AppContext } from 'containers/LegacyFishfarm'

const styles = theme => ({
  row: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
  },
})

const Step3 = ({ classes }) => {
  const context = useContext(AppContext)
  const { inputData, coords } = context.state
  const {
    updateInputData,
    clearAmbient,
    fetchAmbient,
    displayAmbientMap,
  } = context.functions

  return (
    <Wizard.Step>
      <div>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <Tooltip title="Specify ambient conditions at site locations">
            <h4>Ambient Conditions</h4>
          </Tooltip>
        </div>

        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={e => {
              clearAmbient()
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={e => {
              fetchAmbient()
            }}
          >
            From Maps
          </Button>
        </div>

        <div>
          <br />
          <div className={classes.row}>
            <div id="par" style={{ width: 130 }}>
              <input type="text" value="" disabled={true} />
            </div>
            {coords.lat.map((dum, index) => (
              <div key={index} id="loc">
                <input
                  style={{ width: 50 }}
                  type="text"
                  value={'#' + (index + 1).toString()}
                  disabled={true}
                />
              </div>
            ))}
          </div>

          {Object.keys(inputData).map(key => {
            return (
              inputData[key].category === 'Ambient' && (
                <div key={key} className={classes.row}>
                  <div id="par" style={{ width: 130 }}>
                    <input
                      type="text"
                      value={
                        inputData[key].id + ' (' + inputData[key].unit + ') '
                      }
                      disabled={true}
                    />
                  </div>

                  {coords.lat.map((dum, index) => (
                    <div key={index} id="loc">
                      <input
                        style={{ width: 50 }}
                        type="number"
                        value={inputData[key].values[index]}
                        onChange={e => {
                          const value =
                            e.target.value !== ''
                              ? parseFloat(e.target.value)
                              : null
                          updateInputData(key, value, index)
                        }}
                      />
                    </div>
                  ))}

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={key}
                        checked={inputData[key].checked}
                        onChange={e => {
                          displayAmbientMap(e)
                        }}
                      />
                    }
                    //disabled={}
                    //label={}
                  />
                </div>
              )
            )
          })}
        </div>
      </div>
    </Wizard.Step>
  )
}

export default withStyles(styles)(Step3)
