// import { stringify } from 'query-string'
import API, {
    getDataAsJSON,

} from '@dhi-solutions/domain-services'

// import {
//     decodeDilution as decode,
//     encodeDilution as encode,
// } from 'helpers/dilutionsMapper'

// import has from 'lodash/has'

import { HOSTS } from '../config/constants'

const { MARINE_EXPLORER: HOST, MARINE_EXPLORER_ROOT: ROOT } = HOSTS

export const getMarineExplorerScenario = async ({ id }) => {
    try {
        const endpoint = `/scenarios/Scenarios/${id}`
        const response = await API.get(endpoint, {
            baseURL: HOST,
        })
        if (!response) throw Error(`Request rejected`)
        const { data } = await getDataAsJSON(response)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getMapResults = async ({ id }) => {
    try {
        const response = await API.get(`/reports/${id}/maps`, {
            baseURL: ROOT,
        })
        if (!response) throw Error(`Request rejected`)
        const { data } = response || {}
        return Promise.resolve(data.map(item => ({ ...item, baseURL: HOST })))
    } catch (error) {
        return Promise.reject(error)
    }
}