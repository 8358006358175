import React, { useState, useMemo, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { isDate, format } from 'date-fns'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useTranslation } from 'react-i18next'

import Popper from 'components/Popper'
import imagesGrabState from 'components/DeckGLMap/states/imagesGrab'
import mapSourceState from 'containers/Dilution/states/mapSource'
import stepState from 'containers/Dilution/partials/View/states/step'
import timestepsState from 'containers/Dilution/partials/View/states/timesteps'

import { getShapeFile } from 'data/dilution'

import useSelectedItem from './hooks/useSelectedItem'
import useBounds from './hooks/useBounds'

import Layer from 'components/DeckGLMapContent/partials/Layer'

import viewFeaturesState from 'containers/Dilution/partials/View/states/viewFeatures'
import useChart from './hooks/useChart'

const Content = React.memo(
  ({
    scenarioId,
    animations,
    plots,
    modelBounds,
    initialView,
    dischargeOutlets,
    withCustomLegends,
  }) => {
    const step = useRecoilValue(stepState)
    const { requestingTimesteps } = useRecoilValue(timestepsState)
    const [selectedItem, setSelectedItem] = useState({})
    const setDischargePoints = useSetRecoilState(mapSourceState)
    const setMapPreview = useSetRecoilState(imagesGrabState)
    const { t } = useTranslation()
    const viewFeatures = useRecoilValue(viewFeaturesState)
    const { shapefile } = viewFeatures
    const [isDischargeChecked, setIsDischargeChecked] = useState(true)
    useBounds({ modelBounds, initialView })

    useSelectedItem(selectedItem)

    const { chartdata, resetChart } = useChart(selectedItem)

    const timestep = useMemo(() => {
      const activeTimestep = Object.keys(requestingTimesteps)[step]
      const isValid = !!activeTimestep && isDate(new Date(activeTimestep))
      const label = isValid
        ? format(activeTimestep, 'YYYY-MM-DD - HH:mm:ss')
        : ''
      return label
    }, [requestingTimesteps, step])

    const withSelectedItem = !!Object.keys(selectedItem).length

    const { id, path, itemNumber, label, legend } = selectedItem

    const identifier = `${id}-${Date.now()}`

    const handleMapPreview = () =>
      setMapPreview({
        grab: true,
        id: identifier,
        properties: { id, identifier, label, legend, timestep },
      })

    const handleSetDischargeChecked = (event) => {
      setIsDischargeChecked(event.target.checked)
    }

    useEffect(() => {
      if (isDischargeChecked) {
        setDischargePoints(dischargeOutlets)
      } else {
        setDischargePoints([])
      }
    }, [isDischargeChecked])

    const handleDownloadShape = async () => {
      const data = await getShapeFile({ path, itemNumber, step })
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))

      const link = document.createElement('a')

      link.href = downloadUrl

      link.setAttribute('download', `${label}.zip`)

      document.body.appendChild(link)

      link.click()

      link.remove()
    }

    return (
      <Box width={1} height={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          bgcolor="shades.light"
          marginBottom="3rem"
          padding="1rem"
        >
          {shapefile && (
            <Box display="flex" alignItems="center">
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    disabled={!dischargeOutlets.length}
                    onChange={handleSetDischargeChecked}
                    checked={isDischargeChecked}
                  />
                }
                label={
                  <Typography variant="body2">
                    {t('common:showOutlets')}
                  </Typography>
                }
              />
            </Box>
          )}
        </Box>

        <Box>
          <Box
            style={{ display: 'flex', justifyContent: 'space-between' }}
            marginBottom={2}
          >
            <Typography variant="h6">{t('common:animations')}</Typography>
            <Box display="flex" justifyContent="flex-end">
              <Popper
                icon={<HelpOutlineIcon />}
                popperContent={t('documentation:animationsDocumentation')}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width={1}>
            {animations
              .filter((x) => x.itemNumber !== 0)
              .map((item) => (
                <Layer
                  key={`animation-${item.label}-${item.itemNumber}`}
                  scenarioId={scenarioId}
                  item={item}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  handleMapPreview={handleMapPreview}
                  viewFeatures={viewFeatures}
                  chart={chartdata}
                  resetChart={resetChart}
                  withCustomLegends={withCustomLegends}
                  downloadShapeBtn={
                    <Tooltip title={t('pdf:downloadChosenShapeFile')}>
                      <Box display="flex" alignItems="center" marginLeft="1rem">
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={!withSelectedItem}
                          onClick={handleDownloadShape}
                          download
                        >
                          {t('common:downloadShapeFile')}
                        </Button>
                      </Box>
                    </Tooltip>
                  }
                />
              ))}
          </Box>
          <Box marginTop={4} marginBottom={2}>
            <Typography variant="h6">{t('common:plots')}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" width={1}>
            {plots.map((item) => (
              <Layer
                key={`plot-${item.label}-${item.itemNumber}`}
                scenarioId={scenarioId}
                item={item}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                handleMapPreview={handleMapPreview}
                viewFeatures={viewFeatures}
                chart={chartdata}
                resetChart={resetChart}
                withCustomLegends={withCustomLegends}
                downloadShapeBtn={
                  <Tooltip title={t('pdf:downloadChosenShapeFile')}>
                    <Box display="flex" alignItems="center" marginLeft="1rem">
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={!withSelectedItem}
                        onClick={handleDownloadShape}
                        download
                      >
                        {t('common:downloadShapeFile')}
                      </Button>
                    </Box>
                  </Tooltip>
                }
              />
            ))}
          </Box>
        </Box>
      </Box>
    )
  }
)

export default Content
