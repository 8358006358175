import React from 'react'
import { useSetRecoilState } from 'recoil'
import { withStyles } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useTranslation } from 'react-i18next'

import toggleOptionsState from 'components/DeckGLMapContent/states/toggleOptions'

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

const StyledToggleButton = withStyles((theme) => ({
  root: {
    border: 'none',
  },
}))(ToggleButton)

const VectorIsolinesToggle = ({
  vectorOption,
  vectorNumberHorizontal,
  label,
  isolines,
  vectorlines,
}) => {
  const [pick, setPick] = React.useState()
  const toggleOptionsSetState = useSetRecoilState(toggleOptionsState)
  const { t } = useTranslation()

  const handleOnClick = (event, newPick) => {
    setPick(newPick)
    toggleOptionsSetState((oldToggleOptions) => {
      return {
        ...oldToggleOptions,
        [label]: {
          includevector: newPick.indexOf('includevector') > -1,
          isoline: newPick.indexOf('isoline') > -1 ? 'LineAndLabel' : 'None',
          vectornumberhorizontal: vectorNumberHorizontal,
        },
      }
    })
  }

  return (
    <StyledToggleButtonGroup
      value={pick}
      size="small"
      aria-label="text formatting"
      style={{ marginLeft: 'auto' }}
      onChange={handleOnClick}
    >
      {vectorlines && vectorOption && (
        <StyledToggleButton
          disabled={!vectorOption}
          value="includevector"
          aria-label="italic"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          {t('common:vector')}
        </StyledToggleButton>
      )}

      {isolines && (
        <StyledToggleButton
          value="isoline"
          aria-label="underlined"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          {t('common:isolines')}
        </StyledToggleButton>
      )}
    </StyledToggleButtonGroup>
  )
}

export default VectorIsolinesToggle
