import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { useRecoilState, useRecoilValue } from 'recoil'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import LayersIcon from '@material-ui/icons/Layers'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Block from 'components/Block'

import { activeModelState } from 'containers/Dilution/states/activeModel'
import { mapOverlays as mapOverlaysState } from 'containers/Dilution/states/mapOverlays'
import { useTranslation } from 'react-i18next'

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

const LayerManager = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isLayerManageOpen = Boolean(anchorEl)
  const layerManagerId = isLayerManageOpen ? 'spring-popper' : undefined
  const model = useRecoilValue(activeModelState)
  const [mapOverlays, setMapOverlays] = useRecoilState(mapOverlaysState)
  const { t } = useTranslation()

  const handleShowLayerManager = (event) => setAnchorEl(event.currentTarget)
  const handleHideLayerManager = () => setAnchorEl(null)

  React.useEffect(() => {
    const parsedMapOverlays = model && model.mapOverlays && model.mapOverlays.length ? model.mapOverlays : []
    setMapOverlays(parsedMapOverlays)
  }, [model])

  const handleMapOverlays = (id, checked) => {
    let newOverlays = JSON.parse(JSON.stringify(mapOverlays))

    newOverlays = newOverlays.map((mapOverlay) => {
      if (mapOverlay.config.id === id) {
        return {
          ...mapOverlay,
          config: {
            ...mapOverlay.config,
            visible: !checked,
          },
        }
      } else {
        return mapOverlay
      }
    });

    setMapOverlays(newOverlays)
  }

  return (
    <Box
      position="absolute"
      zIndex={9}
      bottom="2.5rem"
      left="0.5rem"
      onMouseEnter={handleShowLayerManager}
      onMouseLeave={handleHideLayerManager}
    >
      <Popper
        id={layerManagerId}
        open={isLayerManageOpen}
        anchorEl={anchorEl}
        transition
        placement="top"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box marginLeft="4px" marginBottom="0.5rem" bgcolor="white">
              <Block>
                <Box paddingX="1.5rem">
                  <Typography variant="overline">
                    {t('common:overlays')}
                  </Typography>
                </Box>
                <List>
                  {mapOverlays
                    .filter(({ inLayerManager = false }) => !!inLayerManager)
                    .map(({ label, config: { id, visible } }) => {
                      return (
                        <ListItem
                          key={label}
                          dense
                          button
                          onClick={() => handleMapOverlays(id, visible)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={visible}
                              onChange={() => handleMapOverlays(id, visible)}
                              color="primary"
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItem>
                      )
                    })}
                </List>
              </Block>
            </Box>
          </Fade>
        )}
      </Popper>
      <Button
        variant="contained"
        color={isLayerManageOpen ? 'secondary' : 'default'}
        aria-label="layers"
        size="small"
      >
        <LayersIcon />
      </Button>
    </Box>
  )
}

export default LayerManager
