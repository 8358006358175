import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const ActionsItemMenu = ({ action, functions, menu }) => {
  const [showMenu, setShowMenu] = useState(null);

  const handleClick = event => setShowMenu(event.currentTarget);

  const closeMenu = () => setShowMenu(null);

  const handleMenu = ({ event, menuItem: { name, connection } }) => {
    const id = event.currentTarget.id;

    const handlers = {
      Action: () => functions.action(action, name),
      Edit: () => functions.edit(action),
      Execute: () => functions.execute(action, connection, name),
      Delete: () => functions.delete(action),
      Clone: () => functions.clone(action),
    };

    const handler = handlers[id];

    if (handler) {
      closeMenu();
      handler();
    }
  };
  return (
    <>
      <IconButton
        aria-owns={showMenu ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={showMenu}
        open={Boolean(showMenu)}
        onClose={closeMenu}
      >
        {menu.map(menuItem => (
          <MenuItem
            key={menuItem.name}
            id={menuItem.field}
            onClick={event => handleMenu({ event, menuItem })}
          >
            {menuItem.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ActionsItemMenu.propTypes = {
  functions: PropTypes.object,
  menu: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.object,
};

export default ActionsItemMenu;
