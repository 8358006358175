import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import useImages from 'hooks/useImages'
import useTimesteps from 'hooks/useTimesteps'

import viewState from 'components/DeckGLMap/states/view'
import imagesState from 'containers/Dilution/partials/View/states/images'
import toggleOptionsState from 'components/DeckGLMapContent/states/toggleOptions'

const useSelectedItem = (selectedItem) => {
  const view = useRecoilValue(viewState)
  const toggleOptions = useRecoilValue(toggleOptionsState)
  const setImages = useSetRecoilState(imagesState)

  const withSelectedItem = !!Object.keys(selectedItem).length

  const { requestingTimesteps } = useTimesteps(selectedItem)

  const images = useImages({
    selectedItem,
    view,
    timesteps: requestingTimesteps,
    toggleOptions: toggleOptions[selectedItem.label],
  })

  useEffect(() => {
    const imagesToSet = images.map((x, i) => {
      if (x[3].tStep === undefined) {
        x[3].tStep = i
      }
      return x
    })
    setImages(withSelectedItem ? imagesToSet : [])
  }, [images])
}

export default useSelectedItem
