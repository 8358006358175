import { object, string, boolean, mixed } from 'yup'
import { isAfter } from 'date-fns'

import Step1 from './Step1'

export default {
  component: Step1,
  validationSchema: ({ t }) => {
    return object().shape({
      version: string(),
      definition: object().shape({
        title: string().required(t('errors:missingTitle')),
        description: string(),
        project: mixed().test({
          name: 'with-project',
          exclusive: true,
          message: t('errors:missingProject'),
          test: ({ id }) => !!id
        }),
        model: mixed().test({
          name: 'with-model',
          exclusive: true,
          message: t('errors:missingModel'),
          test: ({ id }) => !!id
        }),
        type: mixed().test({
          name: 'with-type',
          exclusive: true,
          message: t('errors:missingType'),
          test: ({ id }) => !!id
        }),
        runHd: boolean(),
        startDate: string().required(t('errors:missingStartDate')),
        endDate: string()
          .required(t('errors:missingEndDate'))
          .when('startDate', (startDate, schema) =>
            schema.test('end-date', t('errors:validDate'), endDate => {
              if (endDate) {
                return isAfter(new Date(endDate), new Date(startDate))
              }
            })
          )
      })
    })
  }
}
