import API, { getDataAsJSON } from '@dhi-solutions/domain-services'

import { decodeDilution as decode } from 'helpers/dilutionsMapper'

import has from 'lodash/has'

import { stringify } from 'query-string'

export const getProjectsByUsername = async ({ username, host }) => {
  try {
    const response = await API.post(
      '/scenarios/Projects/query',
      [
        {
          item: 'assignedUsers',
          queryOperator: 'Like',
          value: '%"' + username + '"%',
        },
      ],
      {
        baseURL: host,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const projects = await getDataAsJSON(response)
    return Promise.resolve(projects)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getScenariosByProject = async ({ projectId, host }) => {
  try {
    const response = await API.post(
      '/scenarios/Scenarios/query',
      [
        {
          item: 'project',
          queryOperator: 'Like',
          value: '%' + projectId + '%',
        },
      ],
      {
        baseURL: host,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const scenarios = await getDataAsJSON(response)
    const mappedScenarios = scenarios.map(({ data, ...rest }) => ({
      data: decode(data),
      ...rest,
    }))
    return Promise.resolve(mappedScenarios)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getScenario = async ({ id, baseURL }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${id}`
    const response = await API.get(endpoint, {
      baseURL: baseURL,
    })
    if (!response) throw Error(`Request rejected`)
    const { data, ...rest } = await getDataAsJSON(response)
    const mappedScenario = {
      data: decode(data),
      ...rest,
    }

    return Promise.resolve(mappedScenario)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteScenario = async ({ id, host }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${id}`
    const response = await API.delete(endpoint, {
      baseURL: host,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTimeseries = async ({
  connectionId,
  path,
  itemNumber,
  baseURL,
}) => {
  try {
    const endpoint = `/timeseries/${connectionId}/${path};${itemNumber}/values`
    const response = await API.get(endpoint, {
      baseURL: baseURL,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getImages = async (
  {
    baseURL,
    timesteps,
    mapConnectionId,
    mapStyle: style,
    itemNumber: item,
    width,
    height,
    shadingtype = 'ShadedContour',
    bbox,
    isMajorLoading,
    isoline,
    includevector,
    vectornumberhorizontal,
  },
  source
) => {
  try {
    const query = {
      style,
      item,
      width,
      height,
      shadingtype,
      bbox,
      isoline,
      includevector,
      vectornumberhorizontal,
    }
    const endpoint = `/maps/${mapConnectionId}?` + stringify({ ...query })

    const cancelToken = has(source, 'token')
      ? {
          cancelToken: source.token,
        }
      : {}
    const response = await API.post(endpoint, timesteps, {
      baseURL: baseURL,
      ...cancelToken,
      headers: {
        isMajorLoading,
      },
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
