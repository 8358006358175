import React, { useContext } from 'react';

import { compose } from 'recompose';

import { withStyles } from '@material-ui/core';

import { DataviewerContext } from 'containers/Dataviewer';

import Block from 'components/Block';

import Layers from '../partials/Layers';

import styles from './styles';

const enhancer = compose(withStyles(styles));

const Step2 = () => {
  const { state } = useContext(DataviewerContext);
  const { layers } = state;
  const { step2: step } = layers;
  return (
    <>
      <Block>
        <Layers data={step} identifier="step2" />
      </Block>
    </>
  );
};

export default enhancer(Step2);
