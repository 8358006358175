import React, { useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import { useMeasure } from 'react-use'
import { useRecoilState, useRecoilValue } from 'recoil'
import isEqual from 'lodash/isEqual'

import viewState from './states/view'
import cursorState from './states/cursor'

import CoreMap from './partials/CoreMap'
// import LayerManager from './partials/LayerManager'

const DeckGLMap = ({ layers, children, id = 'shared-component' }) => {
  const [view, setView] = useRecoilState(viewState)
  const cursor = useRecoilValue(cursorState)

  const { width: viewWidth, height: viewHeight } = view
  const [ref, { width, height }] = useMeasure()

  const isMatchingWidth = useMemo(() => isEqual(width, viewWidth), [
    width,
    viewWidth,
  ])

  const isMatchingHeight = useMemo(() => isEqual(height, viewHeight), [
    height,
    viewHeight,
  ])

  useEffect(() => {
    if (!isMatchingWidth || isMatchingHeight) {
      setView({ ...view, width, height })
    }
  }, [isMatchingWidth, isMatchingHeight])

  const withSize = useMemo(() => view.width > 1 && view.height > 1, [
    view.width,
    view.height,
  ])

  return (
    <Box
      ref={ref}
      bgcolor="shades.light"
      position="relative"
      width={1}
      height={1}
    >
      {children}
      {withSize && (
        <CoreMap
          id={id}
          width={width}
          height={height}
          layers={layers}
          view={view}
          setView={setView}
          cursor={cursor}
        />
      )}
    </Box>
  )
}

export default DeckGLMap
