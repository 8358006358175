import React from 'react'
import { Popper as MUIPopper } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import useStyles from './hooks/useStyles'

const Popper = ({ icon, popperContent, placement = 'bottom', prefix }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popper' : undefined

  return (
    <>
      {prefix && <span>{prefix}</span>}
      <IconButton
        className={classes.icon}
        aria-describedby={id}
        onClick={handleClick}
        onMouseLeave={() => setAnchorEl(false)}
      >
        {icon}
      </IconButton>
      <MUIPopper
        className={classes.popper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        <Typography color="primary" variant="subtitle2">
          {popperContent}
        </Typography>
      </MUIPopper>
    </>
  )
}

export default Popper
