export default () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0rem',
  },
  horizontal: {
    flexDirection: 'row',
  },
  slider: {
    maxWidth: '80%',
  },
})
