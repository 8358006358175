import React, { PureComponent, createContext } from 'react'
import classNames from 'classnames'
import { CircularProgress, withStyles } from '@material-ui/core'
import shp from 'shpjs'
import { Circle, FeatureGroup, GeoJSON, Pane, withLeaflet } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import MeasureControlDefault from 'react-leaflet-measure'
import { isNull } from 'util'

import Drawer from 'components/Drawer'
import Map from 'components/CoreMap'
import MapLayer from 'components/CoreMap/MapLayer'
import WizardDrawer from './partials/wizard/WizardDrawer'
import ImpactTable from './partials/ImpactTable'

import styles from 'styles/global'

// import { fetchTimeseriesValues } from 'data'
import { INPUT_DATA, IMPACTS, INDICATORS } from './config'

// Wrap our new variable and assign it to the one we used before. The rest of the codes stays the same.
const MeasureControl = withLeaflet(MeasureControlDefault)
const measureOptions = {
  position: 'bottomleft',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'acres',
  activeColor: '#db4a29',
  completedColor: '#9b2d14',
}

export const AppContext = createContext()

class LegacyFishfarm extends PureComponent {
  state = {
    info: {
      title: '',
      description: '',
    },
    domain: {
      id: '',
      lat: 56,
      lng: 13.5,
      zoom: 7,
    },
    coords: { lat: [], lng: [], changed: false },
    openDrawer: false,
    drawControl: {},
    shapeData: null,
    inputData: JSON.parse(JSON.stringify(INPUT_DATA)),
    impacts: JSON.parse(JSON.stringify(IMPACTS)),
    indicators: JSON.parse(JSON.stringify(INDICATORS)),
    index: null,
    drawStatus: false,
    showTable: false,
    mapView: {
      center: [56, 13.5],
      zoom: 7,
    },
    animationLoading: false,
  }

  updateDrawStatus = status => {
    this.setState({ drawStatus: status })
  }

  updateInfo = info => {
    this.setState({ info: info })
  }

  updateDomain = e => {
    const domain = e.target.value
    this.setState({
      domain,
      mapView: {
        center: [domain.lat, domain.lng],
        zoom: domain.zoom,
      },
    })
  }

  updateCoords = (lat, lng, changed) => {
    this.setState({ coords: { lat: lat, lng: lng, changed: changed } })
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  toggleTable = () => {
    this.setState({
      showTable: !this.state.showTable,
    })
  }

  removeTable = () => {
    this.setState({
      showTable: false,
    })
  }

  onDrawMount = drawControl => {
    this.setState({
      drawControl,
    })
  }

  onDrawCreate = e => {
    // Called for every marker

    let layer = e.layer
    const latnew = Math.round(layer._latlng.lat * 10000) / 10000
    const lngnew = Math.round(layer._latlng.lng * 10000) / 10000

    let lat = this.state.coords.lat
    let lng = this.state.coords.lng
    lat.push(latnew)
    lng.push(lngnew)
    this.updateCoords(lat, lng, false)

    // Add popup
    const info = this.state.info
    const index = lat.length
    var heading = (info.title ? info.title + ' ' : '') + '#' + index.toString()
    heading = '<h4>' + heading + '</h4>'
    layer.bindPopup(heading, { maxWidth: 400 })
  }

  onDrawEdit = e => {
    let layers = e.layers
    layers.eachLayer(layer => {
      // Not used, replaced by drawOnMap()
    })
  }

  onDrawDelete = e => {
    // Not used, replaced by drawOnMap()
  }

  makeGeojson = e => {
    e.preventDefault()
    let zipFile = e.target.files[0]

    let reader = new FileReader()
    reader.readAsArrayBuffer(zipFile)
    reader.onloadend = async e => {
      const geojson = await this.getGeojson(e)
      this.setState({ shapeData: geojson })
    }
  }

  getGeojson = async e => {
    const geojson = shp(e.target.result).then(
      function(data) {
        return data
      },
      function(error) {
        console.error('Shapefile load failed: ', error)
      }
    )
    return geojson
  }

  resetFile = () => {
    const file = document.querySelector('.file')
    file.value = ''
  }

  removeGeojson = () => {
    this.resetFile()
    this.setState({
      shapeData: null,
    })
  }

  onIndicatorCheck = e => {
    const selectedId = e.target.value
    const indicators = this.state.indicators
    var i = null
    indicators.forEach((indicator, index) => {
      if (indicator.id === selectedId) {
        indicators[index].checked = !indicators[index].checked
        indicators[index].checked ? (i = index) : (i = null)
      } else {
        indicators[index].checked = false
      }
    })

    this.setState({ indicators: indicators, index: i })

    // Update marker(s) popup
    this.state.coords.lat.forEach((dum, index) => {
      var heading =
        (this.state.info.title ? this.state.info.title + ' ' : '') +
        '#' +
        (index + 1).toString()
      var msg = '<h4>' + heading + '</h4>'
      if (!isNull(i)) {
        var content = ''
        var tmp = 'bold'
        indicators[i].assessmentArea.forEach(area => {
          var tmp1 =
            '<Span style="font-weight: ' +
            tmp +
            '">' +
            area.impact[index] +
            '</Span>'
          var tmp2 =
            ' <Span style="background-color: ' +
            area.colorCode[index] +
            '">O</Span>'
          content =
            content +
            '<p>' +
            area.label +
            ': ' +
            area.result[index] +
            ' ' +
            indicators[i].unit +
            ' -> ' +
            tmp1 +
            tmp2 +
            '</p>'
        })
        var tmp3 =
          '<Span style="font-weight: ' +
          tmp +
          '">' +
          indicators[i].label +
          '</Span>'
        msg = msg + '<p>Screening impact on: ' + tmp3 + '</p>' + content
      }
      const featureGroup = this.state.drawControl.options.edit.featureGroup
      const layers = featureGroup._layers
      const layer = layers[Object.keys(layers)[index]]
      layer._popup.setContent(msg)
    })
  }

  resetIndicatorDisplay = () => {
    const indicators = [...this.state.indicators]
    this.state.indicators.forEach((dum, index) => {
      indicators[index].checked = false
    })

    this.setState({ indicators: indicators, index: null })

    // Reset popup(s)
    this.state.coords.lat.forEach((dum, index) => {
      var heading =
        (this.state.info.title ? this.state.info.title + ' ' : '') +
        '#' +
        (index + 1).toString()
      heading = '<h4>' + heading + '</h4>'
      const featureGroup = this.state.drawControl.options.edit.featureGroup
      const layers = featureGroup._layers
      const layer = layers[Object.keys(layers)[index]]
      layer._popup.setContent(heading)
    })
  }

  initInputData = () => {
    // Disable draw control, if required
    if (this.state.drawStatus) {
      this.state.drawControl._toolbars.draw.disable()
      this.updateDrawStatus(false)
    }

    // Initialize input data
    Object.keys(this.state.inputData).forEach(key => {
      var par = this.state.inputData[key]
      par.values = []

      if (par.category === 'Ambient') {
        this.state.coords.lat.forEach(() => {
          //par.values.push('');
          par.values.push(par.defaultValue)
        })
      } else {
        //par.values.push('');
        par.values.push(par.defaultValue)
      }

      this.setState({
        inputData: {
          ...this.state.inputData,
          [key]: par,
        },
      })
    })
  }

  updateInputData = (key, value, index) => {
    const par = this.state.inputData[key]
    par.values[index] = value

    this.setState({
      inputData: {
        ...this.state.inputData,
        [key]: par,
      },
    })
  }

  clearAmbient = () => {
    Object.keys(this.state.inputData).forEach(key => {
      var par = this.state.inputData[key]

      if (par.category === 'Ambient') {
        par.values = []
        this.state.coords.lat.forEach(() => {
          par.values.push('')
        })
      }

      this.setState({
        inputData: {
          ...this.state.inputData,
          [key]: par,
        },
      })
    })
  }

  fetchAmbient = () => {
    // Object.keys(this.state.inputData).forEach(key => {
    // var par = this.state.inputData[key]
    // if (par.category === 'Ambient') {
    // var ids = this.state.coords.lat.map(
    //   (lat, index) =>
    //     'Item=' +
    //     par.item +
    //     ';Longitude=' +
    //     this.state.coords.lng[index] +
    //     ';Latitude=' +
    //     lat
    // )
    // var source = [
    //   {
    //     //host: JSON.parse(JSON.stringify(HOST)),
    //     host: par.host,
    //     connection: par.connection,
    //     ids: ids,
    //   },
    // ]
    // fetchTimeseriesValues(source).subscribe(
    //   response => {
    //     const data = response.map(res => {
    //       return res.data
    //     })
    //     this.state.coords.lat.forEach((lat, index) => {
    //       //this.updateInputData(key, data[index][0][1], index); // TimeSeriesWebAPI1
    //       this.updateInputData(key, data[index].Values[0], index) // WebAPI3
    //     })
    //   },
    //   error => {
    //     console.error(error)
    //   }
    // )
    // }
    // })
  }

  displayAmbientMap = e => {
    const selectedKey = e.target.value
    const newData = this.state.inputData
    Object.keys(this.state.inputData).map(key =>
      key === selectedKey
        ? (newData[key].checked = !newData[key].checked)
        : newData[key].checked
        ? (newData[key].checked = false)
        : null
    )

    this.setState({
      inputData: newData,
    })
  }

  updateThreshold = (key, value, index) => {
    const newIndicators = this.state.indicators
    newIndicators[key].thresholds[index] = value

    this.setState({
      indicators: newIndicators,
    })
  }

  calcImpacts = () => {
    const indicators = [...this.state.indicators]
    const impacts = this.state.impacts

    // initialize/reset
    this.state.indicators.forEach((dum, index) => {
      indicators[index].disabled = true
      indicators[index].checked = false

      this.state.indicators[index].assessmentArea.forEach((dum, index2) => {
        indicators[index].assessmentArea[index2].result = []
        indicators[index].assessmentArea[index2].impact = []
        indicators[index].assessmentArea[index2].colorCode = []
      })
    })

    // calculate impacts
    this.state.indicators.forEach((indicator, index1) => {
      indicators[index1].disabled = false

      indicator.assessmentArea.forEach((area, index2) => {
        let result = [],
          impact = [],
          colorCode = []

        this.state.coords.lat.forEach((dum, index3) => {
          // Prediction
          var res = area.intercept
          area.coefficients.forEach((coef, index) => {
            var val
            if (
              this.state.inputData[indicator.inputDataKey[index]].category ===
              'Ambient'
            ) {
              val = this.state.inputData[indicator.inputDataKey[index]].values[
                index3
              ]
            } else {
              val = this.state.inputData[indicator.inputDataKey[index]]
                .values[0]
            }
            res = res + coef * val
          })

          // Round off
          const tmp = Math.pow(10, indicator.decimals)
          res = Math.round(res * tmp) / tmp

          // Impact
          var imp, col
          if (res > indicator.thresholds[3]) {
            imp = impacts[4].impact
            col = impacts[4].colorCode
          } else if (res > indicator.thresholds[2]) {
            imp = impacts[3].impact
            col = impacts[3].colorCode
          } else if (res > indicator.thresholds[1]) {
            imp = impacts[2].impact
            col = impacts[2].colorCode
          } else if (res > indicator.thresholds[0]) {
            imp = impacts[1].impact
            col = impacts[1].colorCode
          } else {
            imp = impacts[0].impact
            col = impacts[0].colorCode
          }

          result.push(res)
          impact.push(imp)
          colorCode.push(col)
        })

        indicators[index1].assessmentArea[index2] = {
          ...indicators[index1].assessmentArea[index2],
          result: result,
          impact: impact,
          colorCode: colorCode,
        }
      })
    })

    this.setState({ indicators })
  }

  render() {
    const { classes } = this.props
    const {
      coords,
      shapeData,
      indicators,
      showTable,
      inputData,
      mapView,
      animationLoading,
    } = this.state

    const progress = (
      <div className={classes.progress}>
        <CircularProgress color="primary" size={42} />
      </div>
    )

    const ambientLayer = Object.keys(inputData).map(key => {
      return (
        inputData[key].category === 'Ambient' &&
        inputData[key].checked && (
          <MapLayer
            key={key}
            layer={inputData[key]}
            //map={this.mapRef.current}
          />
        )
      )
    })

    const impactCircles =
      !isNull(this.state.index) &&
      this.state.indicators[this.state.index].assessmentArea
        .slice(0)
        .reverse()
        .map((area, i) => {
          return (
            <Pane key={area.id} style={{ zIndex: 250 + i }}>
              {this.state.coords.lat.map((dum, i2) => {
                return (
                  <Circle
                    key={i2.toString()}
                    center={[coords.lat[i2], coords.lng[i2]]}
                    fillColor={area.colorCode[i2]}
                    stroke={true}
                    weight={1}
                    color={'black'}
                    fillOpacity={area.id === '1km-to-10km' ? 0.7 : 1}
                    radius={area.radius}
                  />
                )
              })}
            </Pane>
          )
        })

    const tools = (
      <FeatureGroup>
        <EditControl
          // This creates the drawControl handling the featureGroup
          position="topleft"
          onMounted={this.onDrawMount}
          onCreated={e => this.onDrawCreate(e)}
          onEdited={e => this.onDrawEdit(e)}
          onDeleted={e => this.onDrawDelete(e)}
          edit={{
            edit: true,
            remove: true,
          }}
          draw={{
            polygon: false,
            rectangle: false,
            polyline: false,
            circle: false,
            marker: { repeatMode: true }, //allow for several markers
            circlemarker: false,
          }}
        />
      </FeatureGroup>
    )

    const measure = <MeasureControl {...measureOptions} />

    const shapefile = shapeData !== null && (
      <GeoJSON
        key={Math.random()
          .toString(36)
          .substr(2, 5)}
        data={shapeData}
      />
    )

    return (
      <AppContext.Provider
        value={{
          state: this.state,
          functions: {
            updateInfo: this.updateInfo,
            updateDomain: this.updateDomain,
            updateCoords: this.updateCoords,
            makeGeojson: this.makeGeojson,
            removeGeojson: this.removeGeojson,
            updateInputData: this.updateInputData,
            calcImpacts: this.calcImpacts,
            initInputData: this.initInputData,
            clearAmbient: this.clearAmbient,
            fetchAmbient: this.fetchAmbient,
            displayAmbientMap: this.displayAmbientMap,
            updateThreshold: this.updateThreshold,
            onIndicatorCheck: this.onIndicatorCheck,
            resetIndicatorDisplay: this.resetIndicatorDisplay,
            updateDrawStatus: this.updateDrawStatus,
            toggleTable: this.toggleTable,
            removeTable: this.removeTable,
          },
        }}
      >
        <div
          className={classNames(classes.contentDrawerOpen, {
            [classes.content]: !showTable,
            [classes.chart]: showTable,
          })}
        >
          {showTable ? (
            <div className={classes.table}>
              <ImpactTable indicators={indicators} coords={this.state.coords} />
            </div>
          ) : (
            <Map
              animationLoading={animationLoading}
              config={mapView}
              componentRef={this.map}
            >
              {ambientLayer}
              {impactCircles}
              {tools}
              {measure}
              {shapefile}
              {animationLoading && progress}
            </Map>
          )}

          <Drawer>
            <WizardDrawer
              resetIndicatorDisplay={this.resetIndicatorDisplay}
              calcImpacts={this.calcImpacts}
              initInputData={this.initInputData}
              removeTable={this.removeTable}
              coords={this.state.coords}
            />
          </Drawer>
        </div>
      </AppContext.Provider>
    )
  }
}

export default withStyles(styles)(LegacyFishfarm)
