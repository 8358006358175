import React from 'react'
import { useParams } from 'react-router-dom'

import View from 'containers/Dilution/partials/View'

import useScenario from 'containers/MarineExplorer/hooks/useScenario'
import useResults from './hooks/useResults'

const Viewer = ({ apiInfo }) => {
  const { baseURL } = apiInfo
  const { id } = useParams()
  const { scenario } = useScenario({ id, baseURL })
  const content = useResults({ id, ...apiInfo })
  return (
    <View
      scenario={scenario}
      content={{ ...content, heading: 'Report' }}
      shapefile
      isolines
      vectorlines
    />
  )
}

export default Viewer
