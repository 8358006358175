import { directionColumn } from 'styles/mixins'

export default () => ({
  drawerContentWrapper: {
    ...directionColumn,
    padding: '2rem',
    flexGrow: '1',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
  },
})
