//const HOST = 'http://localhost:81';
const HOST = 'http://51.144.61.36/dataviewer';

const GEOGRAPHICAL_DOMAINS = [
  {
    id: 'All',
    label: 'Show all',
    lat: 56,
    lng: 13.5,
    zoom: 7,
  },
  {
    id: 'IDW',
    label: 'Inner Danish Waters',
    lat: 55.55,
    lng: 12.75,
    zoom: 8,
  },
  {
    id: 'KT',
    label: 'Kattegat',
    lat: 56.7,
    lng: 11.5,
    zoom: 9,
  },
  {
    id: 'LF',
    label: 'Limfjorden',
    lat: 56.85,
    lng: 10,
    zoom: 9,
  },
  {
    id: 'TS',
    label: 'The Sound',
    lat: 55.75,
    lng: 13,
    zoom: 10,
  },
  {
    id: 'NS',
    label: 'North Sea',
    lat: 56,
    lng: 9,
    zoom: 6,
  },
  {
    id: 'BS',
    label: 'Baltic Sea',
    lat: 58.5,
    lng: 24,
    zoom: 6,
  },
];

const SITE_TYPES = [
  {
    id: 'draw',
    label: 'Draw the site (point or polygon)',
  },
  {
    id: 'shape',
    label: 'Load a site shapefile',
  },
];

const LAYERS = {
  // BSH: https://www.mdi-de.org/mapapps/?lang=en

  step2: {
    layer1: {
      name: 'Bathing Sites (HELCOM)',
      domains: 'All,IDW,LF,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '279',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      token: '',
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=279',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/28f8019f-66f1-4fa5-87e0-d6e34f0ff695',
      // https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer2: {
      name: 'Fish Farms (HELCOM)',
      domains: 'All,IDW,LF,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '23',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      token: '',
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=23',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/9321a816-57fb-4d71-afd8-c3ad9c548c9c',
      // https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer3: {
      name: 'Offshore Windfarms in DK (ENS)',
      domains: 'All,IDW,LF,KT,TS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'havvindmoellepark,havvindmoelle',
      attribution:
        '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>',
      format: 'image/png',
      transparent: true,
      token: '54368cd0cb4a0fce951c27932453e4e9', // Kortforsyningen (bruger: dhi-dk-eed)
      url: 'https://services.kortforsyningen.dk/havvind',
      legend:
        'https://services.kortforsyningen.dk/havvind?token=54368cd0cb4a0fce951c27932453e4e9&version=1.3.0&service=WMS&request=GetLegendGraphic&layer=havvindmoellepark&format=image/png&STYLE=default',
      info:
        'https://www.geodata-info.dk/srv/dan/catalog.search;jsessionid=A06D2BD114BC89C8063A6D7F102E8F0A#/metadata/dd1a13e8-8551-49bb-9e9f-f4198998edfd',
      //info: 'https://sdfe.dk/data-skaber-vaerdi/nyheder/nyhedsarkiv/2018/jun/data-om-havvindmoeller-lanceres-paa-kortforsyningen/',
      // https://services.kortforsyningen.dk/service?request=GetCapabilities&version=1.1.1&ticket=ffd7f89b20b0a2bed843efdbba764870&servicename=havvind&service=WMS
    },
    layer4: {
      name: 'Offshore Renewables (OSPAR)',
      domains: 'All,NS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'geonode:ospar_offshore_renewables_2018_01_002',
      attribution:
        '&copy; <a target="_blank" href="https://odims.ospar.org/data_policy.html</a>',
      format: 'image/png',
      transparent: true,
      token: '',
      url: 'https://odims.ospar.org/geoserver/ows',
      legend:
        'https://odims.ospar.org/geoserver/geonode/ospar_offshore_renewables_2018_01_002/wms?request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=ospar_offshore_renewables_2018_01_002',
      info:
        'https://odims.ospar.org/layers/geonode:ospar_offshore_renewables_2018_01_002',
      // https://odims.ospar.org/geoserver/ows?service=WMS&request=GetCapabilities
    },
    layer5: {
      name: 'Wind Farms (HELCOM)',
      domains: 'All,LF,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '239',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=239',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/80de3bc3-e3ec-474e-8ae8-4b29c205eb0a',
      // https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer6: {
      name: 'Pipelines in EU (EMODnet)',
      domains: 'All,IDW,LF,KT,TS,NS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'pipelines',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-humanactivities.eu/wms',
      legend:
        'https://ows.emodnet-humanactivities.eu/geoserver/emodnet/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=pipelines',
      info:
        'https://www.emodnet-humanactivities.eu/search-results.php?dataname=Pipelines',
      // https://ows.emodnet-humanactivities.eu/wms?request=GetCapabilities&service=WMS
    },
    layer7: {
      name: 'Pipelines (HELCOM)',
      domains: 'All,IDW,KT,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '249',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=249',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/5260249e-5850-431a-b130-3a096abac852',
      // https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer8: {
      name: 'Offshore Installations (OSPAR)',
      domains: 'All,NS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'geonode:ospar_offshore_installations_2005_01_001',
      attribution:
        '&copy; <a target="_blank" href="https://odims.ospar.org/data_policy.html</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://odims.ospar.org/geoserver/ows',
      legend:
        'https://odims.ospar.org/geoserver/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=geonode%3Aospar_offshore_installations_2005_01_001',
      info:
        'https://odims.ospar.org/layers/geonode:ospar_offshore_installations_2005_01_001',
      // https://odims.ospar.org/geoserver/ows?service=WMS&request=GetCapabilities
    },
    layer9: {
      name: 'Oil Platforms (HELCOM)',
      domains: 'All,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '250',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=250',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/fddc27ec-b6ae-407b-9dd8-f31a42d75412',
      // https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer10: {
      name: 'Telecommunication Cables (OSPAR)',
      domains: 'All,IDW,LF,TS,NS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'geonode:emodnet_cablesschematic',
      attribution:
        '&copy; <a target="_blank" href="https://odims.ospar.org/data_policy.html</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://odims.ospar.org/geoserver/ows',
      legend:
        'https://odims.ospar.org/geoserver/geonode/emodnet_cablesschematic/wms?request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=emodnet_cablesschematic',
      info: 'https://odims.ospar.org/layers/geonode:emodnet_cablesschematic',
      // https://odims.ospar.org/geoserver/ows?service=WMS&request=GetCapabilities
    },
    layer11: {
      name: 'Underwater Cables (HELCOM)',
      domains: 'All,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '277',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=277',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/c0e73e71-cafb-4422-a3a3-115687fd5c49',
      // https://maps.helcom.fi/arcgis/services/MADS/Pressures/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer12: {
      name: 'Vessel Density Mapping (EMODnet)',
      domains: 'All,IDW,LF,TS,NS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '2017_st_All_avg',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-humanactivities.eu/wms',
      legend:
        'https://ows.emodnet-humanactivities.eu/geoserver/emodnet/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=2017_st_All_avg',
      info:
        'https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+',
      // https://odims.ospar.org/geoserver/ows?service=WMS&request=GetCapabilities
    },
    layer12b: {
      name: 'Vessel Density Mapping (OSPAR)',
      domains: '', //'All,IDW,LF,TS,NS,BS',
      type: 'wms',
      checked: false,
      disabled: true,
      layers: 'geonode:vesseldensitytest',
      attribution:
        '&copy; <a target="_blank" href="https://odims.ospar.org/data_policy.html</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://odims.ospar.org/geoserver/ows',
      legend: '',
      info: 'https://odims.ospar.org/layers/geonode:vesseldensitytest',
      // https://odims.ospar.org/geoserver/ows?service=WMS&request=GetCapabilities
    },
    layer13: {
      name: 'Shipping Density (HELCOM)',
      domains: 'All,LF,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '182',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=182',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/95c5098e-3a38-48ee-ab16-b80a99f50fef',
      // https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
  },
  step3: {
    layer1: {
      name: 'Multicolor Bathymetry (EMODnet)',
      domains: 'All,IDW,KT,LF,TS,NS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'emodnet:mean_multicolour,emodnet:contours',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'http://ows.emodnet-bathymetry.eu/ows',
      legend: 'https://ows.emodnet-bathymetry.eu/styles/legend_multicolour.png',
      info: 'https://portal.emodnet-bathymetry.eu/help/help.html#006',
      // http://ows.emodnet-bathymetry.eu/wms?request=GetCapabilities&service=WMS
    },
    layer2: {
      name: 'Baltic Sea Bathy. Database (HELCOM)',
      domains: 'All,BS,IDW,KT,TS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '1,3',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Background/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Background/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=1',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/8b46e4c7-f911-44ab-89e6-2c8b8d9fa2c0',
      // https://maps.helcom.fi/arcgis/services/MADS/Background/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer3: {
      name: 'Bathymetry 500x500m (FEHY/DHI)', // Slow
      domains: '', //'All,IDW,LF,KT,TS',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfs2-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'deep1',
      item: 1,
      filePath: '500x500_crop_noland2_depths.dfs2',
      timestamp: '2003-01-01T00:00:00',
      scale: 2000,
      legendValuePostFix: `m`,
      legendGradient: [
        { value: '> 100', color: '#271a2c' },
        { value: '80 - 100', color: '#362b4d' },
        { value: '60 - 80', color: '#403c73' },
        { value: '50 - 60', color: '#3e528f' },
        { value: '40 - 50', color: '#3e6c96' },
        { value: '30 - 40', color: '#44829b' },
        { value: '20 - 30', color: '#4c99a0' },
        { value: '15 - 20', color: '#56b1a3' },
        { value: '10 - 15', color: '#6fc9a3' },
        { value: '5 - 10', color: '#9cdba5' },
        { value: '2 - 5', color: '#ceecb3' },
        { value: '≤ 2', color: '#fdfdcc' },
      ],
    },
    layer4: {
      name: 'Local Bathy. 50mx50m (FEHY/DHI)',
      domains: 'All,IDW,TS,KT',
      type: 'wms-terracotta',
      checked: false,
      disabled: false,
      layers: '',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: '',
      transparent: true,
      url:
        'https://aa8516sut5.execute-api.eu-central-1.amazonaws.com/production/singleband/THU/EED/20190508/50mBathyDK/{z}/{x}/{y}.png?colormap=ylgnbu_r&stretch_range=' +
        JSON.stringify([-50, 0]),
      legend: '',
    },
    layer5: {
      name: 'Seabed Sediment Map in DK (GEUS)',
      domains: 'All,IDW,KT,LF,TS,NS',
      type: 'wms-single-tile',
      checked: false,
      disabled: false,
      layers: 'sediment_250000_eng',
      attribution:
        '&copy; <a href="https://www.geus.dk/produkter-ydelser-og-faciliteter/data-og-kort/">GEUS</a>',
      format: 'image/png',
      transparent: true,
      url:
        'http://data.geus.dk/geusmap/ows/25832.jsp?nocache=nocache&whoami=193.3.62.162&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&STYLES=&SRS=EPSG:3857&',
      legend: 'https://data.geus.dk/geusmap/get_legend.jsp?layer=15',
      info: 'https://data.geus.dk/MetaVis/Klik.jsp?id=2167',
      // http://data.geus.dk/geusmap/ows/25832.jsp?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetCapabilities
    },
    layer6: {
      name: 'Seabed Sediments (HELCOM)',
      domains: 'All,LF,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '29',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=29',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/41f4f5ca-4d07-4b76-b8ed-8ac2739d57a6',
      // https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer7: {
      name: 'Benthic Marine Landscapes (HELCOM)',
      domains: 'All,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '40',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=40',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/ddb8070e-ee77-4108-a1ab-4ce46afa19fe',
      // https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer8: {
      name: 'EUSeaMap Substrate (EMODnet)',
      domains: 'All,IDW,KT,LF,TS,NS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'eusm2019_subs_group',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms',
      legend:
        'https://ows.emodnet-seabedhabitats.eu:443/emodnet_view/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=emodnet_view%3Aeusm2019_subs_800',
      info:
        'http://gis.ices.dk/geonetwork/srv/eng/catalog.search#/metadata/f7d5a168-0097-4437-944e-cc63111d15c6',
      // https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms?request=GetCapabilities&service=WMS
      // https://ows.emodnet-seabedhabitats.eu/wms?REQUEST=GetFeatureInfo&SERVICE=WMS&SRS=EPSG%3A4326&STYLES=&TRANSPARENT=true&VERSION=1.1&FORMAT=image%2Fpng&BBOX=6.130371093750001%2C53.22576843579022%2C17.863769531250004%2C58.59116158895479&HEIGHT=873&WIDTH=1068&LAYERS=emodnet_view%3Aeusm2016&QUERY_LAYERS=emodnet_view%3Aeusm2016&INFO_FORMAT=text%2Fhtml&X=282&Y=224
    },
    layer9: {
      name: 'EUSeaMap Biozones (EMODnet)',
      domains: 'All,IDW,KT,LF,TS,NS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'eusm2019_bio_group',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms',
      legend:
        'https://ows.emodnet-seabedhabitats.eu:443/emodnet_view/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=emodnet_view%3Aeusm2019_bio_800',
      info:
        'http://gis.ices.dk/geonetwork/srv/eng/catalog.search#/metadata/7a11d605-b1f1-41da-997c-967dfee7c51f',
      // https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms?request=GetCapabilities&service=WMS
      // https://ows.emodnet-seabedhabitats.eu/wms?REQUEST=GetFeatureInfo&SERVICE=WMS&SRS=EPSG%3A4326&STYLES=&TRANSPARENT=true&VERSION=1.1&FORMAT=image%2Fpng&BBOX=6.130371093750001%2C53.22576843579022%2C17.863769531250004%2C58.59116158895479&HEIGHT=873&WIDTH=1068&LAYERS=emodnet_view%3Aeusm2016&QUERY_LAYERS=emodnet_view%3Aeusm2016&INFO_FORMAT=text%2Fhtml&X=282&Y=224
    },
    layer10: {
      name: 'Current Speed, 50th perc., 2016 (DHI)', //surface
      domains: 'All,NS',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfsu-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'speed1',
      item: 3,
      filePath: 'UKNS2_Surface_UVST_2D_2016_CurSpeed_percentiles.dfsu',
      timestamp: '2016-01-01T00:00:00',
      scale: 2000,
      legendValuePostFix: `m/s`,
      legendGradient: [
        { value: '> 1.1', color: '#172312' },
        { value: '1 - 1.1', color: '#193822' },
        { value: '0.9 - 1', color: '#124e2b' },
        { value: '0.8 - 0.9', color: '#0b642c' },
        { value: '0.7 - 0.8', color: '#237924' },
        { value: '0.6 - 0.7', color: '#4b8a14' },
        { value: '0.5 - 0.6', color: '#749905' },
        { value: '0.4 - 0.5', color: '#9da715' },
        { value: '0.3 - 0.4', color: '#c2b63b' },
        { value: '0.2 - 0.3', color: '#ddc96a' },
        { value: '0.1 - 0.2', color: '#efe19c' },
        { value: '≤ 0.1', color: '#fefccd' },
      ],
    },
    layer11: {
      name: 'Current Speed, 95th perc., 2016 (DHI)', //surface
      domains: 'All,NS',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfsu-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'speed1',
      item: 4,
      filePath: 'UKNS2_Surface_UVST_2D_2016_CurSpeed_percentiles.dfsu',
      timestamp: '2016-01-01T00:00:00',
      scale: 2000,
      legendValuePostFix: `m/s`,
      legendGradient: [
        { value: '> 1.1', color: '#172312' },
        { value: '1 - 1.1', color: '#193822' },
        { value: '0.9 - 1', color: '#124e2b' },
        { value: '0.8 - 0.9', color: '#0b642c' },
        { value: '0.7 - 0.8', color: '#237924' },
        { value: '0.6 - 0.7', color: '#4b8a14' },
        { value: '0.5 - 0.6', color: '#749905' },
        { value: '0.4 - 0.5', color: '#9da715' },
        { value: '0.3 - 0.4', color: '#c2b63b' },
        { value: '0.2 - 0.3', color: '#ddc96a' },
        { value: '0.1 - 0.2', color: '#efe19c' },
        { value: '≤ 0.1', color: '#fefccd' },
      ],
    },
    layer12: {
      name: 'Surface Salinity, Mean, 2016 (DHI)',
      domains: 'All,LF',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfsu-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'haline1',
      item: 3,
      filePath: 'Limfjord_HD81_Surface_UVTS_2D_2016_Sstat.dfsu',
      timestamp: '2016-01-01T00:00:00',
      scale: 2000,
      legendValuePostFix: `PSU`,
      legendGradient: [
        { value: '> 30', color: '#fdee99' },
        { value: '27.5 - 30', color: '#d4e170' },
        { value: '25 - 27.5', color: '#a0d65b' },
        { value: '22.5 - 25', color: '#6fc66b' },
        { value: '20 - 22.5', color: '#51b27c' },
        { value: '17.5 - 20', color: '#419d85' },
        { value: '15 - 17.5', color: '#358888' },
        { value: '12.5 - 15', color: '#267489' },
        { value: '10 - 12.5', color: '#125f8e' },
        { value: '7.5 - 10', color: '#0f4799' },
        { value: '5 - 7.5', color: '#2a23a0' },
        { value: '≤ 5', color: '#29186b' },
      ],
    },
    layer13: {
      name: 'Surface Salinity, St.Dev., 2016 (DHI)',
      domains: 'All,LF',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfsu-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'haline2',
      item: 4,
      filePath: 'Limfjord_HD81_Surface_UVTS_2D_2016_Sstat.dfsu',
      timestamp: '2016-01-01T00:00:00',
      scale: 2000,
      legendValuePostFix: `PSU`,
      legendGradient: [
        { value: '> 5.5', color: '#fdee99' },
        { value: '5 - 5.5', color: '#d4e170' },
        { value: '4.5 - 5', color: '#a0d65b' },
        { value: '4 - 4.5', color: '#6fc66b' },
        { value: '3.5 - 4', color: '#51b27c' },
        { value: '3 - 3.5', color: '#419d85' },
        { value: '2.5 - 3', color: '#358888' },
        { value: '2 - 2.5', color: '#267489' },
        { value: '1.5 - 2', color: '#125f8e' },
        { value: '1 - 1.5', color: '#0f4799' },
        { value: '0.5 - 1', color: '#2a23a0' },
        { value: '≤ 0.5', color: '#29186b' },
      ],
    },
    layer14: {
      name: 'Wave Height, 50th perc., 2016 (DHI)', //Hs
      domains: 'All,IDW,KT,TS,BS',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfsu-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      token: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'amp1',
      item: 3,
      filePath: 'DKBS2sw_Total_percentiles.dfsu',
      timestamp: '2016-01-01T06:00:00',
      scale: 2000,
      legendValuePostFix: `m`,
      legendGradient: [
        { value: '> 2.75', color: '#3c0911' },
        { value: '2.5 - 2.75', color: '#590d1f' },
        { value: '2.25 - 2.5', color: '#780e28' },
        { value: '2 - 2.25', color: '#951327' },
        { value: '1.75 - 2', color: '#ac2c24' },
        { value: '1.5 - 1.75', color: '#ba4a2f' },
        { value: '1.25 - 1.5', color: '#c46649' },
        { value: '1 - 1.25', color: '#cd8167' },
        { value: '0.75 - 1', color: '#d59c89' },
        { value: '0.5 - 0.75', color: '#ddb6aa' },
        { value: '0.25 - 0.5', color: '#e6d1cb' },
        { value: '≤ 0.25', color: '#f1ecec' },
      ],
    },
    layer15: {
      name: 'Wave Height, 95th perc., 2016 (DHI)', //Hs
      domains: 'All,IDW,KT,TS,BS',
      type: 'wms-dhi',
      checked: false,
      disabled: false,
      layers: 'dfsu-map',
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      format: 'image/png',
      transparent: true,
      url: HOST + '/api/map',
      legend: '',
      // DHI specific stuff:
      version: '1.3.0',
      styles: 'amp1',
      item: 4,
      filePath: 'DKBS2sw_Total_percentiles.dfsu',
      timestamp: '2016-01-01T06:00:00',
      scale: 2000,
      legendValuePostFix: `m`,
      legendGradient: [
        { value: '> 2.75', color: '#3c0911' },
        { value: '2.5 - 2.75', color: '#590d1f' },
        { value: '2.25 - 2.5', color: '#780e28' },
        { value: '2 - 2.25', color: '#951327' },
        { value: '1.75 - 2', color: '#ac2c24' },
        { value: '1.5 - 1.75', color: '#ba4a2f' },
        { value: '1.25 - 1.5', color: '#c46649' },
        { value: '1 - 1.25', color: '#cd8167' },
        { value: '0.75 - 1', color: '#d59c89' },
        { value: '0.5 - 0.75', color: '#ddb6aa' },
        { value: '0.25 - 0.5', color: '#e6d1cb' },
        { value: '≤ 0.25', color: '#f1ecec' },
      ],
    },
    layer16: {
      name: 'Baltic Sea Ice Maximum (HELCOM)',
      domains: 'All,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '36',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=36',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/bd7dcba8-3e12-4f2d-87c9-4593e1ceab9b',
      // https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer17: {
      name: 'Test animation',
      domains: '', //'All,TS',
      type: 'auto-anim',
      checked: false,
      disabled: true,
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      legend: '',

      // DHI specific stuff
      legendValuePostFix: `Counts/100ml`,
      legendGradient: [
        { value: '> 10000', color: '#3c0911' },
        { value: '5000 - 10000', color: '#590d1f' },
        { value: '2000 - 5000', color: '#780e28' },
        { value: '1000 - 2000', color: '#951327' },
        { value: '500 - 1000', color: '#ac2c24' },
        { value: '200 - 500', color: '#ba4a2f' },
        { value: '100 - 200', color: '#c46649' },
        { value: '50 - 100', color: '#cd8167' },
        { value: '20 - 50', color: '#d59c89' },
        { value: '10 - 20', color: '#ddb6aa' },
        { value: '5 - 10', color: '#e6d1cb' },
        { value: '≤ 5', color: '#f1ecec' },
      ],

      // LeafletAnimation specific stuff
      dataSource: {
        host: 'http://192.43.239.120/WebAPIDemo',
        connection: 'dfsu-mapsource',
        style: 'Ecoli',
        item: 3,
        ids: {
          '2018-09-12T12:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T12:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T13:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T13:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T14:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T14:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T15:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T15:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T16:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T16:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T17:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T17:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T18:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T18:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T19:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T19:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T20:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T20:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T21:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T21:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T22:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T22:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T23:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T23:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-13T00:00:00': 'KBHEC3dF012.dfsu',
        },
      },
    },
    layer18: {
      name: 'Test animation 2',
      domains: '', //'All,TS',
      type: 'auto-anim',
      checked: false,
      disabled: true,
      attribution:
        '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
      legend: '',

      // DHI specific stuff
      legendValuePostFix: `Counts/100ml`,
      legendGradient: [
        { value: '> 10000', color: '#3c0911' },
        { value: '5000 - 10000', color: '#590d1f' },
        { value: '2000 - 5000', color: '#780e28' },
        { value: '1000 - 2000', color: '#951327' },
        { value: '500 - 1000', color: '#ac2c24' },
        { value: '200 - 500', color: '#ba4a2f' },
        { value: '100 - 200', color: '#c46649' },
        { value: '50 - 100', color: '#cd8167' },
        { value: '20 - 50', color: '#d59c89' },
        { value: '10 - 20', color: '#ddb6aa' },
        { value: '5 - 10', color: '#e6d1cb' },
        { value: '≤ 5', color: '#f1ecec' },
      ],

      // LeafletAnimation specific stuff
      dataSource: {
        host: 'http://192.43.239.120/WebAPIDemo',
        connection: 'dfsu-mapsource',
        style: 'Ecoli',
        item: 3,
        ids: {
          '2018-09-12T12:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T12:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T13:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T13:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T14:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T14:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T15:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T15:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T16:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T16:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T17:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T17:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T18:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T18:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T19:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T19:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T20:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T20:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T21:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T21:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T22:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T22:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T23:00:00': 'KBHEC3dF012.dfsu',
          '2018-09-12T23:30:00': 'KBHEC3dF012.dfsu',
          '2018-09-13T00:00:00': 'KBHEC3dF012.dfsu',
        },
      },
    },
  },
  step4: {
    layer1: {
      name: 'Natura 2000 Habitats DK (Miljøportalen)',
      domains: 'All,IDW,KT,LF,TS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'HABITAT_OMR',
      attribution:
        '&copy; <a href="http://www.miljoeportal.dk/Sider/DAIdisclaimer.aspx">Miljøportalen</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer',
      legend:
        'https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=HABITAT_OMR',
      info:
        'https://geodata-info.dk/srv/dan/catalog.search#/metadata/cf11d200-9425-481a-ba9b-f5dc77f95026',
      // https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer2: {
      name: 'Natura 2000 Birds DK (Miljøportalen)',
      domains: 'All,IDW,KT,LF,TS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'FUGLE_BES_OMR',
      attribution:
        '&copy; <a href="http://www.miljoeportal.dk/Sider/DAIdisclaimer.aspx">Miljøportalen</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer',
      legend:
        'https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=FUGLE_BES_OMR',
      info:
        'https://geodata-info.dk/srv/dan/catalog.search#/metadata/a41eda70-0705-4641-8013-bb7348383eb7',
      // https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer3: {
      name: 'Natura 2000 Sites EU (EMODnet)',
      domains: 'All,NS,BS,IDW,KT,TS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'natura2000areas',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-humanactivities.eu/wms',
      legend:
        'https://ows.emodnet-humanactivities.eu/geoserver/emodnet/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=natura2000areas',
      info:
        'https://www.emodnet-humanactivities.eu/search-results.php?dataname=Natura+2000',
      // https://ows.emodnet-humanactivities.eu/wms?request=GetCapabilities&service=WMS
    },
    layer4: {
      name: "HELCOM MPA's (HELCOM)",
      domains: 'All,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '25',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=25',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/d27df8c0-de86-4d13-a06d-35a8f50b16fa',
      // https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer5: {
      name: 'Seagrass Meadows Point (HELCOM)',
      domains: 'All,LF,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '14',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=14',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/6a0c8a15-3ff0-48e3-a878-0aa388122867',
      // https://maps.helcom.fi/arcgis/services/MADS/Shipping/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer6: {
      name: 'Zostera Marina Distribution (HELCOM)',
      domains: 'All,LF,IDW,KT,TS,BS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: '320',
      attribution:
        '&copy; <a target="_blank" href="http://www.helcom.fi/baltic-sea-trends/data-maps/#About%20the%20data">HELCOM</a>',
      format: 'image/png',
      transparent: true,
      url:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer',
      legend:
        'https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=320',
      info:
        'http://metadata.helcom.fi/geonetwork/srv/eng/catalog.search#/metadata/ca327bb1-d3cb-46c2-8316-f5f62f889090',
      // https://maps.helcom.fi/arcgis/services/MADS/Biodiversity/MapServer/WMSServer?request=GetCapabilities&service=WMS
    },
    layer7: {
      name: 'Macroalgae (EMODnet, in progress)',
      domains: 'All,NS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'eov_macroalgae',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms',
      legend:
        'https://ows.emodnet-seabedhabitats.eu:443/emodnet_view/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=eov_macroalgae',
      info:
        'http://gis.ices.dk/geonetwork/srv/eng/catalog.search#/metadata/913a0ee4-45d7-45aa-8de2-3d31af0f7c0e',
      // https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms?request=GetCapabilities&service=WMS
    },
    layer8: {
      name: 'Seagrass (EMODnet, in progress)',
      domains: 'All,NS',
      type: 'wms',
      checked: false,
      disabled: false,
      layers: 'eov_seagrass',
      attribution:
        '&copy; <a target="_blank" href="http://www.emodnet.eu/what-emodnet">EMODnet</a>',
      format: 'image/png',
      transparent: true,
      url: 'https://ows.emodnet-seabedhabitats.eu/emodnet_view/wms',
      legend:
        'https://ows.emodnet-seabedhabitats.eu:443/emodnet_view/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=eov_seagrass',
      info:
        'http://gis.ices.dk/geonetwork/srv/eng/catalog.search#/metadata/39746d9c-4220-425c-bc26-7cb3056c36a5',
      // https://ows.emodnet-seabedhabitats.eu/wms?request=GetCapabilities&service=WMS
    },
  },
};

export { HOST, GEOGRAPHICAL_DOMAINS, SITE_TYPES, LAYERS };
