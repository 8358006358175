import React, { PureComponent } from 'react'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

import Axios from 'axios'

import API from '@dhi-solutions/domain-services'

const enhancer = compose(withSnackbar, withRouter)

class Communicator extends PureComponent {
  static getDerivedStateFromError(error) {
    console.error('error', error)
  }

  componentDidCatch = (error, errorMessage) => {
    console.error('error', error)
    console.error('errorMessage', errorMessage)
  }

  requestInterceptor = null
  responseInterceptor = null

  componentDidMount() {
    this.requestInterceptor = API.interceptors.request.use(
      this.handleRequestData,
      this.handleRequestError
    )
    this.responseInterceptor = API.interceptors.response.use(
      this.handleResponseData,
      this.handleResponseError
    )
  }

  componentWillUnmount() {
    this.requestInterceptor = null
    this.responseInterceptor = null
    API.interceptors.request.eject(this.requestInterceptor)
    API.interceptors.response.eject(this.responseInterceptor)
  }

  handleRequestData = (config) => {
    const { headers } = config
    const { isMajorLoading } = headers
    this.handleStartLoading({ isMajorLoading: !!isMajorLoading })
    return config
  }

  handleResponseData = (response) => {
    this.handleStopLoading()
    return response
  }

  handleRequestError = (error) => this.handleExtractResponseMessage(error)

  handleResponseError = (error) => {
    const isCancel = Axios.isCancel(error)
    if (!isCancel) {
      return this.handleExtractResponseMessage(error)
    }
  }

  handleExtractResponseMessage = (error) => {
    const { response } = error
    const { data, statusText, status } = response
    const { message = '' } = data
    const redirect = status === 401

    this.handleError({
      errorMessage: statusText || message,
      redirect,
    })
  }

  handleError = ({ errorMessage, redirect }) => {
    const { enqueueSnackbar, history } = this.props
    this.handleStopLoading()
    if (redirect) {
      return history.push('/logout')
    }
    return enqueueSnackbar(errorMessage, {
      variant: 'error',
    })
  }

  handleStartLoading = ({ isMajorLoading }) => {
    const { startLoading } = this.props
    startLoading({ isLoading: true, isMajorLoading })
  }

  handleStopLoading = () => {
    const { stopLoading } = this.props
    stopLoading()
  }

  render() {
    const { children } = this.props
    return <>{children}</>
  }
}

export default enhancer(Communicator)
