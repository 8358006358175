import { HOSTS } from 'config/constants'

const HOST = HOSTS.FISHFARM_SCREENING

const GEOGRAPHICAL_DOMAINS = [
  {
    id: 'All',
    label: 'Show all',
    lat: 56,
    lng: 13.5,
    zoom: 7,
  },
  {
    id: 'IDW',
    label: 'Inner Danish Waters',
    lat: 55.55,
    lng: 12.75,
    zoom: 8,
  },
  {
    id: 'KT',
    label: 'Kattegat',
    lat: 56.7,
    lng: 11.5,
    zoom: 9,
  },
  {
    id: 'LF',
    label: 'Limfjorden',
    lat: 56.85,
    lng: 10,
    zoom: 9,
  },
  {
    id: 'TS',
    label: 'The Sound',
    lat: 55.75,
    lng: 13,
    zoom: 10,
  },
  {
    id: 'NS',
    label: 'North Sea',
    lat: 56,
    lng: 9,
    zoom: 6,
  },
  {
    id: 'BS',
    label: 'Baltic Sea',
    lat: 58.5,
    lng: 24,
    zoom: 6,
  },
]

const INPUT_DATA = {
  Bed_shear: {
    id: 'Bed-shear',
    label: 'Bed shear stress (monthly av.)',
    unit: 'Pa',
    value: 0.02,
    defaultValue: 0.02,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-shear',
    item: 'Tau_b_xxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_shear.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  Bed_shear_2mo: {
    id: 'Bed-shear-2mo',
    label: 'Bed shear stress (2 months av.)',
    unit: 'Pa',
    value: 0.02,
    defaultValue: 0.02,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-shear',
    item: 'Tau_b_xxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_shear.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  C_Ld_3mo: {
    id: 'C-Ld-3mo',
    label: 'Load of organic carbon (3 months acc.)',
    unit: 'tonnes',
    value: 34.5,
    defaultValue: 34.5,
    values: [],
    category: 'Load',
  },
  C_Ld_4mo: {
    id: 'C-Ld-4mo',
    label: 'Load of organic carbon (4 months acc.)',
    unit: 'tonnes',
    value: 46,
    defaultValue: 46,
    values: [],
    category: 'Load',
  },
  N_Ld_3mo: {
    id: 'N-Ld-3mo',
    label: 'Load of organic nitrogen (3 months acc.)',
    unit: 'tonnes',
    value: 2.7,
    defaultValue: 2.7,
    values: [],
    category: 'Load',
  },
  P_Ld_2mo: {
    id: 'P-Ld-2mo',
    label: 'Load of organic phosphorous (2 months acc.)',
    unit: 'tonnes',
    value: 0.4,
    defaultValue: 0.4,
    values: [],
    compartment: 'Benthic',
    category: 'Load',
  },
  Bot_temp: {
    id: 'Bot-temp',
    label: 'Bottom water temperature (monthly av.)',
    unit: '°C',
    value: 20,
    defaultValue: 20,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-bottemp',
    item: 'Temp_xxxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_bottemp.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  Bot_temp_2mo: {
    id: 'Bot-temp-2mo',
    label: 'Bottom water temperature (2 months av.)',
    unit: '°C',
    value: 20,
    defaultValue: 20,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-bottemp',
    item: 'Temp_xxxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_bottemp.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  Bot_cur: {
    id: 'Bot-cur',
    label: 'Bottom current speed (monthly av.)',
    unit: 'm/s',
    value: 0.07,
    defaultValue: 0.07,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-cur',
    item: 'Cur_xxxxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_cur.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  Surf_temp: {
    id: 'Surf-temp',
    label: 'Surface water temperature (monthly av.)',
    unit: '°C',
    value: 12,
    defaultValue: 12,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-surftemp',
    item: 'Temp_xxxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_surftemp.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  Surf_cur: {
    id: 'Surf-cur',
    label: 'Surface current speed (monthly av.)',
    unit: 'm/s',
    value: 0.15,
    defaultValue: 0.15,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-cur',
    item: 'Cur_xxxxxxxxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_cur.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  Day_length: {
    id: 'Day-length',
    label: 'Day length (monthly av.)',
    unit: 'hours',
    value: 16,
    defaultValue: 16,
    values: [],
    category: 'Ambient',

    // extract point
    host: HOST,
    connection: 'dfsu-ts-daylength',
    item: 'Daylength_xxxxxxxxxxxxxx',

    // display map
    checked: false,
    type: 'wms-dhi',
    url: HOST + '/map',
    layers: 'dfsu-map',
    format: 'image/png',
    token: '',
    attribution:
      '&copy; <a target="_blank" href="http://www.dhigroup.com">DHI</a>',
    version: '1.3.0',
    styles: 'amp1',
    filePath: 'DKBS2_daylength.dfsu',
    itemNo: 1,
    timestamp: '2016-01-01T06:00:00',
  },
  NH4_Ld: {
    id: 'NH4-Ld',
    label: 'Load of ammonium (1 month acc.)',
    unit: 'tonnes',
    value: 28,
    defaultValue: 28,
    values: [],
    category: 'Load',
  },
}

const IMPACTS = [
  {
    impact: 'Negligible',
    colorCode: 'blue',
  },
  {
    impact: 'Minor',
    colorCode: 'green',
  },
  {
    impact: 'Medium',
    colorCode: 'yellow',
  },
  {
    impact: 'High',
    colorCode: 'orange',
  },
  {
    impact: 'Very High',
    colorCode: 'red',
  },
]

const INDICATORS = [
  {
    id: 'SOC',
    label: 'Organic carbon concentration in sediment',
    disabled: false,
    checked: false,
    info: '',
    unit: 'g/m2',
    thresholds: [50, 100, 200, 500],
    decimals: 0,
    compartment: 'Benthic',
    assessmentArea: [
      {
        id: 'Cent',
        label: 'Centre footprint',
        radius: 174,
        intercept: -97.5,
        coefficients: [-18107, 0.282, 34.6],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Bed_shear', 'C_Ld_3mo', 'Bot_temp_2mo'],
  },
  {
    id: 'SON',
    label: 'Organic nitrogen concentration in sediment',
    disabled: false,
    checked: false,
    info: '',
    unit: 'g/m2',
    thresholds: [2, 5, 10, 50],
    decimals: 1,
    compartment: 'Benthic',
    assessmentArea: [
      {
        id: 'Cent',
        label: 'Centre footprint',
        radius: 174,
        intercept: -3.52,
        coefficients: [-1948, 0.268, 3.0],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Bed_shear', 'N_Ld_3mo', 'Bot_temp_2mo'],
  },
  {
    id: 'SOP',
    label: 'Organic phosphorous concentration in sediment',
    disabled: false,
    checked: false,
    info: '',
    unit: 'g/m2',
    thresholds: [0.5, 1, 2, 5],
    decimals: 2,
    compartment: 'Benthic',
    assessmentArea: [
      {
        id: 'Cent',
        label: 'Centre footprint',
        radius: 174,
        intercept: 0.37,
        coefficients: [-348, 0.293, -0.096],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Bed_shear', 'P_Ld_2mo', 'Bot_temp'],
  },
  {
    id: 'H2S-sed',
    label: 'Total sulphide concentration in sediment',
    disabled: false,
    checked: false,
    info: '',
    unit: 'mM',
    thresholds: [1, 2, 10, 50],
    decimals: 1,
    compartment: 'Benthic',
    assessmentArea: [
      {
        id: 'Cent',
        label: 'Centre footprint',
        radius: 174,
        intercept: -2.3,
        coefficients: [-208, 0.0031, 0.87, -66.0],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Bed_shear_2mo', 'C_Ld_4mo', 'Bot_temp_2mo', 'Bot_cur'],
  },
  {
    id: 'IN-flux',
    label: 'Inorganic nitrogen flux from sediment',
    disabled: false,
    checked: false,
    info: '',
    unit: 'g/m2/d',
    thresholds: [5, 20, 50, 100],
    decimals: 1,
    compartment: 'Benthic',
    assessmentArea: [
      {
        id: 'Cent',
        label: 'Centre footprint',
        radius: 174,
        intercept: -13.8,
        coefficients: [-309.5, 0.076, 2.41, -77.2],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Bed_shear_2mo', 'N_Ld_3mo', 'Bot_temp', 'Bot_cur'],
  },
  {
    id: 'DO-reduc',
    label: 'Dissolved oxygen reduction in near-bed water',
    disabled: false,
    checked: false,
    info: '',
    unit: 'mg/l',
    thresholds: [0.2, 0.5, 1.0, 4.0],
    decimals: 2,
    compartment: 'Benthic',
    assessmentArea: [
      {
        id: 'Cent-to-500m',
        label: 'Centre to 500m distance',
        radius: 500,
        intercept: -0.04,
        coefficients: [-5.21, 0.00014, 0.026, -3.68],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '500m-to-1000m',
        label: '500m to 1000m distance',
        radius: 1000,
        intercept: -0.016,
        coefficients: [-1.61, 0.000049, 0.0084, -1.36],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Bed_shear', 'C_Ld_3mo', 'Bot_temp', 'Bot_cur'],
  },
  {
    id: 'NH4-rel',
    label: 'Ammonium release',
    disabled: false,
    checked: false,
    info: '',
    unit: 'µg/l',
    thresholds: [1, 5, 20, 50],
    decimals: 1,
    compartment: 'Pelagic',
    assessmentArea: [
      {
        id: 'Cent-to-500m',
        label: 'Centre to 500m distance',
        radius: 500,
        intercept: -0.2,
        coefficients: [-14.8, 0.22, 0.26],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '500m-to-1000m',
        label: '500m to 1000m distance',
        radius: 1000,
        intercept: 0.79,
        coefficients: [-7.4, 0.071, 0.66],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Surf_cur', 'NH4_Ld', 'Surf_temp'],
  },
  {
    id: 'PP',
    label: 'Primary production',
    disabled: false,
    checked: false,
    info: '',
    unit: 'gC/m2/d',
    thresholds: [0.005, 0.01, 0.05, 0.2],
    decimals: 4,
    compartment: 'Pelagic',
    assessmentArea: [
      {
        id: 'Cent-to-500m',
        label: 'Centre to 500m distance',
        radius: 500,
        intercept: -0.011,
        coefficients: [0.00096, -0.025, 0.00049, 0.000065],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '500m-to-1000m',
        label: '500m to 1000m distance',
        radius: 1000,
        intercept: -0.013,
        coefficients: [0.0009, -0.021, 0.00041, 0.000085],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '1km-to-10km',
        label: '1km to 10km distance',
        radius: 10000,
        intercept: -0.0062,
        coefficients: [0.00054, -0.015, 0.00025, 0.000092],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Surf_temp', 'Surf_cur', 'Day_length', 'NH4_Ld'],
  },
  {
    id: 'CHL',
    label: 'Chlorophyll-a change',
    disabled: false,
    checked: false,
    info: '',
    unit: 'µgChl-a/l',
    thresholds: [0.01, 0.012, 0.015, 0.02],
    decimals: 4,
    compartment: 'Pelagic',
    assessmentArea: [
      {
        id: 'Cent-to-500m',
        label: 'Centre to 500m distance',
        radius: 500,
        intercept: -0.02,
        coefficients: [0.0027, -0.062, 0.00032],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '500m-to-1000m',
        label: '500m to 1000m distance',
        radius: 1000,
        intercept: -0.019,
        coefficients: [0.0026, -0.0359, 0.0003],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '1km-to-10km',
        label: '1km to 10km distance',
        radius: 10000,
        intercept: -0.02,
        coefficients: [0.0024, -0.035, 0.0003],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Surf_temp', 'Surf_cur', 'NH4_Ld'],
  },
  {
    id: 'SD',
    label: 'Secchi depth change',
    disabled: false,
    checked: false,
    info: '',
    unit: 'm',
    thresholds: [0.1, 0.5, 1.0, 2.0],
    decimals: 3,
    compartment: 'Pelagic',
    assessmentArea: [
      {
        id: 'Cent-to-500m',
        label: 'Centre to 500m distance',
        radius: 500,
        intercept: -0.014,
        coefficients: [0.0014, -0.039, 0.0006, 0.000052],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '500m-to-1000m',
        label: '500m to 1000m distance',
        radius: 1000,
        intercept: -0.013,
        coefficients: [0.0012, -0.034, 0.00051, 0.00008],
        result: [],
        impact: [],
        colorCode: [],
      },
      {
        id: '1km-to-10km',
        label: '1km to 10km distance',
        radius: 10000,
        intercept: -0.02,
        coefficients: [0.0008, -0.023, 0.0003, 0.000097],
        result: [],
        impact: [],
        colorCode: [],
      },
    ],
    inputDataKey: ['Surf_temp', 'Surf_cur', 'Day_length', 'NH4_Ld'],
  },
]

export { HOST, GEOGRAPHICAL_DOMAINS, INPUT_DATA, IMPACTS, INDICATORS }
