import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { AppBar, Toolbar, Typography, Box } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { Grid, withStyles } from '@material-ui/core'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import MenuArea from './partials/MenuArea'
import { useTranslation } from 'react-i18next'
import tools from 'config/tools'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const Navigation = ({ classes }) => {
  const { pathname } = useLocation()
  const translation = useTranslation()
  const { i18n } = translation
  const [dropdownLang, setDropdownLang] = useState(i18n.language)

  const changeLanguage = (event) => setDropdownLang(event.target.value)

  useEffect(() => {
    i18n.changeLanguage(dropdownLang)
  }, [dropdownLang])

  const location = pathname.split('/')[1].replace('-', ' ')
  const showLanguageBtn =
    pathname.split('/')[2] !== 'form' && pathname.split('/')[2] !== 'view'
  const { title = '', path: destination = '/' } =
    tools.find(({ path }) => path === `/${location}`) || {}

  const options = [
    {
      value: 'da',
      placeholder: 'Dansk',
    },
    {
      value: 'en',
      placeholder: 'English',
    },
  ]

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolBar}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Link to="/" className={classes.link}>
              <Box pl={1}>
                <Typography variant="h6" color="primary" noWrap>
                  Marine Environment
                </Typography>
              </Box>
            </Link>
          </Grid>
          <Grid container justify="center" alignItems="center" item xs={4}>
            {pathname !== '/' && (
              <>
                <Link to={destination} className={classes.link}>
                  <Box pl={1}>
                    <Typography variant="h6" color="primary" noWrap>
                      {title}
                    </Typography>
                  </Box>
                </Link>
              </>
            )}
          </Grid>
          <Grid container justify="flex-end" alignItems="center" item xs={4}>
            {showLanguageBtn && (
              <FormControl>
                <Select value={dropdownLang} onChange={changeLanguage}>
                  {options.map(({ value, placeholder }, index) => (
                    <MenuItem key={index} value={value}>
                      {placeholder}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <MenuArea />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default enhancer(Navigation)
