import { stringify } from 'query-string'

import API, { getDataAsJSON } from '@dhi-solutions/domain-services'

import { HOSTS } from '../config/constants'
import { config } from 'config/fishfarm'

const indicatorMapper = (values, index) => {
  const {
    production: { cld3mo, cld4mo, nld3mo, pld2mo, nh4ld },
    conditions: {
      bedShear,
      bedShear2mo,
      botTemp,
      botTemp2mo,
      botCur,
      surfTemp,
      surfCur,
      dayLength,
    },
    thresholds: { soc, son, sop, h2sSed, inFlux, doReduc, nh4Rel, pp, chl, sd },
  } = values

  return {
    c_Ld_3mo: Number(cld3mo),
    c_Ld_4mo: Number(cld4mo),
    n_Ld_3mo: Number(nld3mo),
    p_Ld_2mo: Number(pld2mo),
    nH4_Ld: Number(nh4ld),
    bed_shear: Number(bedShear[index]),
    bed_shear_2mo: Number(bedShear2mo[index]),
    bot_temp: Number(botTemp[index]),
    bot_temp_2mo: Number(botTemp2mo[index]),
    bot_cur: Number(botCur[index]),
    surf_temp: Number(surfTemp[index]),
    surf_cur: Number(surfCur[index]),
    day_length: Number(dayLength[index]),
    thresholds: {
      // soc,
      soc: {
        minor: Number(soc.minor),
        medium: Number(soc.medium),
        high: Number(soc.high),
        veryHigh: Number(soc.veryHigh),
      },
      // son,
      son: {
        minor: Number(son.minor),
        medium: Number(son.medium),
        high: Number(son.high),
        veryHigh: Number(son.veryHigh),
      },
      // sop,
      sop: {
        minor: Number(sop.minor),
        medium: Number(sop.medium),
        high: Number(sop.high),
        veryHigh: Number(sop.veryHigh),
      },
      // h2sSed,
      h2sSed: {
        minor: Number(h2sSed.minor),
        medium: Number(h2sSed.medium),
        high: Number(h2sSed.high),
        veryHigh: Number(h2sSed.veryHigh),
      },
      // inFlux,
      inFlux: {
        minor: Number(inFlux.minor),
        medium: Number(inFlux.medium),
        high: Number(inFlux.high),
        veryHigh: Number(inFlux.veryHigh),
      },
      // doReduc,
      doReduc: {
        minor: Number(doReduc.minor),
        medium: Number(doReduc.medium),
        high: Number(doReduc.high),
        veryHigh: Number(doReduc.veryHigh),
      },
      // nh4Rel,
      nh4Rel: {
        minor: Number(nh4Rel.minor),
        medium: Number(nh4Rel.medium),
        high: Number(nh4Rel.high),
        veryHigh: Number(nh4Rel.veryHigh),
      },
      // pp,
      pp: {
        minor: Number(pp.minor),
        medium: Number(pp.medium),
        high: Number(pp.high),
        veryHigh: Number(pp.veryHigh),
      },
      // chl,
      chl: {
        minor: Number(chl.minor),
        medium: Number(chl.medium),
        high: Number(chl.high),
        veryHigh: Number(chl.veryHigh),
      },
      // sd
      sd: {
        minor: Number(sd.minor),
        medium: Number(sd.medium),
        high: Number(sd.high),
        veryHigh: Number(sd.veryHigh),
      },
    },
  }
}

const { FISHFARM_SCREENING: HOST } = HOSTS

export const getIndicators = async ({ values }) => {
  try {
    const { siteInformation } = values
    const { features } = siteInformation
    const endpoint = `/fishfarm/calculateindicator`

    const indicators = features.map((feature, index) => {
      const mappedValues = indicatorMapper(values, index)
      return API.post(endpoint, mappedValues, {
        baseURL: HOST,
      })
    })

    const responses = await Promise.all(indicators)

    const response = responses.map(({ data }) => data)

    if (!response) throw Error(`Request rejected`)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getConditions = async ({ features }) => {
  try {
    const conditions = Object.entries(config).map(([id, condition]) => {
      const { item, connection } = condition
      const ids = features.map(
        ({ lat, lng }) => `Item=${item};Longitude=${lng};Latitude=${lat}`
      )
      const endpoint = `/timeseries/${connection}/list/values?distinctdatetime=false`
      return API.post(endpoint, ids, {
        baseURL: HOST,
        transformResponse: response => {
          const data = Object.values(response)
          const extactedValues = data.map(item => {
            const { values } = item
            const [value] = values
            return value.toFixed(3)
          })
          return {
            id,
            values: extactedValues,
          }
        },
      })
    })

    const responses = await Promise.all(conditions)

    const response = responses.map(({ data }) => data)

    if (!response) throw Error(`Request rejected`)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getLoads = async ({ tonnes }) => {
  try {
    const endpoint = `/fishfarm/calculateload?Production=${Number(tonnes)}`
    const response = await API.get(endpoint, {
      baseURL: HOST,
      transformResponse: response => {
        const data = Object.entries(response)
        return data.reduce((allItems, thisItem) => {
          const [key, value] = thisItem
          const name = key.split('_').join('')
          const id = name.toLowerCase()
          allItems[id] = value
          return allItems
        }, {})
      },
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getImage = ({
  bbox = '1001875.42,7094762.12,1792243.80,7967317.54',
  crs = 'EPSG:3857',
  filePath = 'DKBS2_shear.dfsu',
  format = 'image/png',
  height = '1920',
  item = 'Tau_b_xxxxxxxxxxxxxxxxxx',
  layers = 'dfsu-map',
  request = 'GetMap',
  service = 'WMS',
  styles = 'amp1',
  timestamp = '2016-01-01T06:00:00',
  version = '1.3.0',
  width = '1920',
}) => {
  const query = {
    bbox,
    crs,
    filePath,
    format,
    height,
    item,
    layers,
    request,
    service,
    styles,
    timestamp,
    version,
    width,
  }
  const endpoint = `${HOST}/maps?` + stringify({ ...query })
  return endpoint
}

export const getFishfarmScenarios = async () => {
  try {
    const response = await API.get(`/scenarios/Scenarios`, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const scenarios = await getDataAsJSON(response)
    return Promise.resolve(scenarios)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getFishfarmScenario = async ({ id }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${id}`
    const response = await API.get(endpoint, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const scenarios = await getDataAsJSON(response)
    return Promise.resolve(scenarios)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addFishfarmScenario = async ({ body }) => {
  try {
    const endpoint = `/scenarios/Scenarios`
    const response = await API.post(endpoint, body, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateFishfarmScenario = async ({ id, body }) => {
  try {
    const endpoint = `/scenarios/Scenarios`
    const response = await API.put(endpoint, body, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteFishfarmScenario = async ({ id }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${id}`
    const response = await API.delete(endpoint, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getLegends = async ({ style }) => {
  try {
    const response = await API.get('/fishfarm/legends', {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data = [] } = response || {}
    const matchingStyle = data.find(item => item.id === style)
    if (!matchingStyle) throw Error(`Request rejected`)
    return Promise.resolve(matchingStyle)
  } catch (error) {
    return Promise.reject(error)
  }
}
