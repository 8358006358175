import { directionColumn, justifyEnd } from 'styles/mixins'

export default theme => ({
  buttonsArea: {
    ...justifyEnd,
    marginLeft: 20
  },
  nameTag: {
    ...directionColumn,
    justifyContent: 'center',
    fontSize: '1rem',
    marginRight: '1rem'
  },
  linkItem: {
    width: 'inherit',
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
})
