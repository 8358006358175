import { useEffect, useState } from 'react'
import slugify from 'slugify'

import { getResult, getDilutionScenario } from 'data/dilution'

const animationFilter = (item) => item.type === 'Animation'
const plotFilter = (item) => item.type === 'Plot'
const pointConverter = ({ label: name, position, type }) => ({
  name,
  include: true,
  lon: position[0],
  lat: position[1],
  type,
})
const idMapper = ({ label, ...rest }) => ({
  id: slugify(label, {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  }),
  label,
  ...rest,
})

const useResults = (id) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    title: '',
    animations: [],
    plots: [],
    mapOverlays: [],
    modelBounds: [],
    dischargeOutlets: [],
  })
  const [error, setError] = useState([])

  const handleGetResult = async () => {
    try {
      if (id) {
        const {
          data: {
            definition: { title },
          },
        } = await getDilutionScenario({
          id,
        })
        const {
          mapResults: results,
          modelBounds = [],
          mapOverlays = [],
          points = [],
        } = await getResult({
          id,
        })
        const animations = results.filter(animationFilter).map(idMapper)
        const plots = results.filter(plotFilter).map(idMapper)
        const dischargeOutlets = points.map(pointConverter)
        setData({
          title,
          animations,
          plots,
          modelBounds,
          mapOverlays,
          dischargeOutlets,
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  useEffect(() => {
    if (id) handleGetResult()
  }, [id])

  useEffect(() => {
    return () => {
      setIsLoading(true)
      setData({
        title: '',
        animations: [],
        plots: [],
        modelBounds: [],
        mapOverlays: [],
        dischargeOutlets: [],
      })
      setError([])
    }
  }, [])

  return {
    isLoading,
    data,
    error,
  }
}

export default useResults
