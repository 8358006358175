import { groupBy } from 'lodash'
import { format } from 'date-fns'

import { getObjectProperty } from 'data/utils'

export default (actions, groupByKey) => {
  // group actions in an object with the date as key
  const groupedActions = groupBy(actions, action =>
    format(
      groupByKey ? getObjectProperty(action, groupByKey) : action[groupByKey],
      'YYYY-MM-DD'
    )
  )
  return groupedActions
}
