import React, { useState, useMemo, useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import SkipPrevious from '@material-ui/icons/SkipPrevious'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'
import SkipNext from '@material-ui/icons/SkipNext'
import FastForward from '@material-ui/icons/FastForward'
import Slider from '@material-ui/core/Slider'
import { isDate, format } from 'date-fns'
import timestepsState from 'containers/Dilution/partials/View/states/timesteps'
import interactingState from 'containers/Dilution/partials/View/states/interacting'
import stepState from 'containers/Dilution/partials/View/states/step'
import dayState from 'containers/Dilution/partials/View/states/day'

import useInterval from 'hooks/useInterval'

import usePaginationStyles from './usePaginationStyles'

const Footer = React.memo(() => {
  const paginationClasses = usePaginationStyles()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isSpeeding, setIsSpeeding] = useState(false)
  const { timesteps, timestepsByDay, requestingTimesteps } = useRecoilValue(
    timestepsState
  )
  const [step, setStep] = useRecoilState(stepState)
  const interacting = useRecoilValue(interactingState)
  const [day, setDay] = useRecoilState(dayState)

  const timestep = useMemo(() => {
    const activeTimestep = Object.keys(requestingTimesteps)[step]
    const isValid = !!activeTimestep && isDate(new Date(activeTimestep))
    const label = isValid ? format(activeTimestep, 'YYYY-MM-DD - HH:mm:ss') : ''
    return label
  }, [timesteps, step])

  const steps = Object.keys(requestingTimesteps).length - 1
  const isAnimation = Object.keys(requestingTimesteps).length > 1
  const isFirstStep = step === 0
  const isLastStep = step === steps

  const handleStepChange = (event, newStep) => setStep(newStep)
  const handleDateChange = (event, newDay) => setDay(newDay)

  const handlePrevious = () => setStep(isFirstStep ? steps : step - 1)
  const handleNext = () => setStep(isLastStep ? 0 : step + 1)

  const timing = useMemo(() => (isSpeeding ? 100 : 200), [isSpeeding])

  useInterval(
    () => {
      if (isPlaying && !isLastStep) setStep(step + 1)
    },
    timing,
    [isPlaying, isLastStep]
  )

  useEffect(() => {
    if (isPlaying && isLastStep) setStep(0)
  }, [step])

  useEffect(() => {
    if (!interacting) {
      setStep(0)
      setIsPlaying(false)
    }
  }, [timesteps])

  return (
    <Box marginY={2}>
      <Box display="flex" flexDirection="column" bgcolor="shades.light">
        {isAnimation && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor="shades.medium"
            padding={1}
            px={4}
          >
            <Typography color="primary" variant="overline">
              Days
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              width={1}
            >
              <Pagination
                classes={paginationClasses}
                page={day}
                size="large"
                count={Object.keys(timestepsByDay).length}
                onChange={handleDateChange}
                renderItem={item => {
                  const { type, page } = item
                  const isPage = type === 'page'
                  if (isPage) {
                    return (
                      <Tooltip
                        title={format(
                          new Date(Object.values(timestepsByDay)[page - 1]),
                          'YYYY-MM-DD'
                        )}
                      >
                        <PaginationItem {...item} />
                      </Tooltip>
                    )
                  } else {
                    return <PaginationItem {...item} />
                  }
                }}
                showFirstButton
                showLastButton
              />
            </Box>
          </Box>
        )}

        <Box padding={1} px={4}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <IconButton
                disabled={!isAnimation || isFirstStep}
                onClick={handlePrevious}
              >
                <SkipPrevious
                  color={!isAnimation || isFirstStep ? 'disabled' : 'primary'}
                />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <IconButton
                disabled={!isAnimation}
                onClick={() => setIsPlaying(!isPlaying)}
              >
                {isPlaying ? (
                  <Pause color={!isAnimation ? 'disabled' : 'primary'} />
                ) : (
                  <PlayArrow color={!isAnimation ? 'disabled' : 'primary'} />
                )}
              </IconButton>
              <IconButton
                disabled={!isAnimation}
                onClick={() => setIsSpeeding(!isSpeeding)}
              >
                <FastForward
                  color={
                    !isAnimation
                      ? 'disabled'
                      : isSpeeding
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                disabled={!isAnimation || isLastStep}
                onClick={handleNext}
              >
                <SkipNext
                  color={!isAnimation || isLastStep ? 'disabled' : 'primary'}
                />
              </IconButton>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row">
            <Slider
              disabled={!isAnimation}
              step={1}
              min={0}
              max={steps}
              value={step}
              onChange={handleStepChange}
              aria-labelledby="timestep-slider"
            />
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography variant="overline">{timestep}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})

export default Footer
