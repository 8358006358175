import { createMuiTheme } from '@material-ui/core/styles'

import { calc } from './variables'

const DHITheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0D3958', // DHI Primary color
    },
    secondary: {
      main: '#648dae', // Automatically generated secondary color
    },
    shades: {
      white: '#ffffff',
      light: '#f7f7f7',
      medium: '#e7e7e7',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    signals: {
      warning: '#F5A623',
      danger: '#C50000',
      success: '#529900',
      fallback: '#000000',
    },
  },
  flexLayout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  drawerColor: '#ffffff',
  drawerWidth: '50vw',
  map: {
    wrapper: {
      height: calc.contentHeight, // screen height - toolbarHeight
    },
  },
  slideup: {
    height: '500px',
  },
  toolbarHeight: 64,

  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        sub: {
          bottom: '0.75em !important',
          position: 'relative !important',
          lineHeight: '1.5em !important',
        },
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#0D3958',
        color: '#FFFFFF',
        padding: '14px',
      },
      body: {
        padding: '14px',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '3.2rem',
      },
    },
    MuiFormControlLabel: {
      root: {
        padding: 0,
        icon: {
          height: 3,
          width: 3,
        },
      },
      label: {
        fontSize: '1.3rem',
        paddingLeft: '1.5rem',
      },
    },
    MuiStepIcon: {
      root: {
        color: '#e0e0e0',
        '&$active': {
          color: '#648dae',
          transform: 'scale(1.3)',
          backgroundColor: '#ffffff',
        },
        '&$completed': {
          color: '#0D3958', // DHI Primary color
        },
      },
      text: {
        fill: '#ffffff',
      },
    },
  },
  props: {
    MuiChip: {
      variant: 'outlined',
    },
  },
})
export default DHITheme
