import React from 'react';
import { string, func, bool } from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const ActionsDialog = ({
  title,
  message,
  command,
  showDialog,
  closeDialog,
}) => (
  <Dialog
    id="ActionsDialog"
    style={{ zIndex: 2000 }}
    open={showDialog}
    onClose={closeDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ padding: '24px', margin: '0px' }}>
      <Button onClick={closeDialog} variant="outlined">
        {'Cancel'}
      </Button>
      <Button onClick={command} color="primary" variant="contained">
        {'Confirm'}
      </Button>
    </DialogActions>
  </Dialog>
);

ActionsDialog.propTypes = {
  title: string,
  message: string,
  showDialog: bool,
  closeDialog: func,
  command: func,
};

export default ActionsDialog;
