import React, { useEffect, useState } from 'react'

const Wizard = ({
  context: Context,
  steps,
  children,
  disabled = false,
  onDisabled,
  onStepChange,
  initialStep,
  shared = {},
}) => {
  // Initial step
  const initialStepFromConfig = [...steps].findIndex(step => step.initial)
  const customStep = initialStep || initialStepFromConfig

  // Validation
  const isAboveMin = customStep >= 0
  const isBelowMax = customStep <= steps.length - 1
  const isValid = isAboveMin && isBelowMax

  // State
  const step = isValid ? customStep : 0
  const [activeStep, setActiveStep] = useState(step)
  const [activeTitle, setActiveTitle] = useState([...steps][step].title)
  const [isDisabled, setIsDisabled] = useState(disabled)

  useEffect(() => {
    handleSetActiveStep(step || initialStep)
  }, [initialStep])

  useEffect(() => {
    handleSetIsDisabled(disabled)
  }, [disabled])

  // Step checkpoints
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === steps.length - 1

  const config = {
    steps,
    isDisabled,
    activeStep,
    activeTitle,
    isFirstStep,
    isLastStep,
  }

  // Handlers
  const handleSetActiveStep = stepToSetActive => {
    const { title } = steps[stepToSetActive]
    const drawerSelector = '[data-drawer-content]'
    const drawerContent = document && document.querySelector(drawerSelector)
    if (drawerContent !== null) drawerContent.scrollTop = 0
    if (onStepChange) onStepChange(stepToSetActive)
    setActiveStep(stepToSetActive)
    setActiveTitle(title)
  }
  const handleSetIsDisabled = disabledBoolean => {
    if (onDisabled) onDisabled(disabledBoolean)
    setIsDisabled(disabledBoolean)
  }

  // Actions
  const goToPrevious = () => handleSetActiveStep(activeStep - 1)
  const goToNext = () => handleSetActiveStep(activeStep + 1)
  const goToStep = newStep => handleSetActiveStep(newStep)

  const actions = {
    goToPrevious,
    goToNext,
    goToStep,
    setIsDisabled: handleSetIsDisabled,
  }

  return (
    <Context.Provider
      value={{
        actions,
        config,
        shared,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Wizard
