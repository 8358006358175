import { isSameDay } from 'date-fns'
import { useRecoilValue } from 'recoil'

import { scenarioPeriods as scenarioPeriodsState } from 'containers/Dilution/states/activeScenarioType'

const useScenarioPeriods = (startDate, endDate) => {
  const scenarioPeriods = useRecoilValue(scenarioPeriodsState)

  const [
    scenarioPeriod = {
      label: '',
      start: '',
      end: '',
    },
  ] = scenarioPeriods

  const matcher = ({ start, end }) => {
    const sameStart = isSameDay(new Date(start), new Date(startDate))
    const sameEnd = isSameDay(new Date(end), new Date(endDate))
    const samePeriod = sameStart && sameEnd
    return samePeriod
  }

  const {
    label = scenarioPeriod.label,
    start = scenarioPeriod.start,
    end = scenarioPeriod.end,
  } = scenarioPeriods.find(matcher) || {}

  return {
    label,
    start,
    end,
  }
}

export default useScenarioPeriods
