import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { isArray } from 'lodash'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core'

import ActionsWrapper from './partials/ActionsWrapper'

import groupActions from './lib/groupActions'

import styles from './styles'

const enhancer = compose(withStyles(styles))

const ActionsList = ({
  classes,
  groupBy,
  actions,
  noMarginOnItems,
  ...rest
}) => {
  const [groupedActions, setGroupedActions] = useState({})

  const handleActionsGrouping = () => {
    const groupedActions = groupActions(actions, groupBy)
    setGroupedActions(groupedActions)
  }

  useEffect(() => {
    handleActionsGrouping()
  }, [actions])

  const groupedByKeys = Object.keys(groupedActions)
    .sort()
    .reverse()

  const hasKeys = isArray(groupedByKeys) && groupedByKeys.length > 0
  if (!hasKeys) return null

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootWithoutMargin]: noMarginOnItems,
      })}
    >
      {groupedByKeys &&
        groupedByKeys.map((groupedByKey, index) => (
          <ActionsWrapper
            key={index}
            groupedByKey={groupedByKey}
            group={groupedActions[groupedByKey]}
            noMarginOnItems={noMarginOnItems}
            {...rest}
          />
        ))}
    </div>
  )
}

ActionsList.defaultProps = {
  idField: 'id',
  nameField: 'data.Name',
  groupBy: 'dateTime',
  showDate: false,
  showHour: false,
  showMenu: false,
  showStatus: false,
  noMarginOnItems: false,
}

export default enhancer(ActionsList)
