import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import { FieldArray } from 'formik'

import Block from 'components/Block'
import ErrorMessage from 'components/ErrorMessage'

const group = 'thresholds'

const headlines = {
  soc: 'Organic carbon concentration in sediment (g/m2)',
  son: 'Organic nitrogen concentration in sediment (g/m2)',
  sop: 'Organic phosphorous concentration in sediment (g/m2)',
  h2sSed: 'Total sulphide concentration in sediment (mM)',
  inFlux: 'Inorganic nitrogen flux from sediment (g/m2/d)',
  doReduc: 'Dissolved oxygen reduction in near-bed water (mg/l)',
  nh4Rel: 'Ammonium release (µg/l)',
  pp: 'Primary production (gC/m2/d)',
  chl: 'Chlorophyll-a change (µgChl-a/l)',
  sd: 'Secchi depth change (m)',
}

const placeholders = {
  minor: 'Type in minor',
  medium: 'Type in medium',
  high: 'Type in high',
  veryHigh: 'Type in very high',
}

const labels = {
  minor: 'Minor',
  medium: 'Medium',
  high: 'High',
  veryHigh: 'Very high',
}

const Step = ({ form }) => {
  const { values, handleChange, errors } = form
  const { thresholds } = values
  const rows = Object.entries(thresholds)

  return (
    <Box width={1}>
      <Box marginBottom={4}>
        <Typography variant="h6">Impact thresholds</Typography>
      </Box>
      <Box marginBottom={4}>
        <ErrorMessage
          errors={errors}
          fields={[
            'thresholds.soc',
            'thresholds.son',
            'thresholds.sop',
            'thresholds.h2sSed',
            'thresholds.inFlux',
            'thresholds.doReduc',
            'thresholds.nh4Rel',
            'thresholds.pp',
            'thresholds.chl',
            'thresholds.sd',
          ]}
        />
      </Box>
      {rows.map(([key, row]) => (
        <Box key={key} marginBottom={4}>
          <Block headline={get(headlines, key)}>
            <Grid container spacing={3}>
              {Object.entries(row).map(([field, value]) => {
                const path = `${key}.${field}`
                const identifier = `${group}.${path}`
                const placeholder = get(placeholders, field)
                const label = get(labels, field)
                return (
                  <FieldArray
                    key={path}
                    name={path}
                    render={() => (
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          id={identifier}
                          name={identifier}
                          placeholder={placeholder}
                          label={label}
                          value={value}
                        />
                      </Grid>
                    )}
                  />
                )
              })}
            </Grid>
          </Block>
        </Box>
      ))}
    </Box>
  )
}

export default Step
