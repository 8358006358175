import React, { PureComponent, createContext, createRef } from 'react'
import { compose } from 'recompose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'

import L from 'leaflet'

import { LAYERS } from './config'
import shp from 'shpjs'

import Sidebar from './partials/Sidebar'

import Map from './partials/Map'

import styles from 'styles/global'

const enhancer = compose(withStyles(styles, { withTheme: true }))

export const DataviewerContext = createContext()

class Dataviewer extends PureComponent {
  state = {
    position: {
      lat: '',
      lng: '',
      exists: false,
    },
    coords: [],
    openChart: false,
    layers: JSON.parse(JSON.stringify(LAYERS)),
    dataSource: {},
    value: 0,
    timestep: null,
    animationLoading: false,
    drawControl: {},
    shapeData: null,
    mapView: {
      center: [55.8, 12.1],
      zoom: 10,
    },
    step: null,
    dataviewerActiveStep: 0,
  }

  mapRef = createRef()
  timer = null
  animationFrequency = 1000

  setDataviewerActiveStep = dataviewerActiveStep =>
    this.setState({ dataviewerActiveStep })

  updateInfo = info => {
    this.setState({ info: info })
  }

  updateDomain = domain => {
    this.setState({
      domain,
      mapView: {
        center: [domain.lat, domain.lng],
        zoom: domain.zoom,
      },
    })
  }

  updateSite = site =>
    this.setState({ site, shapeData: null }, () => {
      const layerContainer = this.state.drawControl.options.edit.featureGroup
      const layers = layerContainer._layers
      Object.keys(layerContainer._layers).forEach(id => {
        const layer = layers[id]
        layerContainer.removeLayer(layer)
      })
    })

  updatePosition = position => this.setState({ position })

  updateCoords = coords => this.setState({ coords })

  resetLayers = () => {
    this.setState({
      layers: JSON.parse(JSON.stringify(LAYERS)),
    })
  }

  onMounted = drawControl => {
    this.setState({
      drawControl,
    })
  }

  onCreated = e => {
    let layer = e.layer
    let type = e.layerType

    if (type === 'circlemarker') {
      const { lat, lng } = layer._latlng
      const position = { lat, lng }
      this.updatePosition({ ...position, exists: true })
      this.updateCoords([position])
    } else if (type === 'polygon') {
      const { lat: centerLat, lng: centerLng } = layer.getBounds().getCenter()
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const position = {
        lat: centerLat,
        lng: centerLng,
      }
      this.updatePosition({
        ...position,
        exists: true,
        area,
      })
      this.updateCoords(layer._latlngs[0])
    }
  }

  onEdited = e => {
    let layers = e.layers
    layers.eachLayer(layer => {
      if (layer instanceof L.CircleMarker) {
        const lat = layer._latlng.lat
        const lng = layer._latlng.lng
        this.updatePosition({ lat: lat, lng: lng, exists: true })
      } else if (layer instanceof L.Polygon) {
        let center = layer.getBounds().getCenter()
        let area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
        const lat = center.lat
        const lng = center.lng
        this.updatePosition({
          lat: lat,
          lng: lng,
          exists: true,
          area: area,
        })
      }
    })
  }

  onDeleted = e => {
    this.updatePosition({ lat: '', lng: '', exists: false })
  }

  makeGeojson = e => {
    e.preventDefault()
    let zipFile = e.target.files[0]

    let reader = new FileReader()
    reader.readAsArrayBuffer(zipFile)
    reader.onloadend = async e => {
      const geojson = await this.getGeojson(e)
      this.setState({ shapeData: geojson })

      // Position as center of bbox
      var geojsonLayer = L.geoJson(geojson)
      let center = geojsonLayer.getBounds().getCenter()
      const lat = center.lat
      const lng = center.lng
      this.updatePosition({ lat, lng, exists: true })
    }
  }

  getGeojson = async e => {
    const geojson = shp(e.target.result).then(
      function(data) {
        return data
      },
      function(error) {
        console.error('Shapefile load failed: ', error)
      }
    )
    return geojson
  }

  onStepChange = (event, step) => {
    const { layers } = this.state
    const selectedStep = layers[step]
    const selectedKey = event.target.value
    Object.keys(selectedStep).map(key =>
      key === selectedKey
        ? (selectedStep[key].checked = !selectedStep[key].checked)
        : selectedStep[key].type === 'auto-anim' &&
          selectedStep[selectedKey].type === 'auto-anim'
        ? (selectedStep[key].checked = false)
        : null
    )
    this.setState({
      step,
      layers: {
        ...layers,
        [step]: selectedStep,
      },
    })

    //start or stop animation stepper
    if (selectedStep[selectedKey].type === 'auto-anim') {
      this.setState({ value: 0 })
      if (selectedStep[selectedKey].checked) {
        this.setState(
          { dataSource: selectedStep[selectedKey].dataSource },
          this.stepper()
        )
        //this.stepper();
      } else {
        clearTimeout(this.timer)
        this.timer = null
        this.setState({ dataSource: {} })
      }
    }
  }

  onStatus = status => {
    this.setState({ animationLoading: status === 'loading' })
  }

  stepper = () => {
    const { dataSource, value } = this.state

    this.timer = setTimeout(() => {
      this.setState(state => {
        const timestep = Object.keys(dataSource.ids)[value]
        const newValue = state.value + 1

        return {
          ...state,
          value: newValue,
          timestep,
        }
      })

      // Start over
      if (value === Object.keys(dataSource.ids).length - 1) {
        this.setState({ value: 0 })
      }

      this.stepper()
    }, this.animationFrequency)
  }

  render() {
    const { classes } = this.props
    const { openChart, dataviewerActiveStep } = this.state

    return (
      <DataviewerContext.Provider
        value={{
          state: this.state,
          refs: {
            mapRef: this.mapRef,
          },
          actions: {
            makeGeojson: this.makeGeojson,
            onStepChange: this.onStepChange,
            resetLayers: this.resetLayers,
            updateInfo: this.updateInfo,
            updateDomain: this.updateDomain,
            updateSite: this.updateSite,
            updatePosition: this.updatePosition,
            updateCoords: this.updateCoords,
            onCreated: this.onCreated,
            onDeleted: this.onDeleted,
            onEdited: this.onEdited,
            onMounted: this.onMounted,
            onStatus: this.onStatus,
          },
        }}
      >
        <div
          className={classNames(classes.contentDrawerOpen, {
            [classes.content]: !openChart,
            [classes.chart]: openChart,
          })}
        >
          <Map isFullWidth />
          <Sidebar
            dataviewerActiveStep={dataviewerActiveStep}
            setDataviewerActiveStep={this.setDataviewerActiveStep}
          />
        </div>
      </DataviewerContext.Provider>
    )
  }
}

export default enhancer(Dataviewer)
