import { justifySpaceBetween, alignCenter } from 'styles/mixins'

export default {
  title: {
    ...justifySpaceBetween,
    ...alignCenter,
    padding: '1rem 0',
  },
  stepper: {
    borderTop: '1px solid lightgrey',
    padding: '2.5rem 0rem',
    color: 'white !important',
  },
}
