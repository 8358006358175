import { useEffect, useState } from 'react'

import { getModels } from 'data/dilution'

const useModels = () => {
  const [models, setModels] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleGetModels = async () => {
    try {
      const modelsToSet = await getModels()
      setModels(modelsToSet)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetModels()
    return () => {
      setModels([])
      setIsLoading(true)
    }
  }, [])

  return {
    models,
    isLoading,
  }
}

export default useModels
