import { useEffect, useState } from 'react'
import proj4 from 'proj4'
import Axios from 'axios'
import { WebMercatorViewport } from '@deck.gl/core'
import compareAsc from 'date-fns/compare_asc'
import { getImages } from 'data/common'

const useImages = ({ selectedItem, view, timesteps, toggleOptions }) => {
  const [localImages, setLocalImages] = useState([])
  const { itemNumber, mapStyle, mapConnectionId, baseURL } = selectedItem

  const handleGetImages = async ({ interacting, source }) => {
    try {
      const viewport = new WebMercatorViewport(view)
      const [north, west] = viewport.unproject([0, 0])
      const [south, east] = viewport.unproject([view.width, view.height])
      const [NORTH, WEST] = proj4('WGS84', 'EPSG:3857', [north, west])
      const [SOUTH, EAST] = proj4('WGS84', 'EPSG:3857', [south, east])
      const bounds = [north, east, south, west]
      const images = await getImages(
        {
          baseURL,
          timesteps,
          mapConnectionId,
          mapStyle,
          itemNumber,
          width: Math.round(view.width),
          height: Math.round(view.height),
          bbox: `${NORTH},${EAST},${SOUTH},${WEST}`,
          isMajorLoading: !interacting,
          ...toggleOptions,
        },
        source
      )

      const imagesWithBounds = Object.entries(images)
        .sort(([a], [b]) => compareAsc(new Date(a), new Date(b)))
        .map(([timestep, image]) => [timestep, image, bounds, { interacting }])

      setLocalImages(imagesWithBounds)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCheckpoint = (options) => {
    const withSelectedItem = !!Object.keys(selectedItem).length
    const withTimesteps = !!Object.keys(timesteps).length
    const isValid = withSelectedItem && withTimesteps
    if (isValid) {
      handleGetImages(options)
    } else {
      setLocalImages([])
    }
  }

  useEffect(() => {
    handleCheckpoint({ interacting: false, source: undefined })
  }, [timesteps])

  useEffect(() => {
    const source = Axios.CancelToken.source()
    handleCheckpoint({ interacting: true, source })
    return () => source.cancel()
  }, [view.zoom, view.latitude, view.longitude, toggleOptions])

  return localImages
}

export default useImages
