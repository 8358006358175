export default ({ initialValues = {} }) => {
  const { domain = {}, site = {}, info = {}, coordinates = [] } = initialValues;
  return {
    domain: {
      id: domain.id || '',
      label: domain.label || '',
      lat: domain.lat || 0,
      lng: domain.lng || 0,
      zoom: domain.zoom || 0,
    },
    site: {
      id: site.id || '',
      label: site.label || '',
    },
    info: {
      title: info.title || '',
      description: info.description || '',
    },
    coordinates,
  };
};
