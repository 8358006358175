import { atom, selector } from 'recoil'

import modelsState from './models'

export const activeModelId = atom({
  key: 'activeModel',
  default: null,
})

export const activeModelState = selector({
  key: 'activeModelState',
  get: ({ get }) => {
    const models = get(modelsState)
    const modelId = get(activeModelId)
    return models.find((model) => model.id === modelId) || {}
  },
})

export default activeModelId
