import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { string, func, bool, number, shape, arrayOf } from 'prop-types'
import { parse } from 'date-fns'
import {
  Box,
  IconButton,
  Slider,
  Typography,
  // Tooltip,
  withStyles,
} from '@material-ui/core'

import { formatToTimeZone } from 'date-fns-timezone'

// import { CloudDownload as ExportIcon } from '@material-ui/icons'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import PauseRoundedIcon from '@material-ui/icons/PauseRounded'
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded'
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded'
import FastForwardRoundedIcon from '@material-ui/icons/FastForwardRounded'
import FastRewindRoundedIcon from '@material-ui/icons/FastRewindRounded'

import styles from './styles'

class AnimationControl extends PureComponent {
  static propTypes = {
    // Status of whether the component is currently playing or not
    playing: bool,
    // Element is enabled if this is set to true. Disabled if set to false.
    enabled: bool,
    // Returns current datetime out of all the time steps.
    onChange: func,
    // Orientation of control.
    horizontal: bool,
    // Hide controls.
    hideControls: bool,
    // Required proptype by ESLint for React Material Library
    classes: shape({}),
    // Datetime postfix appended to date to indicate the timezone
    dateTimePostfix: string,
    // Rate of change of selected value on slider when the animation is playing
    animationFrequency: number,
    // Datetimes available for stepping to in animation control.
    dateTimes: arrayOf(string),
    // Timezone from model
    timezone: string,
    // Datetime display format. Default: 'YYYY/MM/DD HH:mm:ss'.
    dateTimeDisplayFormat: string,
  }

  static defaultProps = {
    playing: false,
    classes: null,
    enabled: true,
    onChange: null,
    dateTimes: [],
    horizontal: false,
    hideControls: false,
    dateTimePostfix: null,
    animationFrequency: 500,
    dateTimeDisplayFormat: 'YYYY/MM/DD HH:mm:ss',
  }

  timer = null

  state = { value: 0 }

  animationFrequency = this.props.animationFrequency || 1000

  componentDidMount() {
    const { onRef } = this.props
    onRef(this)
  }

  componentWillUnmount() {
    const { onRef } = this.props
    onRef(undefined)
  }

  formatCurrentDate = date => {
    const { timezone, dateTimeDisplayFormat } = this.props
    if (!date) {
      return
    }

    const initialDate = parse(`${date}Z`)

    return formatToTimeZone(initialDate, dateTimeDisplayFormat, {
      timeZone: timezone,
      convertTimeZone: false,
    })
  }

  step = () => {
    const { value } = this.state
    const { onChange, dateTimes } = this.props

    this.timer = setTimeout(() => {
      this.setState(state => {
        const newValue = state.value + 1
        const dateTimeCurrent = this.getCurrentDateTime(newValue)
        onChange(dateTimeCurrent)

        return {
          ...state,
          value: newValue,
          dateTimeCurrent,
        }
      })

      if (value === dateTimes.length - 1) {
        this.setState({ value: 0 })
      }

      this.step()
    }, this.animationFrequency)
  }

  play = () => {
    if (this.timer === null) {
      this.setState({ playing: true })
      this.step()
    }
  }

  pause = () => {
    this.setState({ playing: false })

    clearTimeout(this.timer)
    this.timer = null
  }

  stop = () => {
    const { dateTimes } = this.props
    this.pause()
    this.setState({ value: 0, dateTimeCurrent: dateTimes[0], playing: false })
  }

  forward = () => {
    const { value } = this.state
    const { dateTimes, onChange } = this.props
    this.pause()

    if (value < dateTimes.length - 1) {
      this.setState(state => {
        const newValue = state.value + 1
        const dateTimeCurrent = this.getCurrentDateTime(newValue)
        onChange(dateTimeCurrent)

        return {
          ...state,
          value: newValue,
          dateTimeCurrent,
        }
      })
    }
  }

  backward = () => {
    const { value } = this.state
    const { onChange } = this.props
    this.pause()

    if (value > 0) {
      this.setState(state => {
        const newValue = state.value - 1
        const dateTimeCurrent = this.getCurrentDateTime(newValue)
        onChange(dateTimeCurrent)

        return {
          ...state,
          value: newValue,
          dateTimeCurrent,
        }
      })
    }
  }

  faster = () => {
    if (this.animationFrequency > 100) {
      this.animationFrequency -= 100
    }
  }

  slower = () => {
    this.animationFrequency += 100
  }

  getCurrentDateTime = value => {
    const { dateTimes } = this.props

    return dateTimes[value]
  }

  onChange = (_, value) => {
    const { onChange } = this.props
    const { dateTimeCurrent } = this.state

    this.pause()
    this.setState(state => {
      const newDateTimeCurrent = this.getCurrentDateTime(value)
      onChange(newDateTimeCurrent)

      return {
        ...state,
        value,
        dateTimeCurrent: newDateTimeCurrent,
      }
    })

    onChange(dateTimeCurrent)
  }

  render() {
    const { dateTimeCurrent, value, playing } = this.state
    const {
      enabled,
      dateTimes,
      hideControls,
      horizontal,
      classes,
      dateTimePostfix,
    } = this.props
    const title = (
      <Box py={0.5}>
        <Typography
          variant="caption"
          color={enabled ? 'textPrimary' : 'textSecondary'}
        >
          {dateTimeCurrent
            ? this.formatCurrentDate(dateTimeCurrent)
            : this.formatCurrentDate(dateTimes[0])}
          {dateTimePostfix}
        </Typography>
      </Box>
    )
    const slider = (
      <Slider
        min={0}
        step={1}
        value={value}
        disabled={!enabled}
        onChange={this.onChange}
        classes={{ root: classes.slider }}
        max={dateTimes && dateTimes.length - 1}
      />
    )
    const actions = !hideControls && (
      <Box>
        <IconButton disabled={!enabled} size="small" onClick={this.backward}>
          <SkipPreviousRoundedIcon
            color={enabled ? 'primary' : 'disabled'}
            fontSize="large"
          />
        </IconButton>
        <IconButton disabled={!enabled} size="small" onClick={this.slower}>
          <FastRewindRoundedIcon
            color={enabled ? 'primary' : 'disabled'}
            fontSize="large"
          />
        </IconButton>
        {playing ? (
          <IconButton disabled={!enabled} size="small" onClick={this.pause}>
            <PauseRoundedIcon
              color={enabled ? 'primary' : 'disabled'}
              fontSize="large"
            />
          </IconButton>
        ) : (
          <IconButton disabled={!enabled} size="small" onClick={this.play}>
            <PlayArrowRoundedIcon
              color={enabled ? 'primary' : 'disabled'}
              fontSize="large"
            />
          </IconButton>
        )}
        <IconButton disabled={!enabled} size="small" onClick={this.faster}>
          <FastForwardRoundedIcon
            color={enabled ? 'primary' : 'disabled'}
            fontSize="large"
          />
        </IconButton>
        <IconButton disabled={!enabled} size="small" onClick={this.forward}>
          <SkipNextRoundedIcon
            color={enabled ? 'primary' : 'disabled'}
            fontSize="large"
          />
        </IconButton>
        {/* <Tooltip title="Export shapefile from animation at a specific timestep. Tip: Pause the animation and go to the desired timestep, then download.">
          <IconButton
            color="secondary"
            aria-label="Export"
            onClick={this.props.onShapefileDownload}
            disabled
          >
            <ExportIcon />
          </IconButton>
        </Tooltip> */}
      </Box>
    )

    return (
      <Box
        className={classNames(classes.root, horizontal && classes.horizontal)}
      >
        {title}
        {slider}
        {actions}
      </Box>
    )
  }
}

export default withStyles(styles)(AnimationControl)
