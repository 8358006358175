import React, { useEffect, useMemo, useState, useRef } from 'react'
import { StaticMap } from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import { useDebounce } from 'react-use'
import isEqual from 'lodash/isEqual'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import imagesGrabState from 'components/DeckGLMap/states/imagesGrab'
import imagesReportState from 'components/DeckGLMap/states/imagesReport'

const CoreMap = React.memo(
  ({ view, setView, width, height, layers, cursor, id }) => {
    const [localView, setLocalView] = useState(view)
    const handleViewStateChange = ({ viewState }) => setLocalView(viewState)

    const isMatchingView = useMemo(() => isEqual(view, localView), [
      view,
      localView,
    ])

    useEffect(() => {
      if (!isMatchingView) setLocalView(view)
    }, [view])

    useDebounce(
      () => {
        if (!isMatchingView) setView(localView)
      },
      500,
      [localView]
    )

    // This could be in hook ... If we wrap DeckGl in our own extension the code will be much nicer
    const actionRef = useRef()
    const imagesGrap = useRecoilValue(imagesGrabState)
    const setImagesReport = useSetRecoilState(imagesReportState)

    const onAfterRender = () => {
      const { grab, id: toPdfId, properties } = imagesGrap
      if (grab && actionRef.current.props.id === 'report-component') {
        setImagesReport((imagesInStore) => ({
          ...imagesInStore,
          [toPdfId]: {
            properties: properties,
            report: actionRef.current.deck.canvas.toDataURL('image/png'),
          },
        }))
      }
    }

    return (
      <DeckGL
        id={id}
        ref={actionRef}
        width={width}
        height={height}
        viewState={localView}
        controller
        layers={layers}
        onViewStateChange={handleViewStateChange}
        getTooltip={({ object, b, c }) => {
          return (
            object &&
            `${object.name || object.properties.name || object.properties.Name}`
          )
        }}
        getCursor={() => cursor}
        // onHover={({ object }) => (isHoveringDeck = Boolean(object))}
        onAfterRender={onAfterRender}
        onError={() => ({})}
      >
        <StaticMap
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          mapStyle={`mapbox://styles/${process.env.REACT_APP_MAPBOX_STYLE}`}
        />
      </DeckGL>
    )
  }
)

export default CoreMap
