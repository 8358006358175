import { object, array, string } from 'yup'

import Step1 from './Step1'

export default {
  title: 'Site',
  component: Step1,
  validationSchema: () =>
    object().shape({
      siteInformation: object().shape({
        title: string().required('No project title added.'),
        features: array().min(1, 'No features added to the map.'),
      }),
    }),
}
