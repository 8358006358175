import { directionRow, justifySpaceBetween } from 'styles/mixins'

export default theme => ({
  appBar: {
    ...directionRow,
    ...justifySpaceBetween,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#ffffff',
    color: theme.palette.primary.main,
    padding: '.5rem 1.5rem'
  },
  toolBar: {
    width: '100%'
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  navBar: {
    marginLeft: 'auto',
    marginRight: 'right'
  },
  navButton: {
    marginLeft: -12,
    marginRight: 20,
    textDecoration: 'none',
    color: 'white'
  },
  hide: {
    display: 'none'
  },
  grow: {
    flexGrow: 1
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
    textTransform: 'capitalize'
  },
  textAlignCenter: {
    textAlign: 'center'
  }
})
