import { selector } from 'recoil'

import modelsState from './models'
import activeModelState from './activeModel'

import { usersScenarioTypes as usersScenarioTypesState } from 'states/user'

export const scenarioTypes = selector({
  key: 'scenarioTypes',
  get: ({ get }) => {
    const models = get(modelsState)
    const activeModel = get(activeModelState)
    const matcher = (model) => model.id === activeModel
    const model = models.find(matcher) || {}
    const { scenarioTypes = [] } = model
    return scenarioTypes
  },
})

export const allowedScenarioTypes = selector({
  key: 'allowedScenarioTypes',
  get: ({ get }) => {
    const activeModel = get(activeModelState)
    const allScenarioTypes = get(scenarioTypes)
    const usersScenarioTypes = get(usersScenarioTypesState)
    return allScenarioTypes.filter(({ id: modelsTypeId }) => {
      const isAvailable = usersScenarioTypes[activeModel].find(
        (usersTypeId) => usersTypeId === modelsTypeId
      )
      return !!isAvailable
    })
  },
})

export default scenarioTypes
