import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { ScatterplotLayer } from '@deck.gl/layers'

import pickableObjectState from 'containers/Dilution/partials/View/states/pickableObject'

const geojson = (mapSource, id) => {
  return new ScatterplotLayer({
    id: `scatterPlot ${id}`,
    data: mapSource,
    getFillColor: (d) => (d.color ? d.color : [128, 128, 128]),
    pickable: false,
    stroked: true,
    filled: true,
    radiusMinPixels: 5,
    radiusMaxPixels: 5,
    getPosition: (d) => [d.lon, d.lat],
  })
}

const useChartLayer = ({ id }) => {
  const mapSource = useRecoilValue(pickableObjectState)

  const layers = useMemo(() => {
    return geojson(mapSource, `chart-layer-${id}`)
  }, [mapSource, id])

  // useEffect(() => {
  //     return () => { resetSource() }
  // }, [])

  return layers
}

export default useChartLayer
