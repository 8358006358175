import { object, array, string, number, boolean } from 'yup'
import some from 'lodash/some'

import Step2 from './Step2'

export default {
  component: Step2,
  validationSchema: () =>
    object().shape({
      components: array()
        .min(1)
        .of(
          object().shape({
            id: string(),
            componentDescription: string().required(
              'No component description added.'
            ),
            concentrationValue: number(),
            decayRate: number(),
            unit: string(),
            include: boolean(),
            disabled: boolean()
          })
        )
        .test({
          name: 'with-selected',
          message: 'errors:checkboxes',
          test: components => {
            const withSelectedComponents = some(components, {
              include: true
            })
            return components.length === 0 ? true : withSelectedComponents
          }
        })
    })
}
