import { array, string, number, object } from 'yup';

import Step1 from './Step1';

export default {
  title: 'Site Location',
  component: Step1,
  validationSchema: () =>
    object().shape({
      domain: object()
        .shape({
          id: string()
            .min(1)
            .required(),
          label: string()
            .min(1)
            .required(),
          lat: number()
            .min(-180)
            .max(180)
            .required(),
          lng: number()
            .min(-180)
            .max(180)
            .required(),
          zoom: number()
            .min(1)
            .required(),
        })
        .required(),
      site: object()
        .shape({
          id: string()
            .min(1)
            .required(),
          label: string()
            .min(1)
            .required(),
        })
        .required(),
      coordinates: array()
        .of(
          object().shape({
            lat: number()
              .min(-180)
              .max(180)
              .required(),
            lng: number()
              .min(-180)
              .max(180)
              .required(),
          })
        )
        .min(1),
    }),
};
