import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';

import { DataviewerContext } from 'containers/Dataviewer';

const Progress = () => {
  const { refs, state } = useContext(DataviewerContext);
  const { animationLoading } = state;
  const { mapRef } = refs;
  if (animationLoading && mapRef.current) {
    return (
      <div
        style={{
          zIndex: 1000,
          position: 'absolute',
          left: '50%',
          top: '50%',
        }}
      >
        <CircularProgress color="primary" size={40} />
      </div>
    );
  }
  return null;
};

export default Progress;
