import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { authentication } from '@dhi-solutions/domain-services'
import { useRecoilState, useResetRecoilState } from 'recoil'
import get from 'lodash/get'
import userState from 'states/user'

const useProfile = () => {
  const { pathname } = useLocation()
  const [loading, setIsLoading] = useState(true)
  const [user, setUser] = useRecoilState(userState)
  const resetUser = useResetRecoilState(userState)

  const roles = useMemo(() => get(user, 'roles', []), [user])

  const isRole = role => roles.includes(role)

  const isUser = isRole('User')
  const isAdmin = isRole('Administrator')

  const getProfile = async () => {
    setIsLoading(true)
    try {
      const isLoggedIn = await authentication.isLoggedIn()
      if (isLoggedIn) {
        const userFromLocalStorage = await authentication.loginFromLocalStorage()
        setUser(userFromLocalStorage)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const login = async ({ username, password, callback }) => {
    setIsLoading(true)
    try {
      const user = await authentication.login({
        config: {
          baseURL:
            'https://me-prod-brain.westeurope.cloudapp.azure.com/users/api',
        },
        id: username,
        password,
      })
      setUser(user)
      setIsLoading(false)
      if (callback) callback()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const logout = async ({ callback }) => {
    setIsLoading(true)
    try {
      await authentication.logout()
      resetUser()
      setIsLoading(false)
      if (callback) callback()
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getProfile()
  }, [pathname])

  useEffect(() => {
    return () => {
      resetUser()
      setIsLoading(true)
    }
  }, [])

  return { loading, user, login, logout, isUser, isAdmin }
}

export default useProfile
