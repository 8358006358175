import { stringify } from 'query-string'

import API, {
  maps,
  getDataAsJSON,
  setDataAsString,
} from '@dhi-solutions/domain-services'

import {
  decodeDilution as decode,
  encodeDilution as encode,
} from 'helpers/dilutionsMapper'

// import has from 'lodash/has'

import { HOSTS } from '../config/constants'

const { DILUTION: HOST, DILUTION_ROOT: ROOT } = HOSTS

export const getTimeseries = async ({
  connectionId,
  path,
  itemNumber,
  baseURL,
}) => {
  try {
    const endpoint = `/timeseries/${connectionId}/${path};${itemNumber}/values`
    const response = await API.get(endpoint, {
      baseURL: baseURL,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

// export const getImages = async (
//   {
//     baseURL,
//     timesteps,
//     mapConnectionId,
//     mapStyle: style,
//     itemNumber: item,
//     width,
//     height,
//     shadingtype = 'ShadedContour',
//     bbox,
//     isMajorLoading,
//     isoline,
//     includevector,
//     vectornumberhorizontal,
//   },
//   source
// ) => {
//   try {
//     const query = {
//       style,
//       item,
//       width,
//       height,
//       shadingtype,
//       bbox,
//       isoline,
//       includevector,
//       vectornumberhorizontal,
//     }
//     const endpoint = `/maps/${mapConnectionId}?` + stringify({ ...query })

//     const cancelToken = has(source, 'token')
//       ? {
//         cancelToken: source.token,
//       }
//       : {}
//     const response = await API.post(endpoint, timesteps, {
//       baseURL: baseURL,
//       ...cancelToken,
//       headers: {
//         isMajorLoading,
//       },
//     })
//     if (!response) throw Error(`Request rejected`)
//     const { data } = response || {}
//     return Promise.resolve(data)
//   } catch (error) {
//     return Promise.reject(error)
//   }
// }

export const getImage = ({
  itemNumber: item,
  mapStyle: styles,
  path: filePath,
  timestamp,
  mapConnectionId: layers,
}) => {
  const layer = {
    bbox: '1001875.42,7094762.12,1792243.80,7967317.54',
    crs: 'EPSG:3857',
    filePath,
    format: 'image/png',
    host: ROOT,
    item,
    layers,
    request: 'GetMap',
    service: 'WMS',
    styles,
    timestamp,
    version: '1.3.0',
    width: '1920',
    height: '1920',
  }
  return maps.getImageUrl(layer)
}

export const executeScenario = async ({ job, scenario }) => {
  try {
    const { modelId, scenarioId } = job
    const jobResponse = await API.post(
      `/jobs/Worker`,
      {
        TaskId: 'dilution',
        Parameters: {
          Model: modelId,
          ScenarioId: scenarioId,
        },
      },
      {
        baseURL: HOST,
      }
    )
    if (!jobResponse) throw Error(`Request rejected`)
    const { data } = jobResponse || {}
    const { id: lastJobId, status } = data

    const updateScenarioItem = {
      body: {
        ...scenario,
        data: JSON.stringify(scenario.data),
        lastJobId,
        lastJobStatus: status,
      },
    }

    const scenarioResponse = await updateDilutionScenario(updateScenarioItem)

    return Promise.resolve({ jobResponse, scenarioResponse })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getResult = async ({ id }) => {
  try {
    const response = await API.get(`/scenarios/${id}/result`, {
      baseURL: ROOT,
      transformResponse: (response) => {
        const { mapResults, ...restOfResponse } = response
        return {
          mapResults: mapResults.map(({ legend, ...restOfResult }) => ({
            baseURL: HOST,
            legend: legend.reverse(),
            ...restOfResult,
          })),
          ...restOfResponse,
        }
      },
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getModels = async () => {
  try {
    const response = await API.get(`/models`, {
      baseURL: ROOT,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getLayers = async ({ modelId, layerIds }) => {
  try {
    const layers = layerIds.map((layerId, index) => {
      return API.get(`/models/${modelId}/map-overlays/${layerId}`, {
        baseURL: ROOT,
      })
    })

    const responses = await Promise.all(layers)

    const response = responses.map(({ data }) => data)

    if (!response) throw Error(`Request rejected`)

    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDilutionScenarios = async () => {
  try {
    const response = await API.get(`/scenarios/Scenarios`, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const scenarios = await getDataAsJSON(response)
    const mappedScenarios = scenarios.map(({ data, ...rest }) => ({
      data: decode(data),
      ...rest,
    }))
    return Promise.resolve(mappedScenarios)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDischargeList = async ({ id }) => {
  try {
    const response = await API.get(`/scenarios/${id}/discharge-list`, {
      baseURL: ROOT,
    })
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getJetTable = async ({ id }) => {
  try {
    const response = await API.get(`/scenarios/${id}/jet-table`, {
      baseURL: ROOT,
    })
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getUserDefinedLegends = async ({ id, legends }) => {
  try {
    const thresholds = stringify({
      thresholds: legends.reverse().map(({ threshold }) => threshold),
    })
    const response = await API.get(
      `/scenarios/${id}/generate-user-defined-map-style?${thresholds}`,
      {
        baseURL: ROOT,
        transformResponse: ({ legend, mapStyle }) => {
          return {
            mapStyle,
            legend: legend.reverse(),
          }
        },
      }
    )
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDilutionScenariosByProject = async ({ projectId }) => {
  try {
    const response = await API.post(
      '/scenarios/Scenarios/query',
      [
        {
          item: 'project',
          queryOperator: 'Like',
          value: '%' + projectId + '%',
        },
      ],
      {
        baseURL: HOST,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const scenarios = await getDataAsJSON(response)
    const mappedScenarios = scenarios.map(({ data, ...rest }) => ({
      data: decode(data),
      ...rest,
    }))
    return Promise.resolve(mappedScenarios)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDilutionScenario = async ({ id }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${id}`
    const response = await API.get(endpoint, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data, ...rest } = await getDataAsJSON(response)
    const mappedScenario = {
      data: decode(data),
      ...rest,
    }
    // console.log('Fetched data:', mappedScenario)
    return Promise.resolve(mappedScenario)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addDilutionScenario = async ({ body }) => {
  try {
    const endpoint = `/scenarios/Scenarios`
    const data = encode(body)
    // console.log('Added data:', data)
    const requestData = await setDataAsString({ data })
    const response = await API.post(endpoint, requestData, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateDilutionScenario = async (updateScenarioItem) => {
  try {
    const {
      body: { data, ...rest },
    } = updateScenarioItem
    const endpoint = `/scenarios/Scenarios`
    const encodedData = encode({ data })
    // console.log('Updated data:', encodedData)
    const requestData = await setDataAsString({
      data: encodedData,
      ...rest,
    })
    const response = await API.put(endpoint, requestData, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteDilutionScenario = async ({ id }) => {
  try {
    const endpoint = `/scenarios/Scenarios/${id}`
    const response = await API.delete(endpoint, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDilutionProjects = async () => {
  try {
    const response = await API.get(`/scenarios/Projects`, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const projects = await getDataAsJSON(response)
    return Promise.resolve(projects)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDilutionProjectsByUserId = async ({ userId }) => {
  try {
    const response = await API.post(
      '/scenarios/Projects/query',
      [
        {
          item: 'assignedUsers',
          queryOperator: 'Like',
          value: '%"' + userId + '"%',
        },
      ],
      {
        baseURL: HOST,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const projects = await getDataAsJSON(response)
    return Promise.resolve(projects)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDilutionProject = async ({ id }) => {
  try {
    const endpoint = `/scenarios/Projects/${id}`
    const response = await API.get(endpoint, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const project = await getDataAsJSON(response)
    return Promise.resolve(project)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addDilutionProject = async (values) => {
  try {
    const endpoint = `/scenarios/Projects`
    const requestData = await setDataAsString({ data: values })
    const response = await API.post(endpoint, requestData, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateDilutionProject = async (values, meta) => {
  try {
    const endpoint = `/scenarios/Projects`
    const requestData = await setDataAsString({ data: values, ...meta })
    const response = await API.put(endpoint, requestData, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data: responseData } = response || {}
    return Promise.resolve(responseData)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteDilutionProject = async ({ id }) => {
  try {
    const endpoint = `/scenarios/Projects/${id}`
    const response = await API.delete(endpoint, {
      baseURL: HOST,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDischargeValuesFromFile = async ({ file }) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    const endpoint = `/scenarios/convert-to-source-discharges`
    const response = await API.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      baseURL: ROOT,
    })
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getShapeFile = async ({ path, itemNumber, step }) => {
  try {
    const response = await API.get(
      `/shape/${path}?itemNumber=${itemNumber}&timeStep=${step}`,
      {
        responseType: 'blob',
        baseURL: ROOT,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDepth = async ({ model, latitude, longitude }) => {
  try {
    const response = await API.get(
      `/models/${model}/depth?lat=${latitude}&lon=${longitude}`,
      {
        baseURL: ROOT,
      }
    )
    if (!response) throw Error(`Request rejected`)
    const { data } = response || {}
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
