import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import set from 'lodash/set'
import isEqual from 'lodash/isEqual'

import Block from 'components/Block'

import { ToolContext } from 'containers/NewFishfarm'

const Step = ({ form }) => {
  const { states } = useContext(ToolContext)
  const { mapbox } = states
  const {
    setMapEditor,
    mapEditableData,
    setMapIsEditable,
    setMapEditableData,
  } = mapbox
  const { values, handleChange, setFieldValue } = form
  const { siteInformation } = values
  const { features, title } = siteInformation
  const hasFeatures = !!features.length
  const { id } = useParams()

  useEffect(() => {
    setMapIsEditable(true)
    if (!id) {
      const conditions = features.map(() => '')
      setFieldValue('conditions.bedShear', conditions)
      setFieldValue('conditions.bedShear2mo', conditions)
      setFieldValue('conditions.botTemp', conditions)
      setFieldValue('conditions.botTemp2mo', conditions)
      setFieldValue('conditions.botCur', conditions)
      setFieldValue('conditions.surfTemp', conditions)
      setFieldValue('conditions.surfCur', conditions)
      setFieldValue('conditions.dayLength', conditions)
    }
    return () => {
      setMapIsEditable(false)
    }
  }, [])

  useEffect(() => {
    const mapEditorOptions = {
      withPoint: true,
      withPolygon: false,
      withPath: false,
    }
    setMapEditor(mapEditorOptions)
    return () => setMapEditor({ ...mapEditorOptions, withPoint: false })
  }, [])

  useEffect(() => {
    const isUpdated = !isEqual(mapEditableData, features)
    if (isUpdated) handleFeaturesFromMap(mapEditableData)
  }, [mapEditableData])

  const handleFeaturesDecode = featuresFromMap =>
    featuresFromMap.map(feature => {
      const { geometry } = feature
      const { coordinates } = geometry
      const [longitude, latitude] = coordinates
      const lng = Number(longitude).toFixed(6)
      const lat = Number(latitude).toFixed(6)
      return { lng, lat }
    })

  const handleFeaturesFromMap = ({ features: featuresFromMap }) => {
    const positions = handleFeaturesDecode(featuresFromMap)
    setFieldValue('siteInformation.features', positions)
  }

  const handleChangeAndUpdateMap = ({ target }, index, type) => {
    const { value } = target
    const valueAsNumber = Number(value)
    const isLng = type === 'lng'
    const coordinateIndex = isLng ? 0 : 1
    const identifier = `features[${index}].geometry.coordinates[${coordinateIndex}]`
    const newMapEditableData = set(
      { ...mapEditableData },
      identifier,
      valueAsNumber
    )
    setMapEditableData(newMapEditableData)
  }

  return (
    <Box width={1} height={1}>
      <Box marginBottom={4}>
        <Typography variant="h6">Scenario name and site location</Typography>
      </Box>

      <Box marginBottom={4}>
        <Block headline="Title">
          <TextField
            fullWidth
            onChange={handleChange}
            id="siteInformation.title"
            name="siteInformation.title"
            placeholder="Type in a title"
            value={title}
          />
        </Block>
      </Box>
      {hasFeatures ? (
        <>
          {features.map(({ lng, lat }, index) => (
            <Box key={index} marginBottom={4}>
              <Block headline={`Point ${index + 1}`}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      onBlur={event =>
                        handleChangeAndUpdateMap(event, index, 'lat')
                      }
                      onChange={handleChange}
                      id={`siteInformation.features.${index}.lat`}
                      name={`siteInformation.features.${index}.lat`}
                      label="Latitude"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">°N</InputAdornment>
                        ),
                      }}
                      value={lat}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      onBlur={event =>
                        handleChangeAndUpdateMap(event, index, 'lng')
                      }
                      onChange={handleChange}
                      id={`siteInformation.features.${index}.lng`}
                      name={`siteInformation.features.${index}.lng`}
                      label="Longitude"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">°E</InputAdornment>
                        ),
                      }}
                      value={lng}
                    />
                  </Grid>
                </Grid>
              </Block>
            </Box>
          ))}
        </>
      ) : (
        <Box
          border={1}
          bgcolor="shades.light"
          borderColor="shades.medium"
          padding={3}
          height={1}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="secondary">No features added.</Typography>
        </Box>
      )}
    </Box>
  )
}

export default Step
