import React, { useState } from 'react'
import { compose } from 'recompose'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import tools from 'config/tools'

import useUser from 'hooks/useUser'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const MenuArea = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { user, isAdmin } = useUser()
  const { name, company } = user !== null ? user : {}

  return (
    <>
      <div className={classes.buttonsArea}>
        <Typography component="span" variant="h6" className={classes.nameTag}>
          {name} - {company}
        </Typography>

        <IconButton onClick={handleClick}>
          <MenuIcon />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <List
            subheader={<ListSubheader component="div">Tools</ListSubheader>}
          >
            {tools.map(tool => (
              <ListItem
                component={Link}
                to={tool.path}
                key={tool.path}
                dense
                disableGutters
              >
                <MenuItem className={classes.linkItem} onClick={handleClose}>
                  {tool.title}
                </MenuItem>
              </ListItem>
            ))}
          </List>

          <List
            subheader={<ListSubheader component="div">Projects</ListSubheader>}
          >
            <ListItem
              component={Link}
              to="/dilution/projects"
              dense
              disableGutters
            >
              <MenuItem className={classes.linkItem} onClick={handleClose}>
                EnviroCast
              </MenuItem>
            </ListItem>
          </List>

          {isAdmin && (
            <List
              subheader={
                <ListSubheader component="div">Administration</ListSubheader>
              }
            >
              <ListItem component={Link} to="/users" dense disableGutters>
                <MenuItem className={classes.linkItem} onClick={handleClose}>
                  Users
                </MenuItem>
              </ListItem>
            </List>
          )}

          <List
            subheader={<ListSubheader component="div">Account</ListSubheader>}
          >
            <ListItem component={Link} to="/logout" dense disableGutters>
              <MenuItem className={classes.linkItem} onClick={handleClose}>
                Logout
              </MenuItem>
            </ListItem>
          </List>
        </Menu>
      </div>
    </>
  )
}

export default enhancer(MenuArea)
