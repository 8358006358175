import groups from '../steps/Step2/lib/groups';

export default ({ initialValues = {} }) => {
  const { csv = {}, dfsu = {} } = initialValues;
  return {
    csv: {
      file: csv.file || '',
    },
    dfsu: {
      file: dfsu.file || '',
      groups: dfsu.groups || groups,
    },
  };
};
