import { atom, selector } from 'recoil'

import { usersModels as usersModelsState } from 'states/user'

export const models = atom({
  key: 'models',
  default: [],
})

export const allowedModels = selector({
  key: 'allowedModels',
  get: ({ get }) => {
    const allModels = get(models)
    const usersModels = get(usersModelsState)
    console.log('allowed models', { usersModels })
    return allModels.filter(({ id: allModelsItemId }) => {
      const isAvailable = usersModels.find(
        (userModelsItemId) => userModelsItemId === allModelsItemId
      )
      return !!isAvailable
    })
  },
})

export default models
