import React, { useContext } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { DataviewerContext } from 'containers/Dataviewer';

const Tools = () => {
  const {
    state: { position },
    actions,
  } = useContext(DataviewerContext);
  return (
    <FeatureGroup>
      <EditControl
        position="topleft"
        onCreated={actions.onCreated}
        onDeleted={actions.onDeleted}
        onEdited={actions.onEdited}
        onMounted={actions.onMounted}
        draw={{
          circle: false,
          marker: false,
          polyline: false,
          rectangle: false,
        }}
        edit={{
          edit: position.exists,
          remove: position.exists,
        }}
      />
    </FeatureGroup>
  );
};

export default Tools;
