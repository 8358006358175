import React from 'react'
import { Drawer as MaterialDrawer } from '@material-ui/core'

import Container from './partials/Container'
import Header from './partials/Header'
import Content from './partials/Content'
import Footer from './partials/Footer'

import useStyles from './hooks/useStyles'

const Drawer = ({ children, open = true }) => {
  const classes = useStyles()
  return (
    <MaterialDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {children}
    </MaterialDrawer>
  )
}

Drawer.Container = Container
Drawer.Header = Header
Drawer.Content = Content
Drawer.Footer = Footer

export default Drawer
