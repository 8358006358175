import React, { useEffect, useMemo } from 'react'
import { useResetRecoilState } from 'recoil'
import Box from '@material-ui/core/Box'

import DeckGLMap from 'components/DeckGLMap'
import LayerManager from 'components/LayerManager'

import useOverlays from 'containers/Dilution/hooks/useOverlays'
import useDischargeOutlets from 'containers/Dilution/hooks/useDischargeOutlets'
import activeModelState from 'containers/Dilution/states/activeModel'
import activeScenarioState from 'containers/Dilution/states/activeScenarioType'
import { mapOverlays } from 'containers/Dilution/states/mapOverlays'

import useDampingLayer from './hooks/useDampingLayer'
import useEditLayers from './hooks/useEditLayers'
import EditControl from './hooks/editControl'

import Wizard from './partials/Wizard'

const Form = () => {
  const resetActiveModel = useResetRecoilState(activeModelState)
  const resetMapOverlays = useResetRecoilState(mapOverlays)
  const resetActiveScenario = useResetRecoilState(activeScenarioState)

  const editLayers = useEditLayers()
  const geojsons = useDischargeOutlets({ id: 'edit-view' })
  const dampingLayer = useDampingLayer()
  const overlays = useOverlays({ id: 'edit-view' })

  const layers = useMemo(
    () => [...overlays, geojsons, editLayers, dampingLayer],
    [geojsons, editLayers, dampingLayer, overlays]
  )

  useEffect(() => {
    return () => {
      resetActiveModel()
      resetMapOverlays()
      resetActiveScenario()
    }
  }, [])

  return (
    <>
      <Box width={0.5} height="calc(100vh - 80px)">
        <DeckGLMap layers={layers}>
          <LayerManager />
          <EditControl />
        </DeckGLMap>
      </Box>
      <Box>
        <Wizard />
      </Box>
    </>
  )
}

export default Form
