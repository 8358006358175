import React from 'react';
import { ImageOverlay } from 'react-leaflet';
import L from 'leaflet';

const WmsSingleTile = ({ attribution, layers, map, transparent, url }) => {
  // ImageOverlay geographical bounds (in Lat/Lng)
  const corner1 = L.latLng(
    map.leafletElement.getBounds().getSouth(),
    map.leafletElement.getBounds().getWest()
  );
  const corner2 = L.latLng(
    map.leafletElement.getBounds().getNorth(),
    map.leafletElement.getBounds().getEast()
  );
  const bounds = L.latLngBounds(corner1, corner2);

  // GetMap bounding box in EPSG:3857 (map looks distorted when using EPSG:4326)
  const c1 = L.Projection.SphericalMercator.project(corner1);
  const c2 = L.Projection.SphericalMercator.project(corner2);
  const bbox = c1.x + ',' + c1.y + ',' + c2.x + ',' + c2.y;

  // Pixel sizes
  const size = map.leafletElement.getSize();
  const height = size.y;
  const width = size.x;

  // url
  const urlBase = url;
  const urlLayers = '&LAYERS=' + layers;
  const urlGeo = '&BBOX=' + bbox + '&WIDTH=' + width + '&HEIGHT=' + height;
  const formattedUrl = urlBase + urlLayers + urlGeo;
  // url="http://data.geus.dk/geusmap/ows/25832.jsp?nocache=nocache&whoami=193.3.62.162&LAYERS=sediment_250000_eng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&STYLES=&SRS=EPSG:3857&BBOX=611496.2262814101,7024868.647520842,2059519.2901157893,8092541.058608185&WIDTH=1184&HEIGHT=873"

  return (
    <ImageOverlay
      attribution={attribution}
      bounds={bounds}
      url={formattedUrl}
      transparent={transparent}
    />
  );
};

export default WmsSingleTile;
