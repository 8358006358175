// TODO: This looks like a spaceship. Let's get rid of it :-)

// import { range } from 'lodash'
// import { addDays, eachDay, format, setHours } from 'date-fns'
import { addSeconds, differenceInSeconds, format as formatDate } from 'date-fns'

import { HOST, SEDIMENT_CLASSES } from '../containers/SedimentSpill/config'

// const buildDates = () =>
//   eachDay(StartDate, EndDate).reduce((allDaysWithHours, thisDay) => {
//     const hours = range(24).map(hour =>
//       format(setHours(thisDay, hour), 'YYYY-MM-DDTHH:mm:ss')
//     )
//     allDaysWithHours.push(...hours)
//     return allDaysWithHours
//   }, [])

// const getSedimentClass = async (point, map) => {
//   try {
//     const size = map._size;
//     const { _southWest, _northEast } = map.getBounds();

//     const southWest = `${_southWest.lat},${_southWest.lng}`;
//     const northEast = `${_northEast.lat},${_northEast.lng}`;

//     const baseUrl = HOST + '/geoserver/SeaStatus/wms';
//     const query = stringify({
//       service: 'wms',
//       version: '1.3.0',
//       request: 'GetFeatureInfo',
//       layers: 'SeaStatus%3ASedimentClassesDK',
//       styles: 'polygon',
//       crs: 'EPSG%3A4326',
//       info_format: 'application%2Fjson',
//       format: 'image%2Fpng',
//       width: size.x,
//       height: size.y,
//       query_layers: 'SeaStatus%3ASedimentClassesDK',
//       TYPENAME: 'SeaStatus%3ASedimentClassesDK',
//       I: point.x,
//       J: point.y,
//       bbox: `${southWest},${northEast}`,
//     });
//     const endpoint = baseUrl + stringify({ ...query });

//     const response = await API.post(endpoint);

//     const { data } = response || {};

//     const sedimentClass = SEDIMENT_CLASSES.filter(
//       sediment => sediment.SedimentType === data.features[0].properties.Sediment
//     );
//     return Promise.resolve(sedimentClass[0]);
//   } catch (err) {
//     console.error(err.response);
//     return Promise.resolve({
//       SedimentType: 'Unknown',
//       Composition: {
//         Clay: 0,
//         Silt: 0,
//         Sand: 0,
//         CoarseSand: 0,
//       },
//     });
//   }
// };

const getSedimentClass = async (point, map) => {
  try {
    const size = map._size
    const bbox = map.getBounds()

    const url =
      'https://cors-anywhere.herokuapp.com/' +
      HOST +
      '/geoserver/SeaStatus/wms?service=wms&version=1.3.0&request=GetFeatureInfo&layers=SeaStatus%3ASedimentClassesDK&styles=polygon&crs=EPSG%3A4326&info_format=application%2Fjson&format=image%2Fpng&bbox=' +
      bbox._southWest.lat +
      ',' +
      bbox._southWest.lng +
      ',' +
      bbox._northEast.lat +
      ',' +
      bbox._northEast.lng +
      '&width=' +
      size.x +
      '&height=' +
      size.y +
      '&query_layers=SeaStatus%3ASedimentClassesDK&TYPENAME=SeaStatus%3ASedimentClassesDK&I=' +
      point.x +
      '&J=' +
      point.y

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const json = await res.json()

    const sedimentClass = SEDIMENT_CLASSES.filter(
      sediment => sediment.SedimentType === json.features[0].properties.Sediment
    )

    return sedimentClass[0]
  } catch (err) {
    console.error(err)
    return {
      SedimentType: 'Unknown',
      Composition: {
        Clay: 0,
        Silt: 0,
        Sand: 0,
        CoarseSand: 0,
      },
    }
  }
}

const generateDates = file => {
  let timeSpan = parseTimespan(file.timeSpan)
  let iterations =
    differenceInSeconds(timeSpan.end, timeSpan.start) / file.timeStep

  let dates = []
  for (let i = 0; i < iterations; i++) {
    dates = [...dates, addSeconds(new Date(timeSpan.start), i * file.timeStep)]
  }

  dates = dates.map(date => formatDate(date, `YYYY-MM-DDTHH:mm:ss`))

  return dates
}

const parseTimespan = timeSpan => {
  let momentTimeSpan = []
  let now = new Date().now
  if (timeSpan.type === 'RelativeToNow') {
    momentTimeSpan.start = new Date(now).add(timeSpan.start, timeSpan.unit)
    momentTimeSpan.end = new Date(now).add(timeSpan.end, timeSpan.unit)
    return momentTimeSpan
  } else if (timeSpan.type === 'Fixed') {
    momentTimeSpan.start = new Date(timeSpan.start)
    momentTimeSpan.end = new Date(timeSpan.end)
    return momentTimeSpan
  } else if (timeSpan.type === 'RelativeToData') {
    return timeSpan
  }
}

const formatValues = timeseries => {
  return Object.keys(timeseries).map(tsId => ({
    id: tsId,
    data: timeseries[tsId],
  }))
}

const toDateTimeString = dateString => {
  return dateString.replace(new RegExp(':', 'g'), '').split('.')[0]
}

const toArray = any => {
  if (!Array.isArray(any)) return [any]

  return any
}

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const uniqueId = () => {
  return (
    formatDate(new Date(), 'YYYYMMDDhhmmss') +
    '-' +
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

const debounce = (delay, fn) => {
  let timerId
  return function(...args) {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

const getObjectProperty = (objectItem, property, compareValue) => {
  let valid = true
  const properties = property.split('.')
  let value = objectItem
  for (let i = 0; i < properties.length; i++) {
    if (properties[i].indexOf('!') >= 0) {
      valid = !valid
      properties[i] = properties[i].replace('!', '')
    }
    value = value[properties[i]]
  }
  if (compareValue) {
    if (typeof compareValue === 'object') {
      for (let i = 0; i < compareValue.length; i++) {
        if (value === compareValue[i]) {
          return valid
        }
      }
      return !valid
    }
    return valid ? value === compareValue : !(value === compareValue)
  }
  return valid ? value : !value
}

const colorToRGB = color => {
  if (color.indexOf('rgb') > -1) {
    return {
      R: color
        .split('(')[1]
        .split(')')[0]
        .split(',')[0],
      G: color
        .split('(')[1]
        .split(')')[0]
        .split(',')[1],
      B: color
        .split('(')[1]
        .split(')')[0]
        .split(',')[2],
    }
  }

  return {
    R: parseInt(color.substring(1, 3), 16),
    G: parseInt(color.substring(3, 5), 16),
    B: parseInt(color.substring(5, 7), 16),
  }
}

// const onMapClicked = (e, data) => {
//    this.getJsonInfo(e.detail.latlng);
// };

// const getBBox = () => {
//   var sw = this.$.mapArea.map.getBounds().getSouthWest();
//   var ne = this.$.mapArea.map.getBounds().getNorthEast();
//   var bbox = sw.lat + ',' + sw.lng + ',' + ne.lat + ',' + ne.lng;
//   return bbox;
// }

// const getJsonInfo = latlng => {
//   var point = this.$.mapArea.map.latLngToContainerPoint(
//     latlng,
//     this.$.mapArea.map.getZoom()
//   );
//   var size = this.$.mapArea.map.getSize();
//   var requestOptions = {
//     url:
//       this.configuration.settings.host +
//       '/geoserver/SeaStatus/wms?service=wms&version=1.3.0&request=GetFeatureInfo&layers=SeaStatus%3ASedimentClassesDK&styles=polygon&crs=EPSG%3A4326&info_format=application%2Fjson&format=image%2Fpng&bbox=' +
//       this.getBBox() +
//       '&width=' +
//       size.x +
//       '&height=' +
//       size.y +
//       '&query_layers=SeaStatus%3ASedimentClassesDK&TYPENAME=SeaStatus%3ASedimentClassesDK&I=' +
//       point.x +
//       '&J=' +
//       point.y,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json'
//     },
//     async: true
//   };
//   request.send(requestOptions);
// };

const passwordStrength = password => {
  let score = 0

  if (!password) {
    return 0
  }

  // Length 4 or less
  if (password.length < 5) {
    score += 3
    // Length between 5 and 7
  } else if (password.length < 8) {
    score += 6
    // Length between 8 and 15
  } else if (password.length < 16) {
    score += 12
    // Length 16 or more
  } else {
    score += 18
  }

  // At least one lower case letter
  if (password.match(/[a-z]/)) {
    score += 1
  }

  // At least one upper case letter
  if (password.match(/[A-Z]/)) {
    score += 5
  }

  // At least one number
  if (password.match(/\d+/)) {
    score += 5
  }

  // At least three numbers
  if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
    score += 5
  }

  // At least one special character
  if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~]/)) {
    score += 5
  }

  // Aat least two special characters
  if (password.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)) {
    score += 5
  }

  // Combinations both upper and lower case
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    score += 2
  }

  // Both letters and numbers
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    score += 2
  }

  // Letters, numbers, and special characters
  if (
    password.match(
      /([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/
    )
  ) {
    score += 2
  }

  if (score < 16) {
    return 0
  }

  if (score < 25) {
    return 1
  }

  if (score < 35) {
    return 2
  }

  if (score < 45) {
    return 3
  }

  return 0
}

export {
  getSedimentClass,
  formatValues,
  toArray,
  toDateTimeString,
  uniqueId,
  debounce,
  getObjectProperty,
  colorToRGB,
  generateDates,
  passwordStrength,
}
