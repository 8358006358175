import { object, number } from 'yup'

import Step6 from './Step6'

export default {
  component: Step6,
  validationSchema: () =>
    object().shape({
      postProcessing: object().shape({
        fractileFractions: object().shape({
          first: number().min(0).max(1),
          second: number().min(0).max(1),
        }),
      }),
    }),
}
