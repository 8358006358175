import React, { useEffect, useState, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { Field } from 'formik'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import RadioChecked from '@material-ui/icons/RadioButtonChecked'
import RadioUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { format } from 'date-fns'
import { WebMercatorViewport } from '@deck.gl/core'

import { useTranslation } from 'react-i18next'

import Block from 'components/Block'
import DropdownSearch from 'components/DropdownSearch'
import ErrorMessage from 'components/ErrorMessage'
import TimePicker from './partials/TimePicker'

import viewState from 'components/DeckGLMap/states/view'
import Popper from 'components/Popper'
import { allowedModels as modelsState } from 'containers/Dilution/states/models'
import projectsState from 'containers/Dilution/states/projects'
import { allowedScenarioTypes as scenarioTypesState } from 'containers/Dilution/states/scenarioTypes'
import activeModelIdState, {
  activeModelState,
} from 'containers/Dilution/states/activeModel'

import activeScenarioTypeIdState, {
  scenarioPeriods as scenarioPeriodsState,
  options as optionsState,
  predefinedComponents as predefinedComponentsState,
} from 'containers/Dilution/states/activeScenarioType'

import useScenarioPeriods from '../../hooks/useScenarioPeriods'

const Step = ({ form, shared: { id: scenarioId } }) => {
  const models = useRecoilValue(modelsState)
  const projects = useRecoilValue(projectsState)
  const scenarioTypes = useRecoilValue(scenarioTypesState)
  const scenarioPeriods = useRecoilValue(scenarioPeriodsState)
  const predefinedComponents = useRecoilValue(predefinedComponentsState)
  const options = useRecoilValue(optionsState)
  const [activeModelId, setActiveModelId] = useRecoilState(activeModelIdState)
  const activeModel = useRecoilValue(activeModelState)
  const setActiveScenarioType = useSetRecoilState(activeScenarioTypeIdState)
  const [view, setView] = useRecoilState(viewState)
  const { state = {} } = useLocation()
  const { id: stateId } = state
  const translation = useTranslation()
  const { t } = translation
  const {
    runHDOptionUserDefined = false,
    scenarioPeriodLocked = false,
  } = useMemo(() => options, [options])

  const {
    values: { definition },
    handleChange,
    setFieldValue,
    errors,
  } = form

  const {
    title,
    description,
    project,
    model,
    type,
    startDate,
    endDate,
    runHd,
  } = definition

  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')
  const [firstTime, setFirstTime] = useState(true)

  const {
    label: periodLabel,
    start: periodStart,
    end: periodEnd,
  } = useScenarioPeriods(startDate, endDate)

  const handleTypeChange = (typeItem) => {
    const { options: typeOptions } = typeItem
    setFieldValue('definition.type', typeItem)
    setFieldValue('definition.runHd', typeOptions.runHDOption)
    setFieldValue('outlet.hypotheticalOutlet', [])
  }

  const handleTimePickerChange = ({ target: { value } }) => {
    const matcher = ({ label }) => value === label
    const period = scenarioPeriods.find(matcher) || {}
    const { start, end } = period
    setFieldValue('definition.startDate', format(new Date(start), 'YYYY-MM-DD'))
    setFieldValue('definition.endDate', format(new Date(end), 'YYYY-MM-DD'))
  }

  useEffect(() => {
    if (!!periodStart.length) {
      setFieldValue(
        'definition.startDate',
        format(new Date(periodStart), 'YYYY-MM-DD')
      )
    }
  }, [periodStart])

  useEffect(() => {
    if (!!periodEnd.length) {
      setFieldValue(
        'definition.endDate',
        format(new Date(periodEnd), 'YYYY-MM-DD')
      )
    }
  }, [periodEnd])

  useEffect(() => {
    if (model.id) {
      setActiveModelId(model.id)
    }
  }, [model.id])

  useEffect(() => {
    if (type.id) {
      setActiveScenarioType(type.id)
    }
  }, [type.id])

  useEffect(() => {
    if (state.projectId) setFieldValue('definition.project.id', state.projectId)
  }, [state.projectId])

  useEffect(() => {
    if (activeModelId && activeModelId !== null) {
      const {
        bounds = undefined,
        startDate: modelStartDate = '',
        endDate: modelEndDate = '',
      } = activeModel

      if (!firstTime) {
        setFieldValue(
          'definition.startDate',
          format(new Date(modelStartDate), 'YYYY-MM-DD')
        )

        setFieldValue(
          'definition.endDate',
          format(new Date(modelEndDate), 'YYYY-MM-DD')
        )
      }

      if (!!modelStartDate.length) {
        const minDateToSet = format(new Date(modelStartDate), 'YYYY-MM-DD')
        setMinDate(minDateToSet)
      }

      if (!!modelEndDate.length) {
        const maxDateToSet = format(new Date(modelEndDate), 'YYYY-MM-DD')
        setMaxDate(maxDateToSet)
      }

      if (bounds) {
        const viewPort = new WebMercatorViewport(view)
        const [first, second] = bounds
        const [firstLat, firstLong] = first
        const [secondLat, secondLong] = second
        const { latitude, longitude, zoom } = viewPort.fitBounds([
          [firstLong, firstLat],
          [secondLong, secondLat],
        ])

        setView({ ...view, latitude, longitude, zoom })
      }
      setFirstTime(false)
    }
  }, [activeModelId])

  useEffect(() => {
    if (!scenarioId && !stateId && !!predefinedComponents.length) {
      setFieldValue(
        'components',
        predefinedComponents.map((item) => {
          const componentDescription =
            item.componentDescription === 'New tracer 1'
              ? t('common:newTracer', { suffix: '1' })
              : item.componentDescription
          return { ...item, componentDescription }
        })
      )
    }
  }, [predefinedComponents])

  useEffect(() => {
    if (scenarioTypes.length === 1) {
      const [scenarioType] = scenarioTypes
      handleTypeChange(scenarioType)
    }
  }, [scenarioTypes])

  const handleModelChange = (data) => {
    console.log('handle model change', { data })
    setFieldValue('definition.model', data)
    setFieldValue('outlet.hypotheticalOutlet', [])
  }

  const hasModels = !!models.length > 0

  // useEffect(() => {
  //   if (models.length === 1) {
  //     const [modelToSet] = models
  //     handleModelChange(modelToSet)
  //   }
  // }, [models])

  if (!hasModels) return null

  return (
    <>
      <Box
        width={1}
        height={1}
        display="flex"
        gridGap={4}
        flexDirection="column"
      >
        <Box display="flex" justifyContent="flex-end">
          <Popper
            icon={<HelpOutlineIcon />}
            popperContent={t('documentation:definitionTitle')}
            placement="left"
          />
        </Box>
        <Box display="flex" gridGap={30} alignItems="center">
          <Block headline={t('common:title')}>
            <TextField
              onChange={handleChange}
              id="definition.title"
              fullWidth
              autoComplete="off"
              name="definition.title"
              placeholder={t('common:typeIn', { suffix: t('common:title') })}
              value={title}
            />
          </Block>
          <Block headline={t('common:project')}>
            <Field
              component={DropdownSearch}
              onChange={({ id }) => {
                setFieldValue('definition.project', { id })
              }}
              autoComplete="off"
              fullWidth
              required
              id="definition.project"
              name="definition.project"
              placeholder={t('common:select', { suffix: t('common:projects') })}
              margin="dense"
              variant="outlined"
              options={projects}
              disabled={!!stateId || projects.length === 1}
              getOptionLabel={({ data }) => {
                const { title } = data
                return title
              }}
              value={projects.find(
                (projectItem) => projectItem.id === project.id
              )}
            />
          </Block>
        </Box>
        <Box>
          <ErrorMessage
            errors={errors}
            fields={['definition.title', 'definition.project']}
          />
        </Box>
        <Box>
          <Block headline={t('common:description')}>
            <TextField
              onChange={handleChange}
              autoComplete="off"
              fullWidth
              id="definition.description"
              name="definition.description"
              placeholder={t('common:typeIn', {
                suffix: t('common:description'),
              })}
              value={description}
            />
          </Block>
        </Box>
        <Box>
          <Block headline={t('common:geographicalDomain')}>
            <Field
              component={DropdownSearch}
              onChange={handleModelChange}
              autoComplete="off"
              fullWidth
              value={model}
              id="definition.model"
              name="definition.model"
              placeholder={t('common:select', {
                suffix: t('common:model'),
              })}
              margin="dense"
              variant="outlined"
              options={models}
              disabled={
                !!stateId || !!scenarioId
                // || models.length === 1
              }
              getOptionLabel={(option) => {
                const matcher = (item) => item.id === option.id
                const { label } = models.find(matcher) || {}
                return label
              }}
            />
          </Block>
        </Box>
        <Box>
          <ErrorMessage errors={errors} fields={['definition.model']} />
        </Box>
        {!!model.id && (
          <Box marginBottom={4}>
            <Block headline={t('common:scenarioType')}>
              {!scenarioTypes.length ? (
                <Box
                  padding={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="shades.light"
                  border={1}
                  borderColor="shades.medium"
                >
                  <Typography color="primary" variant="subtitle1">
                    {t('common:select', { suffix: t('common:scenario') })}
                  </Typography>
                </Box>
              ) : (
                <List>
                  {scenarioTypes.map((typeItem) => {
                    const { id, label, description } = typeItem
                    const isSelected = type.id === typeItem.id
                    return (
                      <ListItem
                        key={id}
                        dense
                        button
                        disabled={!!stateId || !!scenarioId}
                        disableGutters
                        onClick={() => handleTypeChange(typeItem)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={isSelected}
                            onChange={() => handleTypeChange(typeItem)}
                            icon={<RadioUnchecked fontSize="small" />}
                            checkedIcon={<RadioChecked fontSize="small" />}
                            color="primary"
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={label} secondary={description} />
                      </ListItem>
                    )
                  })}
                </List>
              )}
              <ErrorMessage errors={errors} fields={['definition.type']} />
            </Block>
          </Box>
        )}
        {runHDOptionUserDefined && (
          <Box>
            <Block headline={t('dilution:hydrodynamicBasis')}>
              <List>
                <ListItem
                  disabled={!runHDOptionUserDefined}
                  dense
                  button
                  disableGutters
                  onClick={() => setFieldValue('definition.runHd', false)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={!runHd}
                      onChange={() => setFieldValue('definition.runHd', false)}
                      icon={<RadioUnchecked fontSize="small" />}
                      checkedIcon={<RadioChecked fontSize="small" />}
                      color="primary"
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('dilution:hydrodynamicRadio1Primary')}
                    secondary={t('dilution:hydrodynamicRadio1Secondary')}
                  />
                </ListItem>
                <ListItem
                  disabled={!runHDOptionUserDefined}
                  dense
                  button
                  disableGutters
                  onClick={() => setFieldValue('definition.runHd', true)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={runHd}
                      onChange={() => setFieldValue('definition.runHd', true)}
                      icon={<RadioUnchecked fontSize="small" />}
                      checkedIcon={<RadioChecked fontSize="small" />}
                      color="primary"
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('dilution:hydrodynamicRadio2Secondary')}
                    secondary={t('dilution:hydrodynamicRadio1Secondary')}
                  />
                </ListItem>
              </List>
            </Block>
          </Box>
        )}
        {!!type.id && (
          <Box>
            <Block headline={t('common:scenarioPeriod')}>
              <Grid container spacing={2}>
                {!scenarioPeriodLocked && (
                  <>
                    <Grid item xs={6}>
                      <Field
                        disabled={scenarioPeriodLocked}
                        id="definition.startDate"
                        name="definition.startDate"
                        value={startDate}
                        onChange={handleChange}
                        type="date"
                        component={TextField}
                        autoComplete="off"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            min: minDate,
                            max: maxDate,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        disabled={scenarioPeriodLocked}
                        id="definition.endDate"
                        name="definition.endDate"
                        value={endDate}
                        onChange={handleChange}
                        type="date"
                        component={TextField}
                        autoComplete="off"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            min: minDate,
                            max: maxDate,
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <TimePicker
                handleChange={handleTimePickerChange}
                value={periodLabel}
                values={scenarioPeriods}
              />
              <ErrorMessage
                errors={errors}
                fields={['definition.startDate', 'definition.endDate']}
              />
            </Block>
          </Box>
        )}
      </Box>
    </>
  )
}

export default Step
