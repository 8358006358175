export default () => ({
  footer: {
    //keeps footer to bottom
    position: 'absolute',
    width: '100%',
    bottom: 0,
    height: '3rem',
    //keeps footer to bottom

    zIndex: 100,
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
});
