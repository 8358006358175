import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import eachDay from 'date-fns/each_day'
import isSameDay from 'date-fns/is_same_day'

import { getTimeseries } from 'data/dilution'

import timestepsState from 'containers/Dilution/partials/View/states/timesteps'
import dayState from 'containers/Dilution/partials/View/states/day'

const replacer = String.fromCharCode(47) + String.fromCharCode(47)

const useTimesteps = (selectedItem) => {
  const [day, setDay] = useRecoilState(dayState)
  const withSelectedItem = !!Object.keys(selectedItem).length

  const [timesteps, setTimesteps] = useRecoilState(timestepsState)

  const {
    timeSeriesConnectionId: connectionId,
    path,
    itemNumber,
    baseURL,
  } = selectedItem

  const handleSelectedItem = async () => {
    try {
      const timeseries = await getTimeseries({
        connectionId,
        path,
        itemNumber,
        baseURL,
      })

      const reducer = (allTimesteps, thisTimestep) => {
        const newPath = path.replace('|', replacer).replace('|', replacer)
        allTimesteps[thisTimestep] = newPath
        return allTimesteps
      }

      const allTimesteps = timeseries.dateTimes.reduce(reducer, {})
      const { 0: first, length: l, [l - 1]: last } = Object.keys(allTimesteps)
      const days = eachDay(first, last)

      if (days.length === 1) setDay(1)

      const isLastDay = day === Object.keys(days).length

      const chosenTimesteps = Object.values(days).filter((timestep, index) => {
        const dayIndex = index + 1
        const matchingDay = dayIndex === day
        const matchingSidekick = isLastDay
          ? dayIndex === day - 1
          : dayIndex === day + 1
        return matchingDay || matchingSidekick
      })

      const requestingTimesteps = Object.entries(allTimesteps).reduce(
        (accumulatedTimesteps, [timestep, path]) => {
          const isInChosenRange = chosenTimesteps.some((chosenTime) => {
            const left = new Date(chosenTime)
            const right = new Date(timestep)
            return isSameDay(left, right)
          })

          if (isInChosenRange) {
            accumulatedTimesteps[timestep] = path
          }

          return accumulatedTimesteps
        },
        {}
      )

      setTimesteps({
        timesteps: allTimesteps,
        timestepsByDay: days,
        chosenTimesteps,
        requestingTimesteps,
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (withSelectedItem) {
      handleSelectedItem()
    } else {
      setTimesteps({
        timesteps: {},
        timestepsByDay: {},
        chosenTimesteps: [],
        requestingTimesteps: {},
      })
      setDay(1)
    }
  }, [selectedItem, day])

  return timesteps
}

export default useTimesteps
