import { useEffect, useState } from 'react'
import {
  getDilutionProject,
  addDilutionProject,
  updateDilutionProject,
} from 'data/dilution'

const initialValues = {
  data: {
    title: '',
  },
}

const useProject = ({ id }) => {
  const [project, setProject] = useState(initialValues)

  const getProject = async () => {
    try {
      const projectToSet = await getDilutionProject({ id })
      setProject(projectToSet)
    } catch (error) {
      console.error(error)
    }
  }

  const addProject = async values => {
    try {
      const projectToAdd = await addDilutionProject(values)
      return projectToAdd
    } catch (error) {
      console.error(error)
    }
  }

  const editProject = async (values, meta) => {
    try {
      const projectToEdit = await updateDilutionProject(values, meta)
      return projectToEdit
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (id && id !== null) getProject()
  }, [id])

  return {
    project,
    addProject,
    editProject,
  }
}

export default useProject
