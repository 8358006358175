import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

import Block from 'components/Block'
import Popper from 'components/Popper'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const Step = ({ form }) => {
  const { t } = useTranslation()
  const { values, handleChange, isValid } = form
  const { postProcessing } = values
  const { fractileFractions } = postProcessing
  const { first, second } = fractileFractions

  return (
    <Box width={1} height={1} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Popper
          icon={<HelpOutlineIcon />}
          popperContent={t('documentation:fractileTitle')}
          placement="left"
        />
      </Box>
      <Box marginBottom={4}>
        <Block headline={t('dilution:firstFractileFraction')}>
          <TextField
            type="number"
            fullWidth
            onChange={handleChange}
            id="postProcessing.fractileFractions.first"
            name="postProcessing.fractileFractions.first"
            placeholder="First"
            value={first}
          />
        </Block>
      </Box>

      <Box marginBottom={4}>
        <Block headline={t('dilution:secondFractileFraction')}>
          <TextField
            type="number"
            fullWidth
            onChange={handleChange}
            id="postProcessing.fractileFractions.second"
            name="postProcessing.fractileFractions.second"
            placeholder="Second"
            value={second}
            error={!isValid}
          />
        </Block>
      </Box>
    </Box>
  )
}

export default Step
