export default () => ({
  root: {
    //width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
  },
  paper: {
    //marginTop: theme.spacing(3),
    //width: '100%',
    //overflowX: 'auto',
    //marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    //minWidth: 650,
    display: 'flex',
    flexDirection: 'column',
  },
});
