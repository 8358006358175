import mapPropsToValues from './mapPropsToValues';

import steps from '../steps/index';

export default {
  enableReinitialize: true,
  validationSchema: ({ habitatsActiveStep }) => {
    const activeStep = steps[habitatsActiveStep];
    const { validationSchema = () => ({}) } = activeStep;
    return validationSchema();
  },
  mapPropsToValues,
  async handleSubmit(values, { setSubmitting }) {
    try {
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  },
  displayName: 'HabitatsForm',
};
