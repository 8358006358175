import React, { memo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import CoreWizard, {
  WizardHeader,
  WizardContent,
  WizardFooter,
} from '@dhi-solutions/wizard'

import { addDilutionScenario, updateDilutionScenario } from 'data/dilution'

import { activeModelState } from 'containers/Dilution/states/activeModel'

import Drawer from 'components/Drawer'

import getFullWidthProps from './lib/getFullWidthProps'

import useSteps from './hooks/useSteps'
import useValues from './hooks/useValues'

const fullWidthProps = getFullWidthProps()

const Wizard = memo(() => {
  const { existingSources = [] } = useRecoilValue(activeModelState)

  const withExistingSources = !!existingSources.length
  const { state: locationState = {} } = useLocation()
  const { id: copyId, projectId } = locationState
  const { id } = useParams()
  const { push } = useHistory()
  const { t } = useTranslation()
  const steps = useSteps({ t, withExistingSources })

  const identifier = id || copyId
  const isCopy = !!copyId

  const { values, isLoading: isLoadingValues } = useValues({
    identifier,
    isCopy,
  })

  const { data, meta } = values

  const isLoading = isLoadingValues

  const handleSubmit = async (submittedValues, { resetForm }) => {
    try {
      const submitter = !!id ? updateDilutionScenario : addDilutionScenario
      const withMeta = !!id ? meta : {}
      await submitter({
        body: {
          data: JSON.stringify(submittedValues),
          ...withMeta,
        },
      })
      resetForm()
      push(`/dilution/${projectId}`)
    } catch (error) {
      console.error(error)
    }
  }

  const form = {
    initialValues: data,
    validateOnMount: true,
    validateOnChange: true,
    isInitialValid: !!identifier,
    onSubmit: handleSubmit,
  }

  const shared = { meta, id, isCopy }

  return (
    <Drawer>
      {isLoading ? (
        <>
          <Box
            width={1}
            height={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <CoreWizard steps={steps} initialStep={0} form={form} shared={shared}>
          <Drawer.Container>
            <Drawer.Header>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6">{t('common:scenario')}</Typography>
                </Box>
                <Box>
                  <Tooltip title={t('common:exitScenario')}>
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="Close"
                      onClick={() => push(`/dilution/${projectId}`)}
                    >
                      <CloseIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              </Box>
              <WizardHeader boxProps={{ ...fullWidthProps }} />
            </Drawer.Header>
            <Drawer.Content>
              <WizardContent boxProps={{ ...fullWidthProps }} />
            </Drawer.Content>
            <Drawer.Footer>
              <WizardFooter
                boxProps={{ ...fullWidthProps }}
                labels={{
                  submitLabel: !!id ? t('common:update') : t('common:create'),
                  backLabel: t('common:back', { prefix: '«' }),
                  nextLabel: t('common:next', { suffix: '»' }),
                }}
              />
            </Drawer.Footer>
          </Drawer.Container>
        </CoreWizard>
      )}
    </Drawer>
  )
})

export default Wizard
