export const spacing = {
  small: 16,
  medium: 20,
  large: 30,
};

export const calc = {
  contentWidth: '50vw',
  contentHeight: 'calc(100vh - 80px)',
};

export const drawerWidth = '50%';
