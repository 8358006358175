import { directionRow, justifySpaceBetween } from 'styles/mixins'

export default () => ({
  root: {
    ...directionRow,
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px',
  },
  content: {
    ...directionRow,
    overflowX: 'hidden',
  },
  header: {
    ...justifySpaceBetween,
    padding: '1rem 0rem',
  },
  fab: { margin: '20px' },
  layers: {
    padding: '0rem 1.6rem',
  },
})
