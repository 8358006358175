import React from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import loaderState from 'states/loader'
import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const Loader = ({ classes }) => {
  const { isLoading, isMajorLoading } = useRecoilValue(loaderState)

  if (!isLoading) return null

  return (
    <>
      {isMajorLoading ? (
        <div className={classes.majorLoading}>
          <CircularProgress color="inherit" size={50} thickness={5} />
        </div>
      ) : (
        <div className={classes.minorLoading}>
          <LinearProgress color="primary" />
        </div>
      )}
    </>
  )
}

export default enhancer(Loader)
