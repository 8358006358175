import React from 'react'
import { compose } from 'recompose'
import { Box, Typography, Container, withStyles } from '@material-ui/core'

import Footer from 'components/Footer'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const Article = ({ headline, children, classes, withFooter = true }) => (
  <div className={classes.content}>
    <Container maxWidth="lg">
      <Box p={20}>
        <Box pb={4}>
          <Typography variant="h2">{headline}</Typography>
        </Box>
        <Typography variant="h5">{children}</Typography>
      </Box>
    </Container>
    {withFooter && <Footer />}
  </div>
)

export default enhancer(Article)
