import { useMemo, useEffect } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { ScatterplotLayer } from '@deck.gl/layers'

import mapSourceState from 'containers/Dilution/states/mapSource'

import CirclesLayer from 'layers/CirclesLayer'

const _geojson = (mapSource, id) => {
  return new ScatterplotLayer({
    id: `scatterPlot ${id}`,
    pickable: true,
    data: mapSource,
    getFillColor: (d) => (d.include ? [13, 57, 88] : [128, 128, 128]),
    stroked: true,
    filled: true,
    radiusMinPixels: 5,
    radiusMaxPixels: 5,
    getPosition: (d) => [d.lon, d.lat],
  })
}

const useLayers = ({ id }) => {
  const mapSource = useRecoilValue(mapSourceState)
  const resetSource = useResetRecoilState(mapSourceState)
  const layers = useMemo(() => {
    return [
      new CirclesLayer({
        id: `circlesLayer ${id}`,
        data: mapSource.filter((item) => item.type === 'hypothetical'),
      }),
      _geojson(mapSource, id),
    ]
  }, [mapSource, id])

  useEffect(() => {
    return () => {
      resetSource()
    }
  }, [])

  return layers
}

export default useLayers
