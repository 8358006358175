import { useEffect, useState } from 'react'
import { fetchAccounts } from 'data/accounts'

const useUsers = () => {
  const [users, setUsers] = useState([])
  const getUsers = async () => {
    try {
      const usersToSet = await fetchAccounts()
      setUsers(usersToSet)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  return users
}

export default useUsers
