export default () => ({
  error: {
    color: 'red',
  },
  label: {
    display: 'flex',
    width: '300px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    padding: '.8rem 1.5rem',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: '.25s',
  },
  labelHidden: { visibility: 'hidden', position: 'absolute' },
  icon: {
    borderRadius: '50%',
    backgroundColor: '#648dae',
    color: 'white',
    padding: '.5rem',
    marginLeft: '.5rem',
  },
})
