const category = 'Ambient'
const format = 'image/png'
const layers = 'dfsu-map'
const timestamp = '2004-07-01T00:00:00'
const type = 'wms-dhi'
const version = '1.3.0'
const styles = 'amp1'
const itemNo = 1

export const sharedConfig = {
  category,
  format,
  itemNo,
  layers,
  styles,
  timestamp,
  type,
  version,
}

export const config = {
  bedShear: {
    connection: 'dfsu-shear',
    filePath: 'DK_July_shear_stress_mean.dfsu',
    item: 'Mean_bed_shear_stress',
    label: 'Bed shear stress (July mean)',
    unit: 'Pa',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
  },
  bedShear2mo: {
    connection: 'dfsu-shear-2mo',
    filePath: 'DK_June_July_shear_stress_mean.dfsu',
    item: 'Mean_bed_shear_stress',
    label: 'Bed shear stress (June-July mean)',
    unit: 'Pa',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
    timestamp: '2004-06-01T00:00:00',
  },
  botTemp: {
    connection: 'dfsu-bottemp',
    filePath: 'DK_seabed_July_Temp_mean.dfsu',
    item: 'Statistical mean : Temp',
    label: 'Bottom water temperature (July mean)',
    unit: '°C',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
  },
  botTemp2mo: {
    connection: 'dfsu-bottemp-2mo',
    filePath: 'DK_seabed_June_July_Temp_mean.dfsu',
    item: 'Statistical mean : Temp',
    label: 'Bottom water temperature (June-July mean)',
    unit: '°C',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
    timestamp: '2004-06-01T00:00:00',
  },
  botCur: {
    connection: 'dfsu-botcur',
    filePath: 'DK_seabed_July_current_mean.dfsu',
    item: 'Statistical mean : Current speed',
    label: 'Bottom current speed (July mean)',
    unit: 'm/s',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
  },
  surfTemp: {
    connection: 'dfsu-surftemp',
    filePath: 'DK_surface_July_Temp_mean.dfsu',
    item: 'Statistical mean : Temperature',
    label: 'Surface water temperature (July mean)',
    unit: '°C',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
  },
  surfCur: {
    connection: 'dfsu-surfcur',
    filePath: 'DK_surface_July_current_mean.dfsu',
    item: 'Statistical mean : Current speed',
    label: 'Surface current speed (July mean)',
    unit: 'm/s',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
  },
  dayLength: {
    connection: 'dfsu-daylength',
    filePath: 'DK_July_Daylength_mean.dfsu',
    item: 'DK July mean Daylength ',
    label: 'Day length (July mean)',
    unit: 'hours',
    isLayer: true,
    style: 'amp1',
    ...sharedConfig,
    timestamp: '2004-06-01T00:00:00',
  },
}
