export default ({ lastJobStatus: status, lastJobProgress: progress }) => {
  const messages = {
    ReadyToInitiate: {
      color: 'red',
      message: 'Ready',
    },
    Pending: {
      color: 'orange',
      message: 'Pending',
      progress: 0,
    },
    InProgress: {
      color: 'orange',
      message: 'Running',
      progress,
    },
    Completed: {
      color: 'green',
      message: 'Completed',
    },
    Error: {
      color: 'black',
      message: 'Error',
    },
  };

  const defaultMessage = {
    color: 'red',
    message: 'Unknown',
  };

  const message = messages[status] || defaultMessage;

  return message;
};
