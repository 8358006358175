// TODO: This looks like a spaceship. Let's get rid of it :-)

import { getObjectProperty } from "data/utils";

export default (action, descriptionFields) => {
  const descriptionArray = [];
  for (let i = 0; i < descriptionFields.length; i++) {
    const descriptionObject = {};
    if (descriptionFields[i].condition) {
      if (typeof descriptionFields[i].condition === "object") {
        const condtion = getObjectProperty(
          action,
          descriptionFields[i].condition.field,
          descriptionFields[i].condition.value
        );
        if (condtion) {
          descriptionObject.name = descriptionFields[i].name;
          descriptionObject.value = getObjectProperty(
            action,
            descriptionFields[i].field
          );
          descriptionArray.push(descriptionObject);
          continue;
        }
      }
      if (
        getObjectProperty(
          action,
          descriptionFields[i].field,
          descriptionFields[i].condition
        )
      ) {
        descriptionObject.name = descriptionFields[i].name;
        descriptionObject.value = getObjectProperty(
          action,
          descriptionFields[i].field
        );
        descriptionArray.push(descriptionObject);
        continue;
      }
    } else {
      descriptionObject.name = descriptionFields[i].name;
      descriptionObject.value = getObjectProperty(
        action,
        descriptionFields[i].field
      );
      descriptionArray.push(descriptionObject);
    }
  }
  return descriptionArray;
};
