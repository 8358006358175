import React from 'react'
import { useHistory } from 'react-router-dom'
// import { deleteScenario } from 'data/common'
// import PlayIcon from '@material-ui/icons/PlayArrow'
import VisibilityIcon from '@material-ui/icons/Visibility'
// import EditIcon from '@material-ui/icons/Edit'
// import CopyIcon from '@material-ui/icons/FileCopy'
// import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
// import { useConfirm } from 'material-ui-confirm'

const Actions = ({
  id,
  data,
  dateTime,
  lastJobStatus,
  version,
  handleGetScenarios,
  handleExecuteScenario,
  projectId,
  path,
  host,
}) => {
  //   const confirm = useConfirm()
  // const isCompleted = lastJobStatus === 'Completed'
  // const handleExecution = async () => {
  //     try {
  //         await confirm({
  //             title: 'Execute',
  //             description: 'Yes, I want to execute the scenario.',
  //         })
  //         const { definition } = data
  //         const {
  //             model: { id: modelId },
  //         } = definition
  //         await handleExecuteScenario({
  //             job: {
  //                 scenarioId: id,
  //                 modelId,
  //             },
  //             scenario: {
  //                 id,
  //                 data,
  //                 dateTime,
  //                 lastJobStatus,
  //                 version,
  //             },
  //         })
  //         await handleGetScenarios()
  //     } catch (error) { }
  // }

  // const handleDelete = async () => {
  //     try {
  //         await confirm({
  //             title: 'Delete',
  //             description: 'Yes, I want to delete the scenario.',
  //         })
  //         await deleteScenario({ id, host: host })
  //         await handleGetScenarios()
  //     } catch (error) { }
  // }

  const { push } = useHistory()
  return (
    <>
      {/* <Tooltip title="Execute">
                <IconButton
                    size="small"
                    color="primary"
                    aria-label="exexute"
                    onClick={handleExecution}
                >
                    <PlayIcon />
                </IconButton>
            </Tooltip> */}
      <Tooltip title="View">
        <div>
          <IconButton
            size="small"
            color="primary"
            aria-label="view"
            // disabled={!isCompleted}
            onClick={() =>
              push(`/${path}/view/${id}`, {
                projectId,
              })
            }
          >
            <VisibilityIcon />
          </IconButton>
        </div>
      </Tooltip>
      {/* <Tooltip title="Delete">
                <IconButton
                    size="small"
                    color="primary"
                    aria-label="delete"
                    onClick={handleDelete}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip> */}
    </>
  )
}

export default Actions
