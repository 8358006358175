import React from 'react'
import { compose } from 'recompose'
import { Typography } from '@material-ui/core'
import { CloudUpload as UploadIcon } from '@material-ui/icons'
import ReplayIcon from '@material-ui/icons/Replay'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core'

import styles from './styles'

const enhancer = compose(withStyles(styles, { withTheme: true }))

const UploadFile = ({ classes, disabled = false, onChange, value, name }) => {
  const { t } = useTranslation()
  const handleChange = ({ target }) => {
    const { files } = target
    const [file] = files
    onChange(file)
  }

  return (
    <>
      <label htmlFor={name} className={classNames(classes.label)}>
        <Typography variant="h6" className={classes.fileName}>
          {(value && value.name) || t('common:uploadFile')}
        </Typography>
        <input
          autoComplete="off"
          type="file"
          onChange={handleChange}
          id={name}
          name={name}
          className={classes.labelHidden}
          disabled={disabled}
        />
        {value ? (
          <ReplayIcon fontSize="large" className={classes.icon} />
        ) : (
          <UploadIcon fontSize="large" className={classes.icon} />
        )}
      </label>
    </>
  )
}

export default enhancer(UploadFile)
