import React, { useState } from 'react';
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const ImpactTable = ({ classes, indicators, coords }) => {
  const [No, setNo] = useState(0);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <h4>Impact Table</h4>

        <FormControl fullWidth variant="outlined">
          <Select
            value={No}
            onChange={e => {
              const val = e.target.value;
              setNo(val);
            }}
            input={<OutlinedInput labelWidth={0} />}
          >
            {coords.lat.map((dum, index) => (
              <MenuItem key={index} value={index}>
                {'Site Location #' + (index + 1).toString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Centre footprint</TableCell>
              <TableCell align="right">Cent-to-500m</TableCell>
              <TableCell align="right">500m-to-1000m</TableCell>
              <TableCell align="right">1km-to-10km</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indicators.map((indicator, index) => (
              <TableRow key={indicator.id}>
                <TableCell component="th" scope="row">
                  {indicator.label + ' (' + indicator.unit + ')'}
                </TableCell>

                {index < 5 && (
                  <>
                    <TableCell
                      style={{
                        backgroundColor:
                          indicator.assessmentArea[0].colorCode[No],
                      }}
                      align="right"
                    >
                      {indicator.assessmentArea[0].result[No]}
                    </TableCell>
                    <TableCell align="right">{null}</TableCell>
                    <TableCell align="right">{null}</TableCell>
                    <TableCell align="right">{null}</TableCell>
                  </>
                )}

                {index >= 5 && index < 7 && (
                  <>
                    <TableCell align="right">{null}</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          indicator.assessmentArea[0].colorCode[No],
                      }}
                      align="right"
                    >
                      {indicator.assessmentArea[0].result[No]}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          indicator.assessmentArea[1].colorCode[No],
                      }}
                      align="right"
                    >
                      {indicator.assessmentArea[1].result[No]}
                    </TableCell>
                    <TableCell align="right">{null}</TableCell>
                  </>
                )}

                {index >= 7 && (
                  <>
                    <TableCell align="right">{null}</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          indicator.assessmentArea[0].colorCode[No],
                      }}
                      align="right"
                    >
                      {indicator.assessmentArea[0].result[No]}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          indicator.assessmentArea[1].colorCode[No],
                      }}
                      align="right"
                    >
                      {indicator.assessmentArea[1].result[No]}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          indicator.assessmentArea[2].colorCode[No],
                      }}
                      align="right"
                    >
                      {indicator.assessmentArea[2].result[No]}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ImpactTable);
