import { object, string } from 'yup'

import Step4 from './Step4'

const thresholdsSchema = {
  minor: string(),
  medium: string(),
  high: string(),
  veryHigh: string(),
}

const thresholdsTest = ({ minor, medium, high, veryHigh }) => {
  const isThresholdValid = threshold => threshold.length > 0
  const isMinorValid = isThresholdValid(minor)
  const isMediumValid = isThresholdValid(medium)
  const isHighValid = isThresholdValid(high)
  const isVeryHighValid = isThresholdValid(veryHigh)
  return isMinorValid && isMediumValid && isHighValid && isVeryHighValid
}

export default {
  title: 'Thresholds',
  component: Step4,
  validationSchema: () =>
    object().shape({
      thresholds: object().shape({
        soc: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No SOC thresholds added.', thresholdsTest),
        son: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No SON thresholds added.', thresholdsTest),
        sop: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No SOP thresholds added.', thresholdsTest),
        h2sSed: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No H2S-sed thresholds added.', thresholdsTest),
        inFlux: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No IN-flux thresholds added.', thresholdsTest),
        doReduc: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No DO-reduc thresholds added.', thresholdsTest),
        nh4Rel: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No NH4-rel thresholds added.', thresholdsTest),
        pp: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No PP thresholds added.', thresholdsTest),
        chl: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No CHL thresholds added.', thresholdsTest),
        sd: object()
          .shape({ ...thresholdsSchema })
          .test('thresholds', 'No SD thresholds added.', thresholdsTest),
      }),
    }),
}
