import { atom, selector } from 'recoil'

export const imagesReport = atom({
  key: 'imagesReport',
  default: {},
})

export const readyToPrintImages = selector({
  key: 'readyToPrintImages',
  get: ({ get }) =>
    Object.values(get(imagesReport)).filter(({ basemap }) => !!basemap),
})

export default imagesReport
